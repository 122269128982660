import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function DeleteIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Delete-a,.Delete-b{fill:none;}.Delete-b{stroke:currentColor;stroke-linecap:round;stroke-width:2.5px;}.Delete-c{stroke:none;}`}</style>
      </defs>
      <g transform="translate(-2908 -1573)">
        <rect
          className="Delete-a"
          width="30"
          height="30"
          transform="translate(2908 1573)"
        />
        <g transform="translate(2914 1575.761)">
          <g className="Delete-b" transform="translate(1.144 5.239)">
            <path
              className="Delete-c"
              d="M1,0H14a1,1,0,0,1,1,1V17a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V1A1,1,0,0,1,1,0Z"
            />
            <path
              className="Delete-a"
              d="M1.5,1.25h12a.25.25,0,0,1,.25.25V17a.75.75,0,0,1-.75.75H2A.75.75,0,0,1,1.25,17V1.5A.25.25,0,0,1,1.5,1.25Z"
            />
          </g>
          <line
            className="Delete-b"
            x2="17.367"
            transform="translate(0 5.688)"
          />
          <g transform="translate(5.079 8.409)">
            <line
              className="Delete-a"
              y2="6.486"
              transform="translate(3.216)"
            />
            <line className="Delete-a" y2="6.486" />
            <line
              className="Delete-a"
              y2="6.486"
              transform="translate(6.433)"
            />
          </g>
          <g className="Delete-b" transform="translate(4.144 0.239)">
            <path
              className="Delete-c"
              d="M2,0H7A2,2,0,0,1,9,2V7A0,0,0,0,1,9,7H0A0,0,0,0,1,0,7V2A2,2,0,0,1,2,0Z"
            />
            <path
              className="Delete-a"
              d="M2,1.25H7A.75.75,0,0,1,7.75,2V4.5A1.25,1.25,0,0,1,6.5,5.75h-4A1.25,1.25,0,0,1,1.25,4.5V2A.75.75,0,0,1,2,1.25Z"
            />
          </g>
          <line
            className="Delete-b"
            y2="7.894"
            transform="translate(6.315 10.425)"
          />
          <line
            className="Delete-b"
            y2="7.894"
            transform="translate(11.051 10.425)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default DeleteIcon;
