import { Checkbox, IconButton, MenuItem, TableCell } from '@material-ui/core';
import { CARD_LABELS } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from 'theme';
import Menu from '../elements/Menu';
import Opener from '../elements/Opener';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import FilterIcon from '../icons/FilterIcon';
import GearBoldIcon from '../icons/GearBoldIcon';
import { TableValue } from './types';

const Wrapper = styled(TableCell)<{ small?: boolean }>`
  color: var(--color-dark-grey);
  font-size: 17px;
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  background-color: ${(p) =>
    p.small ? 'var(--color-bg)' : 'var(--color-white)'};

  div {
    display: flex;
    align-items: center;
  }

  ${(p) =>
    p.small &&
    `
    padding: 10px 16px;
  `}

  .on-hover {
    transition: opacity 0.3s;
    opacity: 0;
    color: var(--color-light-grey);
  }

  &:hover .on-hover {
    opacity: 1;
  }

  .active {
    color: var(--color-primary);
  }
`;

const SortArrow = styled(ArrowUpIcon)<{ reverse?: boolean }>`
  vertical-align: middle;
  transform: ${(p) => (p.reverse ? 'rotate(180deg)' : 'none')};
  transition: transform 0.3s;
`;

const IconWrapper = styled(IconButton)`
  padding: 2px;
  margin-left: 5px;

  svg {
    font-size: 15px;
  }
`;

interface HeadingCellProps {
  ordering: string | null;
  filters: Record<string, string[]>;
  filterValues?: Record<string, string[]>;
  renderValue?: React.ComponentType<{
    value: string;
    type: string;
    valueKey: string;
    color?: string;
  }>;
  value: TableValue;
  setOrdering: (value: string | null) => void;
  setFilters: (value: Record<string, string[]>) => void;
  small?: boolean;
  disableFilters?: boolean;
}

function HeadingCell({
  value,
  ordering,
  filters,
  setOrdering,
  setFilters,
  small,
  renderValue,
  disableFilters,
  filterValues,
}: HeadingCellProps) {
  const rev = `-${value.key}`;
  const isSorted = ordering === value.key || ordering === rev;
  const isFiltered = filters[value.key] && filters[value.key].length !== 0;
  const { t } = useTranslation();

  function toggleSort() {
    if (ordering === value.key) {
      setOrdering(rev);
    } else if (ordering === rev) {
      setOrdering(null);
    } else {
      setOrdering(value.key);
    }
  }

  function handleNameClick() {
    if (!disableFilters && value.sortable) {
      return toggleSort();
    }

    if (value.settings) {
      return value.settings();
    }
  }

  const availableValues = filterValues ? filterValues[value.key] : [];
  const filteredValues = filters ? filters[value.key] || [] : [];

  const ValueDisplay = renderValue;

  return (
    <Wrapper key={`thead-${value.key}`} small={small}>
      <div>
        <span onClick={handleNameClick}>{value.name}</span>
        {!disableFilters && value.filterable && availableValues !== undefined && (
          <Opener
            content={({ anchorEl, close }) => (
              <Menu
                open={Boolean(anchorEl)}
                onClose={close}
                anchorEl={anchorEl}
              >
                <MenuItem
                  onClick={() => {
                    setFilters({ ...filters, [value.key]: [] });
                    close();
                  }}
                >
                  <Checkbox checked={filteredValues.length === 0} />
                  {t('table.filters.all')}
                </MenuItem>
                {availableValues.map((v) => {
                  const active = filteredValues.includes(v);

                  return (
                    <MenuItem
                      style={{ paddingRight: '30px' }}
                      onClick={() => {
                        setFilters({
                          ...filters,
                          [value.key]: active
                            ? (filters[value.key] || []).filter((c) => c !== v)
                            : [...(filters[value.key] || []), v],
                        });
                      }}
                    >
                      <Checkbox checked={active} />
                      {ValueDisplay && (
                        <ValueDisplay
                          value={v}
                          type={value.type}
                          valueKey={value.key}
                          color={
                            value.type === 'choice' ||
                            value.type === 'multiple-choice'
                              ? (value.options.colors || []).concat([
                                  CARD_LABELS.blue,
                                  CARD_LABELS.red,
                                ])[value.options.choices.indexOf(v)] ||
                                COLORS.primary
                              : undefined
                          }
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          >
            <IconWrapper>
              {isFiltered ? (
                <FilterIcon style={{ fontSize: '17px' }} className="active" />
              ) : (
                <FilterIcon style={{ fontSize: '17px' }} className="on-hover" />
              )}
            </IconWrapper>
          </Opener>
        )}

        {!disableFilters && value.sortable && (
          <IconWrapper onClick={toggleSort}>
            {isSorted ? (
              <SortArrow className="active" reverse={ordering === rev} />
            ) : (
              <SortArrow className="on-hover" />
            )}
          </IconWrapper>
        )}

        {value.settings && (
          <IconWrapper onClick={value.settings}>
            <GearBoldIcon className="on-hover" />
          </IconWrapper>
        )}
      </div>
    </Wrapper>
  );
}

export default HeadingCell;
