import { Box } from '@material-ui/core';
import Progress from 'components/utils/Progress';
import { Emoji } from 'emoji-mart';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import retry from 'utils/retry';
import RoundedButton from '../elements/RoundedButton';
import CenteredContent from '../layouts/CenteredContent';

const VideoPlayer = React.lazy(() =>
  retry(() => import('components/VideoPlayer')),
);

interface VideoOnBoardingProps {}

function VideoOnBoarding(props: VideoOnBoardingProps) {
  const { uiStore, profileStore } = useStore();
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace();

  const videoUrl = workspace.init_config
    ? workspace.init_config.video_url
    : t('onboarding.video.url');

  if (!videoUrl) {
    profileStore.reset();
    profileStore.init();
    uiStore.popSide();
  }

  return (
    <Suspense fallback={<Progress />}>
      <CenteredContent width="var(--md-center-width)">
        <Box textAlign="center" mb={3}>
          <Emoji size={64} emoji={t('onboarding.video.emoji')} set="apple" />
        </Box>
        <Box mt={4} mb={4}>
          <VideoPlayer
            src={videoUrl || ''}
            thumbnail={t('onboarding.video.thumbnail')}
          />
        </Box>
        <Box textAlign="center">
          <RoundedButton
            color="primary"
            variant="contained"
            onClick={() => {
              profileStore.reset();
              profileStore.init();
              uiStore.popSide();
            }}
          >
            {t('onboarding.video.submit')}
          </RoundedButton>
        </Box>
      </CenteredContent>
    </Suspense>
  );
}

export default VideoOnBoarding;
