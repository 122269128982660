import { Close } from '@material-ui/icons';
import InviteMemberModal from 'modals/workspaces/InviteMember';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import stopPropagation from 'utils/stopPropagation';
import AddPeopleIcon from '../icons/AddPeopleIcon';
import FlexSeparator from '../utils/FlexSeparator';

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  align-items: center;
  min-width: 300px;
  background-color: var(--color-primary);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: var(--color-white);
  padding: 8px 15px;
  padding-top: 10px;
  z-index: 8;
  cursor: pointer;

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

interface InviteButtonProps {}

function InviteButton(props: InviteButtonProps) {
  const { workspaceStore, uiStore } = useStore();
  const { t } = useTranslation();

  return (
    <Wrapper onClick={() => uiStore.pushSide(<InviteMemberModal />)}>
      <AddPeopleIcon />
      <span>{t('workspaces.invite_button')}</span>
      <FlexSeparator />
      <Close
        style={{ fontSize: '20px' }}
        onClick={(e) => {
          workspaceStore.setPreventInvite();
          return stopPropagation(e);
        }}
      />
    </Wrapper>
  );
}

export default InviteButton;
