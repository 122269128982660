import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function NextIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Next-a,.Next-b{fill:none;}.Next-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.55px;}`}</style>
      </defs>
      <g transform="translate(-3019 -1564)">
        <rect
          className="Next-a"
          width="30"
          height="30"
          transform="translate(3019 1564)"
        />
        <g transform="translate(0 -0.525)">
          <line
            className="Next-b"
            x2="8.525"
            y2="8.525"
            transform="translate(3038.525 1579.05) rotate(90)"
          />
          <line
            className="Next-b"
            x1="8.525"
            y2="8.525"
            transform="translate(3038.525 1570.525) rotate(90)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default NextIcon;
