import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function PlayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Play-a{fill:none;}.Play-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2829 -1564)">
        <rect
          className="Play-a"
          width="30"
          height="30"
          transform="translate(2829 1564)"
        />
        <g transform="translate(2773.946 1557.1)">
          <path
            className="Play-b"
            d="M68.829,33.886A12.111,12.111,0,0,1,58.19,23.7a11.7,11.7,0,0,1,.477-5.534,12.025,12.025,0,0,1,14.642-7.81,12.023,12.023,0,0,1-4.481,23.53Zm3.533-3.067a9.35,9.35,0,0,0,6.209-5.464,9.533,9.533,0,0,0-.4-7.728,9.177,9.177,0,0,0-16.946,1.8,6.585,6.585,0,0,0-.282,2.507,6.585,6.585,0,0,0,.282,2.507,9.166,9.166,0,0,0,7.6,6.6,11.078,11.078,0,0,0,3.529-.214ZM67.55,25.965a1.436,1.436,0,0,1-.931-1.165c-.036-.246-.051-1.87-.033-3.608.037-3.484.052-3.572.691-3.966a1.007,1.007,0,0,1,.746-.166c.331.047,5.5,2.839,6.188,3.342a1.349,1.349,0,0,1,.574,1.181.991.991,0,0,1-.29.819A62.407,62.407,0,0,1,68.144,26a1.641,1.641,0,0,1-.594-.037Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default PlayIcon;
