import { createMuiTheme, Theme } from '@material-ui/core';
import { MINIMUM_WIDTH } from 'config';
import { setLightness, transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

export const COLORS = {
  primary: '#5824FB',
  secondary: '#1D0051',
  success: '#25CE85',
  warning: '#E8B040',
  error: '#EF425E',
  white: '#FFFFFF',
  black: '#000000',
  green: '#4cdfd4',
  darkGrey: '#5B5B5B',
  lightGrey: '#AFAFAF',
  lighterGrey: '#f9f9f9',
  placeholderGrey: '#b7b7b7',
  placeholderDarkGrey: '#cccccc',
  separatorGrey: '#dcdcdc',
  bgGrey: '#EFEFEF',
  bg: '#FAFAFA',
  hoverBg: '#FBFBFB',
  borderGrey: '#DEDEDE',
  primaryHover: setLightness(0.94, '#5D00FF'),
  primaryLightHover: setLightness(0.98, '#5D00FF'),
  primaryBg: '#f5eeff',
  errorHover: setLightness(0.98, '#EF425E'),
  successHover: setLightness(0.94, '#25CE85'),
};

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    
  }
  
  body {
    min-width: ${MINIMUM_WIDTH}px;
    overflow: auto;
  }

  :root {
    /* Colors */
    --color-primary: ${COLORS.primary};
    --color-primary-bg: ${COLORS.primaryBg};
    --color-secondary: ${COLORS.secondary};
    --color-success: ${COLORS.success};
    --color-warning: ${COLORS.warning};
    --color-error: ${COLORS.error};
    --color-white: ${COLORS.white};
    --color-black: ${COLORS.black};
    --color-light-grey: ${COLORS.lightGrey};
    --color-lighter-grey: ${COLORS.lighterGrey};
    --color-dark-grey: ${COLORS.darkGrey};
    --color-border-grey: ${COLORS.borderGrey};
    --color-placeholder-grey: ${COLORS.placeholderGrey};
    --color-placeholder-dark-grey: ${COLORS.placeholderDarkGrey};
    --color-bg-grey: ${COLORS.bgGrey};
    --color-separator-grey: ${COLORS.separatorGrey};
    --color-primary-hover: ${COLORS.primaryHover};
    --color-primary-light-hover: ${COLORS.primaryLightHover};
    --color-bg: ${COLORS.bg};
    --color-hover-bg: ${COLORS.hoverBg};
    --color-error-hover: ${COLORS.errorHover};
    --color-success-hover: ${COLORS.successHover};
    --color-green: ${COLORS.green};

    /* Sizing */
    --sidebar-width: 75px;
    --topbar-height: 55px;
    --sm-center-width: 500px;
    --center-width: 600px;
    --md-center-width: 800px;
    --lg-center-width: 1000px;
    --xl-center-width: 1300px;
    --home-center-width: 900px;

    /* Shadows */
    --shadow-md: 0 1px 9px rgba(0, 0, 0, 0.08), 0 1px 9px rgba(0, 0, 0, 0.17);
    --shadow-sm: 0 1px 6px rgba(0, 0, 0, 0.06), 0 1px 6px rgba(0, 0, 0, 0.13);
    --shadow-xs: 0 1px 3px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.09);
  }

  #nprogress .bar {
    background-color: ${COLORS.secondary}!important;
  }

  #nprogress .spinner-icon {
    border-top-color: ${COLORS.secondary}!important;
    border-left-color: ${COLORS.secondary}!important;
  }

  .horizontal-scroll:hover::-webkit-scrollbar-track {
    box-shadow: none;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll::-webkit-scrollbar {
    height: 14px;
  }

  .horizontal-scroll::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 14px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll::-webkit-scrollbar-thumb {
    border-radius: 14px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll:hover::-webkit-scrollbar-track {
    box-shadow:none;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll::-webkit-scrollbar {
    width: 14px;
  }

  .vertical-scroll::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 14px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll::-webkit-scrollbar-thumb {
    border-radius: 14px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll-bg:hover::-webkit-scrollbar-track {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.20) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll-bg::-webkit-scrollbar {
    height: 14px;
  }

  .horizontal-scroll-bg::-webkit-scrollbar-track {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.20) inset;
    border-radius: 14px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll-bg::-webkit-scrollbar-thumb {
    border-radius: 14px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll-bg:hover::-webkit-scrollbar-track {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.20) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll-bg::-webkit-scrollbar {
    width: 14px;
  }

  .vertical-scroll-bg::-webkit-scrollbar-track {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.20) inset;
    border-radius: 14px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .vertical-scroll-bg::-webkit-scrollbar-thumb {
    border-radius: 14px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5) inset;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .intercom-lightweight-app {
    z-index: 50!important;
  }

  .checkable-item {
    list-style: none;
    transform: translateX(-1.5em);
  }
`;

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    success: {
      main: COLORS.success,
    },
    error: {
      main: COLORS.error,
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: transparentize(0.2, COLORS.secondary),
        borderRadius: '14px',
        padding: '5px 12px',
        fontSize: '13px',
        whiteSpace: 'pre-line',
        boxShadow: 'var(--shadow-sm)',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: '8px',
      },
    },
  },
});
