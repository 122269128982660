import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import RoundedButton, { RoundedButtonProps } from './RoundedButton';

const Wrapper = styled.div`
  position: relative;
`;

const Loader = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

interface LoadingRoundedButtonProps extends RoundedButtonProps {
  loading?: boolean;
}

function LoadingRoundedButton({
  loading,
  children,
  ...props
}: LoadingRoundedButtonProps) {
  return (
    <Wrapper>
      <RoundedButton {...(props as any)} disabled={props.disabled || loading}>
        {children}
      </RoundedButton>
      {loading && <Loader size={24} />}
    </Wrapper>
  );
}

export default LoadingRoundedButton;
