import { ContentState, convertFromRaw } from 'draft-js';

function contentFromString(content: string) {
  try {
    return convertFromRaw(JSON.parse(content));
  } catch (e) {
    return ContentState.createFromText('');
  }
}

export default contentFromString;
