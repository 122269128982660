import Toast from 'components/Toasts/Toast';
import { action, computed, observable, runInAction } from 'mobx';
import React, { ReactElement } from 'react';

export class UIStore {
  @observable.shallow
  public sideStack: ReactElement[] = [];

  @observable.shallow
  public popupStack: ReactElement[] = [];

  @observable.shallow
  public toastStack: ReactElement[] = [];

  @observable
  public preventPop: boolean = false;

  @computed
  get currentSide() {
    if (this.sideStack.length > 0) {
      return this.sideStack[this.sideStack.length - 1];
    }
    return null;
  }

  @computed
  get currentPopup() {
    if (this.popupStack.length > 0) {
      return this.popupStack[this.popupStack.length - 1];
    }
    return null;
  }

  @action
  public pushSide(element: ReactElement, preventPop: boolean = false) {
    this.sideStack.push(element);
    this.preventPop = preventPop;
  }

  @action
  public pushPopup(element: ReactElement) {
    this.popupStack.push(element);
  }

  @action.bound
  public popPopup() {
    this.popupStack.pop();
  }

  @action.bound
  public popSide(force: boolean = true) {
    if (this.preventPop) {
      if (force) {
        this.preventPop = false;
      } else {
        return;
      }
    }
    this.sideStack.pop();
  }

  @action
  public addToast(element: ReactElement, duration: number = 4000) {
    this.toastStack.push(element);
    return setTimeout(() => {
      runInAction(() => {
        const index = this.toastStack.indexOf(element);
        if (index !== -1) {
          this.toastStack.splice(index, 1);
        }
      });
    }, duration);
  }

  @action
  public toast(emoji: string, text: string, duration?: number) {
    this.addToast(<Toast emoji={emoji}>{text}</Toast>, duration);
  }

  public reset() {
    this.popupStack = [];
    this.sideStack = [];
    this.toastStack = [];
    this.preventPop = false;
  }
}
