import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { TemplateSchema } from 'api/generated/models';
import BackLink from 'components/elements/BackLink';
import BloomupEmoji from 'components/elements/BloomupEmoji';
import GreySubTitle from 'components/elements/GreySubTitle';
import InfoButton from 'components/elements/InfoButton';
import LoadingRoundedButton from 'components/elements/LoadingRoundedButton';
import RoundedTextField from 'components/elements/RoundedTextField';
import Title from 'components/elements/Title';
import ProjectBlankIcon from 'components/icons/ProjectBlankIcon';
import ProjectsIcon from 'components/icons/ProjectsIcon';
import ProjectTemplateIcon from 'components/icons/ProjectTemplateIcon';
import CenteredContent from 'components/layouts/CenteredContent';
import paths from 'paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import useNavigate from 'utils/useNavigate';
import ProjectTemplatesList from '../project_templates/List';
import { ModalFooter, ModalTitle } from '../styles';

const Wrapper = styled.form``;

const PrivacyCaption = styled(Typography)`
  font-size: 0.8em;
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const ChoiceButton = styled.div`
  border-radius: 20px;
  box-shadow: var(--shadow-sm);
  background-color: var(--color-white);
  padding: 8px 20px;
  display: flex;
  align-items: center;
  color: var(--color-light-grey);
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background-color: var(--color-primary-hover);
    color: var(--color-primary);
  }

  svg {
    font-size: 20px;
    margin-right: 10px;
  }
`;

const TemplateName = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: 300;
  color: var(--color-black);
  margin-top: 5px;
  margin-bottom: 30px;

  &&& {
    > * {
      margin-right: 8px;
      vertical-align: middle;
    }
  }
`;

interface NewProjectModalProps {
  template?: TemplateSchema;
}

function NewProjectModal(props: NewProjectModalProps) {
  const { projectStore, uiStore } = useStore();
  const [name, setName] = useState('');
  const [_private, setPrivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noTemplate, setNoTemplate] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    const project = await projectStore.createProject({
      name,
      is_private: _private,
      template_id: props.template?.id,
    });
    if (!props.template?.id) {
      await project.createStep({
        name: t('projects.new.default_step_name'),
        order: 0,
      });
    }
    navigate(paths.projects.details(project));
    setLoading(false);
    while (uiStore.currentSide) {
      uiStore.popSide();
    }
    uiStore.toast(
      t('toasts.new_project.emoji'),
      t('toasts.new_project.content'),
    );
    return false;
  }

  if (!props.template?.id && !noTemplate) {
    return (
      <CenteredContent width="var(--sm-center-width)">
        <ModalTitle>
          <Title>
            <ProjectsIcon />
            {t('projects.new.title')}
          </Title>
        </ModalTitle>
        <Box>
          <ChoiceButton
            onClick={() => {
              uiStore.popSide();
              uiStore.pushSide(<ProjectTemplatesList />);
            }}
          >
            <ProjectTemplateIcon />
            {t('projects.new.from_template.title')}
            <InfoButton
              style={{ marginLeft: '5px' }}
              tooltip={t('projects.new.from_template.tooltip')}
            />
          </ChoiceButton>
        </Box>
        <Box mt={2}>
          <ChoiceButton onClick={() => setNoTemplate(true)}>
            <ProjectBlankIcon />
            {t('projects.new.blank.title')}
            <InfoButton
              style={{ marginLeft: '5px' }}
              tooltip={t('projects.new.blank.tooltip')}
            />
          </ChoiceButton>
        </Box>
        <ModalFooter>
          <BackLink popSide />
        </ModalFooter>
      </CenteredContent>
    );
  }

  const valid = name.length > 0;

  return (
    <CenteredContent width="var(--sm-center-width)">
      <Wrapper onSubmit={onSubmit}>
        <ModalTitle>
          <Title>
            <ProjectsIcon />
            {t('projects.new.title')}
          </Title>
        </ModalTitle>
        {props.template && (
          <>
            <GreySubTitle>{t('projects.new.template.title')}</GreySubTitle>
            <TemplateName>
              {props.template.label && (
                <BloomupEmoji emoji={props.template.label} size={25} />
              )}
              {props.template.name}
            </TemplateName>
          </>
        )}
        <Box mt={3} mb={4}>
          <RoundedTextField
            autoFocus
            margin="normal"
            fullWidth
            maxLength={80}
            placeholder={t('projects.new.name.placeholder')}
            label={t('projects.new.name.label')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>

        <GreySubTitle>{t('projects.new.privacy.title')}</GreySubTitle>
        <Box mt={1}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="private"
              name="private"
              value={String(_private)}
              onChange={(_, value) => setPrivate(value === 'true')}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label={
                  <>
                    {t('projects.new.privacy.private.title')}{' '}
                    <PrivacyCaption>
                      {t('projects.new.privacy.private.description')}
                    </PrivacyCaption>
                  </>
                }
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label={
                  <>
                    {t('projects.new.privacy.public.title')}{' '}
                    <PrivacyCaption>
                      {t('projects.new.privacy.public.description')}
                    </PrivacyCaption>
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Footer>
          <BackLink
            onClick={
              noTemplate
                ? () => setNoTemplate(false)
                : () => uiStore.pushSide(<ProjectTemplatesList />)
            }
          />
          <LoadingRoundedButton
            size="small"
            color="primary"
            loading={loading}
            disabled={!valid}
            type="submit"
            variant="contained"
          >
            {t('commons.next')}
          </LoadingRoundedButton>
        </Footer>
      </Wrapper>
    </CenteredContent>
  );
}

export default NewProjectModal;
