import paths from 'paths';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useStore } from 'stores';
import useNavigate from 'utils/useNavigate';

interface JoinPageProps {}

function JoinPage(props: JoinPageProps) {
  const { workspaceStore } = useStore();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const token = params.get('token');
  const workspaceID = params.get('workspaceID');

  useEffect(() => {
    async function f() {
      if (token && workspaceID) {
        try {
          await workspaceStore.join({
            workspace_id: +workspaceID,
            key: token,
          });
        } finally {
          workspaceStore.setCurrentWorkspaceID(+workspaceID);
          navigate(paths.projects.list);
          return;
        }
      }
      navigate(paths.workspaces.init);
    }
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default JoinPage;
