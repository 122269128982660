/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CardShareLinkData } from '../models';
// @ts-ignore
import { CardShareLinkSchema } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ProjectShareLinkCreateSchema } from '../models';
// @ts-ignore
import { ProjectShareLinkData } from '../models';
// @ts-ignore
import { ProjectShareLinkSchema } from '../models';
// @ts-ignore
import { StepShareLinkData } from '../models';
// @ts-ignore
import { StepShareLinkSchema } from '../models';
/**
 * SharingApi - axios parameter creator
 * @export
 */
export const SharingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Card Share Link
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardShareLink: async (cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling createCardShareLink.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling createCardShareLink.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createCardShareLink.');
            }
            const localVarPath = `/sharing/{project_id}/steps/{step_id}/cards/{card_id}/share`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Project Share Link
         * @param {number} projectId 
         * @param {ProjectShareLinkCreateSchema} projectShareLinkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectShareLink: async (projectId: number, projectShareLinkCreateSchema: ProjectShareLinkCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createProjectShareLink.');
            }
            // verify required parameter 'projectShareLinkCreateSchema' is not null or undefined
            if (projectShareLinkCreateSchema === null || projectShareLinkCreateSchema === undefined) {
                throw new RequiredError('projectShareLinkCreateSchema','Required parameter projectShareLinkCreateSchema was null or undefined when calling createProjectShareLink.');
            }
            const localVarPath = `/sharing/{project_id}/share`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof projectShareLinkCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(projectShareLinkCreateSchema !== undefined ? projectShareLinkCreateSchema : {}) : (projectShareLinkCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Step Share Link
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStepShareLink: async (stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling createStepShareLink.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createStepShareLink.');
            }
            const localVarPath = `/sharing/{project_id}/steps/{step_id}/share`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Card Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardShareLinkData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getCardShareLinkData.');
            }
            const localVarPath = `/sharing/cards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectShareLinkData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProjectShareLinkData.');
            }
            const localVarPath = `/sharing/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Step Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepShareLinkData: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getStepShareLinkData.');
            }
            const localVarPath = `/sharing/steps/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharingApi - functional programming interface
 * @export
 */
export const SharingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Card Share Link
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCardShareLink(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardShareLinkSchema>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).createCardShareLink(cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Project Share Link
         * @param {number} projectId 
         * @param {ProjectShareLinkCreateSchema} projectShareLinkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectShareLink(projectId: number, projectShareLinkCreateSchema: ProjectShareLinkCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectShareLinkSchema>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).createProjectShareLink(projectId, projectShareLinkCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Step Share Link
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStepShareLink(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepShareLinkSchema>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).createStepShareLink(stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Card Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardShareLinkData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardShareLinkData>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).getCardShareLinkData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Project Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectShareLinkData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectShareLinkData>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).getProjectShareLinkData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Step Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStepShareLinkData(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepShareLinkData>> {
            const localVarAxiosArgs = await SharingApiAxiosParamCreator(configuration).getStepShareLinkData(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SharingApi - factory interface
 * @export
 */
export const SharingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create Card Share Link
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCardShareLink(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<CardShareLinkSchema> {
            return SharingApiFp(configuration).createCardShareLink(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Project Share Link
         * @param {number} projectId 
         * @param {ProjectShareLinkCreateSchema} projectShareLinkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectShareLink(projectId: number, projectShareLinkCreateSchema: ProjectShareLinkCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProjectShareLinkSchema> {
            return SharingApiFp(configuration).createProjectShareLink(projectId, projectShareLinkCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Step Share Link
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStepShareLink(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<StepShareLinkSchema> {
            return SharingApiFp(configuration).createStepShareLink(stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Card Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardShareLinkData(id: string, options?: any): AxiosPromise<CardShareLinkData> {
            return SharingApiFp(configuration).getCardShareLinkData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectShareLinkData(id: string, options?: any): AxiosPromise<ProjectShareLinkData> {
            return SharingApiFp(configuration).getProjectShareLinkData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Step Share Link Data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStepShareLinkData(id: string, options?: any): AxiosPromise<StepShareLinkData> {
            return SharingApiFp(configuration).getStepShareLinkData(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharingApi - object-oriented interface
 * @export
 * @class SharingApi
 * @extends {BaseAPI}
 */
export class SharingApi extends BaseAPI {
    /**
     * 
     * @summary Create Card Share Link
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public createCardShareLink(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return SharingApiFp(this.configuration).createCardShareLink(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Project Share Link
     * @param {number} projectId 
     * @param {ProjectShareLinkCreateSchema} projectShareLinkCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public createProjectShareLink(projectId: number, projectShareLinkCreateSchema: ProjectShareLinkCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return SharingApiFp(this.configuration).createProjectShareLink(projectId, projectShareLinkCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Step Share Link
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public createStepShareLink(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return SharingApiFp(this.configuration).createStepShareLink(stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Card Share Link Data
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public getCardShareLinkData(id: string, options?: any) {
        return SharingApiFp(this.configuration).getCardShareLinkData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project Share Link Data
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public getProjectShareLinkData(id: string, options?: any) {
        return SharingApiFp(this.configuration).getProjectShareLinkData(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Step Share Link Data
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    public getStepShareLinkData(id: string, options?: any) {
        return SharingApiFp(this.configuration).getStepShareLinkData(id, options).then((request) => request(this.axios, this.basePath));
    }
}
