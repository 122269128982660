import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'stores';
import styled from 'styled-components';

const Dimmer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 89;
`;

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
`;

interface PopupProps {}

function Popup(props: React.PropsWithChildren<PopupProps>) {
  const { uiStore } = useStore();

  useEffect(() => {
    function keyDown(e: KeyboardEvent) {
      if (e.key === 'Escape' && !uiStore.currentSide) {
        uiStore.popPopup();
      }
    }
    document.addEventListener('keydown', keyDown);

    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  }, [uiStore]);

  return (
    <>
      {uiStore.currentPopup && <Dimmer onClick={uiStore.popPopup} />}
      <Wrapper className="vertical-scroll">
        <AnimatePresence>
          {uiStore.currentPopup && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
              exit={{ opacity: 0, y: -50, transition: { duration: 0.2 } }}
            >
              {uiStore.currentPopup}
            </motion.div>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
}

export default observer(Popup);
