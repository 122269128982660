import { Typography } from '@material-ui/core';
import Avatar from 'components/Avatar';
import Link from 'components/Router/Link';
import { observer } from 'mobx-react-lite';
import { Project } from 'models/projects';
import paths from 'paths';
import React from 'react';
import styled from 'styled-components';
import ProjectProgress from './ProjectProgress';

const Wrapper = styled(Link)`
  cursor: pointer;
  display: block;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  padding: 10px;
`;

const Title = styled(Typography)`
  color: var(--color-secondary);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 7px;
`;

const Members = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

interface ProjectDisplayProps {
  project: Project;
  onClick?: () => void;
}

function ProjectDisplay(props: ProjectDisplayProps) {
  return (
    <Wrapper to={paths.projects.details(props.project)} onClick={props.onClick}>
      <Title>{props.project.name}</Title>
      <Footer>
        <Members>
          {props.project.memberships.map((m) => (
            <Avatar profile={m.profile} size={25} />
          ))}
        </Members>
        <ProjectProgress value={props.project.progress} />
      </Footer>
    </Wrapper>
  );
}

export default observer(ProjectDisplay);
