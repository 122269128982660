import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function PersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Person-a,.Person-b,.Person-c{fill:none;}.Person-a{stroke:currentColor;stroke-width:2.2px;}.Person-d,.Person-e{stroke:none;}.Person-e{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-3140 -1374)">
        <g transform="translate(2625.001 970)">
          <g className="Person-a" transform="translate(523.498 407)">
            <circle className="Person-d" cx="6.414" cy="6.414" r="6.414" />
            <circle className="Person-c" cx="6.414" cy="6.414" r="5.314" />
          </g>
          <g
            className="Person-b"
            transform="translate(542 431.238) rotate(180)"
          >
            <path
              className="Person-d"
              d="M0,1.122C.136.593.139.489.708.211s22.117-.283,22.658,0A.985.985,0,0,1,24,1.122,11.817,11.817,0,0,1,12,12.751,11.818,11.818,0,0,1,0,1.122Z"
            />
            <path
              className="Person-e"
              d="M 12.10089874267578 2.200005531311035 C 7.428066253662109 2.200005531311035 3.991958618164062 2.246363639831543 2.276393890380859 2.302691459655762 C 2.881704330444336 6.946763515472412 7.01194953918457 10.55093574523926 12.00091934204102 10.55093574523926 C 16.98993682861328 10.55093574523926 21.12015724182129 6.944588184356689 21.72407531738281 2.299182891845703 C 20.00215721130371 2.244515419006348 16.64297676086426 2.200005531311035 12.10089874267578 2.200005531311035 M 12.10090255737305 3.814697265625e-06 C 17.60896873474121 3.814697265625e-06 23.09682464599609 0.06979942321777344 23.3668384552002 0.2107257843017578 C 23.90835952758789 0.4925756454467773 23.96537971496582 0.7905359268188477 24 1.122495651245117 C 24 7.546055793762207 18.62865829467773 12.75093555450439 12.00091934204102 12.75093555450439 C 5.373418807983398 12.75093555450439 -1.9073486328125e-06 7.546055793762207 -1.9073486328125e-06 1.122495651245117 C 0.1359596252441406 0.5927858352661133 0.1387290954589844 0.4889955520629883 0.7084083557128906 0.2107257843017578 C 0.9941539764404297 0.07069778442382812 6.557760238647461 3.814697265625e-06 12.10090255737305 3.814697265625e-06 Z"
            />
          </g>
        </g>
        <g transform="translate(266 -142)">
          <rect
            className="Person-c"
            width="30"
            height="30"
            transform="translate(2874 1516)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default PersonIcon;
