import api from 'api';
import { TemplateStepSchema, TemplateUpdateSchema } from 'api/generated/models';
import { action, observable } from 'mobx';

export class Template {
  public id: number;

  @observable
  public name: string;

  @observable
  public description: string;

  @observable
  public label?: string;

  public steps: TemplateStepSchema[] = [];

  public workspace_id?: number;

  public profile_id?: number;

  @observable
  public categories: Array<string>;

  @observable
  public image?: string;

  constructor(data: Partial<Template>) {
    Object.assign(this, data);
  }

  public async update(newData: TemplateUpdateSchema) {
    const { data } = await api.templates.updateTemplate(this.id, newData);
    this._update(data);
  }

  @action
  private _update(data: Partial<Template>) {
    Object.assign(this, data);
  }
}
