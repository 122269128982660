import { observer } from 'mobx-react-lite';
import { workspacePath } from 'paths';
import React from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';
import { useStore } from 'stores';
import styled from 'styled-components';

const Wrapper = styled(BaseLink)`
  text-decoration: none;
`;

function Link(props: LinkProps) {
  const { workspaceStore } = useStore();
  return (
    <Wrapper
      {...(props as any)}
      to={workspacePath(props.to, workspaceStore.currentWorkspace)}
    />
  );
}

export type { LinkProps };
export default observer(Link);
