import { Paper } from '@material-ui/core';
import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'theme';
import AbsoluteLogo from '../elements/AbsoluteLogo';
import CircleIcon from '../icons/CircleIcon';
import CenteredContent from './CenteredContent';

const Wrapper = styled(CenteredContent)`
  min-height: 100vh;
  background-image: linear-gradient(165deg, #5824fb, #6a4ee1 56%, #46aacf);
`;

const ContentWrapper = styled(Paper)`
  padding: 60px;
  max-width: 500px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  position: relative;

  form {
    text-align: center;
    max-width: 350px;
  }

  hr {
    height: 1px;
    width: 80%;
    margin: 0 auto;

    border: 0;
    background-color: var(--color-border-grey);

    margin-top: 20px;
    margin-bottom: 10px;
  }

  ${(p) => theme.breakpoints.down('xs')} {
    overflow: auto;
    border-radius: 0;
    padding: 20px;
    min-width: 100vw;
    max-width: 100vw;
  }
`;

const CircleLeft = styled.div`
  position: fixed;
  z-index: -1;
  left: -600px;
  bottom: -600px;

  svg {
    font-size: 1000px;
  }
`;

const CircleRight = styled.div`
  position: fixed;
  z-index: -1;
  right: -600px;
  top: -600px;

  svg {
    font-size: 1000px;
  }
`;

interface AuthLayoutProps {}

function AuthLayout(props: AuthLayoutProps) {
  return (
    <Wrapper width="100vw">
      <ContentWrapper className="vertical-scroll">
        <AbsoluteLogo breakpoint="lg" />
        <Outlet />
        <CircleLeft>
          <CircleIcon />
        </CircleLeft>
        <CircleRight>
          <CircleIcon />
        </CircleRight>
      </ContentWrapper>
    </Wrapper>
  );
}

export default AuthLayout;
