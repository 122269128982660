import { OutlinedTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 60px;
  }

  .MuiInputLabel-root {
    transform: translate(20px, -6px) scale(0.75);
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;

    legend {
      margin-left: 6px;
    }
  }

  && .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) =>
      p.error ? 'var(--color-error)' : 'var(--color-primary)'};
  }

  input {
    border-radius: 60px;
    padding: 15px;
  }
`;

interface RoundedTextFieldProps extends Partial<OutlinedTextFieldProps> {
  maxLength?: number;
  value: string;
}

function RoundedTextField(props: RoundedTextFieldProps) {
  function maxOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!props.onChange) return;
    props.onChange({
      ...e,
      target: {
        ...e.target,
        value: e.target.value.slice(0, props.maxLength),
      },
    });
  }

  return (
    <Wrapper
      {...(props as any)}
      value={props.value}
      onChange={props.maxLength ? maxOnChange : props.onChange}
      variant="outlined"
      helperText={
        props.helperText ||
        (props.maxLength
          ? `${props.value.length}/${props.maxLength}`
          : undefined)
      }
      InputProps={{ notched: true }}
      InputLabelProps={{ focused: true, shrink: true }}
    />
  );
}

export default RoundedTextField;
