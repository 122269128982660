import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function VideoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Video-a{fill:none;}.Video-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2752 -1674)">
        <rect
          className="Video-a"
          width="30"
          height="30"
          transform="translate(2752 1674)"
        />
        <g transform="translate(2748.438 1714.075)">
          <path
            className="Video-b"
            d="M9.316-17.682a3.222,3.222,0,0,1-1.554-1.642c-.19-.508-.2-.715-.2-5.415,0-4.591.011-4.916.185-5.373a2.929,2.929,0,0,1,1.518-1.645l.643-.316H22.191l.528.253A2.928,2.928,0,0,1,24.47-29.5l.12.71L26.6-29.879a17.407,17.407,0,0,1,2.226-1.093,1.312,1.312,0,0,1,1.035.635c.131.276.146.99.123,5.737l-.026,5.422-.314.314a.829.829,0,0,1-.767.31c-.394,0-.7-.138-2.312-1.031-1.022-.565-1.891-1.028-1.931-1.028s-.1.229-.137.509a3.2,3.2,0,0,1-1.637,2.4c-.38.2-.444.206-6.714.23l-6.33.024ZM22-19.886c.18-.132.186-.295.186-4.851s-.006-4.72-.186-4.851c-.156-.114-1.132-.136-6.013-.136H10.164l-.149.213a67.5,67.5,0,0,0,0,9.549l.149.213h5.827c4.881,0,5.857-.022,6.013-.136Zm5.7-4.851c0-1.688-.026-3.069-.057-3.069s-.743.374-1.583.832l-1.526.832v2.826L26-22.5a17.814,17.814,0,0,0,1.583.824c.094.007.12-.664.12-3.06Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default VideoIcon;
