import getFilename from './getFilename';

async function downloadFile(url: string, filename?: string) {
  const resp = await fetch(url, {
    headers: new Headers({ Origin: window.location.origin }),
    mode: 'cors',
  });
  const blob = await resp.blob();

  const newUrl = URL.createObjectURL(blob);

  const element = document.createElement('a');
  element.setAttribute('href', newUrl);
  element.setAttribute('download', filename || getFilename(url));

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  document.body.removeChild(element);
}

export default downloadFile;
