import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Download-a{fill:none;}.Download-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2791 -1564)">
        <rect
          className="Download-a"
          width="30"
          height="30"
          transform="translate(2791 1564)"
        />
        <g transform="translate(2751.684 1583.604)">
          <path
            className="Download-b"
            d="M53.7,7.3a40.616,40.616,0,0,1-3.952-3.977,1.284,1.284,0,0,1,.733-1.646c.583-.243.924-.107,1.754.7l.68.663V-1.063c0-2.47.025-4.193.061-4.325a1.5,1.5,0,0,1,.718-.823,1.268,1.268,0,0,1,1.674.666c.109.236.116.5.118,4.424V3.052l.581-.57c.727-.714.907-.836,1.3-.882a.945.945,0,0,1,.653.129,1.176,1.176,0,0,1,.719,1.008c.052.624.018.671-1.831,2.536-.936.944-1.828,1.8-1.982,1.911A1.25,1.25,0,0,1,53.7,7.3ZM46.61.785a5.733,5.733,0,0,1-3.395-2.351A5.486,5.486,0,0,1,43.26-7.65,5.673,5.673,0,0,1,46.1-9.811c.475-.155.532-.19.6-.377a3.89,3.89,0,0,1,1.7-1.845,3.238,3.238,0,0,1,1.959-.476l.793,0,.189-.382a7.426,7.426,0,0,1,2.26-2.5,7.085,7.085,0,0,1,6.878-.6,7.189,7.189,0,0,1,4.042,4.817,5.413,5.413,0,0,1,.173,1.615L64.7-8.535l.283.334A5.816,5.816,0,0,1,66.2-5.854a6.629,6.629,0,0,1-.027,2.631,5.516,5.516,0,0,1-1.6,2.649A5.489,5.489,0,0,1,62.522.654l-.6.2H57.9L57.638.684a1.441,1.441,0,0,1,0-2.416l.266-.173,1.726-.036c1.905-.04,2.064-.069,2.726-.508a2.636,2.636,0,0,0,1.011-2.784A2.761,2.761,0,0,0,62.4-6.706c-.78-.759-.832-.9-.664-1.816a4.845,4.845,0,0,0-.094-2.264,4.282,4.282,0,0,0-4.82-2.862,4.27,4.27,0,0,0-2.981,2.155,5.96,5.96,0,0,0-.392,1.063,3.477,3.477,0,0,1-.33.869,1.558,1.558,0,0,1-1.139.6,1.74,1.74,0,0,1-1.009-.407,1.039,1.039,0,0,0-1.189-.159,1.037,1.037,0,0,0-.533.876c-.116.922-.579,1.346-1.585,1.45a2.677,2.677,0,0,0-2.378,1.9,2.639,2.639,0,0,0,1.756,3.232,8.51,8.51,0,0,0,1.9.135l1.642.039.273.2a2.083,2.083,0,0,1,.457.491,1.421,1.421,0,0,1-.67,2.009A19.683,19.683,0,0,1,46.61.785Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default DownloadIcon;
