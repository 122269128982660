import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function AddPersonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.AddPerson-a{fill:none;}.AddPerson-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2874 -1516)">
        <rect
          className="AddPerson-a"
          width="30"
          height="30"
          transform="translate(2874 1516)"
        />
        <g transform="translate(2918.859 1174.311)">
          <path
            className="AddPerson-b"
            d="M-.89,227.607a.922.922,0,0,1-.443-.359,12.041,12.041,0,0,1-.077-1.727v-1.527H-2.983c-1.42,0-1.6-.024-1.832-.241a.879.879,0,0,1-.259-.711.627.627,0,0,1,.384-.7c.328-.2.6-.234,1.832-.235H-1.41l0-1.507c0-1.622.065-1.818.626-2.031a.9.9,0,0,1,1.072.366c.149.212.189.582.189,1.721V222.1H1.833a8.128,8.128,0,0,1,1.675.123.882.882,0,0,1,.393,1.457c-.243.309-.258.311-1.832.311H.481v1.53c0,1.421-.019,1.551-.266,1.823a.844.844,0,0,1-1.105.26Zm-18.583-.775a3.277,3.277,0,0,1-2.209-1.852c-.8-1.925,1.232-6.448,3.938-8.748a13.981,13.981,0,0,1,4.1-2.451c.215-.077.2-.106-.139-.374a6.89,6.89,0,0,1-1.554-2.132,6.591,6.591,0,0,1,.965-7.257,6.634,6.634,0,0,1,9.59-.579,6.314,6.314,0,0,1,2.2,4.875,6.628,6.628,0,0,1-2.178,5.055l-.335.309.925.505A21.925,21.925,0,0,1-1.01,216.3c.19.21.174.222-.358.3a8.543,8.543,0,0,0-1.16.266l-.6.19-.582-.369A12.921,12.921,0,0,0-6.937,215.4a14.326,14.326,0,0,0-4.723,0,9.92,9.92,0,0,0-5.175,2.967,9.318,9.318,0,0,0-2.436,3.669q-.715,1.852-.263,2.408l.223.276,6.222-.014,6.222-.014.142.448a7.407,7.407,0,0,0,.484,1.068l.343.621H-9.713c-2.1,0-5.036.025-6.532.055a28.856,28.856,0,0,1-3.229-.055Zm12.194-14.295A4.828,4.828,0,0,0-5.1,210.309a5.142,5.142,0,0,0,.142-3.483,4.929,4.929,0,0,0-2.9-2.815,5.987,5.987,0,0,0-3.191.128,5.438,5.438,0,0,0-2.393,2.255,5.194,5.194,0,0,0,0,4,5.05,5.05,0,0,0,2.921,2.451,5.332,5.332,0,0,0,3.241-.31Z"
            transform="translate(-21 142)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default AddPersonIcon;
