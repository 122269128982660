import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function AddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Add-a,.Add-b{fill:none;}.Add-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.5px;}`}</style>
      </defs>
      <g transform="translate(-3022 -1516)">
        <rect
          className="Add-a"
          width="30"
          height="30"
          transform="translate(3022 1516)"
        />
        <g transform="translate(2320.172 773)">
          <g transform="translate(716.914 749.429) rotate(45)">
            <line className="Add-b" x2="6" y2="6" transform="translate(6 6)" />
            <line className="Add-b" x1="6" y2="6" transform="translate(0 6)" />
            <g transform="translate(12 6) rotate(180)">
              <line
                className="Add-b"
                x2="6"
                y2="6"
                transform="translate(6 0)"
              />
              <line
                className="Add-b"
                x1="6"
                y2="6"
                transform="translate(0 0)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default AddIcon;
