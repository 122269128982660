import { loadStripe } from '@stripe/stripe-js';
import api from 'api';
import {
  Plan,
  WorkspaceConfigSchema,
  WorkspaceUpdateSchema,
} from 'api/generated/models';
import { WorkspaceInvitationCreateSchema } from 'api/generated/models/workspace-invitation-create-schema';
import { WorkspaceInvitationSchema } from 'api/generated/models/workspace-invitation-schema';
import i18n from 'i18n';
import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import { store } from 'stores';
import { Diff } from './diff';
import { MyProfile } from './profiles';
import { ValueType } from './utils';

export enum VisibleTo {
  admins = 0,
  project_admins = 1,
  project_members = 2,
  everyone = 3,
}

type VisibleAt = 'project' | 'table';

export type ProjectValueType =
  | ValueType
  | 'team'
  | 'name'
  | 'progress'
  | 'favorite';
export const READONLY_PROJECT_VALUES = ['team', 'name', 'progress'];
export const WITH_OPTIONS_PROJECT_VALUES = [
  'choice',
  'multiple-choice',
  'money',
  'date',
];
export const PREVENT_DELETE_PROJECT_VALUES = ['team', 'name', 'progress'];
export const INLINE_PROJECT_VALUES = ['money'];
export const SORTABLE_PROJECT_VALUES = [
  'money',
  'choice',
  'multiple-choice',
  'date',
  'name',
  'emoji',
  'team',
  'progress',
];
export const FILTERABLE_PROJECT_VALUES = [
  'choice',
  'multiple-choice',
  'emoji',
  'team',
];

export interface ProjectValue {
  name: string;
  type: ProjectValueType;
  key: string;
  visibleTo: VisibleTo;
  visibleAt: VisibleAt[];
  options?: any;
}

export interface ProjectConfig {
  values: ProjectValue[];
}

export class Workspace {
  public id: number;

  @observable
  public name: string;

  @observable
  public avatar: string | null = null;

  @observable
  public profile: MyProfile;

  @observable
  public owner_id?: number;

  @observable
  public is_stripe: boolean;

  @observable
  public invitations: WorkspaceInvitationSchema[] = [];

  @observable
  public resentInvitations: WorkspaceInvitationSchema[] = [];

  @observable
  public projects_config: ProjectConfig;

  @observable
  public init_config?: WorkspaceConfigSchema;

  public date_created: DateTime;

  @observable
  public max_card_count: number;

  @observable
  public card_count: number;

  public current_plan: Plan;
  public max_profile_count: number;
  public max_storage: number;
  public total_storage: number;

  constructor(data: Partial<Workspace>) {
    Object.assign(this, data);
  }

  private _update(data: Partial<Workspace>) {
    Object.assign(this, data);
  }

  public async update(newData: WorkspaceUpdateSchema) {
    const { data } = await api.workspaces.updateCurrentWorkspace(newData);
    this._update(data);
  }

  @action.bound
  public async updateConfigValue(value: ProjectValue, diffs: Diff[]) {
    await this.update({
      projects_config: {
        ...this.projectsConfig,
        values: this.projectsConfig.values.map((c) =>
          c.key === value.key ? value : c,
        ),
      },
      projects_config_diffs: diffs.map((c) => ({ ...c, key: value.key })),
    });
    if (diffs.length > 0) {
      store.projectStore.loadProjects();
    }
  }

  public async loadInvitations() {
    const { data } = await api.workspaces.getInvitations();
    runInAction(() => {
      this.invitations = data;
    });
  }

  public async createInvitation(newData: WorkspaceInvitationCreateSchema) {
    const { data } = await api.workspaces.createInvitation(newData);
    runInAction(() => {
      this.invitations.push(data);
    });
  }

  public async deleteInvitation(invitation: WorkspaceInvitationSchema) {
    await api.workspaces.deleteInvitation(invitation.id);
    runInAction(() => {
      this.invitations = this.invitations.filter((i) => i.id !== invitation.id);
    });
  }

  public async resendInvitation(invitation: WorkspaceInvitationSchema) {
    await api.workspaces.resendInvitation(invitation.id);
    runInAction(() => {
      this.resentInvitations.push(invitation);
    });
  }

  public async switchToPlan(
    plan: Plan,
    frequency: 'monthly' | 'yearly' = 'monthly',
  ) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
    if (!stripe) return;

    const { data } = await api.billing.startCheckout(plan, frequency);
    if (data.startsWith('https://')) {
      window.location.href = data;
    } else {
      stripe.redirectToCheckout({
        sessionId: data,
      });
    }
  }

  @computed
  get canCreateCard() {
    return !this.max_card_count || this.card_count < this.max_card_count;
  }

  @computed
  get freemiumEndingSoon() {
    return !!(
      this.max_card_count && this.card_count / this.max_card_count > 0.9
    );
  }

  public async openBillingPortal() {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
    if (!stripe) return;

    const { data } = await api.billing.billingPortal();
    window.location.href = data;
  }

  @computed
  get projectsConfig() {
    return {
      ...this.projects_config,
      values: this.projects_config.values || [
        {
          key: 'name',
          name: i18n.t('config_editor.defaults.name'),
          type: 'name',
          visibleTo: VisibleTo.everyone,
          visibleAt: ['table'],
        },
        {
          key: 'team',
          name: i18n.t('config_editor.defaults.team'),
          type: 'team',
          visibleTo: VisibleTo.everyone,
          visibleAt: ['project', 'table'],
        },
        {
          key: 'progress',
          name: i18n.t('config_editor.defaults.progress'),
          type: 'progress',
          visibleTo: VisibleTo.everyone,
          visibleAt: ['project', 'table'],
        },
      ],
    };
  }
}
