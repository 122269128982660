import { CircularProgress } from '@material-ui/core';
import CustomLogs from 'components/custom/CustomLogs';
import BackLink from 'components/elements/BackLink';
import Title from 'components/elements/Title';
import CenteredContent from 'components/layouts/CenteredContent';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ModalContent, ModalFooter, ModalTitle } from '../styles';

const ProgressWrapper = styled.div`
  min-height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface LogsModalProps {}

function LogsModal(props: LogsModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <CenteredContent width="var(--center-width)">
      <ModalTitle>
        <Title>{t('logs.title')}</Title>
      </ModalTitle>
      <ModalContent>
        <CustomLogs full onLoad={() => setLoading(false)} />
        {loading && (
          <ProgressWrapper>
            <CircularProgress color="primary" />
          </ProgressWrapper>
        )}
      </ModalContent>
      <ModalFooter>
        <BackLink popSide />
      </ModalFooter>
    </CenteredContent>
  );
}

export default LogsModal;
