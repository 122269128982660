import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'i18n';
import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import App from './components/App';
import { RELOAD_KEY } from './config';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.0,
    ignoreErrors: [/Loading chunk [d]+ failed/],
  });
}

const reloadCount = +(localStorage.getItem(RELOAD_KEY) || 0);

function reload() {
  if (window.location.hostname !== 'localhost' && reloadCount <= 2) {
    localStorage.setItem(RELOAD_KEY, (reloadCount + 1).toString());
    window.location.reload();
  }

  return null;
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={reload}>
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

if (reloadCount > 2) {
  setTimeout(() => {
    localStorage.removeItem(RELOAD_KEY);
  }, 10000);
}
