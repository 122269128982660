import { ClickAwayListener, IconButton, Typography } from '@material-ui/core';
import SearchBar from 'components/search/SearchBar';
import { MINIMUM_WIDTH } from 'config';
import { observer } from 'mobx-react-lite';
import MyProfile from 'modals/profiles/MyProfile';
import paths from 'paths';
import React, { useState } from 'react';
import { useStore } from 'stores';
import { useCurrentProfile, useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import useWindowSize from 'utils/useWindowSize';
import Avatar from '../Avatar';
import DownIcon from '../icons/DownIcon';
import HelpIcon from '../icons/HelpIcon';
import LogoIcon from '../icons/LogoIcon';
import Link from '../Router/Link';
import ProfileMenu from '../Sidebar/ProfileMenu';
import FlexSeparator from '../utils/FlexSeparator';

const Wrapper = styled.div`
  background-color: var(--color-white);
  height: var(--topbar-height);
  width: 100%;
  box-shadow: var(--shadow-xs);
  position: relative;
  z-index: 16;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 15px;
  user-select: none;
`;

const MenuWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 40px;
  z-index: 17;
`;

const WorkspaceWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-secondary);
  position: relative;

  cursor: pointer;

  > a {
    margin-right: 10px;
    svg {
      display: block;
    }
  }

  &:hover {
    color: var(--color-primary);
  }
`;

const WorkspaceName = styled(Typography)`
  font-weight: bold;
  font-size: 17px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

const MyProfileMenu = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-left: 20px;
  min-width: 100px;
`;

const ProfileName = styled(Typography)`
  color: var(--color-secondary);
  font-weight: 400;
  font-size: 16px;
  margin-left: 7px;

  &:hover {
    color: var(--color-primary);
  }
`;

interface TopbarProps {}

function Topbar(props: TopbarProps) {
  const { uiStore } = useStore();
  const [menuOpen, setMenuOpen] = useState(false);
  const workspace = useCurrentWorkspace();
  const profile = useCurrentProfile();
  const { width } = useWindowSize();

  function openHelp() {
    window.Intercom('show');
  }

  function openMenu() {
    if (!menuOpen) {
      setMenuOpen(!menuOpen);
    }
  }

  return (
    <Wrapper>
      <WorkspaceWrapper>
        <Link to={paths.tasks.list}>
          <LogoIcon style={{ fontSize: '30px' }} />
        </Link>
        <WorkspaceName onClick={openMenu}>{workspace.name}</WorkspaceName>
        <DownIcon
          onClick={openMenu}
          style={{ marginLeft: '5px', fontSize: '17px' }}
        />
        {menuOpen && (
          <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
            <MenuWrapper>
              <ProfileMenu />
            </MenuWrapper>
          </ClickAwayListener>
        )}
      </WorkspaceWrapper>
      <FlexSeparator />
      {(width || 0) >= MINIMUM_WIDTH && <SearchBar />}
      <FlexSeparator />
      <RightSide>
        <IconButton style={{ padding: '5px' }} onClick={openHelp}>
          <HelpIcon style={{ fontSize: '25px', color: COLORS.secondary }} />
        </IconButton>

        <MyProfileMenu onClick={() => uiStore.pushSide(<MyProfile />)}>
          <Avatar size={25} profile={profile} />
          <ProfileName>{profile.name}</ProfileName>
        </MyProfileMenu>
      </RightSide>
    </Wrapper>
  );
}

export default observer(Topbar);
