import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import api from 'api';
import { UserUpdateSchema } from 'api/generated/models';
import i18n from 'i18n';
import { action, computed, observable, runInAction } from 'mobx';
import { User } from 'models/auth';
import { RootStore } from '.';
import { Auth } from '../amplify';

export class AuthStore {
  @observable
  private user: User | null;

  @observable
  public loading: boolean = true;

  constructor(private rootStore: RootStore) {
    this.updateAuthStatus();
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        try {
          await api.auth.getCurrentUser();
        } catch (_) {
          if (!this.authenticated) return;
          this.signOut();
        }
      }
    });
  }

  private init() {
    this.rootStore.workspaceStore.init();
  }

  @action
  private async updateAuthStatus() {
    this.loading = true;
    try {
      const { data } = await api.auth.getCurrentUser(
        i18n?.language.split('-')[0],
      );

      runInAction(() => {
        this.user = new User(data);
        this.init();

        heap?.identify(this.user.id);

        if (this.user.language) {
          i18n.changeLanguage(this.user.language);
        }
      });
    } catch (e) {
      runInAction(() => {
        heap?.resetIdentity();
        window.Intercom('shutdown');
        window.Intercom('boot', {
          app_id: window.intercomSettings?.app_id,
        });
        this.user = null;
        this.rootStore.reset(true);
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async signIn(email: string, password: string) {
    await Auth.signIn(email, password);
    this.updateAuthStatus();
  }

  public async signInWithGoogle() {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    this.updateAuthStatus();
  }

  public async signUp(email: string, password: string) {
    const { user } = await Auth.signUp({ username: email, password });
    return user;
  }

  public async signOut() {
    await Auth.signOut();
    window.Intercom('shutdown');
    window.Intercom('boot', {
      app_id: window.intercomSettings?.app_id,
    });
    runInAction(() => {
      this.user = null;
      this.rootStore.reset(true);
    });
  }

  public async updateCurrentUser(newData: Partial<UserUpdateSchema>) {
    const { data } = await api.auth.updateCurrentUser(newData);
    runInAction(() => {
      this.currentUser.update(data);
    });
  }

  @computed
  get authenticated() {
    return !!this.user;
  }

  @computed
  get currentUser() {
    if (!this.user) throw new Error('Not authenticated');
    return this.user;
  }
}
