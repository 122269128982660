import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import CheckContainedIcon from 'components/icons/CheckContainedIcon';
import { NimbleEmoji } from 'emoji-mart';
import { emojisData } from 'emojis';
import React from 'react';
import { COLORS } from 'theme';

interface BloomupEmojiProps {
  emoji: string;
  size?: number;
  onClick?: () => void;
}

function BloomupEmoji(props: BloomupEmojiProps) {
  const colons =
    props.emoji.length && props.emoji[0] === ':'
      ? props.emoji
      : `:${props.emoji}:`;
  const size = props.size || 25;

  if (colons === ':bloomup-todo:') {
    return (
      <CheckCircleIcon
        onClick={props.onClick}
        style={{ color: COLORS.lightGrey, fontSize: `${size}px` }}
      />
    );
  }

  if (colons === ':bloomup-inprogress:') {
    return (
      <CheckCircleIcon
        onClick={props.onClick}
        style={{ color: COLORS.warning, fontSize: `${size}px` }}
      />
    );
  }

  if (colons === ':bloomup-done:') {
    return (
      <CheckContainedIcon
        onClick={props.onClick}
        style={{ color: COLORS.success, fontSize: `${size}px` }}
      />
    );
  }

  if (colons.startsWith(':bloomup-check-#')) {
    return (
      <CheckCircleIcon
        onClick={props.onClick}
        style={{
          color: colons.slice(15, -1),
          fontSize: `${size}px`,
        }}
      />
    );
  }

  if (colons.startsWith(':bloomup-check-filled-#')) {
    return (
      <CheckContainedIcon
        onClick={props.onClick}
        style={{
          color: colons.slice(22, -1),
          fontSize: `${size}px`,
        }}
      />
    );
  }

  return (
    <NimbleEmoji
      onClick={props.onClick}
      emoji={colons}
      size={size}
      data={emojisData}
    />
  );
}

export default BloomupEmoji;
