import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ProjectTemplateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g transform="translate(-3023 -1428)">
        <g transform="translate(45 -1)">
          <g transform="translate(37)">
            <rect
              width="30"
              height="30"
              transform="translate(2941 1429)"
              fill="none"
            />
          </g>
        </g>
        <g
          transform="translate(3028 1432)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <rect width="6" height="22" rx="3" stroke="none" />
          <rect x="1" y="1" width="4" height="20" rx="2" fill="none" />
        </g>
        <g
          transform="translate(3036 1446) rotate(-90)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <rect width="6" height="13" rx="3" stroke="none" />
          <rect x="1" y="1" width="4" height="11" rx="2" fill="none" />
        </g>
        <g
          transform="translate(3036 1438) rotate(-90)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <rect width="6" height="13" rx="3" stroke="none" />
          <rect x="1" y="1" width="4" height="11" rx="2" fill="none" />
        </g>
        <g
          transform="translate(3036 1454) rotate(-90)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <rect width="6" height="13" rx="3" stroke="none" />
          <rect x="1" y="1" width="4" height="11" rx="2" fill="none" />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ProjectTemplateIcon;
