import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CheckContainedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...(props as any)}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.CheckContained-a,.CheckContained-b,.CheckContained-c{fill:none;}.CheckContained-a,.CheckContained-b{stroke:currentColor;stroke-width:2px;}.CheckContained-b{stroke-linecap:round;}.CheckContained-d{stroke:none;}`}</style>
      </defs>
      <g transform="translate(-3140 -1429)">
        <g
          transform="rotate(-3 29094 -59424)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-width="2px"
        >
          <path
            className="CheckContained-b"
            transform="translate(0,2.706)"
            d="m0 0 3.936 3.441"
          />
          <line
            className="CheckContained-b"
            transform="translate(3.936)"
            x2="6.146"
            y1="6.146"
          />
        </g>
        <circle cx="3154.9" cy="1443.9" r="11.056" fill="currentColor" />
        <path
          transform="translate(3140,1429)"
          d="m13.796 19.092c-0.082025-0.035628-0.38539-0.25316-0.67415-0.4834s-1.2677-1.0018-2.1755-1.7145c-1.4903-1.1701-1.6615-1.318-1.764-1.5236-0.20193-0.40522-0.13233-0.79259 0.20042-1.1154 0.31162-0.30232 0.72078-0.37408 1.0862-0.1905 0.0656 0.03295 0.89364 0.66783 1.8401 1.4108l1.7208 1.3509 0.11494-0.11464c0.06321-0.06305 1.2576-1.383 2.6542-2.9331 1.6651-1.8481 2.6012-2.8534 2.7191-2.9198 0.40322-0.22732 0.98502-0.09041 1.257 0.29581 0.17905 0.25421 0.21524 0.70793 0.07784 0.97603-0.04957 0.09673-1.3273 1.5511-2.8394 3.232-3.4153 3.7965-3.3025 3.6771-3.5343 3.7442-0.24398 0.07062-0.49939 0.06511-0.68324-0.01475z"
          fill="currentColor"
          stroke-width=".044943"
        />
        <path
          transform="translate(3140,1429)"
          d="m13.82 19.101c-0.12156-0.044298-3.3897-2.577-4.2902-3.3248-0.31828-0.2643-0.44568-0.49846-0.44861-0.82453-0.0042873-0.47712 0.36215-0.90873 0.83124-0.97908 0.39279-0.0589 0.52681 0.02475 2.3993 1.4977 1.6721 1.3153 1.7401 1.3634 1.8088 1.2792 0.03924-0.04809 1.1736-1.3081 2.5208-2.8001s2.505-2.7787 2.5729-2.8594c0.17849-0.21203 0.46012-0.3346 0.76883-0.3346 0.71978 0 1.1784 0.714 0.87107 1.3561-0.06613 0.13816-1.1005 1.3214-3.0219 3.4565-1.6067 1.7855-2.9618 3.2861-3.0112 3.3346-0.23302 0.2286-0.67689 0.31658-1.0011 0.19843z"
          fill="currentColor"
          stroke-width=".044943"
        />
        <path
          transform="translate(3140,1429)"
          d="m13.764 19.079c-0.13564-0.070488-0.88044-0.64729-3.5713-2.7657-0.34606-0.27245-0.69671-0.55746-0.77921-0.63337-0.49297-0.45357-0.43749-1.2051 0.11484-1.5553 0.25775-0.16346 0.51196-0.20204 0.7834-0.11888 0.21718 0.06653 0.2891 0.11971 2.3733 1.7547 0.62922 0.49359 1.1998 0.92941 1.2679 0.9685 0.11343 0.0651 0.12868 0.06488 0.18178-0.0026 0.03188-0.04054 1.2185-1.3596 2.6369-2.9312 1.979-2.1928 2.6227-2.8787 2.767-2.9485 0.24809-0.1201 0.64224-0.12181 0.87357-0.0038 0.46726 0.23838 0.66646 0.78578 0.45526 1.2511-0.08598 0.18944-0.44853 0.60175-4.0388 4.5931-2.0862 2.3193-2.0586 2.2903-2.2764 2.3893-0.23238 0.10559-0.58803 0.10687-0.78824 0.0028z"
          fill="currentColor"
          stroke-width=".044943"
        />
        <path
          transform="translate(3140,1429)"
          d="m13.764 19.08c-0.16074-0.082922-4.0839-3.1459-4.3505-3.3966-0.62639-0.58911-0.34305-1.5571 0.5022-1.7157 0.36542-0.06855 0.47742 9.12e-4 2.3346 1.4479 0.96186 0.74943 1.7656 1.3626 1.7862 1.3626 0.04496 0 0.29662-0.27481 3.1163-3.4031 2.3911-2.6528 2.3549-2.6193 2.831-2.6193 0.71003 0 1.1728 0.70104 0.88348 1.3384-0.08933 0.19681-0.36712 0.51327-3.9933 4.5491-2.2297 2.4816-2.2678 2.5168-2.7219 2.5168-0.13521 0-0.29767-0.03359-0.38812-0.08025z"
          fill="#fff"
        />
      </g>
    </SvgIcon>
  );
}

export default CheckContainedIcon;
