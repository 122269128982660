import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ProjectBlankIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.ProjectBlank-a{fill:currentColor;}.ProjectBlank-b{fill:none;}`}</style>
      </defs>
      <g transform="translate(-2978 -1429)">
        <g transform="translate(2983 1432)">
          <g transform="translate(0)">
            <path
              className="ProjectBlank-a"
              d="M33.181,20.532H30.17a1.773,1.773,0,0,1-1.8-1.74V3.48a1.773,1.773,0,0,1,1.8-1.74H41.237a1.773,1.773,0,0,1,1.8,1.74V8.831a.9.9,0,0,0,1.8,0V3.48A3.546,3.546,0,0,0,41.237,0H30.17a3.546,3.546,0,0,0-3.6,3.48V18.792a3.546,3.546,0,0,0,3.6,3.48h3.01a.871.871,0,1,0,0-1.74Z"
              transform="translate(-26.57 0)"
            />
            <g transform="translate(19.456 9.259) rotate(45)">
              <g transform="translate(0 0)">
                <path
                  className="ProjectBlank-a"
                  d="M3.987,15.174h0a.781.781,0,0,0,.781-.78L4.691,2.342a2.342,2.342,0,0,0-4.685,0L0,14.534a.781.781,0,0,0,.132.435l1.513,2.26a.781.781,0,0,0,.638.346h.011a.781.781,0,0,0,.637-.329l1.611-2.272a.781.781,0,1,0-1.274-.9l-.956,1.348L1.562,14.3,1.567,4.255H3.129l.078,10.138A.781.781,0,0,0,3.987,15.174ZM1.568,2.694V2.342a.781.781,0,0,1,1.562,0v.352Z"
                  transform="translate(0 0)"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(37)">
          <rect
            className="ProjectBlank-b"
            width="30"
            height="30"
            transform="translate(2941 1429)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ProjectBlankIcon;
