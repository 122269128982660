import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 1127.957 1120.859">
      <path
        id="Path_1898"
        data-name="Path 1898"
        d="M1072.4,644.337c0,238.479-196.435,420.837-424.45,420.837S223.5,882.816,223.5,644.337,419.934,223.5,647.95,223.5,1072.4,405.858,1072.4,644.337Z"
        transform="matrix(1, 0.017, -0.017, 1, -72.627, -95.118)"
        fill="none"
        stroke="#fff"
        stroke-width="260"
        opacity="0.15"
      />
    </SvgIcon>
  );
}

export default CircleIcon;
