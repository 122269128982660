import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import LogoWhite from 'assets/images/logo_white.png';
import Logo from 'assets/images/logo.png';
import React from 'react';
import styled from 'styled-components';
import { theme } from 'theme';

const Wrapper = styled.div<{ split?: boolean; breakpoint?: Breakpoint }>`
  position: fixed;
  top: 20px;
  z-index: 4;
  left: ${(p) => (p.split ? '75%' : '50%')};
  width: auto;
  transform: translateX(-50%);
  display: flex;
  align-items: center;

  img {
    height: 50px;
  }

  .logo-white {
    display: block;
  }

  .logo-black {
    display: none;
  }

  ${(p) => theme.breakpoints.down(p.breakpoint || 'sm')} {
    position: static;
    transform: none;
    margin-bottom: 30px;

    .logo-white {
      display: none;
    }

    .logo-black {
      display: block;
    }

    img {
      height: 40px;
    }
  }
`;

interface AbsoluteLogoProps {
  black?: boolean;
  split?: boolean;
  breakpoint?: Breakpoint;
}

function AbsoluteLogo(props: AbsoluteLogoProps) {
  return (
    <Wrapper split={props.split} breakpoint={props.breakpoint}>
      {props.black ? (
        <img src={Logo} alt="logo" />
      ) : (
        <>
          <img className="logo-white" src={LogoWhite} alt="logo" />
          <img className="logo-black" src={Logo} alt="logo" />
        </>
      )}
    </Wrapper>
  );
}

export default AbsoluteLogo;
