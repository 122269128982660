import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ReturnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Return-a{fill:none;}.Return-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2985 -1516)">
        <rect
          className="Return-a"
          width="30"
          height="30"
          transform="translate(2985 1516)"
        />
        <g transform="translate(3029.277 1528.311)">
          <path
            className="Return-b"
            d="M-32.758-8.079c-.35.142-.576.353-1.167,1.088-1.98,2.463-3.11,3.9-3.145,3.99a.942.942,0,0,0,.02.6c.071.164,3.39,4.325,3.719,4.663a1.417,1.417,0,0,0,1.129.465,1.334,1.334,0,0,0,.581-.094,1.73,1.73,0,0,0,.757-.74,1.065,1.065,0,0,0,.126-.626c0-.457-.045-.555-.642-1.451-.235-.352-.427-.653-.427-.668a8.957,8.957,0,0,1,1-.027,5.781,5.781,0,0,1,3.1.621A5.531,5.531,0,0,1-24.9,6.086a5.534,5.534,0,0,1-4.433,3.994,16.544,16.544,0,0,1-2.652.082,16.87,16.87,0,0,0-2.4.062,1.9,1.9,0,0,0-1.3,1.274,1.862,1.862,0,0,0,1.05,2.182c.374.154.522.163,2.63.163a16.175,16.175,0,0,0,3.725-.22,9.333,9.333,0,0,0,6.027-4.4,10.9,10.9,0,0,0,.525-1.072.338.338,0,0,1,.042-.123,6.178,6.178,0,0,0,.267-.734c.019-.072.064-.24.1-.373a10.362,10.362,0,0,0,.292-2.279,10.733,10.733,0,0,0-.283-2.257c-.059-.229-.3-.988-.369-1.14a9.328,9.328,0,0,0-2.657-3.655,9.262,9.262,0,0,0-2.082-1.315l-.373-.159a10.122,10.122,0,0,0-4.048-.678c-.766,0-.975-.013-.959-.056s.184-.291.383-.581.433-.654.52-.811a.974.974,0,0,0,.156-.679,1.178,1.178,0,0,0-.108-.614,1.656,1.656,0,0,0-.8-.76,1.847,1.847,0,0,0-1.111-.015Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ReturnIcon;
