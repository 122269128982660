import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Image-a{fill:none;}.Image-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2830 -1674)">
        <rect
          className="Image-a"
          width="30"
          height="30"
          transform="translate(2830 1674)"
        />
        <g transform="translate(2827.745 1737.747)">
          <path
            className="Image-b"
            d="M7.429-40.513A3.008,3.008,0,0,1,5.5-42.124l-.218-.465-.024-6.332c-.018-4.763,0-6.423.075-6.7a3.1,3.1,0,0,1,1.591-1.886l.4-.2,9.789-.022,9.789-.022.47.19a2.723,2.723,0,0,1,1.35,1.079c.52.787.493.433.522,6.98.028,6.357.006,6.823-.344,7.508a3.681,3.681,0,0,1-1.143,1.2c-.6.372-.525.369-10.546.362-5.213,0-9.616-.04-9.785-.08Zm19.189-2.343c.216-.145.278-.459.278-1.424v-1.007l-2.736-2.735-2.736-2.735L17.97-47.313c-3.018,3.01-3.491,3.451-3.759,3.5-.673.124-.8.044-2.283-1.414l-1.379-1.359L9.081-45.119c-1.332,1.331-1.468,1.492-1.468,1.746a.744.744,0,0,0,.135.43c.13.143.529.149,9.457.148,5.127,0,9.362-.028,9.412-.061Zm-9.242-7.2a41.749,41.749,0,0,1,3.662-3.482,1.879,1.879,0,0,1,.566-.011c.312.042.507.216,2.753,2.461,1.329,1.329,2.446,2.416,2.481,2.416s.053-1.447.04-3.215a15.3,15.3,0,0,0-.135-3.35c-.1-.124-.868-.135-9.471-.135s-9.371.011-9.508.135-.149.365-.149,4.167c0,2.217.026,4.032.058,4.032s.6-.533,1.252-1.185l1.194-1.185h.95l1.4,1.388c.769.763,1.416,1.388,1.438,1.389s1.584-1.541,3.472-3.425Zm-4.963,1.218a2.788,2.788,0,0,1-1.33-.791,2.679,2.679,0,0,1-.743-2.619,2.841,2.841,0,0,1,1.734-1.976,3.108,3.108,0,0,1,2.183.067A3.417,3.417,0,0,1,15.624-52.8a3.1,3.1,0,0,1,.073,2.2,2.923,2.923,0,0,1-1.463,1.547,3.194,3.194,0,0,1-1.821.21Zm.937-2.388a.427.427,0,0,0,.082-.632.486.486,0,0,0-.726-.037.468.468,0,0,0,.644.669Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ImageIcon;
