import api from 'api';
import {
  CardCreateSchema,
  CardStatus,
  CardUpdateSchema,
  LinkUpdateSchema,
  NoteCreateSchema,
  ProjectConfig,
  ProjectUpdateSchema,
  StepConfig,
  StepCreateSchema,
  StepUpdateSchema,
} from 'api/generated/models';
import { LinkCreateSchema } from 'api/generated/models/link-create-schema';
import { getCheckableState } from 'components/TextEditor/utils';
import { ContentState } from 'draft-js';
import i18n from 'i18n';
import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import { store } from 'stores';
import contentFromString from 'utils/contentFromString';
import { isAdmin } from 'utils/isAdmin';
import isEmptyContent from 'utils/isEmptyContent';
import { RECONNECT_TYPE } from 'utils/ReconnectingWebsocket';
import { v4 } from 'uuid';
import { Profile } from './profiles';
import { ProjectValue, VisibleTo } from './workspaces';

type ProjectData = Record<string, any>;

type CardConfig = Record<string, any>;
type CardData = Record<string, any>;

export interface Membership {
  id: number;
  profile: Profile;
  is_admin: boolean;
}

export class Link {
  public id: number;

  public project: Project;

  @observable
  public name: string;

  @observable
  public target: string;

  @observable
  public icon: string;

  constructor(project: Project, data: Partial<Link>) {
    Object.assign(this, data);
    this.project = project;
  }

  private _update(data: Partial<Link>) {
    Object.assign(this, data);
  }

  public async update(newData: LinkUpdateSchema) {
    const { data } = await api.projects.updateLink(
      this.id,
      this.project.id,
      newData,
    );
    this._update(data);
  }
}

export class Note {
  public id: number;

  public card: Card;

  public date_created: DateTime;

  public profile: Profile;

  @observable
  public content: string;

  constructor(card: Card, data: Partial<Note>) {
    Object.assign(this, data);
    this.card = card;
  }

  private _update(data: Partial<Note>) {
    Object.assign(this, data);
  }

  @computed
  get isOwner() {
    const profile = store.workspaceStore.currentProfile;
    return profile && profile.id === this.profile.id;
  }
}

export class Card {
  public step: Step;

  public id: number;

  @observable
  public title: string;

  @observable
  public description: string;

  @observable
  public order: number;

  @observable
  public status_order: number;

  @observable
  public status: string;

  @observable
  public label: string | null;

  @observable
  public due_date: DateTime | null;

  @observable
  public config: CardConfig;

  @observable
  public data: CardData;

  @observable
  public notes: Note[] = [];

  @observable
  public note_count: number;

  @observable
  public postit_count: number;

  @observable
  public assignees: Profile[] = [];

  @observable
  public responsibles: Record<number, boolean> = {};

  @observable
  public locked_by: number | null = null;

  @observable
  public has_new_note: boolean = false;

  @observable
  public notes_open: boolean = false;

  @observable
  public done: boolean = false;

  @observable
  public blocked: boolean = false;

  @observable
  public recurrence_id: number | null;

  public created: boolean = false;

  private lockTimeout: number | null;

  constructor(step: Step, data: Partial<Card>) {
    Object.assign(this, data);
    this.step = step;
  }

  @action
  public _update(data: Partial<Card>) {
    Object.assign(this, data);
  }

  @action
  public async update(newData: CardUpdateSchema) {
    if (newData.order !== undefined) {
      // prevent flash of unsorted content
      this.order = newData.order;
    }

    if (newData.status !== undefined) {
      // prevent flash of unsorted content
      this.status = newData.status;
    }

    if (newData.status_order !== undefined) {
      // prevent flash of unsorted content
      this.status_order = newData.status_order;
    }

    const { data } = await api.projects.updateCard(
      this.id,
      this.step.id,
      this.step.project.id,
      newData,
    );
    this._update(data);
  }

  public async loadNotes() {
    const { data } = await api.projects.getNotes(
      this.id,
      this.step.id,
      this.step.project.id,
    );
    runInAction(() => {
      this.notes = data.map((note) => new Note(this, note));
    });
  }

  public async createNote(note: NoteCreateSchema) {
    const { data } = await api.projects.createNote(
      this.id,
      this.step.id,
      this.step.project.id,
      note,
    );
    runInAction(() => {
      this.notes.push(new Note(this, data));
    });
    return data;
  }

  public async deleteNote(note: Note) {
    await api.projects.deleteNote(
      note.id,
      this.id,
      this.step.id,
      this.step.project.id,
    );
    runInAction(() => {
      this.note_count -= 1;
      this.notes = this.notes.filter((c) => c.id !== note.id);
    });
  }

  public async assign(profile: Profile) {
    await api.projects.assign(
      this.id,
      this.step.id,
      this.step.project.id,
      profile.id,
    );
    runInAction(() => {
      this.assignees.push(profile);
    });
  }

  public async unassign(profile: Profile) {
    await api.projects.unassign(
      this.id,
      this.step.id,
      this.step.project.id,
      profile.id,
    );
    runInAction(() => {
      this.assignees = this.assignees.filter((p) => p.id !== profile.id);
    });
  }

  public async setResponsible(profile: Profile, responsible: boolean) {
    await api.projects.setResponsible(
      this.id,
      this.step.id,
      this.step.project.id,
      profile.id,
      responsible,
    );
    runInAction(() => {
      this.responsibles[profile.id] = responsible;
    });
  }

  public async setBlocked(blocked: boolean) {
    await api.projects.setBlocked(
      this.id,
      this.step.id,
      this.step.project.id,
      blocked,
    );
    runInAction(() => {
      this.blocked = blocked;
    });
  }

  @action.bound
  public closeNotes() {
    this.notes_open = false;
  }

  @action.bound
  public openNotes() {
    this.notes_open = true;
  }

  @computed
  get noteCount() {
    return this.notes.length || this.note_count;
  }

  @computed
  get locked() {
    return !!(
      this.locked_by &&
      this.locked_by !== store.workspaceStore.currentProfile.id
    );
  }

  @action
  public lock() {
    if (this.lockTimeout) {
      window.clearTimeout(this.lockTimeout);
    }
    if (this.locked_by === store.workspaceStore.currentProfile.id) return;
    this.locked_by = store.workspaceStore.currentProfile.id;
    store.socket.send({
      type: 'cards/lock',
      data: { project_id: this.step.project.id, card_id: this.id },
    });
  }

  @action
  public unlock() {
    this.lockTimeout = window.setTimeout(() => {
      this.locked_by = null;
      store.socket.send({
        type: 'cards/unlock',
        data: { project_id: this.step.project.id, card_id: this.id },
      });
    }, 500);
  }

  public async reload() {
    const oldNoteCount = this.noteCount;
    const { data } = await api.projects.getCard(
      this.id,
      this.step.id,
      this.step.project.id,
    );
    this._update(data);

    // Add notif bubble when new note
    if (this.noteCount !== oldNoteCount) {
      runInAction(() => {
        this.has_new_note = true;
      });
    }
  }

  @action
  public async moveToStep(step: Step, update: boolean = true) {
    const status = step.statusList.find(
      (s) =>
        s.icon === this.statusInfos.icon &&
        s.name === this.statusInfos.name &&
        s.percent === this.statusInfos.percent,
    );
    const oldStepId = this.step.id;
    const newStatus =
      status ||
      (await step.addStatus({
        icon: this.statusInfos.icon,
        name: this.statusInfos.name,
        percent: this.statusInfos.percent,
      }));

    runInAction(() => {
      this.status = newStatus.key;
      this.step.cards = this.step.cards.filter((c) => c.id !== this.id);
      step.cards.push(this);
      this.step = step;
    });

    if (update) {
      const { data } = await api.projects.updateCard(
        this.id,
        oldStepId,
        this.step.project.id,
        { step_id: step.id, status: this.status },
      );
      this._update(data);
    }
  }

  @computed
  get statusInfos() {
    const status = this.step.statusList.find((c) => c.key === this.status);
    if (!status) {
      throw Error(`Invalid status ${this.status}`);
    }
    return status;
  }

  @computed
  get content() {
    if (!this.description) return ContentState.createFromText('');
    return contentFromString(this.description);
  }

  @computed
  get checkableState() {
    return getCheckableState(this.content);
  }

  @computed
  get hasContent() {
    return !isEmptyContent(this.content);
  }

  @computed
  get hasRecurrence() {
    return !!this.recurrence_id;
  }

  @computed
  get isBlocked() {
    return this.blocked && !this.step.project.isAdmin;
  }
}

export class Step {
  public project: Project;

  public id: number;

  @observable
  public name: string;

  @observable
  public order: number;

  @observable
  public cards: Card[] = [];

  @observable
  public config: StepConfig;

  @observable
  public loadingCards: boolean = false;

  constructor(project: Project, data: Partial<Step>) {
    Object.assign(this, data);
    this.project = project;
  }

  private _update(data: Partial<Step>) {
    Object.assign(this, data);
  }

  @action
  public async update(newData: StepUpdateSchema) {
    if (newData.order !== undefined) {
      // prevent flash of unsorted content
      this.order = newData.order;
    }
    const { data } = await api.projects.updateStep(
      this.id,
      this.project.id,
      newData,
    );
    this._update(data);
  }

  public async loadCards() {
    this.loadingCards = true;
    const { data } = await api.projects.getCards(this.id, this.project.id);
    runInAction(() => {
      this.cards = data.map((card) => new Card(this, card));
      this.loadingCards = false;
    });
  }

  public async createCard(card: CardCreateSchema) {
    const { data } = await api.projects.createCard(
      this.id,
      this.project.id,
      card,
    );
    const newCard = new Card(this, data);
    newCard.created = true;

    runInAction(() => {
      this.cards.push(newCard);
      store.workspaceStore.currentWorkspace!.card_count += 1;
    });
    return newCard;
  }

  public async deleteCard(card: Card) {
    await api.projects.deleteCard(card.id, this.id, this.project.id);
    runInAction(() => {
      this.cards = this.cards.filter((c) => c.id !== card.id);
      store.workspaceStore.currentWorkspace!.card_count -= 1;
    });
  }

  @computed
  get progress() {
    if (this.cards.length === 0) return 0;
    return this.doneCardCount / this.cardCount;
  }

  @action
  public async updateStatusList(status: CardStatus[]) {
    const newConfig = {
      ...this.config,
      status,
    };
    this.config = newConfig;
    await this.update({
      config: newConfig,
    });
  }

  public async updateStatus(status: CardStatus, newData: Partial<CardStatus>) {
    if (newData.percent !== undefined) {
      if (status.percent && !newData.percent) {
        this.cards.forEach((c) => {
          if (c.status === status.key) {
            c._update({ done: false });
          }
        });
      }
      if (!status.percent && newData.percent) {
        this.cards.forEach((c) => {
          if (c.status === status.key) {
            c._update({ done: true });
          }
        });
      }
    }
    await this.update({
      config: {
        ...this.config,
        status: this.config.status.map((s) =>
          s.key === status.key ? { ...status, ...newData } : s,
        ),
      },
    });
  }

  public async addStatus(status?: Partial<CardStatus>) {
    const new_status = {
      name: '',
      key: v4(),
      icon: ':bloomup-todo:',
      ...status,
    };
    await this.updateStatusList([...this.statusList, new_status]);
    return new_status;
  }

  public async moveStatus(status: CardStatus, toIndex: number) {
    const previousIndex = this.statusKeys.indexOf(status.key);
    const newStatusList = [...this.statusList];
    newStatusList.splice(previousIndex, 1);
    newStatusList.splice(toIndex, 0, status);
    await this.updateStatusList(newStatusList);
  }

  public async deleteStatus(status: CardStatus) {
    const previousIndex = this.statusKeys.indexOf(status.key);
    const newStatusList = [...this.statusList];
    newStatusList.splice(previousIndex, 1);
    await this.updateStatusList(newStatusList);
  }

  @computed
  get defaultStatus() {
    return this.config.status[0];
  }

  @computed
  get statusKeys() {
    return this.config.status.map((c) => c.key);
  }

  @computed
  get statusList() {
    return this.config.status;
  }

  @computed
  get doneCardCount() {
    if (this.cards.length === 0) return 0;
    return this.cards.filter((c) => c.done).length;
  }

  @computed
  get cardCount() {
    if (this.cards.length === 0) return 0;
    return this.cards.filter((c) => !c.statusInfos.count_disabled).length;
  }

  @computed
  get sortedCards() {
    return this.cards.sort((c1, c2) => c1.order - c2.order);
  }

  @computed
  get countEnabled() {
    return this.statusList.some((c) => !c.count_disabled);
  }

  @computed
  get sortedCardsByStatus() {
    const result: Record<string, Card[]> = {};

    this.statusKeys.forEach((c) => {
      result[c] = [];
    });

    this.cards
      .sort((c1, c2) => c1.status_order - c2.status_order)
      .forEach((c) => result[c.status]?.push(c));
    return result;
  }
}

export class Project {
  public id: number;

  @observable
  public name: string;

  @observable
  public data: ProjectData;

  @observable
  public is_private: boolean;

  @observable
  public memberships: Membership[];

  @observable
  public date_created: DateTime;

  @observable
  public steps: Step[] = [];

  @observable
  public links: Link[] = [];

  public config?: ProjectConfig;

  public card_count: number;
  public done_card_count: number;

  public percent_enabled: boolean = true;

  constructor(data: Partial<Project>) {
    Object.assign(this, data);
  }

  private _update(data: Partial<Project>) {
    Object.assign(this, data);
  }

  public async update(newData: ProjectUpdateSchema) {
    const { data } = await api.projects.updateProject(this.id, newData);
    this._update(data);
  }

  public async loadSteps() {
    const { data } = await api.projects.getSteps(this.id);
    runInAction(() => {
      this.steps = data.map((step) => new Step(this, step));
    });
    return this.steps;
  }

  public async createStep(step: StepCreateSchema) {
    const { data } = await api.projects.createStep(this.id, step);
    const newStep = new Step(this, data);
    runInAction(() => {
      this.steps.push(newStep);
    });
    return newStep;
  }

  public async deleteStep(step: Step) {
    await api.projects.deleteStep(step.id, this.id);
    runInAction(() => {
      store.workspaceStore.currentWorkspace!.card_count -= step.cardCount;
      this.steps = this.steps.filter((c) => c.id !== step.id);
    });
  }

  public async cloneStep(step: Step) {
    const { data } = await api.projects.cloneStep(step.id, this.id, {
      name: `${step.name} - ${i18n.t('commons.copy')}`,
      order: step.order + 0.1,
    });
    const newStep = new Step(this, data);
    runInAction(() => {
      store.workspaceStore.currentWorkspace!.card_count += step.cardCount;
      this.steps.push(newStep);
    });
    newStep.loadCards();
    return newStep;
  }

  public async loadLinks() {
    const { data } = await api.projects.getLinks(this.id);
    runInAction(() => {
      this.links = data.map((link) => new Link(this, link));
    });
    return this.links;
  }

  public async createLink(link: LinkCreateSchema) {
    const { data } = await api.projects.createLink(this.id, link);
    runInAction(() => {
      this.links.push(new Link(this, data));
    });
    return data;
  }

  public async deleteLink(link: Link) {
    await api.projects.deleteLink(link.id, this.id);
    runInAction(() => {
      this.links = this.links.filter((c) => c.id !== link.id);
    });
  }

  public async addMember(profile: Profile) {
    const { data } = await api.projects.createMembership(this.id, {
      profile_id: profile.id,
      is_admin: false,
    });
    runInAction(() => {
      this.memberships.push(data);
    });
  }

  public async removeMember(profile: Profile) {
    const membership = this.memberships.find(
      (p) => p.profile.id === profile.id,
    );
    if (!membership) return;
    await api.projects.deleteMembership(membership.id, this.id);
    runInAction(() => {
      this.memberships = this.memberships.filter((c) => c.id !== membership.id);
    });
  }

  public async updateMember(profile: Profile, isAdmin: boolean) {
    const membership = this.memberships.find(
      (p) => p.profile.id === profile.id,
    );
    if (!membership) return;
    const { data } = await api.projects.updateMembership(
      membership.id,
      this.id,
      {
        is_admin: isAdmin,
      },
    );
    runInAction(() => {
      Object.assign(membership, data);
    });
  }

  @computed
  get isAdmin() {
    const profile = store.workspaceStore.currentProfile;

    if (isAdmin(profile)) return true;

    return this.memberships.some(
      (m) => m.profile.id === profile.id && m.is_admin,
    );
  }

  @computed
  get isMember() {
    const profile = store.workspaceStore.currentProfile;
    if (isAdmin(profile)) {
      return true;
    }
    return this.memberships.some((m) => m.profile.id === profile.id);
  }

  @computed
  get isFavorite() {
    return store.projectStore.favoriteProjects.has(this.id);
  }

  public isProfileMember(profile: Profile) {
    return this.memberships.some((m) => m.profile.id === profile.id);
  }

  public isProfileAdmin(profile: Profile) {
    return this.memberships.some(
      (m) => m.is_admin && m.profile.id === profile.id,
    );
  }

  @computed
  get assigneeIds() {
    const res = new Set();
    this.steps.forEach((step) => {
      step.cards.forEach((card) => {
        card.assignees.forEach((profile) => {
          res.add(profile.id);
        });
      });
    });
    return res;
  }

  @computed
  get percentEnabled() {
    if (this.steps.length === 0) return this.percent_enabled;
    return this.steps.some((c) => c.countEnabled);
  }

  public canSee(value: ProjectValue) {
    if (value.visibleTo === VisibleTo.everyone) return true;
    if (value.visibleTo === VisibleTo.project_members) return this.isMember;
    if (value.visibleTo === VisibleTo.project_admins) return this.isAdmin;
    if (value.visibleTo === VisibleTo.admins) {
      return store.workspaceStore.isAdmin;
    }

    return true;
  }

  @computed
  get progress(): [number, number] {
    if (this.steps.filter((c) => !c.loadingCards).length === 0) {
      return [this.done_card_count, this.card_count];
    }

    let doneCount = 0;
    let count = 0;

    this.steps.forEach((step) => {
      if (!step.countEnabled) return;
      doneCount += step.doneCardCount;
      count += step.cardCount;
    });

    if (count === 0) return [0, 0];

    return [doneCount, count];
  }

  @computed
  get adminMemberships() {
    return this.memberships.filter((ms) => ms.is_admin);
  }

  @computed
  get memberMemberships() {
    return this.memberships.filter((ms) => !ms.is_admin);
  }

  public getProperty(prop: string) {
    if (prop === 'name') return this.name;
    if (prop === 'progress') {
      return this.progress;
    }
    if (prop === 'team') {
      return this.memberships.map((c) => c.profile.id);
    }
    return this.data[prop];
  }

  public startRealtime() {
    store.socket.send({
      type: 'cards/watch',
      data: { project_id: this.id },
    });

    const stopWatchReconnect = store.socket.on(RECONNECT_TYPE, () => {
      store.socket.send({
        type: 'cards/watch',
        data: { project_id: this.id },
      });
    });

    const stopWatchCreated = store.socket.on('cards/created', (message) => {
      const { card_id, step_id, project_id } = message.data;
      if (project_id !== this.id) return;

      this.steps.some((step) => {
        if (step.id !== step_id) return false;

        async function f() {
          const { data } = await api.projects.getCard(
            card_id,
            step_id,
            project_id,
          );
          runInAction(() => {
            step.cards.push(new Card(step, data));
          });
        }
        f();

        return true;
      });
    });

    const stopWatchUpdated = store.socket.on('cards/updated', (message) => {
      const { card_id, step_id } = message.data;
      this.steps.some((step) =>
        step.cards.some((card) => {
          if (card_id === card.id) {
            if (step_id !== step.id) {
              const destStep = this.steps.find((c) => c.id === step_id);
              if (destStep) {
                card.moveToStep(destStep, false);
              }
            }
            card.reload();
            return true;
          }
          return false;
        }),
      );
    });

    const stopWatchDeleted = store.socket.on('cards/deleted', (message) => {
      const { card_id, step_id, project_id } = message.data;
      if (project_id !== this.id) return;

      this.steps.some((step) => {
        if (step.id !== step_id) return false;
        runInAction(() => {
          step.cards = step.cards.filter((c) => c.id !== card_id);
        });
        return true;
      });
    });

    const stopWatchLocked = store.socket.on('cards/locked', (message) => {
      const { card_id, profile_id } = message.data;
      this.steps.some((step) =>
        step.cards.some((card) => {
          if (card_id === card.id) {
            runInAction(() => {
              card.locked_by = profile_id;
            });
            return true;
          }
          return false;
        }),
      );
    });

    const stopWatchUnlocked = store.socket.on('cards/unlocked', (message) => {
      const { card_id } = message.data;
      this.steps.some((step) =>
        step.cards.some((card) => {
          if (card_id === card.id) {
            runInAction(() => {
              card.locked_by = null;
            });
            return true;
          }
          return false;
        }),
      );
    });

    const stopWatchLockeds = store.socket.on('cards/locks', (message) => {
      const { project_id, locks } = message.data;
      if (project_id !== this.id) return;
      (locks || []).forEach(({ card_id, profile_id }: any) => {
        this.steps.some((step) =>
          step.cards.some((card) => {
            if (card_id === card.id) {
              runInAction(() => {
                card.locked_by = profile_id;
              });
              return true;
            }
            return false;
          }),
        );
      });
    });

    return () => {
      store.socket.send({
        type: 'cards/unwatch',
        data: { project_id: this.id },
      });
      stopWatchLocked();
      stopWatchUnlocked();
      stopWatchLockeds();
      stopWatchUpdated();
      stopWatchCreated();
      stopWatchDeleted();
      stopWatchReconnect();
    };
  }

  @computed
  get sortedSteps() {
    return this.steps.sort((c1, c2) => c1.order - c2.order);
  }
}

export class Postit {
  public id: number;
  public card: Card;

  @observable
  public content?: string;

  @observable
  public color?: string;

  @observable
  public x: number;

  @observable
  public y: number;

  @observable
  public width: number;

  @observable
  public height: number;

  @observable
  public locked_by: number | null = null;

  @observable
  public selected: boolean = false;

  @observable
  public moving: boolean = false;

  private lockTimeout: number | null;

  constructor(card: Card, data: Partial<Postit>) {
    this.card = card;
    Object.assign(this, data);
  }

  @action
  public update(data: Partial<Postit>) {
    Object.assign(this, data);
  }

  @computed
  get locked() {
    return !!(
      this.locked_by &&
      this.locked_by !== store.workspaceStore.currentProfile.id
    );
  }

  @action
  public select() {
    this.selected = true;
  }

  @action
  public unselect() {
    this.selected = false;
  }

  @action
  public lock() {
    if (this.lockTimeout) {
      window.clearTimeout(this.lockTimeout);
    }
    if (this.locked_by === store.workspaceStore.currentProfile.id) return;
    this.locked_by = store.workspaceStore.currentProfile.id;
    store.socket.send({
      type: 'postits/lock',
      data: { card_id: this.card.id, postit_id: this.id },
    });
  }

  @action
  public unlock() {
    this.lockTimeout = window.setTimeout(() => {
      this.locked_by = null;
      store.socket.send({
        type: 'postits/unlock',
        data: { card_id: this.card.id, postit_id: this.id },
      });
    }, 500);
  }

  @computed
  get lockedByProfile() {
    if (!this.locked_by) return null;
    return store.profileStore.profilesByID.get(this.locked_by);
  }

  @action
  public startMove() {
    this.moving = true;
  }

  @action
  public stopMove() {
    this.moving = false;
  }

  public async save() {
    await api.projects.updatePostit(
      this.id,
      this.card.id,
      this.card.step.id,
      this.card.step.project.id,
      {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        color: this.color,
        content: this.content,
      },
    );
  }
}
