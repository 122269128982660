/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CustomCardSchema } from '../models';
// @ts-ignore
import { CustomLogSchema } from '../models';
// @ts-ignore
import { CustomProjectSchema } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * CustomApi - axios parameter creator
 * @export
 */
export const CustomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Calendar Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarCards: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/calendar_cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Custom Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomCards: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/cards`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Custom Logs
         * @param {number} [offset]
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomLogs: async (offset?: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/logs`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get Custom Projects
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomProjects: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/custom/projects`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }



            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomApi - functional programming interface
 * @export
 */
export const CustomApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get Calendar Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarCards(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomCardSchema>>> {
            const localVarAxiosArgs = await CustomApiAxiosParamCreator(configuration).getCalendarCards(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Custom Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomCards(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomCardSchema>>> {
            const localVarAxiosArgs = await CustomApiAxiosParamCreator(configuration).getCustomCards(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Custom Logs
         * @param {number} [offset]
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomLogs(offset?: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomLogSchema>>> {
            const localVarAxiosArgs = await CustomApiAxiosParamCreator(configuration).getCustomLogs(offset, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get Custom Projects
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomProjects(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomProjectSchema>>> {
            const localVarAxiosArgs = await CustomApiAxiosParamCreator(configuration).getCustomProjects(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomApi - factory interface
 * @export
 */
export const CustomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         *
         * @summary Get Calendar Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarCards(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<CustomCardSchema>> {
            return CustomApiFp(configuration).getCalendarCards(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Custom Cards
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomCards(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<CustomCardSchema>> {
            return CustomApiFp(configuration).getCustomCards(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Custom Logs
         * @param {number} [offset]
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomLogs(offset?: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<CustomLogSchema>> {
            return CustomApiFp(configuration).getCustomLogs(offset, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Custom Projects
         * @param {string} [lang]
         * @param {string} [xWorkspace]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomProjects(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<CustomProjectSchema>> {
            return CustomApiFp(configuration).getCustomProjects(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomApi - object-oriented interface
 * @export
 * @class CustomApi
 * @extends {BaseAPI}
 */
export class CustomApi extends BaseAPI {
    /**
     *
     * @summary Get Calendar Cards
     * @param {string} [lang]
     * @param {string} [xWorkspace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomApi
     */
    public getCalendarCards(lang?: string, xWorkspace?: string, options?: any) {
        return CustomApiFp(this.configuration).getCalendarCards(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Custom Cards
     * @param {string} [lang]
     * @param {string} [xWorkspace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomApi
     */
    public getCustomCards(lang?: string, xWorkspace?: string, options?: any) {
        return CustomApiFp(this.configuration).getCustomCards(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Custom Logs
     * @param {number} [offset]
     * @param {string} [lang]
     * @param {string} [xWorkspace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomApi
     */
    public getCustomLogs(offset?: number, lang?: string, xWorkspace?: string, options?: any) {
        return CustomApiFp(this.configuration).getCustomLogs(offset, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get Custom Projects
     * @param {string} [lang]
     * @param {string} [xWorkspace]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomApi
     */
    public getCustomProjects(lang?: string, xWorkspace?: string, options?: any) {
        return CustomApiFp(this.configuration).getCustomProjects(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }
}
