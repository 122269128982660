import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-radius: 40px;

  padding-left: 20px;
  padding-right: 20px;
  text-transform: none;
  white-space: nowrap;

  &.MuiButton-contained,
  &.MuiButton-outlined,
  &.MuiButton-text {
    font-size: 16px;
    height: 35px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &.MuiButton-containedSizeSmall,
  &.MuiButton-outlinedSizeSmall,
  &.MuiButton-textSizeSmall {
    padding: 2px 14px;
    font-size: 13px;

    height: 28px;
  }

  &.MuiButton-containedSizeLarge,
  &.MuiButton-outlinedSizeLarge,
  &.MuiButton-textSizeLarge {
    padding: 10px 28px;
    height: 50px;
  }

  svg {
    font-size: 1em;
    margin-right: 5px;
    margin-left: 5px;
  }

  .info-button {
    color: var(--color-light-grey);
  }
`;

export interface RoundedButtonProps extends ButtonProps {}

function RoundedButton(props: RoundedButtonProps) {
  return <StyledButton {...(props as any)} />;
}

export default RoundedButton;
