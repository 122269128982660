import api from 'api';
import { ProjectCreateSchema } from 'api/generated/models';
import { RecurrenceCreateSchema } from 'api/generated/models/recurrence-create-schema';
import { FAVORITE_PROJECTS_KEY } from 'config';
import { action, computed, observable, runInAction } from 'mobx';
import { Project } from 'models/projects';
import { Recurrence } from 'models/recurrence';
import { RootStore } from '.';

export class ProjectStore {
  public projectsByID = observable.map<number, Project>();
  public recurrencesByID = observable.map<number, Recurrence>();

  constructor(private rootStore: RootStore) {}

  public async loadProject(id: number) {
    const found = this.projectsByID.get(id);
    if (found) return found;

    const { data } = await api.projects.getProject(id);
    const project = new Project(data);
    runInAction(() => {
      this.projectsByID.set(id, project);
    });

    return project;
  }

  public reset() {
    this.projectsByID.clear();
    this.recurrencesByID.clear();
  }

  public async loadProjects() {
    const { data } = await api.projects.getProjects();
    runInAction(() => {
      data.forEach((projectData) => {
        const project = new Project(projectData);
        this.projectsByID.set(project.id, project);
      });
    });
  }

  public async createProject(newData: ProjectCreateSchema) {
    const { data } = await api.projects.createProject(newData);
    const project = new Project(data);
    runInAction(() => {
      this.projectsByID.set(project.id, project);
    });
    return project;
  }

  @computed
  get projects() {
    return Array.from(this.projectsByID.values());
  }

  public async deleteProject(project: Project) {
    await api.projects.deleteProject(project.id);
    runInAction(() => {
      this.projectsByID.delete(project.id);
    });
  }

  public async loadRecurrence(id: number) {
    const found = this.recurrencesByID.get(id);
    if (found) return found;

    const { data } = await api.projects.getRecurrence(id);
    const recurrence = new Recurrence(data);
    runInAction(() => {
      this.recurrencesByID.set(id, recurrence);
    });

    return recurrence;
  }

  public async createRecurrence(newData: RecurrenceCreateSchema) {
    const { data } = await api.projects.createRecurrence(newData);
    const recurrence = new Recurrence(data);
    runInAction(() => {
      this.recurrencesByID.set(recurrence.id, recurrence);
    });
    return recurrence;
  }

  public async deleteRecurrence(recurrence: Recurrence) {
    await api.projects.deleteRecurrence(recurrence.id);
    runInAction(() => {
      this.recurrencesByID.delete(recurrence.id);
    });
  }

  @computed
  get favoriteProjects(): Set<number> {
    const data: number[] = this.rootStore.workspaceStore.getStorageItem(
      FAVORITE_PROJECTS_KEY,
      true,
      [],
    )!;
    return new Set(data);
  }

  @action
  public toggleFavorite(project: Project) {
    if (project.isFavorite) {
      this.favoriteProjects.delete(project.id);
      this.rootStore.workspaceStore.setStorageItem(
        FAVORITE_PROJECTS_KEY,
        Array.from(this.favoriteProjects),
        true,
      );
    } else {
      this.favoriteProjects.add(project.id);
      this.rootStore.workspaceStore.setStorageItem(
        FAVORITE_PROJECTS_KEY,
        Array.from(this.favoriteProjects),
        true,
      );
    }
  }
}
