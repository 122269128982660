import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function InfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Info-a{fill:none;}.Info-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2870 -1630)">
        <g transform="translate(42)">
          <g transform="translate(75 66)">
            <rect
              className="Info-a"
              width="30"
              height="30"
              transform="translate(2753 1564)"
            />
          </g>
        </g>
        <path
          className="Info-b"
          d="M9.135,0a9.135,9.135,0,1,0,9.135,9.135A9.146,9.146,0,0,0,9.135,0Zm.594,14.555c-.434.072-1.3.253-1.736.289a1.069,1.069,0,0,1-.935-.487,1.142,1.142,0,0,1-.138-1.045L8.647,8.564h-1.8a2.146,2.146,0,0,1,1.69-2,6.611,6.611,0,0,1,1.736-.288,1.377,1.377,0,0,1,.935.487,1.142,1.142,0,0,1,.138,1.045L9.624,12.561h1.795a2.021,2.021,0,0,1-1.689,1.994Zm.548-8.846a1.142,1.142,0,1,1,1.142-1.142A1.142,1.142,0,0,1,10.277,5.71Z"
          transform="translate(2876 1636)"
        />
      </g>
    </SvgIcon>
  );
}

export default InfoIcon;
