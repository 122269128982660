/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExtractResult } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { ResourceCreateSchema } from '../models';
// @ts-ignore
import { ResourceSchema } from '../models';
// @ts-ignore
import { ResourceUpdateSchema } from '../models';
/**
 * ResourcesApi - axios parameter creator
 * @export
 */
export const ResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Resource
         * @param {ResourceCreateSchema} resourceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResource: async (resourceCreateSchema: ResourceCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceCreateSchema' is not null or undefined
            if (resourceCreateSchema === null || resourceCreateSchema === undefined) {
                throw new RequiredError('resourceCreateSchema','Required parameter resourceCreateSchema was null or undefined when calling createResource.');
            }
            const localVarPath = `/resources`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resourceCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resourceCreateSchema !== undefined ? resourceCreateSchema : {}) : (resourceCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource: async (resourceId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            if (resourceId === null || resourceId === undefined) {
                throw new RequiredError('resourceId','Required parameter resourceId was null or undefined when calling deleteResource.');
            }
            const localVarPath = `/resources/{resource_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extract Resource Link Meta
         * @param {string} url 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractResourceLinkMeta: async (url: string, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling extractResourceLinkMeta.');
            }
            const localVarPath = `/resources/extract`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Global Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalResources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources/globals`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource: async (resourceId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            if (resourceId === null || resourceId === undefined) {
                throw new RequiredError('resourceId','Required parameter resourceId was null or undefined when calling getResource.');
            }
            const localVarPath = `/resources/{resource_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Resources
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResources: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/resources`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Resource
         * @param {number} resourceId 
         * @param {ResourceUpdateSchema} resourceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource: async (resourceId: number, resourceUpdateSchema: ResourceUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            if (resourceId === null || resourceId === undefined) {
                throw new RequiredError('resourceId','Required parameter resourceId was null or undefined when calling updateResource.');
            }
            // verify required parameter 'resourceUpdateSchema' is not null or undefined
            if (resourceUpdateSchema === null || resourceUpdateSchema === undefined) {
                throw new RequiredError('resourceUpdateSchema','Required parameter resourceUpdateSchema was null or undefined when calling updateResource.');
            }
            const localVarPath = `/resources/{resource_id}`
                .replace(`{${"resource_id"}}`, encodeURIComponent(String(resourceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resourceUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resourceUpdateSchema !== undefined ? resourceUpdateSchema : {}) : (resourceUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Resource
         * @param {ResourceCreateSchema} resourceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResource(resourceCreateSchema: ResourceCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceSchema>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).createResource(resourceCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).deleteResource(resourceId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Extract Resource Link Meta
         * @param {string} url 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extractResourceLinkMeta(url: string, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtractResult>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).extractResourceLinkMeta(url, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Global Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalResources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceSchema>>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).getGlobalResources(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceSchema>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).getResource(resourceId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Resources
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResources(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResourceSchema>>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).getResources(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Resource
         * @param {number} resourceId 
         * @param {ResourceUpdateSchema} resourceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResource(resourceId: number, resourceUpdateSchema: ResourceUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResourceSchema>> {
            const localVarAxiosArgs = await ResourcesApiAxiosParamCreator(configuration).updateResource(resourceId, resourceUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create Resource
         * @param {ResourceCreateSchema} resourceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResource(resourceCreateSchema: ResourceCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ResourceSchema> {
            return ResourcesApiFp(configuration).createResource(resourceCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ResourcesApiFp(configuration).deleteResource(resourceId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extract Resource Link Meta
         * @param {string} url 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extractResourceLinkMeta(url: string, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ExtractResult> {
            return ResourcesApiFp(configuration).extractResourceLinkMeta(url, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Global Resources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalResources(options?: any): AxiosPromise<Array<ResourceSchema>> {
            return ResourcesApiFp(configuration).getGlobalResources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Resource
         * @param {number} resourceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ResourceSchema> {
            return ResourcesApiFp(configuration).getResource(resourceId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Resources
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResources(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<ResourceSchema>> {
            return ResourcesApiFp(configuration).getResources(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Resource
         * @param {number} resourceId 
         * @param {ResourceUpdateSchema} resourceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResource(resourceId: number, resourceUpdateSchema: ResourceUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ResourceSchema> {
            return ResourcesApiFp(configuration).updateResource(resourceId, resourceUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
    /**
     * 
     * @summary Create Resource
     * @param {ResourceCreateSchema} resourceCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public createResource(resourceCreateSchema: ResourceCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).createResource(resourceCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Resource
     * @param {number} resourceId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public deleteResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).deleteResource(resourceId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extract Resource Link Meta
     * @param {string} url 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public extractResourceLinkMeta(url: string, lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).extractResourceLinkMeta(url, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Global Resources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public getGlobalResources(options?: any) {
        return ResourcesApiFp(this.configuration).getGlobalResources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Resource
     * @param {number} resourceId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public getResource(resourceId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).getResource(resourceId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Resources
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public getResources(lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).getResources(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Resource
     * @param {number} resourceId 
     * @param {ResourceUpdateSchema} resourceUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResourcesApi
     */
    public updateResource(resourceId: number, resourceUpdateSchema: ResourceUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ResourcesApiFp(this.configuration).updateResource(resourceId, resourceUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }
}
