/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { MyProfileSchema } from '../models';
// @ts-ignore
import { MyProfileUpdateSchema } from '../models';
// @ts-ignore
import { ProfileSchema } from '../models';
// @ts-ignore
import { ProfileUpdateSchema } from '../models';
/**
 * ProfilesApi - axios parameter creator
 * @export
 */
export const ProfilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete My Profile
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyProfile: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile: async (profileId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling deleteProfile.');
            }
            const localVarPath = `/profiles/{profile_id}`
                .replace(`{${"profile_id"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (profileId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling getProfile.');
            }
            const localVarPath = `/profiles/{profile_id}`
                .replace(`{${"profile_id"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profiles
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update My Profile
         * @param {MyProfileUpdateSchema} myProfileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile: async (myProfileUpdateSchema: MyProfileUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'myProfileUpdateSchema' is not null or undefined
            if (myProfileUpdateSchema === null || myProfileUpdateSchema === undefined) {
                throw new RequiredError('myProfileUpdateSchema','Required parameter myProfileUpdateSchema was null or undefined when calling updateMyProfile.');
            }
            const localVarPath = `/profiles/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof myProfileUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(myProfileUpdateSchema !== undefined ? myProfileUpdateSchema : {}) : (myProfileUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update My Profile Storage Data
         * @param {any} body 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfileStorageData: async (body: any, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateMyProfileStorageData.');
            }
            const localVarPath = `/profiles/current/storage_data`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Profile
         * @param {number} profileId 
         * @param {ProfileUpdateSchema} profileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (profileId: number, profileUpdateSchema: ProfileUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling updateProfile.');
            }
            // verify required parameter 'profileUpdateSchema' is not null or undefined
            if (profileUpdateSchema === null || profileUpdateSchema === undefined) {
                throw new RequiredError('profileUpdateSchema','Required parameter profileUpdateSchema was null or undefined when calling updateProfile.');
            }
            const localVarPath = `/profiles/{profile_id}`
                .replace(`{${"profile_id"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof profileUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(profileUpdateSchema !== undefined ? profileUpdateSchema : {}) : (profileUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfilesApi - functional programming interface
 * @export
 */
export const ProfilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete My Profile
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMyProfile(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).deleteMyProfile(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).deleteProfile(profileId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSchema>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).getProfile(profileId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Profiles
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfiles(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfileSchema>>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).getProfiles(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update My Profile
         * @param {MyProfileUpdateSchema} myProfileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyProfile(myProfileUpdateSchema: MyProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyProfileSchema>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).updateMyProfile(myProfileUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update My Profile Storage Data
         * @param {any} body 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyProfileStorageData(body: any, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyProfileSchema>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).updateMyProfileStorageData(body, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Profile
         * @param {number} profileId 
         * @param {ProfileUpdateSchema} profileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(profileId: number, profileUpdateSchema: ProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSchema>> {
            const localVarAxiosArgs = await ProfilesApiAxiosParamCreator(configuration).updateProfile(profileId, profileUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProfilesApi - factory interface
 * @export
 */
export const ProfilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete My Profile
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyProfile(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProfilesApiFp(configuration).deleteMyProfile(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProfilesApiFp(configuration).deleteProfile(profileId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Profile
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProfileSchema> {
            return ProfilesApiFp(configuration).getProfile(profileId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Profiles
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfiles(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<ProfileSchema>> {
            return ProfilesApiFp(configuration).getProfiles(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update My Profile
         * @param {MyProfileUpdateSchema} myProfileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfile(myProfileUpdateSchema: MyProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<MyProfileSchema> {
            return ProfilesApiFp(configuration).updateMyProfile(myProfileUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update My Profile Storage Data
         * @param {any} body 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfileStorageData(body: any, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<MyProfileSchema> {
            return ProfilesApiFp(configuration).updateMyProfileStorageData(body, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Profile
         * @param {number} profileId 
         * @param {ProfileUpdateSchema} profileUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(profileId: number, profileUpdateSchema: ProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProfileSchema> {
            return ProfilesApiFp(configuration).updateProfile(profileId, profileUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfilesApi - object-oriented interface
 * @export
 * @class ProfilesApi
 * @extends {BaseAPI}
 */
export class ProfilesApi extends BaseAPI {
    /**
     * 
     * @summary Delete My Profile
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public deleteMyProfile(lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).deleteMyProfile(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Profile
     * @param {number} profileId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public deleteProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).deleteProfile(profileId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Profile
     * @param {number} profileId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public getProfile(profileId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).getProfile(profileId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Profiles
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public getProfiles(lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).getProfiles(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update My Profile
     * @param {MyProfileUpdateSchema} myProfileUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public updateMyProfile(myProfileUpdateSchema: MyProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).updateMyProfile(myProfileUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update My Profile Storage Data
     * @param {any} body 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public updateMyProfileStorageData(body: any, lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).updateMyProfileStorageData(body, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Profile
     * @param {number} profileId 
     * @param {ProfileUpdateSchema} profileUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfilesApi
     */
    public updateProfile(profileId: number, profileUpdateSchema: ProfileUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProfilesApiFp(this.configuration).updateProfile(profileId, profileUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }
}
