import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function FileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.File-a{fill:none;}.File-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2868 -1674)">
        <rect
          className="File-a"
          width="30"
          height="30"
          transform="translate(2868 1674)"
        />
        <g transform="translate(2875 1680)">
          <path
            className="File-b"
            d="M34.486,4.263a4.271,4.271,0,0,0-1.57-2.892L32.4.956a4.3,4.3,0,0,0-6.048.638L18.468,11.34a4.3,4.3,0,0,0,.638,6.048l.513.415a4.3,4.3,0,0,0,6.031-.618l5.321-6.574a2.734,2.734,0,0,0-.4-3.84L30.3,6.553a2.733,2.733,0,0,0-3.84.4l-5.311,6.563A1,1,0,0,0,22.7,14.78l5.311-6.563a.73.73,0,0,1,1.025-.108l.269.218a.73.73,0,0,1,.108,1.025l-5.3,6.556a2.3,2.3,0,0,1-3.233.34l-.513-.415a2.3,2.3,0,0,1-.341-3.233l7.887-9.747a2.3,2.3,0,0,1,3.233-.341l.513.415A2.3,2.3,0,0,1,32,6.16a1,1,0,0,0,.777,1.63h0a1,1,0,0,0,.778-.371A4.271,4.271,0,0,0,34.486,4.263Z"
            transform="translate(-17.51 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default FileIcon;
