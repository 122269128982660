import { observer } from 'mobx-react-lite';
import Pricing from 'modals/billing/Pricing';
import { transparentize } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled, { createGlobalStyle } from 'styled-components';
import { COLORS } from 'theme';
import stopPropagation from 'utils/stopPropagation';
import ArrowRightIcon from './icons/ArrowRightIcon';
import CrossIcon from './icons/CrossIcon';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  padding: 8px 20px;
  text-align: center;
  background-color: ${transparentize(0.2, COLORS.secondary)};
  color: var(--color-white);
  cursor: pointer;
  z-index: 10;
  box-shadow: var(--shadow-sm);

  svg {
    vertical-align: middle;
    font-size: 17px;
    margin-left: 7px;
    margin-top: -3px;
  }
`;

const Link = styled.span`
  text-decoration: underline;
`;

const FreemiumStyles = createGlobalStyle`
  .intercom-lightweight-app-launcher {
    bottom: 50px!important;
  }
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 8px;

  svg {
    vertical-align: middle;
    font-size: 25px;
  }
`;

interface FreemiumBannerProps {}

function FreemiumBanner(props: FreemiumBannerProps) {
  const workspace = useCurrentWorkspace();
  const [closed, setClosed] = useState(false);
  const { uiStore } = useStore();
  const { t } = useTranslation();

  if (closed) return null;

  return (
    <Wrapper onClick={() => uiStore.pushSide(<Pricing />)}>
      <FreemiumStyles />
      {t('freemium.banner.title')} ({workspace.card_count}/
      {workspace.max_card_count} -{' '}
      {((workspace.card_count / workspace.max_card_count) * 100).toFixed(0)}%).{' '}
      <Link>{t('freemium.banner.subscribe_action')}</Link>{' '}
      {t('freemium.banner.subscribe_label')}
      <ArrowRightIcon />
      <Close>
        <CrossIcon
          style={{ color: COLORS.white }}
          onClick={(e) => {
            setClosed(true);
            return stopPropagation(e);
          }}
        />
      </Close>
    </Wrapper>
  );
}

export default observer(FreemiumBanner);
