import { ResourceType } from 'models/resources';
import React from 'react';
import FileIcon from '../icons/FileIcon';
import ImageIcon from '../icons/ImageIcon';
import LinkIcon from '../icons/LinkIcon';
import PdfIcon from '../icons/PdfIcon';
import VideoIcon from '../icons/VideoIcon';

interface ResourceTypeIconProps {
  type: ResourceType;
}

const ICONS: Record<ResourceType, React.ReactElement | null> = {
  file: <FileIcon />,
  text: null,
  video: <VideoIcon />,
  pdf: <PdfIcon />,
  img: <ImageIcon />,
  link: <LinkIcon />,
};

function ResourceTypeIcon(props: ResourceTypeIconProps) {
  return ICONS[props.type] || <div>Error</div>;
}

export default ResourceTypeIcon;
