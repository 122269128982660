import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CheckCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.CheckCircle-a,.CheckCircle-b,.CheckCircle-c{fill:none;}.CheckCircle-a,.CheckCircle-b{stroke:currentColor;stroke-width:2px;}.CheckCircle-b{stroke-linecap:round;}.CheckCircle-d{stroke:none;}`}</style>
      </defs>
      <g transform="translate(-3140 -1429)">
        <g transform="translate(-1)">
          <g className="CheckCircle-a" transform="translate(3146 1434)">
            <circle className="CheckCircle-d" cx="10.5" cy="10.5" r="10.5" />
            <circle className="CheckCircle-c" cx="10.5" cy="10.5" r="9.5" />
          </g>
          <g transform="translate(3151.43 1441.758) rotate(-3)">
            <path
              className="CheckCircle-b"
              d="M0,0,3.936,3.441"
              transform="translate(0 2.706)"
            />
            <line
              className="CheckCircle-b"
              y1="6.146"
              x2="6.146"
              transform="translate(3.936 0)"
            />
          </g>
        </g>
        <g transform="translate(38)">
          <g transform="translate(38)">
            <g transform="translate(123)">
              <rect
                className="CheckCircle-c"
                width="30"
                height="30"
                transform="translate(2941 1429)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default CheckCircleIcon;
