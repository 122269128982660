import { Box, MenuItem, Typography } from '@material-ui/core';
import { WorkspaceInvitationCreateSchema } from 'api/generated/models';
import BackLink from 'components/elements/BackLink';
import InfoButton from 'components/elements/InfoButton';
import LoadingRoundedButton from 'components/elements/LoadingRoundedButton';
import RoundedSelect from 'components/elements/RoundedSelect';
import RoundedTextField from 'components/elements/RoundedTextField';
import Title from 'components/elements/Title';
import AddPeopleIcon from 'components/icons/AddPeopleIcon';
import AddPersonIcon from 'components/icons/AddPersonIcon';
import CenteredContent from 'components/layouts/CenteredContent';
import InvitationTableRow from 'components/profiles/InvitationTableRow';
import Table from 'components/Table';
import { TableConfig } from 'components/Table/types';
import { observer } from 'mobx-react-lite';
import { Role } from 'models/profiles';
import { Project } from 'models/projects';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import isValidEmail from 'utils/isValidEmail';
import Pricing from '../billing/Pricing';
import { ModalContent, ModalFooter, ModalTitle } from '../styles';

const Wrapper = styled.div`
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const AddLink = styled.div`
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
  }
`;

const Caption = styled(Typography)`
  color: var(--color-dark-grey);
  font-weight: 300;
  margin-top: 10px;

  .link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const INVITATIONS_CONFIG: TableConfig = {
  values: [
    {
      key: 'email',
      name: 'Email',
      type: 'text',
      sortable: true,
    },

    {
      key: 'date_created',
      name: 'Date',
      type: 'date',
      sortable: true,
    },

    {
      key: 'role',
      name: 'Role',
      type: 'text',
      sortable: true,
    },
  ],
};

interface InviteMemberModalProps {
  project?: Project;
}

function InviteMemberModal(props: InviteMemberModalProps) {
  const workspace = useCurrentWorkspace();
  const { t } = useTranslation();
  const { uiStore, workspaceStore, profileStore } = useStore();

  useEffect(() => {
    workspace.loadInvitations();
  }, [workspace]);

  const [invitations, setInvitations] = useState<
    WorkspaceInvitationCreateSchema[]
  >([
    {
      email: '',
      role: Role.MEMBER as number,
    },
    {
      email: '',
      role: Role.MEMBER as number,
    },
  ]);

  const [loading, setLoading] = useState(false);

  async function onInvite() {
    setLoading(true);
    try {
      await Promise.all(
        invitations
          .filter((i) => i.email.length !== 0)
          .map((invitation) =>
            workspace.createInvitation({
              project_id: props.project?.id,
              email: invitation.email,
              role: invitation.role,
            }),
          ),
      );
    } finally {
      setLoading(false);
    }
    uiStore.popSide();
    uiStore.toast(
      t('toasts.invite_member.emoji'),
      t('toasts.invite_member.content'),
    );
  }

  const placeholders = t('members.invite.email.placeholder').split('|');
  const invitationCount =
    workspace.max_profile_count !== 0
      ? workspace.max_profile_count -
        profileStore.profiles.length -
        workspace.invitations.length
      : Infinity;

  const validInvitations = invitations.slice(0, invitationCount);

  const valid =
    validInvitations.length > 0 &&
    invitations.some((p) => p.email) &&
    !invitations.some((p) => p.email && !isValidEmail(p.email));

  return (
    <CenteredContent width="var(--center-width)">
      <ModalTitle>
        <Title>
          <AddPersonIcon />
          {t('members.invite.title')}
        </Title>
      </ModalTitle>
      <ModalContent>
        <Wrapper>
          {validInvitations.map((invitation, index) => {
            function updateInvitation(
              data: Partial<WorkspaceInvitationCreateSchema>,
            ) {
              setInvitations(
                validInvitations.map((inv, i) =>
                  i === index ? { ...inv, ...data } : inv,
                ),
              );
            }
            return (
              <Row>
                <RoundedTextField
                  autoFocus
                  fullWidth
                  label={t('members.invite.email.label')}
                  placeholder={placeholders[index % placeholders.length]}
                  error={
                    invitation.email.length > 0 &&
                    !isValidEmail(invitation.email)
                  }
                  value={invitation.email}
                  onChange={(e) => updateInvitation({ email: e.target.value })}
                  variant="outlined"
                />

                {workspaceStore.isAdmin && (
                  <RoundedSelect
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        horizontal: 'center',
                        vertical: 'bottom',
                      },
                    }}
                    style={{ marginLeft: '10px' }}
                    label={t('members.invite.roles.label')}
                    onChange={(e) =>
                      updateInvitation({ role: e.target.value as number })
                    }
                    value={invitation.role}
                    fullWidth
                    renderValue={(v) => (
                      <RoleWrapper>
                        {v === Role.ADMINISTRATOR
                          ? t('members.roles.admin')
                          : t('members.roles.member')}
                        <InfoButton
                          tooltip={
                            v === Role.ADMINISTRATOR
                              ? t('members.roles.admin_tooltip')
                              : t('members.roles.member_tooltip')
                          }
                        />
                      </RoleWrapper>
                    )}
                  >
                    <MenuItem value={Role.MEMBER}>
                      {t('members.roles.member')}
                    </MenuItem>
                    <MenuItem value={Role.ADMINISTRATOR}>
                      {t('members.roles.admin')}
                    </MenuItem>
                  </RoundedSelect>
                )}
              </Row>
            );
          })}
          {invitationCount > invitations.length ? (
            <Row>
              <AddLink
                onClick={() =>
                  setInvitations([
                    ...invitations,
                    { email: '', role: Role.MEMBER as number },
                  ])
                }
              >
                {t('members.invite.add_invitation')}
              </AddLink>
            </Row>
          ) : (
            <div>
              <Caption>
                <Trans i18nKey="members.invite.caption">
                  $1
                  <span
                    className={workspaceStore.isOwner ? 'link' : undefined}
                    onClick={() =>
                      workspaceStore.isOwner
                        ? uiStore.pushSide(<Pricing />)
                        : undefined
                    }
                  >
                    $2
                  </span>
                </Trans>
              </Caption>
            </div>
          )}
          {workspace.invitations.length > 0 && (
            <Box mt={5}>
              <Title>
                <AddPeopleIcon />
                {t('members.invitations.title')}
              </Title>
              <Box mt={2}>
                <Table
                  smallHeading
                  config={INVITATIONS_CONFIG}
                  items={workspace.invitations}
                  rounded
                  rowComponent={InvitationTableRow}
                />
              </Box>
            </Box>
          )}
        </Wrapper>
      </ModalContent>
      <ModalFooter>
        <BackLink popSide />
        <LoadingRoundedButton
          color="primary"
          variant="contained"
          size="small"
          onClick={onInvite}
          loading={loading}
          disabled={!valid}
        >
          {t('members.invite.submit')}
        </LoadingRoundedButton>
      </ModalFooter>
    </CenteredContent>
  );
}

export default observer(InviteMemberModal);
