import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Check-a,.Check-b{fill:none;}.Check-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.5px;}`}</style>
      </defs>
      <g transform="translate(-2984 -1573)">
        <rect
          className="Check-a"
          width="30"
          height="30"
          transform="translate(2984 1573)"
        />
        <line
          className="Check-b"
          x2="6.853"
          y2="6.853"
          transform="translate(2991.264 1588.362)"
        />
        <line
          className="Check-b"
          y1="13.706"
          x2="9.137"
          transform="translate(2998.117 1581.509)"
        />
      </g>
    </SvgIcon>
  );
}

export default CheckIcon;
