import { ButtonProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import RoundedButton from './RoundedButton';

const Wrapper = styled(RoundedButton)`
  color: var(--color-light-grey);
  transition: color 0.1s;
  font-weight: 400;

  &:hover {
    color: var(--color-primary);
  }
`;

interface TextButtonProps extends ButtonProps {}

function TextButton(props: TextButtonProps) {
  return <Wrapper color="primary" variant="text" {...(props as any)} />;
}

export default TextButton;
