import { Paper, TextareaAutosize, Typography } from '@material-ui/core';
import BloomupEmoji from 'components/elements/BloomupEmoji';
import ColoredIconButton from 'components/elements/ColoredIconButton';
import RoundedButton from 'components/elements/RoundedButton';
import Tooltip from 'components/elements/Tooltip';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import ProjectTemplateIcon from 'components/icons/ProjectTemplateIcon';
import FlexSeparator from 'components/utils/FlexSeparator';
import { AVATAR_COLORS } from 'config';
import { observer } from 'mobx-react-lite';
import NewProjectModal from 'modals/projects/New';
import { Template } from 'models/templates';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import stopPropagation from 'utils/stopPropagation';
import uploadFile from 'utils/uploadFile';

const Wrapper = styled(Paper)`
  width: 100%;
  border-radius: 7px;
  position: relative;
  box-shadow: var(--shadow-sm);
  overflow: hidden;
`;

const Header = styled.div`
  padding: 15px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;

  .hover {
    opacity: 0;
  }

  &:hover {
    .hover {
      opacity: 1;
    }
  }
`;

const TitleInput = styled.input`
  padding: 0;
  outline: none;
  font-size: 20px;
  font-family: Roboto;
  appearance: none;
  background-color: transparent;
  border: none;
  width: 100%;
  letter-spacing: 0.0325em;
`;

const Title = styled.div`
  padding: 0;
  outline: none;
  font-size: 20px;
  font-family: Roboto;
  appearance: none;
  background-color: transparent;
  border: none;
  letter-spacing: 0.0325em;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Label = styled.div`
  margin-right: 7px;
  height: 30px;
  width: 30px;
`;

const DescriptionInput = styled(TextareaAutosize)`
  padding: 0;
  outline: none;
  padding: 10px 15px;
  padding-top: 0px;
  appearance: none;
  background-color: transparent;
  border: none;
  resize: none;
  font-family: Roboto;
  font-size: 15px;
  width: 100%;
  font-weight: 300;
  letter-spacing: 0.0325em;
  line-height: 1.3em;
  margin: 10px 0;
  margin-bottom: 5px;
`;

const Description = styled.div`
  position: relative;
  padding: 0;
  outline: none;
  padding: 10px 15px;
  padding-top: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  resize: none;
  white-space: pre-line;
  font-family: Roboto;
  font-size: 15px;
  width: 100%;
  font-weight: 300;
  letter-spacing: 0.0325em;
  line-height: 1.3em;
  margin: 10px 0;
  .hover {
    opacity: 0;
  }

  &:hover {
    .hover {
      opacity: 1;
    }
  }
`;

const ImageActions = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;

  display: flex;
  align-items: center;

  > * {
    margin-left: 5px;
  }
`;

const ImageOuter = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  color: white;

  .hover {
    opacity: 0;
  }

  &:hover {
    .hover {
      opacity: 1;
    }
  }
`;

const StepName = styled.div`
  color: var(--color-dark-grey);
  font-weight: 300;
  cursor: default;
  margin-bottom: 5px;

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const CardCount = styled.span`
  color: var(--color-light-grey);
  font-weight: 300;
  cursor: default;
`;

const Steps = styled.div`
  padding: 15px;
`;

interface TemplateDetailsProps {
  template: Template;
  onDelete?: () => void;
  isSelection?: boolean;
}

function TemplateDetails(props: TemplateDetailsProps) {
  const [title, setTitle] = useState(props.template.name);
  const [description, setDescription] = useState(props.template.description);
  const { t } = useTranslation();
  const { uiStore, workspaceStore } = useStore();
  const currentProfile = useCurrentProfile();

  const [titleEditing, setTitleEditing] = useState(false);
  const [descriptionEditing, setDescriptionEditing] = useState(false);
  const [_image, setImage] = useState<string>('');

  async function updateImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;
    const file = e.target.files[0];
    setImage(URL.createObjectURL(file));
    const url = await uploadFile(file);
    props.template.update({ image: url });
  }

  function deleteImage() {
    setImage('');
    props.template.update({ image: null });
  }

  function update() {
    props.template.update({
      name: title,
      description,
    });
  }

  const canEdit =
    !!props.template.workspace_id &&
    !props.isSelection &&
    (workspaceStore.isAdmin || props.template.profile_id === currentProfile.id);

  const image = _image && _image.length ? _image : props.template.image;

  return (
    <Wrapper>
      <Header>
        {props.template.label && (
          <Label>
            <BloomupEmoji size={30} emoji={props.template.label} />
          </Label>
        )}
        {titleEditing ? (
          <TitleInput
            autoFocus
            onBlur={() => {
              setTitleEditing(false);
              update();
            }}
            value={title}
            readOnly={!canEdit}
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <Title
            onBlur={() => {
              setTitleEditing(false);
              update();
            }}
          >
            {title}
          </Title>
        )}
        {!titleEditing && canEdit && (
          <ColoredIconButton
            className="hover"
            style={{ margin: '0 8px' }}
            onClick={() => setTitleEditing(true)}
          >
            <EditIcon />
          </ColoredIconButton>
        )}
        <FlexSeparator />
        <RoundedButton
          onClick={(e) => {
            uiStore.pushSide(<NewProjectModal template={props.template} />);
            return stopPropagation(e);
          }}
          color="primary"
          variant="contained"
          size="small"
          style={{ marginRight: '7px', flexShrink: 0 }}
        >
          {t('project_templates.list.use_template')}
        </RoundedButton>
        {canEdit && (
          <>
            {props.template.workspace_id && (
              <Tooltip title={t('project_templates.list.delete_tooltip')}>
                <ColoredIconButton
                  withConfirm
                  bgColor={COLORS.error}
                  iconColor={COLORS.white}
                  onClick={props.onDelete}
                >
                  <DeleteIcon />
                </ColoredIconButton>
              </Tooltip>
            )}
          </>
        )}
      </Header>

      {descriptionEditing ? (
        <DescriptionInput
          autoFocus
          onFocus={() => setDescriptionEditing(true)}
          onBlur={() => {
            setDescriptionEditing(false);
            update();
          }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          readOnly={!canEdit}
        />
      ) : (
        <Description>
          {description}
          {!descriptionEditing && canEdit && (
            <ColoredIconButton
              style={{ position: 'absolute', right: '15px', top: 0 }}
              className="hover"
              onClick={() => setDescriptionEditing(true)}
            >
              <EditIcon />
            </ColoredIconButton>
          )}
        </Description>
      )}
      <ImageOuter>
        <ImageWrapper
          style={{
            backgroundImage: image ? `url("${image}")` : undefined,
            backgroundColor: image
              ? undefined
              : AVATAR_COLORS[props.template.id % AVATAR_COLORS.length],
          }}
        >
          {!image && <ProjectTemplateIcon style={{ fontSize: '40px' }} />}
          {canEdit && (
            <ImageActions className="hover">
              <label>
                <ColoredIconButton>
                  <input
                    style={{ display: 'none' }}
                    accept="image/*"
                    type="file"
                    onChange={updateImage}
                  />
                  <EditIcon />
                </ColoredIconButton>
              </label>
              {image && (
                <ColoredIconButton
                  onClick={deleteImage}
                  iconColor={COLORS.white}
                  bgColor={COLORS.error}
                  withConfirm
                >
                  <DeleteIcon />
                </ColoredIconButton>
              )}
            </ImageActions>
          )}
        </ImageWrapper>
      </ImageOuter>
      {props.template.steps.length > 1 && (
        <Steps>
          <Typography style={{ marginBottom: '10px' }}>
            {t('project_templates.list.steps')}
          </Typography>
          {props.template.steps
            .sort((a, b) => a.order - b.order)
            .map((step) => (
              <StepName>
                <CheckCircleIcon style={{ color: COLORS.lightGrey }} />
                {step.name}{' '}
                {step.cards.length > 0 && (
                  <CardCount>
                    ({step.cards.length}{' '}
                    {t('project_templates.list.card_count')})
                  </CardCount>
                )}
              </StepName>
            ))}
        </Steps>
      )}
    </Wrapper>
  );
}

export default observer(TemplateDetails);
