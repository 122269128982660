import BackIcon from 'components/icons/BackIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import useNavigate from 'utils/useNavigate';
import TextButton from './TextButton';

const Wrapper = styled(TextButton)`
  color: var(--color-light-grey);
  font-size: 1em;
  cursor: pointer;
`;

interface BackLinkProps {
  to?: string;
  onClick?: () => void;
  popSide?: boolean;
  withIcon?: boolean;
}

function BackLink(props: React.PropsWithChildren<BackLinkProps>) {
  const { uiStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick =
    props.onClick || (props.popSide ? () => uiStore.popSide() : undefined);
  return (
    <Wrapper
      onClick={() =>
        onClick ? onClick() : props.to ? navigate(props.to) : navigate(-1)
      }
    >
      {props.withIcon && <BackIcon />}
      {props.children || t('commons.back')}
    </Wrapper>
  );
}

export default BackLink;
