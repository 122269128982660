import { css } from 'emotion';
import React from 'react';
import styled from 'styled-components';
import NavLink, { NavLinkProps } from '../Router/NavLink';

const Wrapper = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  color: var(--color-white);

  height: var(--sidebar-width);
  width: var(--sidebar-width);
  text-decoration: none;

  text-align: center;

  svg {
    font-size: 30px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.div`
  font-size: 11px;
  margin-top: 5px;
`;

const ActiveClass = css`
  background-color: rgba(255, 255, 255, 0.2);
`;

interface SidebarItemProps extends NavLinkProps {
  title: string;
  end?: boolean;
}

function SidebarItem({
  children,
  title,
  ...props
}: React.PropsWithChildren<SidebarItemProps>) {
  return (
    <Wrapper activeClassName={ActiveClass} {...(props as any)}>
      {children}
      <Title>{title}</Title>
    </Wrapper>
  );
}

export default SidebarItem;
