import compressedEmojis from 'emoji-mart/data/google.json';
import { Data, Emoji, uncompress } from 'emoji-mart/dist-es/utils/data';
import { TFunction } from 'i18next';
import MiniSearch from 'minisearch';

export const emojisData: Data = compressedEmojis as any;
export const searchIndex: MiniSearch = new MiniSearch({
  fields: ['english_name', 'french_name'],
  storeFields: ['id'],
});

const NAMES = [
  'symbole des cent points',
  "symbole d'entrée des chiffres",
  'visage souriant',
  'visage de singe',
  'raisins',
  'lunettes',
  'drapeau à damiers',
  'lanterne de jackpot',
  'signe de la main',
  'globe terrestre europe-africain',
  'guichet automatique',
  'melon',
  'drapeau triangulaire sur poteau',
  'symbole de mise à la place des déchets',
  'arbre de Noël',
  'singe',
  'globe terrestre amériques',
  'lunettes de soleil noires',
  'dos de la main levé',
  'visage souriant avec la bouche ouverte',
  'globe terrestre asie-australie',
  'drapeaux croisés',
  'pastèque',
  'lunettes de protection',
  'main levée avec les doigts écartés',
  'visage souriant avec la bouche ouverte et les yeux souriants',
  "symbole de l'eau potable",
  "feux d'artifice",
  'gorille',
  'blouse de laboratoire',
  'mandarine',
  'symbole du fauteuil roulant',
  'drapeau noir ondulant',
  'orang-outan',
  "feu d'artifice",
  'globe terrestre avec méridiens',
  'visage souriant avec yeux souriants',
  'main levée',
  'pétard',
  'citron',
  'visage de chien',
  'symbole masculin',
  "main levée avec partie entre le majeur et l'annulaire",
  'carte du monde',
  'visage souriant avec bouche ouverte et yeux fermés',
  'drapeau blanc flottant',
  'gilet de sécurité',
  'visage souriant avec bouche ouverte et sueur froide',
  'scintillements',
  'banane',
  'drapeau arc-en-ciel',
  'signe de la main ok',
  'silhouette du japon',
  'chien',
  'symbole de la femme',
  'cravate',
  'drapeau pirate',
  'chien guide',
  'toilettes',
  'boussole',
  'rouler par terre en riant',
  'ballon',
  'main qui pince',
  'ananas',
  't-shirt',
  'chien de service',
  'symbole du bébé',
  'visage avec des larmes de joie',
  'popper de fête',
  'mangue',
  'main de la victoire',
  'montagne enneigée',
  "drapeau de l'île de l'Ascension",
  'jeans',
  'caniche',
  "main croisée avec l'index et le majeur",
  'drapeau andorran',
  'visage légèrement souriant',
  'pomme rouge',
  'toilettes',
  'foulard',
  'montagne',
  'balle de confetti',
  'signe de la main i love you',
  'visage de loup',
  'gants',
  'drapeau unifié des émirats arabes unis',
  "visage à l'envers",
  'pomme verte',
  'contrôle des passeports',
  'volcan',
  'arbre de tanabata',
  'coutumes',
  'manteau',
  'visage clignotant',
  'décoration de pin',
  'drapeau afghan',
  'visage de renard',
  'poire',
  'mont fuji',
  'signe des cornes',
  'appelez-moi la main',
  'drapeau antigua & barbuda',
  'raton laveur',
  'poupées japonaises',
  'visage souriant avec des yeux souriants',
  'pêche',
  'réclamation de bagages',
  'chaussettes',
  'camping',
  'robe',
  'plage avec parasol',
  'cerises',
  'visage de chat',
  'index blanc du revers gauche',
  'bagage gauche',
  "drapeau de l'Anguilla",
  'visage souriant avec halo',
  'banderole de carpe',
  "signe d'avertissement",
  'fraise',
  'index blanc du revers droit',
  'désert',
  'kimono',
  "drapeau de l'Albanie",
  'carillon du vent',
  'visage souriant avec des yeux et trois cœurs',
  'chat',
  "cérémonie d'observation de la lune",
  'visage souriant avec des yeux en forme de cœur',
  'sari',
  "drapeau de l'Arménie",
  'visage de lion',
  'île déserte',
  'index blanc pointé vers le haut',
  'kiwi',
  'enfants traversant',
  'parc national',
  'entrée interdite',
  'maillot de bain une pièce',
  'visage de tigre',
  'enveloppe cadeau rouge',
  "visage souriant avec des yeux d'étoile",
  'main inversée avec majeur étendu',
  "drapeau de l'Angola",
  'tomate',
  'noix de coco',
  'ruban',
  "signe d'interdiction d'entrée",
  'index blanc pointé vers le bas du revers',
  'drapeau antarctique',
  'slip',
  'visage jetant un baiser',
  'tigre',
  'stade',
  'léopard',
  'pas de vélos',
  'visage embrassant',
  'drapeau argentin',
  'avocat',
  'indice de pointage blanc',
  'cadeau emballé',
  'bâtiment classique',
  'short',
  'signe du pouce levé',
  'tête de cheval',
  'bikini',
  "symbole d'interdiction de fumer",
  'aubergine',
  'drapeau des samoa américaines',
  'ruban de rappel',
  'construction de bâtiments',
  'visage souriant blanc',
  'visage embrassant avec les yeux fermés',
  'signe des pouces vers le bas',
  "billets d'entrée",
  'drapeau autrichien',
  'vêtements de femme',
  'symbole de ne pas jeter de déchets',
  'pomme de terre',
  'cheval',
  'brique',
  'poing levé',
  'construction de maisons',
  'carotte',
  'ticket',
  'drapeau australien',
  "symbole d'eau non potable",
  'sac à main',
  'visage de licorne',
  'visage embrassant avec des yeux souriants',
  'signe de la main du poing',
  'médaille',
  'visage de zèbre',
  'sac à main',
  'maison abandonnée',
  'visage savourant de la nourriture délicieuse',
  'épi de maïs',
  "drapeau d'aruba",
  'pas de piétons',
  'construction de maisons',
  'piment',
  'drapeau des îles åland',
  'trophée',
  'cerf',
  'poing tourné vers la gauche',
  'visage avec langue collée',
  'poche',
  'pas de téléphones portables',
  'visage avec langue collée et œil qui cligne',
  'médaille de sport',
  'concombre',
  'visage de vache',
  'symbole de personne de moins de 18 ans',
  "drapeau de l'Azerbaïdjan",
  'sacs à provisions',
  'poing tourné vers la droite',
  'maison avec jardin',
  'signe des mains qui claquent',
  'vert feuillage',
  'immeuble de bureaux',
  'drapeau de la Bosnie-Herzégovine',
  'visage souriant avec un grand et un petit œil',
  'médaille de la première place',
  'bœuf',
  'cartable',
  'signe radioactif',
  'médaille de la deuxième place',
  'visage avec langue collée et yeux fermés',
  'brocoli',
  'signe de danger biologique',
  "chaussure d'homme",
  'personne levant les deux mains pour célébrer',
  'poste japonaise',
  'drapeau de la barbade',
  "buffle d'eau",
  'médaille de la troisième place',
  'poste européenne',
  'chaussure de sport',
  'flèche noire vers le haut',
  'vache',
  'signe des mains ouvertes',
  'ail',
  'visage bouche-argent',
  'drapeau du Bangladesh',
  'ballon de football',
  'visage étreignant',
  'oignon',
  'flèche du nord-est',
  'paumes des mains ensemble',
  'visage de cochon',
  'hôpital',
  'botte de randonnée',
  'drapeau de la Belgique',
  'drapeau du Burkina faso',
  'champignon',
  'cochon',
  'base-ball',
  'visage souriant avec les yeux et la main couvrant la bouche',
  'poignée de main',
  'chaussure plate',
  'banque',
  'flèche noire vers la droite',
  'cacahuètes',
  'visage avec doigt couvrant les lèvres fermées',
  'personne avec les mains pliées',
  'softball',
  'chaussure à talons hauts',
  'drapeau de la Bulgarie',
  'flèche du sud-est',
  'hôtel',
  'sanglier',
  'sandale de femme',
  'drapeau bahreïnien',
  'flèche noire vers le bas',
  'visage pensant',
  'main qui écrit',
  'marron',
  'basket et cerceau',
  'nez de cochon',
  'love hotel',
  'vernis à ongles',
  'volley-ball',
  'drapeau du Burundi',
  'flèche du sud-ouest',
  'bélier',
  'chaussures de ballet',
  'visage à fermeture éclair',
  'pain',
  'épicerie',
  'bottes de femme',
  'mouton',
  'visage avec un sourcil levé',
  'drapeau du Bénin',
  'flèche noire vers la gauche',
  'selfie',
  'croissant',
  'école',
  'football américain',
  'chèvre',
  'grand magasin',
  'st. barthelemy flag',
  'crown',
  'north west arrow',
  'neutral face',
  'rugby football',
  'flexed biceps',
  'baguette bread',
  'expressionless face',
  'womans hat',
  'pretzel',
  'mechanical arm',
  'up down arrow',
  'dromedary camel',
  'raquette et balle de tennis',
  'drapeau des Bermudes',
  'usine',
  'château japonais',
  'visage sans bouche',
  'jambe mécanique',
  'bagel',
  'chameau de Bactriane',
  'chapeau haut de forme',
  'flèche gauche droite',
  'drapeau brunei',
  'disque volant',
  'visage souriant',
  "casquette de fin d'études",
  'château européen',
  'jambe',
  'crêpes',
  'flèche à gauche avec crochet',
  'drapeau bolivien',
  'bowling',
  'lama',
  'flèche à droite avec crochet',
  'mariage',
  'drapeau caraïbes-pays bas',
  'pied',
  'visage de girafe',
  'visage inamouré',
  'casquette facturée',
  'gaufre',
  'batte et balle de cricket',
  'casque avec croix blanche',
  'oreille',
  'éléphant',
  'coin de fromage',
  'tour de tokyo',
  "flèche pointant vers la droite puis s'incurvant vers le haut",
  'bâton et balle de hockey sur gazon',
  'drapeau brésilien',
  'visage avec yeux roulants',
  'oreille avec appareil auditif',
  "flèche pointant vers la droite puis s'incurvant vers le bas",
  'bâton de hockey sur glace et palet',
  "viande à l'os",
  'chapelet de prières',
  'statue de la liberté',
  'visage grimaçant',
  'drapeau des Bahamas',
  'rhinocéros',
  'bâton et balle de crosse',
  'cuisse de volaille',
  'hippopotame',
  'nez',
  "flèches de cercle ouvert vers le bas et vers le haut dans le sens des aiguilles d'une montre",
  'drapeau du bhoutan',
  'église',
  'rouge à lèvres',
  'visage couché',
  "flèches de cercle ouvert vers le bas et vers le haut dans le sens des aiguilles d'une montre",
  "drapeau de l'île de Bouvet",
  'morceau de viande',
  'mosquée',
  'anneau',
  'cervelle',
  'ping-pong et balle',
  'visage soulagé',
  'visage de souris',
  'temple hindou',
  'dos avec flèche vers la gauche au-dessus',
  'pierre précieuse',
  'visage pensif',
  'drapeau du botswana',
  'souris',
  'bacon',
  'dent',
  'raquette et volant de badminton',
  'rat',
  'synagogue',
  'extrémité avec flèche vers la gauche au-dessus',
  'os',
  'gant de boxe',
  "haut-parleur avec coup d'annulation",
  'hamburger',
  'drapeau du Bélarus',
  'visage endormi',
  "on avec point d'exclamation avec flèche gauche droite au-dessus",
  "uniforme d'arts martiaux",
  'haut-parleur',
  'visage bavant',
  'yeux',
  'drapeau du Belize',
  'visage de hamster',
  'sanctuaire shinto',
  'frites',
  'filet de but',
  'kaaba',
  'bientôt avec flèche droite au-dessus',
  'drapeau du Canada',
  'face de lapin',
  'œil',
  'face de dormeur',
  'tranche de pizza',
  'haut-parleur avec une onde sonore',
  'lapin',
  'fontaine',
  'drapeau dans le trou',
  'haut avec flèche vers le haut',
  'visage avec masque médical',
  'drapeau des îles Cocos (keeling)',
  'hot dog',
  'haut-parleur à trois ondes sonores',
  'langue',
  'lieu de culte',
  'patin à glace',
  'sandwich',
  'tamia',
  'haut-parleur de sonorisation',
  'bouche',
  'congo - drapeau de la kinshasa',
  'tente',
  'visage avec thermomètre',
  'taco',
  'brumeux',
  'drapeau de la république centrafricaine',
  'bébé',
  'symbole atomique',
  'canne à pêche et poisson',
  'hérisson',
  'visage avec bandeau',
  "mégaphone d'encouragement",
  'visage nauséeux',
  'enfant',
  'drapeau congo - brazzaville',
  'chauve-souris',
  'masque de plongée',
  'burrito',
  'cor postal',
  'nuit avec étoiles',
  'symbole om',
  'étoile de david',
  'garçon',
  'cloche',
  'drapeau suisse',
  'chemise de course avec ceinture',
  'pain plat empaillé',
  "visage d'ours",
  'paysage urbain',
  'visage avec vomissement à bouche ouverte',
  'roue du dharma',
  'ski et chaussures de ski',
  'fille',
  'falafel',
  'visage qui éternue',
  "cloche avec coup d'annulation",
  'koala',
  'lever de soleil sur les montagnes',
  "drapeau de la côte d'ivoire",
  'lever de soleil',
  'yin yang',
  'adulte',
  'visage surchauffé',
  'partition musicale',
  'traîneau',
  'œuf',
  'visage de panda',
  'drapeau des îles Cook',
  'drapeau du Chili',
  'personne aux cheveux blonds',
  'paresse',
  'croix latine',
  'pierre de curling',
  'visage gelé',
  'cuisine',
  'paysage urbain au crépuscule',
  'note de musique',
  'drapeau du Cameroun',
  'notes de musique multiples',
  'visage aux yeux inégaux et à la bouche ondulée',
  'coup direct',
  'croix orthodoxe',
  'casserole de nourriture peu profonde',
  'loutre',
  'homme',
  'coucher de soleil sur les bâtiments',
  'personne barbue',
  'mouffette',
  'casserole de nourriture',
  'drapeau de Chine',
  'microphone de studio',
  'étoile et croissant',
  'yo-yo',
  'pont de nuit',
  'visage étourdi',
  'homme aux cheveux rouges',
  'cerf-volant',
  'bol avec cuillère',
  'drapeau colombien',
  'symbole de paix',
  'kangourou',
  'sources chaudes',
  'visage choqué avec tête explosée',
  'curseur de niveau',
  'blaireau',
  'billard',
  'homme aux cheveux bouclés',
  "drapeau de l'île de Clipperton",
  'cheval de carrousel',
  'visage avec chapeau de cow-boy',
  'ménorah à neuf branches',
  'salade verte',
  'boutons de commande',
  'pop-corn',
  'étoile à six branches avec point central',
  'empreintes de pattes',
  'grande roue',
  'microphone',
  'boule de cristal',
  'visage avec corne et chapeau de fête',
  'drapeau du Costa Rica',
  'homme aux cheveux blancs',
  'casque',
  'homme chauve',
  'visage souriant avec lunettes de soleil',
  'beurre',
  'montagnes russes',
  'dinde',
  'amulette de nazar',
  'drapeau de Cuba',
  'Bélier',
  'drapeau du Cap-Vert',
  'poteau de barbier',
  'taurus',
  'salière',
  'femme',
  'jeu vidéo',
  'poulet',
  'radio',
  "visage d'intello",
  'femme aux cheveux roux',
  'tente de cirque',
  'visage avec monocle',
  'aliments en conserve',
  'drapeau du curaçao',
  'gémeaux',
  'saxophone',
  'coq',
  'joystick',
  'guitare',
  'machine à sous',
  'boîte à bento',
  'locomotive à vapeur',
  'visage confus',
  "drapeau de l'île de Noël",
  'poussin à couver',
  'cancer',
  'drapeau chypriote',
  'visage inquiet',
  'wagon',
  'leo',
  'femme aux cheveux frisés',
  'poussin',
  'clavier musical',
  'jeu de dés',
  'biscuit de riz',
  'vierge',
  'drapeau tchèque',
  'boule de riz',
  'poussin de face',
  'pièce de puzzle',
  'trompette',
  'visage légèrement renfrogné',
  'train à grande vitesse',
  'Balance',
  'drapeau allemand',
  'riz cuit',
  'violon',
  'femme aux cheveux blancs',
  'oiseau',
  'visage blanc froncé',
  'train à grande vitesse avec nez de balle',
  'ours en peluche',
  'costume de pique noir',
  'banjo',
  'train',
  'scorpius',
  'curry et riz',
  'visage à la bouche ouverte',
  'drapeau diego garcia',
  'pingouin',
  'costume à cœur noir',
  'bol fumant',
  'sagittaire',
  'femme chauve',
  'colombe de la paix',
  'visage feutré',
  'métro',
  'drapeau de djibouti',
  'tambour avec baguettes',
  'spaghetti',
  'aigle',
  'visage étonné',
  'capricorne',
  'rail léger',
  'drapeau du Danemark',
  'téléphone portable',
  'costume noir en diamant',
  'costume noir de club',
  'aquarius',
  'patate douce grillée',
  'drapeau de la Dominique',
  'canard',
  'téléphone portable avec flèche vers la droite à gauche',
  'gare',
  'femme blonde',
  'visage rouge',
  'poisson',
  "pion d'échec",
  'homme blond',
  'téléphone noir',
  'oden',
  'drapeau de la République dominicaine',
  'tram',
  'cygne',
  'visage aux yeux suppliants',
  "drapeau de l'Algérie",
  'monorail',
  'hibou',
  'sushi',
  'récepteur téléphonique',
  'carte à jouer - blagueur noir',
  'ophiuchus',
  'visage froncé à la bouche ouverte',
  'personne âgée',
  'drapeau de ceuta & melilla',
  'flamant',
  'pager',
  'chemin de fer de montagne',
  'mahjong - dragon rouge',
  'homme âgé',
  'flèches tordues vers la droite',
  'crevettes frites',
  'visage angoissé',
  "flèches en cercle ouvert vers la droite et la gauche dans le sens des aiguilles d'une montre",
  'gâteau de poisson avec motif tourbillon',
  'télécopieur',
  'femme âgée',
  "drapeau de l'Équateur",
  'paon',
  'visage effrayé',
  'tramway',
  'cartes à jouer en forme de fleur',
  "flèches en cercle ouvert dans le sens des aiguilles d'une montre, à droite et à gauche, avec un cercle de recouvrement",
  'gâteau de lune',
  'arts du spectacle',
  'visage avec la bouche ouverte et des sueurs froides',
  'personne qui fronce les sourcils',
  "drapeau de l'Estonie",
  'batterie',
  'perroquet',
  'bus',
  'drapeau égyptien',
  'triangle noir à droite',
  'homme qui fronce les sourcils',
  'visage déçu mais soulagé',
  'prise électrique',
  'cadre avec photo',
  'bus qui arrive',
  'dango',
  'visage de grenouille',
  'ordinateur personnel',
  "palette d'artiste",
  'drapeau du Sahara occidental',
  'double triangle noir à droite',
  'visage en pleurs',
  'femme qui fronce les sourcils',
  'trolleybus',
  'crocodile',
  'boulette',
  'double triangle noir à droite avec barre verticale',
  'ordinateur de bureau',
  'personne faisant la moue',
  'tortue',
  'visage qui pleure fort',
  'drapeau érythréen',
  'bobine de fil',
  'minibus',
  'biscuit chinois',
  'pelote de laine',
  'boîte à emporter',
  'homme faisant la moue',
  'imprimante',
  'visage qui crie de peur',
  'drapeau espagnol',
  'ambulance',
  'triangle noir pointé à droite avec double barre verticale',
  'lézard',
  'drapeau éthiopien',
  'clavier',
  'crabe',
  'visage confondu',
  'serpent',
  'femme faisant la moue',
  'triangle noir pointé à gauche',
  'camion de pompiers',
  'double triangle noir pointé à gauche',
  'souris à trois boutons',
  'visage sans bon geste',
  'voiture de police',
  'visage de dragon',
  'visage persévérant',
  'homard',
  "drapeau de l'Union européenne",
  'visage déçu',
  'crevette',
  'dragon',
  'homme qui ne fait pas de gestes',
  'drapeau finlandais',
  'trackball',
  'double triangle noir pointé à gauche avec barre verticale',
  'voiture de police qui arrive',
  'minidisque',
  'visage avec sueur froide',
  'calamar',
  'sauropode',
  'petit triangle rouge pointé vers le haut',
  'drapeau fidjien',
  'femme faisant un geste de non',
  'taxi',
  'drapeau des îles Falkland',
  'disquette',
  't-rex',
  'huître',
  'double triangle noir pointé vers le haut',
  'taxi en sens inverse',
  'visage avec un geste ok',
  'visage fatigué',
  'homme avec un geste ok',
  'petit triangle rouge pointé vers le bas',
  'visage fatigué',
  'automobile',
  'glace molle',
  'disque optique',
  'baleine qui jaillit',
  'drapeau de la micronésie',
  'automobile qui arrive',
  'double triangle noir pointé vers le bas',
  'femme qui fait des gestes ok',
  'visage qui baille',
  'dvd',
  'baleine',
  'drapeau des îles Féroé',
  'glace rasée',
  'double barre verticale',
  "personne du bureau d'information",
  'dauphin',
  'véhicule de loisirs',
  'glace',
  'drapeau français',
  'visage avec un air de triomphe',
  'boulier',
  'homme qui donne un pourboire',
  'beignet',
  'poisson',
  'camion de livraison',
  'caméra',
  'drapeau du gabon',
  'visage boudeur',
  'carré noir pour arrêt',
  'camion articulé',
  'visage en colère',
  'cookie',
  'drapeau du royaume-uni',
  'poisson tropical',
  'femme qui donne un pourboire',
  'cercle noir pour enregistrement',
  'cadres de film',
  'projecteur de film',
  'drapeau de Grenade',
  'poisson souffleur',
  'visage sérieux avec des symboles couvrant la bouche',
  "gâteau d'anniversaire",
  'éjection',
  'personne heureuse levant une main',
  'tracteur',
  'drapeau de la Géorgie',
  'visage souriant avec des cornes',
  'voiture de course',
  'cinéma',
  'claquette',
  'requin',
  'shortcake',
  'homme levant la main',
  'poulpe',
  'femme levant la main',
  'drapeau de la Guyane',
  'télévision',
  'lutin',
  'cupcake',
  'moto de course',
  'symbole de faible luminosité',
  'coquille en spirale',
  'drapeau de Guernesey',
  'symbole de haute luminosité',
  'personne sourde',
  'crâne',
  'scooter motorisé',
  'appareil photo',
  'tarte',
  'drapeau du ghana',
  'homme sourd',
  'tête de mort',
  'appareil photo avec flash',
  'antenne avec barres',
  'barre de chocolat',
  'fauteuil roulant manuel',
  'escargot',
  'fauteuil roulant motorisé',
  'drapeau de Gibraltar',
  'tas de caca',
  'mode vibration',
  'femme sourde',
  'papillon',
  'caméra vidéo',
  'bonbons',
  'pousse-pousse automatique',
  "personne s'inclinant profondément",
  'téléphone portable éteint',
  'visage de clown',
  'sucette',
  'drapeau du Groenland',
  'vidéocassette',
  'insecte',
  'bicyclette',
  "homme s'inclinant",
  'signe féminin',
  'ogre japonais',
  'crème anglaise',
  'fourmi',
  'loupe à gauche',
  'drapeau gambien',
  'pot de miel',
  "femme s'inclinant",
  'signe masculin',
  'loupe à droite',
  'lutin japonais',
  'scooter',
  'abeille',
  'drapeau guinéen',
  'bougie',
  'skateboard',
  'symbole médical',
  'fantôme',
  'coccinelle',
  'paume de la main',
  'drapeau guadeloupéen',
  'biberon',
  'infini',
  'verre de lait',
  "visage d'homme",
  'cricket',
  'arrêt de bus',
  'drapeau guinéen équatorial',
  'extraterrestre',
  'ampoule électrique',
  'visage de femme',
  'araignée',
  'monstre extraterrestre',
  'autoroute',
  'drapeau grec',
  'symbole universel noir de recyclage',
  'boisson chaude',
  'torche électrique',
  "toile d'araignée",
  "lanterne d'izakaya",
  'drapeau de la géorgie du sud et des îles sandwich du sud',
  "haussement d'épaules",
  'fleur de lys',
  'face de robot',
  'voie ferrée',
  'tasse à thé sans anse',
  'drapeau du Guatemala',
  'baril de pétrole',
  'lampe à diya',
  'bouteille et tasse de saké',
  'emblème du trident',
  'homme haussant les épaules',
  'visage de chat souriant à la bouche ouverte',
  'scorpion',
  'femme qui hausse les épaules',
  'carnet avec couverture décorative',
  'pompe à essence',
  'badge nominatif',
  'moustique',
  'drapeau de Guam',
  'visage de chat souriant avec des yeux souriants',
  'bouteille avec bouchon qui saute',
  'visage de chat avec des larmes de joie',
  'livre fermé',
  'lumière tournante des voitures de police',
  'microbe',
  'drapeau de la Guinée-Bissau',
  'verre de vin',
  'symbole japonais pour débutant',
  'bouquet',
  'visage de chat souriant avec des yeux en forme de cœur',
  'médecin masculin',
  'livre ouvert',
  'feu de circulation horizontal',
  'verre à cocktail',
  'grand cercle lourd',
  'drapeau guyanais',
  'femme médecin',
  'visage de chat au sourire ironique',
  'livre vert',
  'fleur de cerisier',
  'drapeau de la Chine de Hong Kong Sar',
  'feu de circulation vertical',
  'blanc à gros carreaux',
  'boisson tropicale',
  'visage de chat embrasseur aux yeux fermés',
  'drapeau des îles mcdonald et entendu',
  'signe octogonal',
  'fleur blanche',
  'urne à carreaux',
  'livre bleu',
  'chope à bière',
  'signe de construction',
  'rosette',
  'lourde coche',
  'face de chat fatiguée',
  'livre orange',
  'tasses de bière qui cliquètent',
  'étudiant masculin',
  'drapeau du Honduras',
  'face de chat qui pleure',
  'ancre',
  'drapeau de la Croatie',
  'lourde multiplication x',
  'étudiante',
  'rose',
  'livres',
  'verres à clous',
  'marque en croix',
  'visage de chat boudeur',
  'fleur fanée',
  'voilier',
  'drapeau haïtien',
  'verre à gobelet',
  'cahier',
  'professeur masculin',
  'registre',
  'drapeau hongrois',
  'tasse avec paille',
  'hibiscus',
  'singe sans yeux',
  'canoë',
  'croix carrée négative',
  'drapeau des îles Canaries',
  'boîte à boisson',
  'vedette rapide',
  'signe plus lourd',
  'tournesol',
  'page avec boucle',
  'femme professeur',
  'singe sans coeur',
  'boisson du compagnon',
  'navire à passagers',
  'parchemin',
  'fleur',
  'drapeau indonésien',
  'singe qui ne parle pas',
  'signe moins lourd',
  'drapeau irlandais',
  'glaçon',
  'page tournée vers le haut',
  'juge masculin',
  'tulipe',
  'ferry',
  'kiss mark',
  'signe de division lourd',
  'journal',
  'juge féminin',
  'semis',
  "lettre d'amour",
  'baguettes',
  'bateau à moteur',
  'drapeau israélien',
  'boucle bouclée',
  "drapeau de l'île de Man",
  'arbre à feuilles persistantes',
  'cœur avec flèche',
  'double boucle bouclée',
  'bateau',
  'journal enroulé',
  'couteau plaque de fourchette',
  'fourchette et couteau',
  'agriculteur',
  'onglets de marque-page',
  "marque d'alternance de partie",
  'drapeau indien',
  'cœur avec ruban',
  'avion',
  'arbre à feuilles caduques',
  'cuillère',
  "drapeau du territoire britannique de l'océan Indien",
  'palmier',
  'cœur scintillant',
  'agricultrice',
  'astérisque à huit branches',
  'petit avion',
  'signet',
  'étoile noire à huit branches',
  'cœur qui pousse',
  'étiquette',
  'drapeau irakien',
  'hocho',
  'cactus',
  "départ d'avion",
  "arrivée d'avion",
  'oreille de riz',
  'drapeau iranien',
  "sac d'argent",
  'cuisinier',
  'cœur qui bat',
  'étincelle',
  'amphore',
  'billet de banque avec le signe du yen',
  'cœurs tournants',
  "double point d'exclamation",
  'parachute',
  'herbe',
  "drapeau de l'Islande",
  'cuisinière',
  "point d'exclamation",
  'siège',
  'billet de banque avec le signe du dollar',
  'deux cœurs',
  "drapeau de l'Italie",
  'trèfle',
  'trèfle à quatre feuilles',
  "billet de banque avec signe de l'euro",
  "ornement de point d'interrogation noir",
  'hélicoptère',
  'décoration de cœur',
  'drapeau de jersey',
  'mécanicien masculin',
  'chemin de fer suspendu',
  "ornement de point d'exclamation de cœur lourd",
  'femme mécanicienne',
  'drapeau de la Jamaïque',
  "ornement de point d'interrogation blanc",
  "feuille d'érable",
  'billet de banque avec symbole de la livre',
  'argent avec ailes',
  'drapeau de la Jordanie',
  'feuille tombée',
  'cœur brisé',
  "ornement de point d'exclamation blanc",
  'téléphérique de montagne',
  "symbole du point d'exclamation lourd",
  'feuille battant au vent',
  'cœur noir lourd',
  'drapeau japonais',
  "ouvrier d'usine masculin",
  'carte de crédit',
  'tramway aérien',
  "ouvrière d'usine féminine",
  'reçu',
  'tiret ondulé',
  'drapeau du Kenya',
  'satellite',
  'coeur orange',
  'coeur jaune',
  'signe de copyright',
  'fusée',
  'graphique avec tendance à la hausse et signe du yen',
  'drapeau du Kirghizstan',
  'change de devises',
  'signe enregistré',
  'coeur vert',
  'soucoupe volante',
  'drapeau du Cambodge',
  'employé de bureau masculin',
  'signe de la marque',
  'cloche de magasin',
  'cœur bleu',
  'drapeau de Kiribati',
  'signe du dollar lourd',
  'employée de bureau féminine',
  'cœur violet',
  'bagages',
  'clé de hachage',
  'drapeau des Comores',
  'enveloppe',
  'symbole du courrier électronique',
  'étoile de la clé',
  'st. kitts & nevis flag',
  'sablier',
  'brown heart',
  'male scientist',
  'hourglass with flowing sand',
  'black heart',
  'keycap 0',
  'incoming envelope',
  'north korea flag',
  'female scientist',
  'watch',
  'white heart',
  'keycap 1',
  'drapeau de la Corée du Sud',
  'enveloppe avec flèche vers le bas au-dessus',
  "plateau d'envoi",
  'technologue masculin',
  'réveil',
  'drapeau du Koweït',
  'capsule 2',
  'symbole de la colère',
  "plateau d'arrivée",
  'capsule 3',
  'drapeau des îles Caïmans',
  'chronomètre',
  'femme technologue',
  'symbole de collision',
  'drapeau du Kazakhstan',
  'capsule 4',
  'horloge de cheminée',
  'paquet',
  'boîte aux lettres fermée avec drapeau en relief',
  'drapeau du Laos',
  'symbole de vertige',
  'capsule 5',
  'chanteur',
  'horloge de cheminée',
  'chanteuse',
  'drapeau du Liban',
  'capuchon de clé 6',
  'boîte aux lettres fermée avec drapeau abaissé',
  'symbole de la sueur qui éclabousse',
  "cadran de l'horloge à douze heures",
  'capuchon de clé 7',
  'boîte aux lettres ouverte avec drapeau en relief',
  "cadran de l'horloge à douze heures trente",
  'symbole du tiret',
  'st. lucia',
  'trou',
  'artiste masculin',
  'horloge à une heure',
  'capsule 8',
  'boîte aux lettres ouverte avec drapeau abaissé',
  'drapeau du Liechtenstein',
  'bombe',
  'capsule 9',
  'boîte aux lettres',
  'artiste féminine',
  'clock face one-thirty',
  'sri lanka flag',
  'ballotin avec bulletin de vote',
  'keycap ten',
  'clock face two oclock',
  'liberia flag',
  'speech balloon',
  'eye in speech bubble',
  'lesotho flag',
  "cadran de l'horloge deux heures trente",
  'pilote masculin',
  "symbole d'entrée pour les majuscules latines",
  'crayon',
  'pilote féminin',
  'plume noire',
  'bulle de parole gauche',
  "cadran de l'horloge trois heures",
  "symbole d'entrée pour les minuscules latines",
  'drapeau de la Lituanie',
  "cadran de l'horloge trois heures trente",
  'drapeau du Luxembourg',
  'bulle de colère droite',
  'stylo plume en bas à gauche',
  'astronaute masculin',
  'ballon de la pensée',
  "symbole d'entrée pour les symboles",
  "cadran de l'horloge à quatre heures",
  'drapeau de la Lettonie',
  'stylo à bille en bas à gauche',
  "symbole d'entrée pour les lettres latines",
  'symbole du sommeil',
  'pinceau en bas à gauche',
  'femme astronaute',
  'drapeau de la Libye',
  "cadran de l'horloge à quatre heures et demie",
  'drapeau du Maroc',
  'lettre majuscule latine carrée négative a',
  "cadran de l'horloge à cinq heures",
  'crayon en bas à gauche',
  'pompier masculin',
  'mémo',
  'négatif ab au carré',
  'drapeau de Monaco',
  "cadran de l'horloge à cinq heures et demie",
  'mallette',
  'femme pompier',
  "cadran de l'horloge à six heures",
  'négatif b majuscule latine au carré',
  'drapeau de la Moldavie',
  "cadran de l'horloge à six heures et demie",
  'cl au carré',
  'drapeau du Monténégro',
  'dossier',
  'policier',
  'policier',
  'carré cool',
  "cadran de l'horloge à sept heures",
  'st. martin flag',
  'open file folder',
  'card index dividers',
  'madagascar flag',
  'squared free',
  'female police officer',
  'clock face seven-thirty',
  'calendar',
  'clock face eight oclock',
  'information source',
  'sleuth or spy',
  'marshall islands flag',
  "cadran de l'horloge huit heures trente",
  'calendrier détachable',
  'détective masculin',
  'drapeau de la Macédoine du Nord',
  'id au carré',
  'bloc-notes en spirale',
  'détective féminin',
  "cadran de l'horloge neuf heures",
  'drapeau du Mali',
  "lettre majuscule latine entourée d'un cercle m",
  'drapeau du Myanmar (Birmanie)',
  'clock face nine-thirty',
  'guardsman',
  'squared new',
  'spiral calendar pad',
  'squared ng',
  'card index',
  'clock face ten oclock',
  'mongolia flag',
  'male guard',
  'macao sar china flag',
  'clock face ten-thirty',
  'chart with upward trend',
  'négatif',
  'garde féminine',
  'graphique avec tendance à la baisse',
  'drapeau des îles du nord de la mariane',
  'carré ok',
  "cadran d'horloge à onze heures",
  'ouvrier du bâtiment',
  'ouvrier du bâtiment',
  "cadran d'horloge à onze heures et demie",
  'drapeau de la Martinique',
  'bar chart',
  'negative squared latin capital letter p',
  'new moon symbol',
  'female construction worker',
  'squared sos',
  'clipboard',
  'mauritania flag',
  'prince',
  'waxing crescent moon symbol',
  'montserrat flag',
  'pushpin',
  'squared up with exclamation mark',
  'drapeau de Malte',
  'princesse',
  'punaise ronde',
  'symbole du premier quartier de lune',
  'carré contre',
  'drapeau de Maurice',
  'katakana koko carré',
  'homme au turban',
  'symbole de la lune en forme de gibbeau',
  'trombone',
  'trombones reliés',
  'homme au turban',
  'katakana sa carré',
  'symbole de la pleine lune',
  'drapeau des Maldives',
  'drapeau malawi',
  'symbole de la lune en déclin',
  'femme portant un turban',
  'idéogramme unifié du cjk carré-6708',
  'règle droite',
  'idéogramme unifié du cjk carré-6709',
  'règle triangulaire',
  'homme avec gua pi mao',
  'drapeau du mexique',
  'symbole du dernier quartier de lune',
  'personne avec un foulard',
  'croissant de lune en déclin',
  'idéogramme unifié du cjk au carré-6307',
  'ciseaux noirs',
  'drapeau de la malaisie',
  "avantage de l'idéogramme encerclé",
  'homme en smoking',
  'drapeau du mozambique',
  'boîte à fiches',
  'croissant de lune',
  'drapeau namibien',
  'mariée avec voile',
  'nouvelle lune avec visage',
  'classeur',
  'idéogramme unifié du cjk carré-5272',
  'corbeille à papier',
  'femme enceinte',
  'premier quartier de lune avec visage',
  'drapeau de la Nouvelle-Calédonie',
  'squared cjk unified ideograph-7121',
  'lock',
  'niger flag',
  'last quarter moon with face',
  'breast-feeding',
  'squared cjk unified ideograph-7981',
  'circled ideograph accept',
  'baby angel',
  'open lock',
  'norfolk island flag',
  'thermometer',
  'nigeria flag',
  'squared cjk unified ideograph-7533',
  'black sun with rays',
  'lock with ink pen',
  'father christmas',
  'closed lock with key',
  'squared cjk unified ideograph-5408',
  'nicaragua flag',
  'mother christmas',
  'full moon with face',
  'key',
  'superhero',
  'drapeau des Pays-Bas',
  'cjk squared unified ideograph-7a7a',
  'soleil avec visage',
  'superhéros masculin',
  'planète encerclée',
  'vieille clé',
  'félicitations idéographiques encerclées',
  'drapeau de la Norvège',
  'étoile blanche moyenne',
  'secret idéographique encerclé',
  'drapeau du Népal',
  'superhéros féminin',
  'marteau',
  'étoile brillante',
  'drapeau nauru',
  'hache',
  'idéogramme unifié du cjk au carré-55b6',
  'super-vilain',
  'étoile filante',
  'idéogramme unifié du cjk au carré-6e80',
  'drapeau niue',
  'pioche',
  'super-vilain masculin',
  'super-vilain féminin',
  'marteau et pioche',
  'voie lactée',
  'grand cercle rouge',
  'drapeau néo-zélandais',
  'grand cercle orange',
  'marteau et clé à molette',
  'drapeau omanais',
  'nuage',
  'mage',
  'couteau poignard',
  'soleil derrière les nuages',
  'grand cercle jaune',
  'mage masculin',
  'drapeau panama',
  'nuage tonitruant et pluie',
  'grand cercle vert',
  'femme mage',
  'épées croisées',
  'drapeau du Pérou',
  'pistolet',
  'surtout ensoleillé',
  'fée',
  'drapeau de la Polynésie française',
  'grand cercle bleu',
  'grand cercle violet',
  'arc et flèche',
  'fée mâle',
  'à peine ensoleillé',
  'drapeau de la Papouasie-Nouvelle-Guinée',
  'bouclier',
  'pluie partiellement ensoleillée',
  'grand cercle brun',
  'fée',
  'drapeau philippin',
  'drapeau pakistanais',
  'cercle noir moyen',
  'clé',
  'vampire',
  'nuage de pluie',
  'nuage de neige',
  'drapeau polonais',
  'vampire mâle',
  'boulon et écrou',
  'cercle blanc moyen',
  'vampire femelle',
  'st. pierre & miquelon',
  'grand carré rouge',
  'foudre',
  'engin',
  'merperson',
  'tornade',
  'grand carré orange',
  'drapeau des îles Pitcairn',
  'compression',
  'merman',
  'grand carré jaune',
  'brouillard',
  'écailles',
  'drapeau du Porto Rico',
  'visage du vent',
  'drapeau des territoires palestiniens',
  'sirène',
  'canne à pêche',
  'grand carré vert',
  'drapeau portugais',
  'symbole du lien',
  'grand carré bleu',
  'elfe',
  'cyclone',
  'arc-en-ciel',
  'elfe mâle',
  'drapeau de Palau',
  'chaînes',
  'grand carré violet',
  'elfe femelle',
  'drapeau du paraguay',
  'parapluie fermé',
  'boîte à outils',
  'grand carré brun',
  'aimant',
  'génie',
  'drapeau du qatar',
  'parapluie',
  'grand carré noir',
  'génie masculin',
  'parapluie avec gouttes de pluie',
  'drapeau de la réunion',
  'grand carré blanc',
  'alambic',
  'carré moyen noir',
  'éprouvette',
  'drapeau de la Roumanie',
  'génie féminin',
  'parapluie sur terre',
  'zombie',
  'panneau haute tension',
  'carré moyen blanc',
  'drapeau de la serbie',
  'boîte de pétri',
  'flocon de neige',
  "double hélice d'ADN",
  'zombie mâle',
  'carré moyen noir',
  'drapeau de la russie',
  'zombie femelle',
  'drapeau du rwanda',
  'bonhomme de neige',
  'petit carré blanc moyen',
  'microscope',
  'bonhomme de neige sans neige',
  'télescope',
  'massage du visage',
  'petit carré noir',
  "drapeau de l'Arabie saoudite",
  'homme se faisant masser',
  'comète',
  'petit carré blanc',
  'drapeau des îles Salomon',
  'antenne satellite',
  'grand diamant orange',
  'femme se faisant masser',
  'feu',
  'seringue',
  'drapeau des Seychelles',
  'grand diamant bleu',
  'drapeau du Soudan',
  'gouttelette',
  'goutte de sang',
  'coupe de cheveux',
  "vague d'eau",
  'drapeau suédois',
  'homme se faisant couper les cheveux',
  'petit diamant orange',
  'pilule',
  'femme se faisant couper les cheveux',
  'petit diamant bleu',
  'drapeau de Singapour',
  'bandage adhésif',
  'piéton',
  'triangle rouge pointé vers le haut',
  'st. helena flag',
  'stéthoscope',
  'homme qui marche',
  'drapeau slovène',
  'porte',
  'triangle rouge pointant vers le bas',
  'drapeau de svalbard & jan mayen',
  "forme de diamant avec un point à l'intérieur",
  'femme qui marche',
  'lit',
  'bouton radio',
  'drapeau slovaque',
  'personne debout',
  'canapé et lampe',
  'homme debout',
  'bouton carré blanc',
  'drapeau de la sierra leone',
  'chaise',
  'toilettes',
  'bouton carré noir',
  'drapeau de Saint-Marin',
  'femme debout',
  'personne agenouillée',
  'douche',
  'drapeau sénégalais',
  'baignoire',
  'drapeau somalien',
  'homme à genoux',
  'drapeau du suriname',
  'femme à genoux',
  'rasoir',
  'drapeau du sud-soudan',
  'flacon de lotion',
  'drapeau de são tome & principe',
  'épingle à nourrice',
  'homme avec canne de sondage',
  'balai',
  'femme à la canne à pêche',
  'drapeau du Salvador',
  'drapeau de la Saint-Martin',
  'panier',
  'homme en fauteuil roulant motorisé',
  'drapeau de la Syrie',
  'rouleau de papier',
  'femme en fauteuil roulant motorisé',
  "drapeau d'Eswatini",
  'savon',
  'drapeau tristan da cunha',
  'éponge',
  'extincteur',
  'homme en fauteuil roulant manuel',
  'drapeau des îles turques et caïques',
  'femme en fauteuil roulant manuel',
  'drapeau tchad',
  'caddie',
  'coureur',
  'drapeau des territoires français du sud',
  'symbole de la fumée',
  'cercueil',
  'homme courant',
  'drapeau du togo',
  'femme courant',
  'urne funéraire',
  'drapeau de la Thaïlande',
  'moyai',
  'drapeau du tadjikistan',
  'danseur',
  'drapeau de tokelau',
  'homme dansant',
  'drapeau du timor-leste',
  "homme en costume d'affaires lévitant",
  'drapeau du turkménistan',
  'femme aux oreilles de lapin',
  'homme aux oreilles de lapin faisant la fête',
  'drapeau de la Tunisie',
  'drapeau de Tonga',
  'femme aux oreilles de lapin faisant la fête',
  'drapeau de la Turquie',
  'personne dans le sauna',
  'homme en salle de vapeur',
  'drapeau de trinité-et-tobago',
  'femme en salle de vapeur',
  'drapeau de tuvalu',
  'drapeau de taiwan',
  'personne grimpant',
  'homme grimpant',
  'drapeau de tanzanie',
  "drapeau d'ukraine",
  'femme grimpant',
  "drapeau d'ouganda",
  'escrimeur',
  'u.s. drapeau des îles périphériques',
  'course de chevaux',
  'skieur',
  'drapeau des Nations unies',
  'drapeau des États-Unis',
  'snowboarder',
  'golfeur',
  'drapeau uruguayen',
  "drapeau de l'Ouzbékistan",
  'homme de golf',
  'drapeau de la ville de Vatican',
  'femme de golf',
  'st. vincent & grenadines',
  'surfeur',
  'homme de surf',
  'drapeau du Venezuela',
  'drapeau des îles vierges britanniques',
  'femme de surf',
  'barque',
  'États-Unis drapeau des îles vierges',
  'homme à la rame',
  'drapeau du Vietnam',
  'drapeau du Vanuatu',
  'femme à la rame',
  'nageur',
  'drapeau du Wallis et du Futuna',
  'homme à la nage',
  'drapeau des Samoa',
  'femme à la nage',
  'drapeau du Kosovo',
  'personne avec ballon',
  'drapeau du Yémen',
  'balle rebondissante pour homme',
  'drapeau de la Mayotte',
  'balle rebondissante pour femme',
  "drapeau de l'Afrique du Sud",
  'drapeau de la Zambie',
  'haltérophile',
  'homme qui soulève des poids',
  'drapeau du Zimbabwe',
  'femme qui soulève des poids',
  "drapeau de l'Angleterre",
  'cycliste',
  "drapeau de l'Écosse",
  'drapeau du Pays de Galles',
  'homme en vélo',
  'femme en vélo',
  'cycliste de montagne',
  'homme en vélo',
  'femme en vélo',
  'personne faisant la roue',
  'homme en roue',
  'femme en roue',
  'lutteurs',
  'homme en lutte',
  'lutte féminine',
  'water polo',
  'homme jouant au water polo',
  'femme jouant au water polo',
  'handball',
  'homme jouant au handball',
  'femme jouant au handball',
  'jonglerie',
  'homme jonglant',
  'femme jonglant',
  'personne en position de lotus',
  'homme en position de lotus',
  'femme en position de lotus',
  'bain',
  'logement de couchage',
  'personnes se tenant la main',
  'deux femmes se tenant la main',
  'homme et femme se tenant la main',
  'deux hommes se tenant la main',
  'baiser',
  "femme embrasse l'homme",
  'homme embrasse homme',
  'femme embrasse femme',
  'couple avec coeur',
  'femme coeur homme',
  'homme coeur homme',
  'femme coeur femme',
  'famille',
  'homme femme garçon',
  'homme femme fille',
  'homme femme fille garçon',
  'homme femme garçon garçon',
  'homme femme fille fille',
  'homme homme garçon',
  'homme homme fille',
  'homme homme fille garçon',
  'homme homme garçon garçon',
  'homme homme fille fille',
  'femme femme garçon',
  'femme femme fille',
  'femme femme fille garçon',
  'femme femme garçon garçon',
  'femme femme fille fille',
  'homme garçon',
  'homme garçon',
  'homme fille',
  'homme fille garçon',
  'homme fille fille',
  'femme garçon',
  'femme garçon',
  'femme fille',
  'femme fille garçon',
  'femme fille fille',
  'tête parlante en silhouette',
  'buste en silhouette',
  'bustes en silhouette',
  'empreintes de pas',
];

if (emojisData.compressed) {
  uncompress(emojisData);
  Object.keys(emojisData.emojis).forEach((key, index) => {
    searchIndex.add({
      english_name: emojisData.emojis[key].name || '',
      french_name: NAMES[index] || '',
      id: key,
    });
  });
}

interface EmojiCategory {
  name: (t: TFunction) => string;
  emojis: string[];
}

const emojis: { [id: string]: EmojiCategory } = {
  smileys: {
    name: (t) => t('emojis.smileys'),
    emojis: [
      'grinning',
      'smiley',
      'smile',
      'grin',
      'laughing',
      'sweat_smile',
      'joy',
      'slightly_smiling_face',
      'upside_down_face',
      'wink',
      'blush',
      'innocent',
      'heart_eyes',
      'star-struck',
      'relaxed',
      'kissing_smiling_eyes',
      'yum',
      'stuck_out_tongue',
      'stuck_out_tongue_closed_eyes',
      'money_mouth_face',
      'hugging_face',
      'face_with_hand_over_mouth',
      'shushing_face',
      'thinking_face',
      'zipper_mouth_face',
      'face_with_raised_eyebrow',
      'neutral_face',
      'no_mouth',
      'smirk',
      'unamused',
      'face_with_rolling_eyes',
      'grimacing',
      'lying_face',
      'pensive',
      'sleepy',
      'sleeping',
      'mask',
      'face_with_thermometer',
      'face_with_head_bandage',
      'sneezing_face',
      'hot_face',
      'cold_face',
      'dizzy_face',
      'exploding_head',
      'face_with_cowboy_hat',
      'partying_face',
      'sunglasses',
      'nerd_face',
      'confused',
      'open_mouth',
      'astonished',
      'pleading_face',
      'fearful',
      'disappointed_relieved',
      'sob',
      'scream',
      'confounded',
      'yawning_face',
      'triumph',
      'rage',
      'angry',
      'face_vomiting',
      'face_with_monocle',
      'slightly_frowning_face',
      'flushed',
      'frowning',
      'sweat',
      'weary',
      'face_with_symbols_on_mouth',
      'smiling_imp',
      'skull',
      'skull_and_crossbones',
      'clown_face',
      'robot_face',
      'wave',
      'ok_hand',
      'v',
      'crossed_fingers',
      'i_love_you_hand_sign',
      'point_left',
      'point_right',
      'point_up_2',
      'point_down',
      'point_up',
      '+1',
      '-1',
      'clap',
      'raised_hands',
      'handshake',
      'pray',
      'selfie',

      'muscle',
      'leg',
      'foot',
      'ear',
      'nose',
      'hand',
      'fist',
      'brain',
      'tooth',
      'bone',
      'eyes',
      'eye',
      'tongue',
      'lips',
      'baby',
      'adult',
      'princess',
      'prince',
      'bride_with_veil',
      'man_in_tuxedo',
      'bearded_person',
      'red_haired_woman',
      'blond-haired-woman',
      'blond-haired-man',
      'person_frowning',
      'person_with_pouting_face',
      'no_good',
      'ok_woman',
      'information_desk_person',
      'raising_hand',
      'deaf_person',
      'bow',
      'face_palm',
      'shrug',
      'male-doctor',
      'male-student',
      'male-teacher',
      'male-judge',
      'male-farmer',
      'male-cook',
      'male-mechanic',
      'male-factory-worker',
      'male-office-worker',
      'male-scientist',
      'male-technologist',
      'male-singer',
      'male-artist',
      'male-pilot',
      'male-astronaut',
      'male-firefighter',
      'cop',
      'sleuth_or_spy',
      'guardsman',
      'construction_worker',
      'man_with_turban',
      'man_with_gua_pi_mao',
      'person_with_headscarf',
      'pregnant_woman',
      'santa',
      'superhero',
      'supervillain',
      'mage',
      'fairy',
      'vampire',
      'merperson',
      'elf',
      'genie',
      'zombie',
      'massage',
      'haircut',
      'walking',
      'standing_person',
      'kneeling_person',
      'man_with_probing_cane',
      'man_in_motorized_wheelchair',
      'man_in_manual_wheelchair',
      'runner',
      'dancer',
      'man_dancing',
      'man_in_business_suit_levitating',
      'dancers',
      'person_in_steamy_room',
      'person_climbing',
      'fencer',
      'horse_racing',
      'skier',
      'snowboarder',
      'golfer',
      'surfer',
      'rowboat',
      'swimmer',
      'person_with_ball',
      'weight_lifter',
      'bicyclist',
      'mountain_bicyclist',
      'person_doing_cartwheel',
      'wrestlers',
      'water_polo',
      'handball',
      'juggling',
      'person_in_lotus_position',
      'bath',
      'sleeping_accommodation',
      'people_holding_hands',
      'couplekiss',
      'man-woman-boy',
      'footprints',
      'kiss',
      'broken_heart',
      'heart',
      '100',
      'anger',
      'boom',
      'dizzy',
      'sweat_drops',
      'dash',
      'bomb',
      'speech_balloon',
      'eye-in-speech-bubble',
      'thought_balloon',
      'zzz',
    ],
  },
  activities: {
    name: (t) => t('emojis.activities'),
    emojis: [
      'jack_o_lantern',
      'christmas_tree',
      'firecracker',
      'sparkles',
      'balloon',
      'tada',
      'confetti_ball',
      'ribbon',
      'gift',
      'reminder_ribbon',
      'admission_tickets',
      'medal',
      'trophy',
      'sports_medal',
      'soccer',
      'baseball',
      'softball',
      'basketball',
      'volleyball',
      'football',
      'rugby_football',
      'tennis',
      'flying_disc',
      'bowling',
      'cricket_bat_and_ball',
      'field_hockey_stick_and_ball',
      'ice_hockey_stick_and_puck',
      'lacrosse',
      'table_tennis_paddle_and_ball',
      'badminton_racquet_and_shuttlecock',
      'boxing_glove',
      'martial_arts_uniform',
      'goal_net',
      'golf',
      'ice_skate',
      'fishing_pole_and_fish',
      'diving_mask',
      'running_shirt_with_sash',
      'ski',
      'sled',
      'curling_stone',
      'dart',
      'yo-yo',
      'kite',
      '8ball',
      'crystal_ball',
      'nazar_amulet',
      'video_game',
      'joystick',
      'slot_machine',
      'game_die',
      'jigsaw',
      'teddy_bear',
      'spades',
      'hearts',
      'diamonds',
      'clubs',
      'chess_pawn',
      'black_joker',
      'flower_playing_cards',
      'performing_arts',
      'frame_with_picture',
      'art',
      'thread',
      'yarn',
    ],
  },
  travel: {
    name: (t) => t('emojis.travel'),
    emojis: [
      'earth_africa',
      'world_map',
      'japan',
      'compass',
      'snow_capped_mountain',
      'beach_with_umbrella',
      'desert',
      'desert_island',
      'national_park',
      'stadium',
      'classical_building',
      'building_construction',
      'bricks',
      'office',
      'factory',
      'statue_of_liberty',
      'church',
      'kaaba',
      'tent',
      'foggy',
      'night_with_stars',
      'sunrise_over_mountains',
      'sunrise',
      'bridge_at_night',
      'carousel_horse',
      'roller_coaster',
      'barber',
      'circus_tent',
      'steam_locomotive',
      'railway_car',
      'bullettrain_side',
      'monorail',
      'ambulance',
      'police_car',
      'taxi',
      'car',
      'blue_car',
      'tractor',
      'racing_car',
      'racing_motorcycle',
      'motor_scooter',
      'manual_wheelchair',
      'motorized_wheelchair',
      'auto_rickshaw',
      'bike',
      'scooter',
      'skateboard',
      'fuelpump',
      'rotating_light',
      'traffic_light',
      'vertical_traffic_light',
      'octagonal_sign',
      'construction',
      'anchor',
      'boat',
      'canoe',
      'motor_boat',
      'ship',
      'airplane',
      'parachute',
      'helicopter',
      'suspension_railway',
      'aerial_tramway',
      'satellite',
      'rocket',
      'flying_saucer',
      'bellhop_bell',
      'luggage',
      'hourglass',
      'watch',
      'timer_clock',
      'clock12',
      'crescent_moon',
      'thermometer',
      'sunny',
      'ringed_planet',
      'star',
      'star2',
      'stars',
      'milky_way',
      'cloud',
      'partly_sunny',
      'thunder_cloud_and_rain',
      'mostly_sunny',
      'barely_sunny',
      'partly_sunny_rain',
      'rain_cloud',
      'snow_cloud',
      'lightning',
      'tornado',
      'fog',
      'wind_blowing_face',
      'cyclone',
      'rainbow',
      'closed_umbrella',
      'umbrella',
      'umbrella_with_rain_drops',
      'umbrella_on_ground',
      'zap',
      'snowflake',
      'snowman',
      'comet',
      'fire',
      'droplet',
      'ocean',
    ],
  },
  objects: {
    name: (t) => t('emojis.objects'),
    emojis: [
      'eyeglasses',
      'dark_sunglasses',
      'goggles',
      'lab_coat',
      'safety_vest',
      'necktie',
      'shirt',
      'jeans',
      'scarf',
      'gloves',
      'coat',
      'socks',
      'dress',
      'sari',
      'one-piece_swimsuit',
      'briefs',
      'shorts',
      'bikini',
      'womans_clothes',
      'purse',
      'handbag',
      'pouch',
      'shopping_bags',
      'school_satchel',
      'mans_shoe',
      'athletic_shoe',
      'hiking_boot',
      'womans_flat_shoe',
      'high_heel',
      'crown',
      'womans_hat',
      'tophat',
      'mortar_board',
      'billed_cap',
      'helmet_with_white_cross',
      'prayer_beads',
      'lipstick',
      'ring',
      'gem',
      'mute',
      'speaker',
      'loudspeaker',
      'bell',
      'no_bell',
      'studio_microphone',
      'microphone',
      'headphones',
      'radio',
      'saxophone',
      'guitar',
      'musical_keyboard',
      'trumpet',
      'violin',
      'banjo',
      'drum_with_drumsticks',
      'iphone',
      'phone',
      'telephone_receiver',
      'battery',
      'electric_plug',
      'computer',
      'desktop_computer',
      'printer',
      'minidisc',
      'floppy_disk',
      'cd',
      'movie_camera',
      'film_frames',
      'film_projector',
      'clapper',
      'tv',
      'camera',
      'camera_with_flash',
      'video_camera',
      'vhs',
      'mag',
      'mag_right',
      'candle',
      'bulb',
      'flashlight',
      'izakaya_lantern',
      'diya_lamp',
      'notebook_with_decorative_cover',
      'closed_book',
      'book',
      'green_book',
      'books',
      'notebook',
      'ledger',
      'page_with_curl',
      'newspaper',
      'rolled_up_newspaper',
      'bookmark_tabs',
      'bookmark',
      'label',
      'money_with_wings',
      'credit_card',
      'heavy_dollar_sign',
      'email',
      'package',
      'mailbox',
      'postbox',
      'ballot_box_with_ballot',
      'pencil2',
      'lower_left_ballpoint_pen',
      'lower_left_crayon',
      'memo',
      'briefcase',
      'file_folder',
      'card_index_dividers',
      'date',
      'spiral_note_pad',
      'card_index',
      'chart_with_downwards_trend',
      'clipboard',
      'pushpin',
      'round_pushpin',
      'paperclip',
      'straight_ruler',
      'triangular_ruler',
      'scissors',
      'card_file_box',
      'file_cabinet',
      'wastebasket',
      'lock',
      'closed_lock_with_key',
      'key',
      'hammer',
      'pick',
      'hammer_and_wrench',
      'dagger_knife',
      'gun',
      'bow_and_arrow',
      'shield',
      'wrench',
      'gear',
      'scales',
      'probing_cane',
      'toolbox',
      'magnet',
      'alembic',
      'test_tube',
      'petri_dish',
      'dna',
      'microscope',
      'telescope',
      'satellite_antenna',
      'syringe',
      'drop_of_blood',
      'pill',
      'adhesive_bandage',
      'stethoscope',
      'door',
      'bed',
      'couch_and_lamp',
      'chair',
      'toilet',
      'shower',
      'bathtub',
      'razor',
      'lotion_bottle',
      'safety_pin',
      'broom',
      'basket',
      'roll_of_paper',
      'soap',
      'sponge',
      'fire_extinguisher',
      'shopping_trolley',
      'coffin',
      'funeral_urn',
    ],
  },
  symbols: {
    name: (t) => t('emojis.symbols'),
    emojis: [
      'zero',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'arrow_down',
      'arrow_up',
      'arrow_forward',
      'arrow_up_small',
      'arrow_down_small',
      'arrows_counterclockwise',
      'arrow_backward',
      'double_vertical_bar',
      'black_square_for_stop',
      'cinema',
      'keycap_ten',
      'abc',
      'cool',
      'free',
      'information_source',
      'new',
      'ok',
      'parking',
      'sos',
      'up',
      'low_brightness',
      'female_sign',
      'medical_symbol',
      'infinity',
      'recycle',
      'white_check_mark',
      'x',
      'heavy_plus_sign',
      'heavy_minus_sign',
      'heavy_division_sign',
      'question',
      'exclamation',
      'potable_water',
      'warning',
      'no_entry',

      'red_circle',
      'large_orange_circle',
      'large_yellow_circle',
      'large_green_circle',
      'large_blue_circle',
      'large_purple_circle',
      'large_brown_circle',
      'black_circle',
      'white_circle',
      'small_red_triangle',
      'small_red_triangle_down',
      'radio_button',
      'man-heart-man',
      'stopwatch',
      'truck',
      'lower_left_paintbrush',
      'chains',
      'smoking',
      'ferris_wheel',
      'city_sunset',
      'notes',
      'control_knobs',
      'checkered_flag',
      'waving_white_flag',
      'radioactive_sign',
      'mahjong',
      'globe_with_meridians',
      'new_moon',
      'full_moon',
      'coffee',
      'champagne',
      'beer',
      'sparkler',
      'tokyo_tower',
      'receipt',
      'abacus',
      'kimono',
      'blue_book',
      'orange_book',
      'moneybag',
      'house',

      'vs',
      'hole',

      'keyboard',

      'axe',
      'curly_loop',
    ],
  },
  priority: {
    name: (t) => t('emojis.priority'),
    emojis: [
      'red_circle',
      'large_orange_circle',
      'large_yellow_circle',
      'large_green_circle',
      'large_blue_circle',
      'large_purple_circle',
      'large_brown_circle',
      'black_circle',
      'white_circle',
    ],
  },
  weather: {
    name: (t) => t('emojis.weather'),
    emojis: [
      'sunny',
      'cloud',
      'partly_sunny',
      'thunder_cloud_and_rain',
      'mostly_sunny',
      'barely_sunny',
      'partly_sunny_rain',
      'rain_cloud',
      'snow_cloud',
      'lightning',
      'tornado',
      'fog',
      'wind_blowing_face',
      'cyclone',
      'rainbow',
      'closed_umbrella',
      'umbrella',
      'umbrella_with_rain_drops',
      'umbrella_on_ground',
      'zap',
      'snowflake',
    ],
  },
  status: {
    name: (t) => t('emojis.status'),
    emojis: [
      `bloomup-check-#AFAFAF`,
      `bloomup-check-#f2d600`,
      `bloomup-check-#ff9f1a`,
      `bloomup-check-#eb5a46`,
      'bloomup-check-#c377e0',
      'bloomup-check-#0079bf',
      'bloomup-check-#00c2e0',
      `bloomup-check-#48C854`,
      `bloomup-check-filled-#AFAFAF`,
      `bloomup-check-filled-#f2d600`,
      `bloomup-check-filled-#ff9f1a`,
      `bloomup-check-filled-#eb5a46`,
      'bloomup-check-filled-#c377e0',
      'bloomup-check-filled-#0079bf',
      'bloomup-check-filled-#00c2e0',
      `bloomup-check-filled-#48C854`,
    ],
  },
};

type EmojiCategoryID =
  | 'smileys'
  | 'activities'
  | 'travel'
  | 'objects'
  | 'symbols'
  | 'priority'
  | 'weather'
  | 'status';

export const DEFAULT_EMOJIS_CATEGORIES: EmojiCategoryID[] = [
  'smileys',
  'activities',
  'travel',
  'objects',
  'symbols',
  'priority',
  'weather',
  'status',
];

export const EDITOR_EMOJIS_CATEGORIES: EmojiCategoryID[] = [
  'smileys',
  'activities',
  'travel',
  'objects',
  'symbols',
  'priority',
  'weather',
];

export const STATUS_EMOJIS_CATEGORIES: EmojiCategoryID[] = [
  'status',
  'smileys',
  'activities',
  'travel',
  'objects',
  'symbols',
  'priority',
  'weather',
];

export function searchEmoji(value: string): string[] {
  return searchIndex.search(value, { prefix: true }).map((c) => c.id);
}

// eslint-disable-next-line no-useless-escape
const COLONS_REGEX = /^(?:\:([^\:]+)\:)(?:\:skin-tone-(\d)\:)?$/;

export function getEmoji(value: string): Emoji | null {
  const matches = value.match(COLONS_REGEX);
  if (matches) {
    return emojisData.emojis[matches[1]];
  }
  return null;
}

function unifiedToNative(unified: string) {
  const unicodes = unified.split('-');
  const codePoints = unicodes.map((u) => +`0x${u}`);

  return String.fromCodePoint(...codePoints);
}

export function getNativeEmoji(value: string): string {
  const emoji = getEmoji(value);
  if (emoji && emoji.unified) {
    return unifiedToNative(emoji?.unified);
  }
  return '';
}

export default emojis;
