import api from 'api';
import { RecurrenceFrequency } from 'api/generated/models';
import { RecurrenceUpdateSchema } from 'api/generated/models/recurrence-update-schema';
import { action, observable } from 'mobx';

export class Recurrence {
  public id: number;

  @observable
  public status: string;

  @observable
  public frequency: RecurrenceFrequency;

  @observable
  public frequency_config: any;

  @observable
  public relative_due_date: string;

  @observable
  public assignees: number[];

  constructor(data: Partial<Recurrence>) {
    Object.assign(this, data);
  }

  @action
  private _update(data: Partial<Recurrence>) {
    Object.assign(this, data);
  }

  public async update(newData: RecurrenceUpdateSchema) {
    const { data } = await api.projects.updateRecurrence(this.id, newData);
    this._update(data);
  }
}
