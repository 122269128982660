import { MenuItem } from '@material-ui/core';
import FileIcon from 'assets/icons/file.svg';
import MenuIcon from 'assets/icons/menu.svg';
import Menu from 'components/elements/Menu';
import DeleteIcon from 'components/icons/DeleteIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import ResourceDetailsModal from 'modals/resources/Details';
import { ResourceType } from 'models/resources';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import styled from 'styled-components';
import downloadFile from 'utils/downloadFile';
import isDownloadable from 'utils/isDownloadable';
import stopPropagation from 'utils/stopPropagation';

const Actions = styled.span`
  display: flex;
  align-items: center;
  opacity: 0;
  position: absolute;
  margin-right: 10px;
  right: 5px;
  top: 0;
  height: 100%;
  transition: opacity 0.1s;

  > * {
    margin-left: 8px;
  }
`;

const Wrapper = styled.span<{ disableMenu?: boolean }>`
  display: inline-flex;
  justify-content: flex-start;
  min-height: 30px;
  align-items: center;
  position: relative;
  border-radius: 20px;
  padding: 5px 0;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: var(--shadow-sm);
  color: var(--color-light-grey);
  transition: background-color 0.1s;
  vertical-align: middle;
  min-width: 100px;

  &:hover {
    background-color: var(--color-primary-hover);
    color: var(--color-primary);

    ${Actions} {
      opacity: 1;
    }
  }

  ${(p) =>
    !p.disableMenu &&
    `
  &::after {
    position: absolute;
    right: 12px;
    top: 4px;
    width: 20px;
    height: 20px;
    content: ' ';

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: var(--shadow-sm);

    background-color: var(--color-white);
    background-image: url(${MenuIcon});
    background-position: center;
    background-size: 15px 15px;
    background-repeat: no-repeat;
  }
  `}
`;

const Infos = styled.span<{ disableMenu?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: ${(p) => (p.disableMenu ? 10 : 40)}px;

  padding-left: 30px;

  &::before {
    content: ' ';

    position: absolute;
    left: 8px;
    top: 5px;
    width: 20px;
    height: 20px;

    background-image: url(${FileIcon});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  > *:first-child {
    margin-right: 5px;
  }
`;

interface ResourceDisplayProps {
  resource: {
    id?: number;
    name?: string;
    type: ResourceType;
    link?: string;
  };
  onDelete?: () => void;
  offsetKey?: string;
}

function ResourceDisplay(props: ResourceDisplayProps) {
  const { uiStore, resourceStore } = useStore();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();
  const profile = useCurrentProfile();

  async function open(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (!props.resource.id) return;
    if (props.resource.type === 'link' || props.resource.type === 'file') {
      try {
        if (profile.id !== -1) {
          await resourceStore.loadResource(props.resource.id);
        }
        if (props.resource.link) {
          return window.open(props.resource.link, '_blank');
        }
      } catch (_) {}
    }
    if (profile.id !== -1) {
      uiStore.pushSide(<ResourceDetailsModal resourceID={props.resource.id} />);
    }
  }

  async function download(e: React.MouseEvent) {
    e.preventDefault();
    try {
      if (props.resource.link) {
        return downloadFile(props.resource.link);
      }
    } catch (_) {}
  }

  const canDownload = useMemo(() => isDownloadable(props.resource.link), [
    props.resource.link,
  ]);
  const disableMenu = !canDownload && !props.onDelete;

  return (
    <>
      <Wrapper
        data-offset-key={props.offsetKey}
        contentEditable={false}
        onClick={(e) => {
          setAnchorElement(e.currentTarget);
          return stopPropagation(e);
        }}
        disableMenu={disableMenu}
        onPointerDown={stopPropagation}
      >
        <Infos disableMenu={disableMenu} onClick={open}>
          {props.resource.name}
        </Infos>
      </Wrapper>
      <Menu
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        primary
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={() => setAnchorElement(null)}
      >
        {canDownload && (
          <MenuItem
            onClick={(e) => {
              download(e);
              setAnchorElement(null);
            }}
          >
            <DownloadIcon />
            {t('commons.download')}
          </MenuItem>
        )}
        {props.onDelete && (
          <MenuItem
            onClick={(e) => {
              props.onDelete!();
              setAnchorElement(null);
              return stopPropagation(e);
            }}
          >
            <DeleteIcon />
            {t('commons.delete')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default ResourceDisplay;
