import { To } from 'history';
import { Workspace } from './models/workspaces';
import toSearchString from './utils/toSearchString';

interface DetailsPathData {
  id: number | string;
  name: string;
}

export function toPath(value: string) {
  return toSearchString(
    value
      .replace(/\//g, '_')
      .replace(/ /g, '_')
      .replace(/\?/g, '')
      .replace(/#/g, ''),
  ).replace(/[^a-zA-Z0-9-_:]/g, '');
}

const paths = {
  // Absolute paths
  auth: {
    root: '/auth/*',
    signin: '/auth/signin',
    signup: '/auth/signup',
    signupjoin: '/auth/signupjoin',
    signout: '/auth/signout',
    verify: '/auth/verify',
    forgotPassword: '/auth/forgot_password',
    forgotPasswordConfirm: '/auth/forgot_password_confirm',
  },
  workspaces: {
    root: (workspace: DetailsPathData) =>
      `/${workspace.id}/${toPath(workspace.name)}`,
    list: '/workspaces',
    init: '/workspaces/init',
    join: '/workspaces/join',
  },

  // Workspace relative paths
  routerHome: '',
  home: '/',
  editor: '/editor',
  calendar: 'calendar',
  projects: {
    list: 'projects/',
    details: (
      project: DetailsPathData,
      step?: DetailsPathData,
      cardId?: number,
      notes?: boolean,
    ) => {
      let base = `projects/${project.id}/${toPath(project.name)}`;
      if (step) {
        base = `${base}/${step.id}/${toPath(step.name)}`;
        if (cardId) {
          return `${base}#card_modal_${cardId}`;
        }
        return base;
      }
      return base;
    },
  },
  resources: {
    list: 'resources/',
    details: (resource: DetailsPathData) =>
      `resources/${resource.id}/${toPath(resource.name)}`,
  },
  tasks: {
    list: 'tasks/',
  },
  project_templates: {
    list: 'templates/',
    details: (template: DetailsPathData) => `templates?id=${template.id}`,
  },
  sharing: {
    root: 's/',
    card: (id: string) => `cards/${id}`,
    step: (id: string) => `steps/${id}`,
    project: (id: string) => `projects/${id}`,
  },
};

export function workspacePath(path: To, workspace?: Workspace | null) {
  if (!workspace) return path;
  if (typeof path !== 'string') {
    const tmp = path.pathname;
    if (!tmp || tmp.length === 0 || tmp[0] === '/') return path;
    return {
      ...path,
      pathname: `${paths.workspaces.root(workspace)}/${tmp}`,
    };
  }
  if (path.length === 0 || path[0] === '/') return path;
  return `${paths.workspaces.root(workspace)}/${path}`;
}

export default paths;
