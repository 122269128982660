import api from 'api';
import { CustomLogSchema } from 'api/generated/models';
import { LOG_LAST_SEEN_KEY, SEEN_LOGS } from 'config';
import { DateTime } from 'luxon';
import { action, observable, runInAction } from 'mobx';
import { RootStore } from '.';

export class CustomStore {
  @observable
  public hasNewLog: boolean = false;

  @observable
  public seenLogs: number[] = [];

  constructor(private rootStore: RootStore) {
    this.rootStore.socket.on('logs', () => {
      runInAction(() => {
        this.hasNewLog = true;
      });
    });
  }

  @action
  public readLogs() {
    this.hasNewLog = false;
  }

  @action
  public async init() {
    this.seenLogs =
      this.rootStore.workspaceStore.getStorageItem(SEEN_LOGS, true) || [];
    const { data } = await api.custom.getCustomLogs();
    if (data.length > 0) {
      const lastSeenDate = this.rootStore.workspaceStore.getStorageItem<
        DateTime
      >(LOG_LAST_SEEN_KEY);

      this.hasNewLog = !lastSeenDate || data[0].date_created > lastSeenDate;
    }
  }

  @action
  public toggleSeenLog(log: CustomLogSchema) {
    this.seenLogs.push(log.id);
    this.rootStore.workspaceStore.setStorageItem(
      SEEN_LOGS,
      this.seenLogs,
      true,
    );
  }
}
