import { MenuItem } from '@material-ui/core';
import { CardStatus } from 'api/generated/models';
import DownIcon from 'components/icons/DownIcon';
import GearIcon from 'components/icons/GearIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Menu from '../elements/Menu';
import Opener from '../elements/Opener';
import Tooltip from '../elements/Tooltip';
import ProjectCardStatusIcon from './ProjectCardStatusIcon';

const Status = styled.div`
  margin-right: 10px;
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  align-items: center;

  svg {
    vertical-align: middle;
  }
`;

const Name = styled.div`
  margin-left: 5px;
`;

const StatusItem = styled(MenuItem)`
  padding: 15px 20px;
  svg,
  .emoji-mart-emoji {
    margin-right: 10px;
  }
`;

const AdminItem = styled(MenuItem)`
  border-top: 1px solid var(--color-border-grey);
  color: var(--color-light-grey);
  padding: 15px 20px;
  svg,
  .emoji-mart-emoji {
    margin-right: 10px;
  }
`;

interface ProjectCardStatusProps {
  status: string;
  statusList: CardStatus[];
  readOnly?: boolean;
  onAdmin?: () => void;
  onChange?: (status: CardStatus) => void;
  withDefault?: boolean;
  withName?: boolean;
}

function ProjectCardStatus(props: ProjectCardStatusProps) {
  const { t } = useTranslation();
  const status = props.statusList.find((c) => c.key === props.status);

  if (!props.onChange || props.readOnly) {
    return (
      <Status>
        <ProjectCardStatusIcon
          withDefault={props.withDefault}
          status={props.status}
          statusList={props.statusList}
        />
        {status && props.withName && (
          <Name>
            {status.name}{' '}
            <DownIcon
              color="primary"
              style={{ fontSize: '18px', marginLeft: '-5px' }}
            />
          </Name>
        )}
      </Status>
    );
  }
  return (
    <Opener
      content={(contentProps) => (
        <Menu
          anchorEl={contentProps.anchorEl}
          open={Boolean(contentProps.anchorEl)}
          onClose={contentProps.close}
        >
          {props.statusList.map((status) => (
            <StatusItem
              onClick={() => {
                props.onChange && props.onChange(status);
                contentProps.close();
              }}
            >
              <ProjectCardStatusIcon
                status={status.key}
                statusList={props.statusList}
              />
              <span>{status.name}</span>
            </StatusItem>
          ))}
          {props.onAdmin && (
            <AdminItem
              onClick={() => {
                if (props.onAdmin) props.onAdmin();
                contentProps.close();
              }}
            >
              <GearIcon />
              {t('projects.cards.status.edit_status_list')}
            </AdminItem>
          )}
        </Menu>
      )}
    >
      <Tooltip title={t('projects.cards.status.update')}>
        <Status>
          <ProjectCardStatusIcon
            withDefault={props.withDefault}
            status={props.status}
            statusList={props.statusList}
          />
          {status && props.withName && <Name>{status.name}</Name>}
        </Status>
      </Tooltip>
    </Opener>
  );
}

export default ProjectCardStatus;
