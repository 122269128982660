import { ContentState } from 'draft-js';
import { CHECKABLE_ITEM } from './CheckablePlugin/utils';

export interface CheckableState {
  totalCount: number;
  checkedCount: number;
}

export function getCheckableState(contentState: ContentState): CheckableState {
  let totalCount = 0;
  let checkedCount = 0;
  contentState.getBlocksAsArray().forEach((block) => {
    if (block.getType() === CHECKABLE_ITEM) {
      totalCount += 1;
      if (block.getData().get('checked')) {
        checkedCount += 1;
      }
    }
  });
  return { totalCount, checkedCount };
}
