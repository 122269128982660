import { DateTime } from 'luxon';

export enum Role {
  ANONYMOUS = -1,
  MEMBER = 1,
  ADMINISTRATOR = 100,
}

export interface Profile {
  id: number;
  name: string;
  avatar?: string;
  date_created: DateTime;
  email?: string;
  role: number;
  deleted: boolean;
}

export interface MyProfile extends Profile {
  onboarding: boolean;
  storage_data: any;
}
