import { Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ImageTooltip from './ImageTooltip';
import InfoIcon from '../icons/InfoIcon';

const Wrapper = styled.div`
  cursor: pointer;
  vertical-align: middle;
  display: inline-flex;

  svg {
    vertical-align: middle;
  }
`;

interface InfoButtonProps {
  tooltip?: string;
  tooltipImage?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

function InfoButton(props: InfoButtonProps) {
  if (props.tooltip) {
    if (props.tooltipImage) {
      return (
        <ImageTooltip title={props.tooltip} image={props.tooltipImage}>
          <Wrapper
            style={props.style}
            onClick={props.onClick}
            className="info-button"
          >
            <InfoIcon color="inherit" style={{ marginBottom: '-1px' }} />
          </Wrapper>
        </ImageTooltip>
      );
    }
    return (
      <Tooltip title={props.tooltip}>
        <Wrapper
          style={props.style}
          onClick={props.onClick}
          className="info-button"
        >
          <InfoIcon color="inherit" style={{ marginBottom: '-1px' }} />
        </Wrapper>
      </Tooltip>
    );
  }
  return (
    <Wrapper
      style={props.style}
      onClick={props.onClick}
      className="info-button"
    >
      <InfoIcon color="inherit" />
    </Wrapper>
  );
}

export default InfoButton;
