import { ContentBlock, EditorState } from 'draft-js';
import { adjustBlockDepth, mergeBlockDataByKey } from 'draft-js-modifiers';
import { KeyboardEvent } from 'react';

export const CHECKABLE_ITEM = 'checkable-item';

export default class CheckableUtils {
  public static toggleChecked(
    editorState: EditorState,
    block: ContentBlock,
  ): EditorState {
    return mergeBlockDataByKey(editorState, block.getKey(), {
      checked: !block.getData().get('checked'),
    });
  }

  public static onTab(
    event: KeyboardEvent,
    editorState: EditorState,
    maxDepth: number,
  ): EditorState {
    const selection = editorState.getSelection();
    const key = selection.getAnchorKey();
    if (key !== selection.getFocusKey()) {
      return editorState;
    }

    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(key);
    const type = block.getType();
    if (type !== CHECKABLE_ITEM) {
      return editorState;
    }

    event.preventDefault();

    const depth = block.getDepth();
    if (!event.shiftKey && depth === maxDepth) {
      return editorState;
    }

    return adjustBlockDepth(editorState, event.shiftKey ? -1 : 1, maxDepth);
  }
}
