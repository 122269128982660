import { MenuItem } from '@material-ui/core';
import { WorkspaceInvitationCreateSchema } from 'api/generated/models';
import BackLink from 'components/elements/BackLink';
import InfoButton from 'components/elements/InfoButton';
import LoadingRoundedButton from 'components/elements/LoadingRoundedButton';
import RoundedSelect from 'components/elements/RoundedSelect';
import RoundedTextField from 'components/elements/RoundedTextField';
import CenteredContent from 'components/layouts/CenteredContent';
import { Emoji } from 'emoji-mart';
import { ModalFooter } from 'modals/styles';
import { Role } from 'models/profiles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import isValidEmail from 'utils/isValidEmail';
import VideoOnBoarding from './VideoOnBoarding';
import BigTitle from '../elements/BigTitle';
import Description from '../elements/Description';

const Wrapper = styled.div`
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    margin-bottom: 30px;
  }
`;

const AddLink = styled.div`
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
  }
`;

interface InviteOnBoardingModalProps {}

function InviteOnBoardingModal(props: InviteOnBoardingModalProps) {
  const workspace = useCurrentWorkspace();
  const { t } = useTranslation();
  const { uiStore, workspaceStore } = useStore();

  const [invitations, setInvitations] = useState<
    WorkspaceInvitationCreateSchema[]
  >([
    {
      email: '',
      role: Role.MEMBER as number,
    },
    {
      email: '',
      role: Role.MEMBER as number,
    },
  ]);

  const [loading, setLoading] = useState(false);

  async function onInvite() {
    setLoading(true);
    try {
      await Promise.all(
        invitations
          .filter((i) => i.email.length !== 0)
          .map((invitation) =>
            workspace.createInvitation({
              email: invitation.email,
              role: invitation.role,
            }),
          ),
      );
    } finally {
      setLoading(false);
    }
    uiStore.popSide();
    uiStore.pushSide(<VideoOnBoarding />);
  }

  async function next() {
    uiStore.popSide();
    uiStore.pushSide(<VideoOnBoarding />);
  }

  const placeholders = t('members.invite.email.placeholder').split('|');
  const valid = !invitations.some((p) => p.email && !isValidEmail(p.email));

  return (
    <CenteredContent width="var(--center-width)">
      <Header>
        <div>
          <Emoji
            size={64}
            emoji={t('workspaces.onboarding_invite.emoji')}
            set="apple"
          />
        </div>
        <BigTitle>{t('workspaces.onboarding_invite.title')}</BigTitle>
        <Description>
          {t('workspaces.onboarding_invite.description')}
        </Description>
      </Header>
      <Wrapper>
        {invitations.map((invitation, index) => {
          function updateInvitation(
            data: Partial<WorkspaceInvitationCreateSchema>,
          ) {
            setInvitations(
              invitations.map((inv, i) =>
                i === index ? { ...inv, ...data } : inv,
              ),
            );
          }
          return (
            <Row>
              <RoundedTextField
                autoFocus
                fullWidth
                label={t('members.invite.email.label')}
                placeholder={placeholders[index % placeholders.length]}
                error={
                  invitation.email.length > 0 && !isValidEmail(invitation.email)
                }
                value={invitation.email}
                onChange={(e) => updateInvitation({ email: e.target.value })}
                variant="outlined"
              />

              {workspaceStore.isAdmin && (
                <RoundedSelect
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'bottom',
                    },
                  }}
                  style={{ marginLeft: '10px' }}
                  label={t('members.invite.roles.label')}
                  onChange={(e) =>
                    updateInvitation({ role: e.target.value as number })
                  }
                  value={invitation.role}
                  fullWidth
                  renderValue={(v) => (
                    <RoleWrapper>
                      {v === Role.ADMINISTRATOR
                        ? t('members.roles.admin')
                        : t('members.roles.member')}
                      <InfoButton
                        tooltip={
                          v === Role.ADMINISTRATOR
                            ? t('members.roles.admin_tooltip')
                            : t('members.roles.member_tooltip')
                        }
                      />
                    </RoleWrapper>
                  )}
                >
                  <MenuItem value={Role.MEMBER}>
                    {t('members.roles.member')}
                  </MenuItem>
                  <MenuItem value={Role.ADMINISTRATOR}>
                    {t('members.roles.admin')}
                  </MenuItem>
                </RoundedSelect>
              )}
            </Row>
          );
        })}
        {(!workspace.max_profile_count ||
          invitations.length < workspace.max_profile_count) && (
          <Row>
            <AddLink
              onClick={() =>
                setInvitations([
                  ...invitations,
                  { email: '', role: Role.MEMBER as number },
                ])
              }
            >
              {t('members.invite.add_invitation')}
            </AddLink>
          </Row>
        )}
      </Wrapper>
      <ModalFooter>
        <BackLink onClick={next}>{t('commons.later')}</BackLink>
        <LoadingRoundedButton
          color="primary"
          variant="contained"
          size="small"
          onClick={onInvite}
          loading={loading}
          disabled={!valid}
        >
          {t('members.invite.submit')}
        </LoadingRoundedButton>
      </ModalFooter>
    </CenteredContent>
  );
}

export default InviteOnBoardingModal;
