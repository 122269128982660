import { Typography, TypographyProps } from '@material-ui/core';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Typography)`
  padding: 5px 10px;
  border-radius: 14px;
  font-size: 12px;
  display: inline-block;
  cursor: default;
  white-space: nowrap;
  user-select: none;
`;

interface TagProps extends Omit<TypographyProps, 'color'> {
  color: string;
  pointer?: boolean;
}

function Tag({ color, ...props }: React.PropsWithChildren<TagProps>) {
  return (
    <Wrapper
      {...(props as any)}
      style={{
        backgroundColor: transparentize(0.88, color),
        color,
        cursor: props.pointer ? 'pointer' : 'default',
      }}
    />
  );
}

export default Tag;
