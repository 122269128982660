import Amplify from 'aws-amplify';
import paths from './paths';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_POOL_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin + paths.auth.signout,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  },
});

export { Auth } from 'aws-amplify';
