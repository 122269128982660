import { observer } from 'mobx-react-lite';
import React from 'react';
import { PoseGroup } from 'react-pose';
import { useStore } from 'stores';
import styled from 'styled-components';
import { FadeInItem } from '../animations/FadeIn';

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 15;

  > * {
    margin-top: 20px;
  }
`;

interface ToastsProps {}

function Toasts(props: ToastsProps) {
  const { uiStore } = useStore();
  return (
    <Wrapper>
      <PoseGroup>
        {uiStore.toastStack.map((toast, index) => (
          <FadeInItem key={`toast-${index}`}>{toast}</FadeInItem>
        ))}
      </PoseGroup>
    </Wrapper>
  );
}

export default observer(Toasts);
