import { ContentState } from 'draft-js';

function isEmptyContent(content: ContentState) {
  const blocks = content.getBlockMap();
  return (
    !content.hasText() &&
    (blocks.isEmpty() || blocks.first().getType() === 'unstyled')
  );
}

export default isEmptyContent;
