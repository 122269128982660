import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Edit-a{fill:none;}.Edit-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-3022 -1456)">
        <rect
          className="Edit-a"
          width="30"
          height="30"
          transform="translate(3022 1456)"
        />
        <g transform="translate(3044.168 1458.221) rotate(45)">
          <g transform="translate(0 0)">
            <path
              className="Edit-b"
              d="M6.315,24.031h0A1.236,1.236,0,0,0,7.552,22.8L7.429,3.709a3.709,3.709,0,0,0-7.419,0L0,23.018a1.237,1.237,0,0,0,.209.689l2.4,3.579a1.236,1.236,0,0,0,1.011.548h.017a1.236,1.236,0,0,0,1.009-.521l2.551-3.6a1.236,1.236,0,1,0-2.017-1.43L3.662,24.418,2.473,22.643l.009-15.9H4.956l.123,16.055A1.237,1.237,0,0,0,6.315,24.031ZM2.483,4.266V3.709a1.236,1.236,0,0,1,2.473,0v.557Z"
              transform="translate(0)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default EditIcon;
