import styled from 'styled-components';

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  color: var(--color-light-grey);
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  max-width: 600px;
  text-align: center;
`;

export default Placeholder;
