import { Paper, Typography } from '@material-ui/core';
import { CustomLogSchema } from 'api/generated/models';
import Avatar from 'components/Avatar';
import Link from 'components/Router/Link';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import paths from 'paths';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import { truncateString } from 'utils/truncateString';

const Wrapper = styled(Paper)<{ isNew?: boolean }>`
  box-shadow: var(--shadow-sm);
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;
  position: relative;

  ${(p) =>
    p.isNew &&
    `
    background-color: var(--color-primary-bg);
  `}
`;

const AvatarWrapper = styled.div`
  padding-right: 20px;
`;

const Colored = styled.span`
  &&&& {
    color: var(--color-primary);
  }
`;

const ContentWrapper = styled.div`
  > * {
    color: var(--color-black);
  }
`;

const Date = styled.div`
  color: var(--color-light-grey);
  font-weight: 300;
  font-size: 14px;
  margin-top: 2px;
`;

const NewWrapper = styled(Typography)`
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 2px 10px;
  border-radius: 8px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 15px;
`;

interface CustomLogProps {
  log: CustomLogSchema;
  isNew?: boolean;
}

const LOG_TO: any = {
  'project.create': (log: CustomLogSchema) =>
    log.project ? paths.projects.details(log.project) : undefined,
  'membership.create': (log: CustomLogSchema) =>
    log.project ? paths.projects.details(log.project) : undefined,
  'card.assign': (log: CustomLogSchema) =>
    log.project
      ? paths.projects.details(log.project, log.card?.step, log.card?.id)
      : undefined,
  'note.create': (log: CustomLogSchema) =>
    log.project
      ? paths.projects.details(log.project, log.card?.step, log.card?.id, true)
      : undefined,
  'card.update_status': (log: CustomLogSchema) =>
    log.project
      ? paths.projects.details(log.project, log.card?.step, log.card?.id)
      : undefined,
  'mention.create': (log: CustomLogSchema) =>
    log.project
      ? paths.projects.details(log.project, log.card?.step, log.card?.id)
      : undefined,
};

const AVATARS: any = {
  'membership.create': (log: CustomLogSchema) => log.target_profile!,
};

const LOG_REQUIREMENTS: any = {
  'project.create': ['project', 'profile'],
  'membership.create': ['project', 'target_profile'],
  'card.assign': ['project', 'target_profile'],
  // 'resource.create': ['profile', 'data'],
  'note.create': ['profile', 'card', 'project'],
  'workspace.join': ['profile'],
  'mention.create': ['profile', 'target_profile', 'card', 'project'],
};

function meetRequirements(log: CustomLogSchema) {
  const reqs: string[] = LOG_REQUIREMENTS[log.type] || [];
  return !reqs.some((c: any) => !(log as any)[c]);
}

function CustomLog(props: CustomLogProps) {
  const workspace = useCurrentWorkspace();
  const { uiStore, customStore } = useStore();

  const { t } = useTranslation();
  const seen = customStore.seenLogs.includes(props.log.id);
  const targetFn = LOG_TO[props.log.type];
  const target = targetFn ? targetFn(props.log) : undefined;

  function onClick() {
    customStore.toggleSeenLog(props.log);
  }

  const link = (p: any) => (
    <Link
      {...p}
      onClick={() => {
        onClick();
        uiStore.popSide();
      }}
      style={{ textDecoration: 'none', color: COLORS.black }}
      to={target}
    />
  );

  if (!meetRequirements(props.log)) {
    return null;
  }

  const avatarFn = AVATARS[props.log.type];
  const avatar = avatarFn
    ? avatarFn(props.log) || props.log.profile
    : props.log.profile;

  return (
    <Wrapper
      component={target ? link : undefined}
      onClick={onClick}
      isNew={!seen}
    >
      {props.isNew && !seen && <NewWrapper>{t('logs.new')}</NewWrapper>}
      {avatar && (
        <AvatarWrapper>
          <Avatar profile={avatar} size={35} />
        </AvatarWrapper>
      )}
      <ContentWrapper>
        {props.log.type === 'project.create' && (
          <Trans i18nKey="logs.project_create.content">
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'membership.create' && (
          <Trans i18nKey="logs.membership_create.content">
            <Colored>{{ profile: props.log.target_profile?.name }}</Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'card.assign' && (
          <Trans i18nKey="logs.card_assign.content">
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>
              {{ card: truncateString(props.log.card?.title, 45) }}
            </Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'mention.create' && (
          <Trans
            i18nKey={`logs.mention_create.content${
              props.log.data && props.log.data.note ? '_note' : ''
            }`}
          >
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>
              {{ card: truncateString(props.log.card?.title, 45) }}
            </Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'card.update_status' && (
          <Trans i18nKey="logs.card_update_status.content">
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>
              {{
                status: props.log.data ? props.log.data.current : '',
              }}
            </Colored>
            <Colored>
              {{ card: truncateString(props.log.card?.title, 45) }}
            </Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'note.create' && (
          <Trans i18nKey="logs.note_create.content">
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>
              {{ card: truncateString(props.log.card?.title, 45) }}
            </Colored>
            <Colored>
              {{ project: truncateString(props.log.project?.name, 45) }}
            </Colored>
          </Trans>
        )}
        {props.log.type === 'workspace.join' && (
          <Trans i18nKey="logs.workspace_join.content">
            <Colored>{{ profile: props.log.profile?.name }}</Colored>
            <Colored>{{ workspace: workspace.name }}</Colored>
          </Trans>
        )}
        <Date>
          {props.log.date_created.toLocaleString(DateTime.DATETIME_MED)}
        </Date>
      </ContentWrapper>
    </Wrapper>
  );
}

export default observer(CustomLog);
