import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores';
import styled from 'styled-components';
import { theme } from 'theme';

const Dimmer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 99;
`;

const Wrapper = styled.div`
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16), 0 -3px 6px rgba(0, 0, 0, 0.23);
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: var(--color-white);
  z-index: 100;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: scroll;

  padding: 40px;
  padding-right: 20px;

  ${theme.breakpoints.down('sm')} {
    padding: 20px;
    padding-right: 10px;
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    padding: 80px;
    padding-right: 40px;
  }
`;

interface SideProps {}

function Side(props: React.PropsWithChildren<SideProps>) {
  const { uiStore } = useStore();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(!!uiStore.currentSide);
  }, [uiStore.currentSide]);

  return (
    <>
      {uiStore.currentSide && <Dimmer onClick={() => uiStore.popSide(false)} />}
      <Wrapper
        className="vertical-scroll"
        style={{
          transform: mounted ? 'translateX(0)' : 'translateX(100%)',
          transition: mounted ? 'transform .1s' : 'none',
        }}
      >
        {uiStore.currentSide}
      </Wrapper>
    </>
  );
}

export default observer(Side);
