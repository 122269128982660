import { Box, Typography } from '@material-ui/core';
import BackLink from 'components/elements/BackLink';
import RoundedButton from 'components/elements/RoundedButton';
import Title from 'components/elements/Title';
import WithConfirm from 'components/elements/WithConfirm';
import CenteredContent from 'components/layouts/CenteredContent';
import { observer } from 'mobx-react-lite';
import { ModalContent, ModalFooter, ModalTitle } from 'modals/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import { COLORS } from 'theme';

const Label = styled(Typography)`
  margin-bottom: 5px;
  color: var(--color-primary);
`;

interface WorkspaceSettingsProps {}

function WorkspaceSettings(props: WorkspaceSettingsProps) {
  const { t } = useTranslation();
  const { workspaceStore } = useStore();
  return (
    <CenteredContent width="var(--center-width)">
      <ModalTitle>
        <Title>{t('workspace.settings.title')}</Title>
      </ModalTitle>
      <ModalContent>
        <Label>{t('workspace.settings.export')}</Label>
        <RoundedButton
          onClick={() => workspaceStore.export()}
          variant="outlined"
          color="secondary"
        >
          {t('workspace.settings.export_action')}
        </RoundedButton>
        <Box mt={3}>
          <Label>{t('workspace.settings.danger_zone')}</Label>
          <WithConfirm
            onConfirm={() => workspaceStore.deleteCurrentWorkspace()}
          >
            <RoundedButton
              variant="outlined"
              color="secondary"
              style={{ color: COLORS.error, borderColor: COLORS.error }}
            >
              {t('workspace.settings.delete_workspace')}
            </RoundedButton>
          </WithConfirm>
        </Box>
      </ModalContent>
      <ModalFooter>
        <BackLink popSide />
      </ModalFooter>
    </CenteredContent>
  );
}

export default observer(WorkspaceSettings);
