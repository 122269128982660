import { ResourceType } from './models/resources';

export const SIGNUP_USER_KEY = 'signup_user';
export const WORKSPACE_STORAGE_KEY = 'workspace_id';
export const NEXT_KEY = 'next';
export const RELOAD_KEY = 'reload_count';
export const DEFAULT_WORKSPACE_NAME = 'default';
export const PREVENT_INVITE_KEY = 'prevent_invite';
export const LANGUAGE_KEY = 'lang';
export const FAVORITE_PROJECTS_KEY = 'favorite_projects';
export const RECENT_SEARCH_KEY = 'recent_search';

export const VIEWABLE_RESOURCE_TYPES: ResourceType[] = ['img', 'pdf', 'link'];

export const HEADER_PAGINATION_HAS_MORE = 'x-pagination-has-more';

export const MINIMUM_WIDTH = 800;

export const AVATAR_COLORS = [
  '#ff1744',
  '#f50057',
  '#d500f9',
  '#651fff',
  '#3d5afe',
  '#2979ff',
  '#00b0ff',
  '#00e5ff',
  '#1de9b6',
  '#00e676',
  '#ffc400',
  '#ff9100',
  '#ff3d00',
];

export const LOG_LAST_SEEN_KEY = 'log_last_seen_date';
export const LOG_CALENDAR_FILTERS_KEY = 'calendar_filters';
export const SEEN_LOGS = 'seen_logs';
export const SOURCE_KEY = 'source';

export const CARD_LABELS: any = {
  grey: '#AFAFAF',
  yellow: '#f2d600',
  orange: '#ff9f1a',
  red: '#eb5a46',
  purple: '#c377e0',
  blue: '#0079bf',
  turquoise: '#00c2e0',
  green: '#48C854',
};
