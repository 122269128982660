import { css } from 'emotion';
import React from 'react';
import styled from 'styled-components';
import Tooltip, { CustomTooltipProps } from './Tooltip';

const Wrapper = styled.div`
  img {
    display: block;
    width: 350px;
    height: 250px;
    border-radius: 7px;
    margin-bottom: 12px;
  }
`;

interface ImageTooltipProps extends CustomTooltipProps {
  image: string;
}

function ImageTooltip({ title, image, ...props }: ImageTooltipProps) {
  return (
    <Tooltip
      {...(props as any)}
      classes={{
        tooltip: css`
          &&&& {
            padding: 15px;
            max-width: 380px;
          }
        `,
      }}
      title={
        <Wrapper>
          <img src={image} alt={title} />
          <span>{title}</span>
        </Wrapper>
      }
    />
  );
}

export default ImageTooltip;
