import React, { Suspense } from 'react';
import { Route as BaseRoute, RouteProps } from 'react-router';
import Progress from '../utils/Progress';

function Route({ element, ...props }: RouteProps) {
  return (
    <BaseRoute
      {...(props as any)}
      element={<Suspense fallback={<Progress />}>{element}</Suspense>}
    />
  );
}

export default Route;
