import api from 'api';
import {
  MyProfileUpdateSchema,
  WorkspaceInitSchema,
  WorkspaceJoinSchema,
} from 'api/generated/models';
import { PREVENT_INVITE_KEY } from 'config';
import { DateTime } from 'luxon';
import { action, autorun, computed, observable, runInAction } from 'mobx';
import { MyProfile, Role } from 'models/profiles';
import { Workspace } from 'models/workspaces';
import downloadFile from 'utils/downloadFile';
import { isAdmin } from 'utils/isAdmin';
import { RootStore, useStore } from '.';

const dummyProfile: MyProfile = {
  id: -1,
  name: 'Anonymous',
  date_created: DateTime.local(),
  role: Role.ANONYMOUS,
  deleted: false,
  onboarding: true,
  storage_data: {},
};

export class WorkspaceStore {
  @observable
  public workspaces: Workspace[] = [];

  @observable
  public loading: boolean = true;

  @observable
  public currentWorkspaceID: number | null = null;

  @observable
  private _preventInvite: boolean = true;

  constructor(private rootStore: RootStore) {
    this._preventInvite = !!localStorage.getItem(PREVENT_INVITE_KEY);
  }

  public init() {
    this.loadWorkspaces();

    autorun(() => {
      if (this.currentWorkspace) {
        this.rootStore.socket.init();
      }
    });

    autorun(() => {
      if (!this.currentWorkspace) return;
      if (this.currentProfile && this.currentProfile.onboarding) {
        window.Intercom('boot', {
          app_id: window.intercomSettings?.app_id,
          email: this.currentProfile.email,
          name: this.currentProfile.name,
          created_at: this.currentProfile.date_created.toMillis(),
          user_id: this.currentProfile.id,
        });
      }
    });
  }

  @action
  public setPreventInvite() {
    this._preventInvite = true;
    localStorage.setItem(PREVENT_INVITE_KEY, '1');
  }

  @action
  public reset(isSignout?: boolean) {
    if (isSignout) {
      this.workspaces = [];
    }
    this._preventInvite = !!localStorage.getItem(PREVENT_INVITE_KEY);
    this.currentWorkspaceID = null;
  }

  @action
  private async loadWorkspaces() {
    this.loading = true;
    const { data } = await api.workspaces.getWorkspaces();
    runInAction(() => {
      this.workspaces = data.map((workspace) => new Workspace(workspace));
      this.loading = false;
    });
  }

  public async initWorkspace(schema: WorkspaceInitSchema) {
    const { data } = await api.workspaces.initWorkspace(schema);
    const workspace = new Workspace(data);
    runInAction(() => {
      this.workspaces.push(workspace);
    });

    return workspace;
  }

  public getWorkspace(id: number) {
    return this.workspaces.find((w) => w.id === id);
  }

  public async join(joinData: WorkspaceJoinSchema) {
    await api.workspaces.joinWorkspace(joinData);
    await this.loadWorkspaces();
  }

  @action
  public setCurrentWorkspace(workspace: Workspace) {
    if (!this.workspaces.find((c) => c.id === workspace.id)) {
      this.workspaces.push(workspace);
    }
    this.setCurrentWorkspaceID(workspace.id);
  }

  @action
  public setCurrentWorkspaceID(id: number) {
    this.currentWorkspaceID = id;
  }

  @action
  public async deleteCurrentProfile() {
    await api.profiles.deleteMyProfile();
    this.currentWorkspaceID = null;
    this.rootStore.reset();
    window.location.reload();
  }

  @action
  public async deleteCurrentWorkspace() {
    await api.workspaces.deleteCurrentWorkspace();
    this.currentWorkspaceID = null;
    this.rootStore.reset();
    window.location.href = window.location.origin;
  }

  @computed
  get currentWorkspace() {
    if (!this.currentWorkspaceID) return null;
    return this.getWorkspace(this.currentWorkspaceID);
  }

  @computed
  get preventInvite() {
    if (!this.currentWorkspace) return true;
    return (
      this._preventInvite ||
      this.rootStore.profileStore.loading ||
      this.rootStore.profileStore.profiles.length !== 1
    );
  }

  @computed
  get currentProfile(): MyProfile {
    if (!this.currentWorkspace) return dummyProfile;
    return this.currentWorkspace.profile || dummyProfile;
  }

  public async updateCurrentProfile(newData: MyProfileUpdateSchema) {
    const { data } = await api.profiles.updateMyProfile(newData);
    runInAction(() => {
      if (!this.currentWorkspace) return;
      this.currentWorkspace.profile = data;
    });
  }

  public async updateCurrentProfileStorageData(newData: any) {
    const { data } = await api.profiles.updateMyProfileStorageData(newData);
    runInAction(() => {
      if (!this.currentWorkspace) return;
      this.currentWorkspace.profile = data;
    });
  }

  public getStorageItem<T>(
    key: string,
    json?: boolean,
    def?: T,
  ): T | undefined {
    if (!this.currentWorkspace) return def;

    const value = this.currentProfile.storage_data[key];
    if (json) {
      return value === undefined ? def : JSON.parse(value);
    }
    return value === undefined ? def : value;
  }

  public async setStorageItem(key: string, value: any, json?: boolean) {
    if (!this.currentWorkspace) return;
    if (
      json
        ? this.currentProfile.storage_data[key] === JSON.stringify(value)
        : this.currentProfile.storage_data[key] === value
    ) {
      return;
    }

    const newData = {
      ...this.currentProfile.storage_data,
      [key]: json ? JSON.stringify(value) : value,
    };

    await this.updateCurrentProfileStorageData(newData);
  }

  @computed
  get isAdmin() {
    return isAdmin(this.currentProfile);
  }

  @computed
  get isOwner() {
    if (!this.currentWorkspace) return false;
    return this.currentProfile.id === this.currentWorkspace.owner_id;
  }

  public async export() {
    if (!this.currentWorkspace) return;
    const { data } = await api.axios.get('/workspaces/export', {
      responseType: 'blob',
    });
    downloadFile(
      URL.createObjectURL(data),
      `${this.currentWorkspace.name}-export.xlsx`,
    );
  }
}

export function useCurrentWorkspace() {
  const { workspaceStore } = useStore();
  if (!workspaceStore.currentWorkspace) {
    throw new Error(
      'useCurrentWorkspace must be called in a valid workspace context',
    );
  }
  return workspaceStore.currentWorkspace;
}

export function useCurrentProfile() {
  const { workspaceStore } = useStore();
  return workspaceStore.currentProfile;
}
