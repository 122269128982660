import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function TasksIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g
        id="Group_3597"
        data-name="Group 3597"
        transform="translate(-3229 -1371)"
      >
        <g id="Group_3595" data-name="Group 3595">
          <g id="Group_3593" data-name="Group 3593">
            <g
              id="Group_3592"
              data-name="Group 3592"
              transform="translate(89 -3)"
            >
              <g
                id="Group_2497"
                data-name="Group 2497"
                transform="translate(266 -142)"
              >
                <rect
                  id="Rectangle_1077"
                  data-name="Rectangle 1077"
                  width="30"
                  height="30"
                  transform="translate(2874 1516)"
                  fill="none"
                />
              </g>
            </g>
          </g>
          <g id="layer1" transform="translate(3423.639 1336.786)">
            <path
              id="path863"
              d="M-184.024,60.91a3,3,0,0,1-2.461-1.9,4.408,4.408,0,0,1-.2-1.892V56h-.457a3.969,3.969,0,0,1-1.737-.276,2.977,2.977,0,0,1-1.709-2.116c-.074-.35-.074-12.692,0-13.041a2.986,2.986,0,0,1,2.143-2.271l.282-.076h6.426c6.136,0,6.435,0,6.641.061a2.985,2.985,0,0,1,2.16,2.242,4.118,4.118,0,0,1,.064.957v.67l1.254.013a4.565,4.565,0,0,1,1.546.1,3.012,3.012,0,0,1,2.055,2.048l.077.249.01,6.857c.007,4.88,0,6.915-.027,7.057a2.987,2.987,0,0,1-2.189,2.369l-.318.088-6.641,0C-180.753,60.934-183.868,60.924-184.024,60.91Zm13.553-2.012a1.19,1.19,0,0,0,.493-.443,1.427,1.427,0,0,0,.1-.316c.048-.26.051-12.947,0-13.2a1.488,1.488,0,0,0-.091-.3,1.066,1.066,0,0,0-.526-.469c-.182-.068-.2-.068-6.805-.068-6.535,0-6.625,0-6.8.066a1.1,1.1,0,0,0-.531.475l-.075.14-.01,6.675c-.007,4.968,0,6.708.028,6.807a1.066,1.066,0,0,0,.731.679c.082.016,3.074.027,6.739.024l6.592,0Zm-9.02-2.746a1.716,1.716,0,0,1-.211-.105c-.141-.085-3.4-4.149-3.5-4.362a.986.986,0,0,1,.019-.787.977.977,0,0,1,1.32-.445c.179.092.337.274,1.577,1.825.635.794,1.169,1.459,1.186,1.478s1.1-1.041,2.976-2.928c2.86-2.875,2.951-2.963,3.144-3.033a.967.967,0,0,1,1.323.89c0,.431.2.214-3.548,3.962-2.731,2.734-3.419,3.405-3.553,3.469a1.153,1.153,0,0,1-.731.036Zm-7.189-6.705c0-2.961.012-4.665.034-4.8a2.974,2.974,0,0,1,.76-1.543,2.993,2.993,0,0,1,1.4-.861l.247-.076,4.724-.01,4.724-.01v-.491c0-.776-.077-1.037-.374-1.277s.265-.225-6.65-.215l-6.21.009-.152.089a1.075,1.075,0,0,0-.449.511c-.061.158-.063.36-.063,6.306s0,6.149.063,6.306A1.043,1.043,0,0,0-187.9,54a6.21,6.21,0,0,0,.684.032l.54,0V49.446Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default TasksIcon;
