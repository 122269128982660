import FreemiumBanner from 'components/FreemiumBanner';
import Popup from 'components/Popup';
import { NEXT_KEY } from 'config';
import { observer } from 'mobx-react-lite';
import paths from 'paths';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useParams } from 'react-router-dom';
import { useStore } from 'stores';
import styled from 'styled-components';
import useNavigate from 'utils/useNavigate';
import OnBoarding from '../OnBoarding';
import InviteButton from '../OnBoarding/InviteButton';
import Side from '../Side';
import Sidebar from '../Sidebar';
import Toasts from '../Toasts';
import Topbar from '../Topbar';

const Wrapper = styled.div``;

const Main = styled.main`
  flex-grow: 1;
  max-width: calc(100% - var(--sidebar-width));
`;

const Content = styled.div`
  display: flex;
`;

interface WorkspaceLayoutProps {}

function WorkspaceLayout(props: WorkspaceLayoutProps) {
  const store = useStore();
  const { workspaceStore, profileStore, customStore } = store;
  const { workspaceID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const workspace = workspaceStore.getWorkspace(+workspaceID);
    localStorage.removeItem(NEXT_KEY);

    if (workspace) {
      workspaceStore.setCurrentWorkspace(workspace);
      store.init();
    } else {
      navigate(paths.workspaces.list);
    }

    return () => {
      store.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceID, workspaceStore, profileStore]);

  if (!workspaceStore.currentWorkspace) {
    return null;
  }

  return (
    <Wrapper
      key={`${workspaceStore.currentProfile.avatar}${workspaceStore.currentProfile.name}`}
    >
      <Helmet titleTemplate={`%s - ${workspaceStore.currentWorkspace.name}`}>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={`${process.env.PUBLIC_URL}/apple-icon-57x57${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`${process.env.PUBLIC_URL}/apple-icon-60x60${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={`${process.env.PUBLIC_URL}/apple-icon-72x72${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`${process.env.PUBLIC_URL}/apple-icon-76x76${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={`${process.env.PUBLIC_URL}/apple-icon-114x114${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${process.env.PUBLIC_URL}/apple-icon-120x120${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={`${process.env.PUBLIC_URL}/apple-icon-144x144${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`${process.env.PUBLIC_URL}/apple-icon-152x152${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${process.env.PUBLIC_URL}/apple-icon-180x180${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${process.env.PUBLIC_URL}/android-icon-192x192${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${process.env.PUBLIC_URL}/favicon-32x32${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`${process.env.PUBLIC_URL}/favicon-96x96${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${process.env.PUBLIC_URL}/favicon-16x16${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
        <meta
          name="msapplication-TileImage"
          content={`${process.env.PUBLIC_URL}/ms-icon-144x144${
            customStore.hasNewLog ? '-new' : ''
          }.png`}
        />
      </Helmet>
      {!workspaceStore.preventInvite && <InviteButton />}
      <OnBoarding />
      <Side />
      <Popup />
      <Toasts />
      <Topbar />
      <Content>
        <Sidebar />
        <Main>
          <Outlet />
          {workspaceStore.currentWorkspace.freemiumEndingSoon && (
            <FreemiumBanner />
          )}
        </Main>
      </Content>
    </Wrapper>
  );
}

export default observer(WorkspaceLayout);
