import { Typography } from '@material-ui/core';
import { Emoji } from 'emoji-mart';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Typography)`
  background-color: var(--color-primary);
  padding: 6px 15px;
  padding-left: 7px;
  border-radius: 30px;
  box-shadow: var(--shadow-sm);
  display: flex;
  align-items: center;
  color: var(--color-white);
`;

const EmojiWrapper = styled.div`
  height: 20px;
  width: 20px;
`;

const EmojiOuter = styled.div`
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 27px;
  width: 27px;
  margin-right: 7px;
`;

interface ToastProps {
  emoji: string;
}

function Toast(props: React.PropsWithChildren<ToastProps>) {
  return (
    <Wrapper>
      <EmojiOuter>
        <EmojiWrapper>
          <Emoji emoji={props.emoji} size={20} set="apple" />
        </EmojiWrapper>
      </EmojiOuter>
      {props.children}
    </Wrapper>
  );
}

export default Toast;
