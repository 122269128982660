import { Typography } from '@material-ui/core';
import { CustomCardSchema } from 'api/generated/models';
import Avatar from 'components/Avatar';
import DescriptionIcon from 'components/icons/DescriptionIcon';
import Link from 'components/Router/Link';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import paths from 'paths';
import React from 'react';
import styled from 'styled-components';
import contentFromString from 'utils/contentFromString';
import isEmptyContent from 'utils/isEmptyContent';
import ProjectCardStatus from './ProjectCardStatus';

const Wrapper = styled(Link)`
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  padding: 10px;
  display: block;
`;

const Title = styled(Typography)`
  color: var(--color-secondary);
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > :first-child {
    margin-top: -3px;
    margin-right: 5px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 7px;
`;

const Infos = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-primary);

  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const BreadCrumb = styled(Typography)`
  color: var(--color-light-grey);
`;

const BreadCrumbLink = styled(Link)`
  color: var(--color-light-grey);
  font-size: 15px;
  &:hover {
    color: var(--color-dark-grey);
  }
`;

interface CardDisplayProps {
  card: CustomCardSchema;
  onClick?: () => void;
}

function CardDisplay(props: CardDisplayProps) {
  return (
    <Wrapper
      to={paths.projects.details(
        props.card.step.project,
        props.card.step,
        props.card.id,
        false,
      )}
      onClick={props.onClick}
    >
      <Title>
        {props.card.status && (
          <ProjectCardStatus
            readOnly
            status={props.card.status}
            statusList={props.card.step.config.status}
          />
        )}
        {props.card.title}
      </Title>
      <Footer>
        <BreadCrumb>
          <BreadCrumbLink to={paths.projects.details(props.card.step.project)}>
            {props.card.step.project.name}
          </BreadCrumbLink>{' '}
          /{' '}
          <BreadCrumbLink
            to={paths.projects.details(
              props.card.step.project,
              props.card.step,
            )}
          >
            {props.card.step.name}
          </BreadCrumbLink>
        </BreadCrumb>
        <Infos>
          {!isEmptyContent(contentFromString(props.card.description || '')) && (
            <DescriptionIcon color="inherit" />
          )}
          {props.card.assignees.map((m) => (
            <Avatar profile={m} size={25} />
          ))}
          {props.card.due_date && (
            <span>{props.card.due_date.toLocaleString(DateTime.DATE_MED)}</span>
          )}
        </Infos>
      </Footer>
    </Wrapper>
  );
}

export default observer(CardDisplay);
