/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CardCreateSchema } from '../models';
// @ts-ignore
import { CardSchema } from '../models';
// @ts-ignore
import { CardUpdateSchema } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { LinkCreateSchema } from '../models';
// @ts-ignore
import { LinkSchema } from '../models';
// @ts-ignore
import { LinkUpdateSchema } from '../models';
// @ts-ignore
import { MembershipCreateSchema } from '../models';
// @ts-ignore
import { MembershipSchema } from '../models';
// @ts-ignore
import { MembershipUpdateSchema } from '../models';
// @ts-ignore
import { NoteCreateSchema } from '../models';
// @ts-ignore
import { NoteSchema } from '../models';
// @ts-ignore
import { PostitCreateSchema } from '../models';
// @ts-ignore
import { PostitSchema } from '../models';
// @ts-ignore
import { PostitUpdateSchema } from '../models';
// @ts-ignore
import { ProjectCreateSchema } from '../models';
// @ts-ignore
import { ProjectSchema } from '../models';
// @ts-ignore
import { ProjectUpdateSchema } from '../models';
// @ts-ignore
import { RecurrenceCreateSchema } from '../models';
// @ts-ignore
import { RecurrenceSchema } from '../models';
// @ts-ignore
import { RecurrenceUpdateSchema } from '../models';
// @ts-ignore
import { StepCreateSchema } from '../models';
// @ts-ignore
import { StepSchema } from '../models';
// @ts-ignore
import { StepUpdateSchema } from '../models';
/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign: async (cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling assign.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling assign.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling assign.');
            }
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling assign.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/assign`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile_id'] = profileId;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clone Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStep: async (stepId: number, projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling cloneStep.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling cloneStep.');
            }
            // verify required parameter 'stepCreateSchema' is not null or undefined
            if (stepCreateSchema === null || stepCreateSchema === undefined) {
                throw new RequiredError('stepCreateSchema','Required parameter stepCreateSchema was null or undefined when calling cloneStep.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/clone`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stepCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stepCreateSchema !== undefined ? stepCreateSchema : {}) : (stepCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Card
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardCreateSchema} cardCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard: async (stepId: number, projectId: number, cardCreateSchema: CardCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling createCard.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createCard.');
            }
            // verify required parameter 'cardCreateSchema' is not null or undefined
            if (cardCreateSchema === null || cardCreateSchema === undefined) {
                throw new RequiredError('cardCreateSchema','Required parameter cardCreateSchema was null or undefined when calling createCard.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof cardCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cardCreateSchema !== undefined ? cardCreateSchema : {}) : (cardCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Link
         * @param {number} projectId 
         * @param {LinkCreateSchema} linkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink: async (projectId: number, linkCreateSchema: LinkCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createLink.');
            }
            // verify required parameter 'linkCreateSchema' is not null or undefined
            if (linkCreateSchema === null || linkCreateSchema === undefined) {
                throw new RequiredError('linkCreateSchema','Required parameter linkCreateSchema was null or undefined when calling createLink.');
            }
            const localVarPath = `/projects/{project_id}/links`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof linkCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(linkCreateSchema !== undefined ? linkCreateSchema : {}) : (linkCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Membership
         * @param {number} projectId 
         * @param {MembershipCreateSchema} membershipCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMembership: async (projectId: number, membershipCreateSchema: MembershipCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createMembership.');
            }
            // verify required parameter 'membershipCreateSchema' is not null or undefined
            if (membershipCreateSchema === null || membershipCreateSchema === undefined) {
                throw new RequiredError('membershipCreateSchema','Required parameter membershipCreateSchema was null or undefined when calling createMembership.');
            }
            const localVarPath = `/projects/{project_id}/memberships`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof membershipCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(membershipCreateSchema !== undefined ? membershipCreateSchema : {}) : (membershipCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Note
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {NoteCreateSchema} noteCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (cardId: number, stepId: number, projectId: number, noteCreateSchema: NoteCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling createNote.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling createNote.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createNote.');
            }
            // verify required parameter 'noteCreateSchema' is not null or undefined
            if (noteCreateSchema === null || noteCreateSchema === undefined) {
                throw new RequiredError('noteCreateSchema','Required parameter noteCreateSchema was null or undefined when calling createNote.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/notes`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof noteCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(noteCreateSchema !== undefined ? noteCreateSchema : {}) : (noteCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Postit
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitCreateSchema} postitCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostit: async (cardId: number, stepId: number, projectId: number, postitCreateSchema: PostitCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling createPostit.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling createPostit.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createPostit.');
            }
            // verify required parameter 'postitCreateSchema' is not null or undefined
            if (postitCreateSchema === null || postitCreateSchema === undefined) {
                throw new RequiredError('postitCreateSchema','Required parameter postitCreateSchema was null or undefined when calling createPostit.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/postits`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof postitCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(postitCreateSchema !== undefined ? postitCreateSchema : {}) : (postitCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreateSchema} projectCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectCreateSchema: ProjectCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreateSchema' is not null or undefined
            if (projectCreateSchema === null || projectCreateSchema === undefined) {
                throw new RequiredError('projectCreateSchema','Required parameter projectCreateSchema was null or undefined when calling createProject.');
            }
            const localVarPath = `/projects`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof projectCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(projectCreateSchema !== undefined ? projectCreateSchema : {}) : (projectCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Recurrence
         * @param {RecurrenceCreateSchema} recurrenceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurrence: async (recurrenceCreateSchema: RecurrenceCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recurrenceCreateSchema' is not null or undefined
            if (recurrenceCreateSchema === null || recurrenceCreateSchema === undefined) {
                throw new RequiredError('recurrenceCreateSchema','Required parameter recurrenceCreateSchema was null or undefined when calling createRecurrence.');
            }
            const localVarPath = `/recurrences`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof recurrenceCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(recurrenceCreateSchema !== undefined ? recurrenceCreateSchema : {}) : (recurrenceCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Step
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep: async (projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling createStep.');
            }
            // verify required parameter 'stepCreateSchema' is not null or undefined
            if (stepCreateSchema === null || stepCreateSchema === undefined) {
                throw new RequiredError('stepCreateSchema','Required parameter stepCreateSchema was null or undefined when calling createStep.');
            }
            const localVarPath = `/projects/{project_id}/steps`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stepCreateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stepCreateSchema !== undefined ? stepCreateSchema : {}) : (stepCreateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard: async (cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling deleteCard.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling deleteCard.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteCard.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (linkId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            if (linkId === null || linkId === undefined) {
                throw new RequiredError('linkId','Required parameter linkId was null or undefined when calling deleteLink.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteLink.');
            }
            const localVarPath = `/projects/{project_id}/links/{link_id}`
                .replace(`{${"link_id"}}`, encodeURIComponent(String(linkId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership: async (membershipId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'membershipId' is not null or undefined
            if (membershipId === null || membershipId === undefined) {
                throw new RequiredError('membershipId','Required parameter membershipId was null or undefined when calling deleteMembership.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteMembership.');
            }
            const localVarPath = `/projects/{project_id}/memberships/{membership_id}`
                .replace(`{${"membership_id"}}`, encodeURIComponent(String(membershipId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Note
         * @param {number} noteId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (noteId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            if (noteId === null || noteId === undefined) {
                throw new RequiredError('noteId','Required parameter noteId was null or undefined when calling deleteNote.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling deleteNote.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling deleteNote.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteNote.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/notes/{note_id}`
                .replace(`{${"note_id"}}`, encodeURIComponent(String(noteId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostit: async (postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postitId' is not null or undefined
            if (postitId === null || postitId === undefined) {
                throw new RequiredError('postitId','Required parameter postitId was null or undefined when calling deletePostit.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling deletePostit.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling deletePostit.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deletePostit.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/postits/{postit_id}`
                .replace(`{${"postit_id"}}`, encodeURIComponent(String(postitId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteProject.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecurrence: async (recurrenceId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recurrenceId' is not null or undefined
            if (recurrenceId === null || recurrenceId === undefined) {
                throw new RequiredError('recurrenceId','Required parameter recurrenceId was null or undefined when calling deleteRecurrence.');
            }
            const localVarPath = `/recurrences/{recurrence_id}`
                .replace(`{${"recurrence_id"}}`, encodeURIComponent(String(recurrenceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep: async (stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling deleteStep.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling deleteStep.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard: async (cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling getCard.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling getCard.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getCard.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cards
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards: async (stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling getCards.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getCards.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Links
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks: async (projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getLinks.');
            }
            const localVarPath = `/projects/{project_id}/links`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notes
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling getNotes.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling getNotes.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getNotes.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/notes`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostit: async (postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postitId' is not null or undefined
            if (postitId === null || postitId === undefined) {
                throw new RequiredError('postitId','Required parameter postitId was null or undefined when calling getPostit.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling getPostit.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling getPostit.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getPostit.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/postits/{postit_id}`
                .replace(`{${"postit_id"}}`, encodeURIComponent(String(postitId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Postits
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostits: async (cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling getPostits.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling getPostits.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getPostits.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/postits`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getProject.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Projects
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurrence: async (recurrenceId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recurrenceId' is not null or undefined
            if (recurrenceId === null || recurrenceId === undefined) {
                throw new RequiredError('recurrenceId','Required parameter recurrenceId was null or undefined when calling getRecurrence.');
            }
            const localVarPath = `/recurrences/{recurrence_id}`
                .replace(`{${"recurrence_id"}}`, encodeURIComponent(String(recurrenceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Steps
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSteps: async (projectId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling getSteps.');
            }
            const localVarPath = `/projects/{project_id}/steps`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Blocked
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {boolean} blocked 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBlocked: async (cardId: number, stepId: number, projectId: number, blocked: boolean, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling setBlocked.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling setBlocked.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling setBlocked.');
            }
            // verify required parameter 'blocked' is not null or undefined
            if (blocked === null || blocked === undefined) {
                throw new RequiredError('blocked','Required parameter blocked was null or undefined when calling setBlocked.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/set_blocked`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (blocked !== undefined) {
                localVarQueryParameter['blocked'] = blocked;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Responsible
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {boolean} responsible 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResponsible: async (cardId: number, stepId: number, projectId: number, profileId: number, responsible: boolean, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling setResponsible.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling setResponsible.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling setResponsible.');
            }
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling setResponsible.');
            }
            // verify required parameter 'responsible' is not null or undefined
            if (responsible === null || responsible === undefined) {
                throw new RequiredError('responsible','Required parameter responsible was null or undefined when calling setResponsible.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/set_responsible`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile_id'] = profileId;
            }

            if (responsible !== undefined) {
                localVarQueryParameter['responsible'] = responsible;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unassign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassign: async (cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling unassign.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling unassign.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling unassign.');
            }
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling unassign.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/unassign`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (profileId !== undefined) {
                localVarQueryParameter['profile_id'] = profileId;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardUpdateSchema} cardUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard: async (cardId: number, stepId: number, projectId: number, cardUpdateSchema: CardUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling updateCard.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling updateCard.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateCard.');
            }
            // verify required parameter 'cardUpdateSchema' is not null or undefined
            if (cardUpdateSchema === null || cardUpdateSchema === undefined) {
                throw new RequiredError('cardUpdateSchema','Required parameter cardUpdateSchema was null or undefined when calling updateCard.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}`
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof cardUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cardUpdateSchema !== undefined ? cardUpdateSchema : {}) : (cardUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {LinkUpdateSchema} linkUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink: async (linkId: number, projectId: number, linkUpdateSchema: LinkUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            if (linkId === null || linkId === undefined) {
                throw new RequiredError('linkId','Required parameter linkId was null or undefined when calling updateLink.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateLink.');
            }
            // verify required parameter 'linkUpdateSchema' is not null or undefined
            if (linkUpdateSchema === null || linkUpdateSchema === undefined) {
                throw new RequiredError('linkUpdateSchema','Required parameter linkUpdateSchema was null or undefined when calling updateLink.');
            }
            const localVarPath = `/projects/{project_id}/links/{link_id}`
                .replace(`{${"link_id"}}`, encodeURIComponent(String(linkId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof linkUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(linkUpdateSchema !== undefined ? linkUpdateSchema : {}) : (linkUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {MembershipUpdateSchema} membershipUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMembership: async (membershipId: number, projectId: number, membershipUpdateSchema: MembershipUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'membershipId' is not null or undefined
            if (membershipId === null || membershipId === undefined) {
                throw new RequiredError('membershipId','Required parameter membershipId was null or undefined when calling updateMembership.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateMembership.');
            }
            // verify required parameter 'membershipUpdateSchema' is not null or undefined
            if (membershipUpdateSchema === null || membershipUpdateSchema === undefined) {
                throw new RequiredError('membershipUpdateSchema','Required parameter membershipUpdateSchema was null or undefined when calling updateMembership.');
            }
            const localVarPath = `/projects/{project_id}/memberships/{membership_id}`
                .replace(`{${"membership_id"}}`, encodeURIComponent(String(membershipId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof membershipUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(membershipUpdateSchema !== undefined ? membershipUpdateSchema : {}) : (membershipUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitUpdateSchema} postitUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostit: async (postitId: number, cardId: number, stepId: number, projectId: number, postitUpdateSchema: PostitUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postitId' is not null or undefined
            if (postitId === null || postitId === undefined) {
                throw new RequiredError('postitId','Required parameter postitId was null or undefined when calling updatePostit.');
            }
            // verify required parameter 'cardId' is not null or undefined
            if (cardId === null || cardId === undefined) {
                throw new RequiredError('cardId','Required parameter cardId was null or undefined when calling updatePostit.');
            }
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling updatePostit.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updatePostit.');
            }
            // verify required parameter 'postitUpdateSchema' is not null or undefined
            if (postitUpdateSchema === null || postitUpdateSchema === undefined) {
                throw new RequiredError('postitUpdateSchema','Required parameter postitUpdateSchema was null or undefined when calling updatePostit.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}/cards/{card_id}/postits/{postit_id}`
                .replace(`{${"postit_id"}}`, encodeURIComponent(String(postitId)))
                .replace(`{${"card_id"}}`, encodeURIComponent(String(cardId)))
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof postitUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(postitUpdateSchema !== undefined ? postitUpdateSchema : {}) : (postitUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Project
         * @param {number} projectId 
         * @param {ProjectUpdateSchema} projectUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: number, projectUpdateSchema: ProjectUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateProject.');
            }
            // verify required parameter 'projectUpdateSchema' is not null or undefined
            if (projectUpdateSchema === null || projectUpdateSchema === undefined) {
                throw new RequiredError('projectUpdateSchema','Required parameter projectUpdateSchema was null or undefined when calling updateProject.');
            }
            const localVarPath = `/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof projectUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(projectUpdateSchema !== undefined ? projectUpdateSchema : {}) : (projectUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Recurrence
         * @param {number} recurrenceId 
         * @param {RecurrenceUpdateSchema} recurrenceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurrence: async (recurrenceId: number, recurrenceUpdateSchema: RecurrenceUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recurrenceId' is not null or undefined
            if (recurrenceId === null || recurrenceId === undefined) {
                throw new RequiredError('recurrenceId','Required parameter recurrenceId was null or undefined when calling updateRecurrence.');
            }
            // verify required parameter 'recurrenceUpdateSchema' is not null or undefined
            if (recurrenceUpdateSchema === null || recurrenceUpdateSchema === undefined) {
                throw new RequiredError('recurrenceUpdateSchema','Required parameter recurrenceUpdateSchema was null or undefined when calling updateRecurrence.');
            }
            const localVarPath = `/recurrences/{recurrence_id}`
                .replace(`{${"recurrence_id"}}`, encodeURIComponent(String(recurrenceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof recurrenceUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(recurrenceUpdateSchema !== undefined ? recurrenceUpdateSchema : {}) : (recurrenceUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepUpdateSchema} stepUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStep: async (stepId: number, projectId: number, stepUpdateSchema: StepUpdateSchema, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            if (stepId === null || stepId === undefined) {
                throw new RequiredError('stepId','Required parameter stepId was null or undefined when calling updateStep.');
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError('projectId','Required parameter projectId was null or undefined when calling updateStep.');
            }
            // verify required parameter 'stepUpdateSchema' is not null or undefined
            if (stepUpdateSchema === null || stepUpdateSchema === undefined) {
                throw new RequiredError('stepUpdateSchema','Required parameter stepUpdateSchema was null or undefined when calling updateStep.');
            }
            const localVarPath = `/projects/{project_id}/steps/{step_id}`
                .replace(`{${"step_id"}}`, encodeURIComponent(String(stepId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof stepUpdateSchema !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(stepUpdateSchema !== undefined ? stepUpdateSchema : {}) : (stepUpdateSchema || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).assign(cardId, stepId, projectId, profileId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Clone Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloneStep(stepId: number, projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).cloneStep(stepId, projectId, stepCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Card
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardCreateSchema} cardCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCard(stepId: number, projectId: number, cardCreateSchema: CardCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createCard(stepId, projectId, cardCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Link
         * @param {number} projectId 
         * @param {LinkCreateSchema} linkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLink(projectId: number, linkCreateSchema: LinkCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createLink(projectId, linkCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Membership
         * @param {number} projectId 
         * @param {MembershipCreateSchema} membershipCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMembership(projectId: number, membershipCreateSchema: MembershipCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createMembership(projectId, membershipCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Note
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {NoteCreateSchema} noteCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(cardId: number, stepId: number, projectId: number, noteCreateSchema: NoteCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createNote(cardId, stepId, projectId, noteCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Postit
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitCreateSchema} postitCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPostit(cardId: number, stepId: number, projectId: number, postitCreateSchema: PostitCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostitSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createPostit(cardId, stepId, projectId, postitCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreateSchema} projectCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectCreateSchema: ProjectCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createProject(projectCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Recurrence
         * @param {RecurrenceCreateSchema} recurrenceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurrence(recurrenceCreateSchema: RecurrenceCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrenceSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createRecurrence(recurrenceCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create Step
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createStep(projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).createStep(projectId, stepCreateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteCard(cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(linkId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteLink(linkId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMembership(membershipId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteMembership(membershipId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Note
         * @param {number} noteId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(noteId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteNote(noteId, cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deletePostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteProject(projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteRecurrence(recurrenceId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStep(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).deleteStep(stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getCard(cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Cards
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCards(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CardSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getCards(stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Links
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinks(projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LinkSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getLinks(projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Notes
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getNotes(cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostitSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getPostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Postits
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostits(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostitSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getPostits(cardId, stepId, projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getProject(projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Projects
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getProjects(lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrenceSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getRecurrence(recurrenceId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Steps
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSteps(projectId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StepSchema>>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).getSteps(projectId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set Blocked
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {boolean} blocked 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setBlocked(cardId: number, stepId: number, projectId: number, blocked: boolean, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).setBlocked(cardId, stepId, projectId, blocked, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set Responsible
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {boolean} responsible 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setResponsible(cardId: number, stepId: number, projectId: number, profileId: number, responsible: boolean, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).setResponsible(cardId, stepId, projectId, profileId, responsible, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unassign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).unassign(cardId, stepId, projectId, profileId, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardUpdateSchema} cardUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCard(cardId: number, stepId: number, projectId: number, cardUpdateSchema: CardUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateCard(cardId, stepId, projectId, cardUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {LinkUpdateSchema} linkUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLink(linkId: number, projectId: number, linkUpdateSchema: LinkUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateLink(linkId, projectId, linkUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {MembershipUpdateSchema} membershipUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMembership(membershipId: number, projectId: number, membershipUpdateSchema: MembershipUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateMembership(membershipId, projectId, membershipUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitUpdateSchema} postitUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePostit(postitId: number, cardId: number, stepId: number, projectId: number, postitUpdateSchema: PostitUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostitSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updatePostit(postitId, cardId, stepId, projectId, postitUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Project
         * @param {number} projectId 
         * @param {ProjectUpdateSchema} projectUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: number, projectUpdateSchema: ProjectUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateProject(projectId, projectUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Recurrence
         * @param {number} recurrenceId 
         * @param {RecurrenceUpdateSchema} recurrenceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurrence(recurrenceId: number, recurrenceUpdateSchema: RecurrenceUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecurrenceSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateRecurrence(recurrenceId, recurrenceUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepUpdateSchema} stepUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStep(stepId: number, projectId: number, stepUpdateSchema: StepUpdateSchema, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StepSchema>> {
            const localVarAxiosArgs = await ProjectsApiAxiosParamCreator(configuration).updateStep(stepId, projectId, stepUpdateSchema, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Assign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).assign(cardId, stepId, projectId, profileId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clone Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloneStep(stepId: number, projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<StepSchema> {
            return ProjectsApiFp(configuration).cloneStep(stepId, projectId, stepCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Card
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardCreateSchema} cardCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCard(stepId: number, projectId: number, cardCreateSchema: CardCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<CardSchema> {
            return ProjectsApiFp(configuration).createCard(stepId, projectId, cardCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Link
         * @param {number} projectId 
         * @param {LinkCreateSchema} linkCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(projectId: number, linkCreateSchema: LinkCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<LinkSchema> {
            return ProjectsApiFp(configuration).createLink(projectId, linkCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Membership
         * @param {number} projectId 
         * @param {MembershipCreateSchema} membershipCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMembership(projectId: number, membershipCreateSchema: MembershipCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<MembershipSchema> {
            return ProjectsApiFp(configuration).createMembership(projectId, membershipCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Note
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {NoteCreateSchema} noteCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(cardId: number, stepId: number, projectId: number, noteCreateSchema: NoteCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<NoteSchema> {
            return ProjectsApiFp(configuration).createNote(cardId, stepId, projectId, noteCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Postit
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitCreateSchema} postitCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPostit(cardId: number, stepId: number, projectId: number, postitCreateSchema: PostitCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<PostitSchema> {
            return ProjectsApiFp(configuration).createPostit(cardId, stepId, projectId, postitCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Project
         * @param {ProjectCreateSchema} projectCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectCreateSchema: ProjectCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProjectSchema> {
            return ProjectsApiFp(configuration).createProject(projectCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Recurrence
         * @param {RecurrenceCreateSchema} recurrenceCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurrence(recurrenceCreateSchema: RecurrenceCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<RecurrenceSchema> {
            return ProjectsApiFp(configuration).createRecurrence(recurrenceCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Step
         * @param {number} projectId 
         * @param {StepCreateSchema} stepCreateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStep(projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<StepSchema> {
            return ProjectsApiFp(configuration).createStep(projectId, stepCreateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteCard(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(linkId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteLink(linkId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMembership(membershipId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<any> {
            return ProjectsApiFp(configuration).deleteMembership(membershipId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Note
         * @param {number} noteId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(noteId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteNote(noteId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deletePostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteProject(projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteRecurrence(recurrenceId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).deleteStep(stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<CardSchema> {
            return ProjectsApiFp(configuration).getCard(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cards
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCards(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<CardSchema>> {
            return ProjectsApiFp(configuration).getCards(stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Links
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks(projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<LinkSchema>> {
            return ProjectsApiFp(configuration).getLinks(projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notes
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<NoteSchema>> {
            return ProjectsApiFp(configuration).getNotes(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<PostitSchema> {
            return ProjectsApiFp(configuration).getPostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Postits
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostits(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<PostitSchema>> {
            return ProjectsApiFp(configuration).getPostits(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProjectSchema> {
            return ProjectsApiFp(configuration).getProject(projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Projects
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<ProjectSchema>> {
            return ProjectsApiFp(configuration).getProjects(lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Recurrence
         * @param {number} recurrenceId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<RecurrenceSchema> {
            return ProjectsApiFp(configuration).getRecurrence(recurrenceId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Steps
         * @param {number} projectId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSteps(projectId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<Array<StepSchema>> {
            return ProjectsApiFp(configuration).getSteps(projectId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Blocked
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {boolean} blocked 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setBlocked(cardId: number, stepId: number, projectId: number, blocked: boolean, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).setBlocked(cardId, stepId, projectId, blocked, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Responsible
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {boolean} responsible 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setResponsible(cardId: number, stepId: number, projectId: number, profileId: number, responsible: boolean, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).setResponsible(cardId, stepId, projectId, profileId, responsible, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unassign
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {number} profileId 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<void> {
            return ProjectsApiFp(configuration).unassign(cardId, stepId, projectId, profileId, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Card
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {CardUpdateSchema} cardUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCard(cardId: number, stepId: number, projectId: number, cardUpdateSchema: CardUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<CardSchema> {
            return ProjectsApiFp(configuration).updateCard(cardId, stepId, projectId, cardUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Link
         * @param {number} linkId 
         * @param {number} projectId 
         * @param {LinkUpdateSchema} linkUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink(linkId: number, projectId: number, linkUpdateSchema: LinkUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<LinkSchema> {
            return ProjectsApiFp(configuration).updateLink(linkId, projectId, linkUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Membership
         * @param {number} membershipId 
         * @param {number} projectId 
         * @param {MembershipUpdateSchema} membershipUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMembership(membershipId: number, projectId: number, membershipUpdateSchema: MembershipUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<MembershipSchema> {
            return ProjectsApiFp(configuration).updateMembership(membershipId, projectId, membershipUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Postit
         * @param {number} postitId 
         * @param {number} cardId 
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {PostitUpdateSchema} postitUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostit(postitId: number, cardId: number, stepId: number, projectId: number, postitUpdateSchema: PostitUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<PostitSchema> {
            return ProjectsApiFp(configuration).updatePostit(postitId, cardId, stepId, projectId, postitUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Project
         * @param {number} projectId 
         * @param {ProjectUpdateSchema} projectUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: number, projectUpdateSchema: ProjectUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<ProjectSchema> {
            return ProjectsApiFp(configuration).updateProject(projectId, projectUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Recurrence
         * @param {number} recurrenceId 
         * @param {RecurrenceUpdateSchema} recurrenceUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurrence(recurrenceId: number, recurrenceUpdateSchema: RecurrenceUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<RecurrenceSchema> {
            return ProjectsApiFp(configuration).updateRecurrence(recurrenceId, recurrenceUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Step
         * @param {number} stepId 
         * @param {number} projectId 
         * @param {StepUpdateSchema} stepUpdateSchema 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStep(stepId: number, projectId: number, stepUpdateSchema: StepUpdateSchema, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<StepSchema> {
            return ProjectsApiFp(configuration).updateStep(stepId, projectId, stepUpdateSchema, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Assign
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {number} profileId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public assign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).assign(cardId, stepId, projectId, profileId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clone Step
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {StepCreateSchema} stepCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public cloneStep(stepId: number, projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).cloneStep(stepId, projectId, stepCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Card
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {CardCreateSchema} cardCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createCard(stepId: number, projectId: number, cardCreateSchema: CardCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createCard(stepId, projectId, cardCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Link
     * @param {number} projectId 
     * @param {LinkCreateSchema} linkCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createLink(projectId: number, linkCreateSchema: LinkCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createLink(projectId, linkCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Membership
     * @param {number} projectId 
     * @param {MembershipCreateSchema} membershipCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createMembership(projectId: number, membershipCreateSchema: MembershipCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createMembership(projectId, membershipCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Note
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {NoteCreateSchema} noteCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createNote(cardId: number, stepId: number, projectId: number, noteCreateSchema: NoteCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createNote(cardId, stepId, projectId, noteCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Postit
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {PostitCreateSchema} postitCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createPostit(cardId: number, stepId: number, projectId: number, postitCreateSchema: PostitCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createPostit(cardId, stepId, projectId, postitCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Project
     * @param {ProjectCreateSchema} projectCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(projectCreateSchema: ProjectCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createProject(projectCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Recurrence
     * @param {RecurrenceCreateSchema} recurrenceCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createRecurrence(recurrenceCreateSchema: RecurrenceCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createRecurrence(recurrenceCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Step
     * @param {number} projectId 
     * @param {StepCreateSchema} stepCreateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createStep(projectId: number, stepCreateSchema: StepCreateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).createStep(projectId, stepCreateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Card
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteCard(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Link
     * @param {number} linkId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteLink(linkId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteLink(linkId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Membership
     * @param {number} membershipId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteMembership(membershipId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteMembership(membershipId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Note
     * @param {number} noteId 
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteNote(noteId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteNote(noteId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Postit
     * @param {number} postitId 
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deletePostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deletePostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Project
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteProject(projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Recurrence
     * @param {number} recurrenceId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteRecurrence(recurrenceId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Step
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteStep(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).deleteStep(stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Card
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getCard(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getCard(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cards
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getCards(stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getCards(stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Links
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getLinks(projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getLinks(projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notes
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getNotes(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getNotes(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Postit
     * @param {number} postitId 
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getPostit(postitId: number, cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getPostit(postitId, cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Postits
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getPostits(cardId: number, stepId: number, projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getPostits(cardId, stepId, projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getProject(projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Projects
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getProjects(lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Recurrence
     * @param {number} recurrenceId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getRecurrence(recurrenceId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getRecurrence(recurrenceId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Steps
     * @param {number} projectId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getSteps(projectId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).getSteps(projectId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Blocked
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {boolean} blocked 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setBlocked(cardId: number, stepId: number, projectId: number, blocked: boolean, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).setBlocked(cardId, stepId, projectId, blocked, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Responsible
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {number} profileId 
     * @param {boolean} responsible 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public setResponsible(cardId: number, stepId: number, projectId: number, profileId: number, responsible: boolean, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).setResponsible(cardId, stepId, projectId, profileId, responsible, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unassign
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {number} profileId 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public unassign(cardId: number, stepId: number, projectId: number, profileId: number, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).unassign(cardId, stepId, projectId, profileId, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Card
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {CardUpdateSchema} cardUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateCard(cardId: number, stepId: number, projectId: number, cardUpdateSchema: CardUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateCard(cardId, stepId, projectId, cardUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Link
     * @param {number} linkId 
     * @param {number} projectId 
     * @param {LinkUpdateSchema} linkUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateLink(linkId: number, projectId: number, linkUpdateSchema: LinkUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateLink(linkId, projectId, linkUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Membership
     * @param {number} membershipId 
     * @param {number} projectId 
     * @param {MembershipUpdateSchema} membershipUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateMembership(membershipId: number, projectId: number, membershipUpdateSchema: MembershipUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateMembership(membershipId, projectId, membershipUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Postit
     * @param {number} postitId 
     * @param {number} cardId 
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {PostitUpdateSchema} postitUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updatePostit(postitId: number, cardId: number, stepId: number, projectId: number, postitUpdateSchema: PostitUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updatePostit(postitId, cardId, stepId, projectId, postitUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Project
     * @param {number} projectId 
     * @param {ProjectUpdateSchema} projectUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(projectId: number, projectUpdateSchema: ProjectUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateProject(projectId, projectUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Recurrence
     * @param {number} recurrenceId 
     * @param {RecurrenceUpdateSchema} recurrenceUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateRecurrence(recurrenceId: number, recurrenceUpdateSchema: RecurrenceUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateRecurrence(recurrenceId, recurrenceUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Step
     * @param {number} stepId 
     * @param {number} projectId 
     * @param {StepUpdateSchema} stepUpdateSchema 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateStep(stepId: number, projectId: number, stepUpdateSchema: StepUpdateSchema, lang?: string, xWorkspace?: string, options?: any) {
        return ProjectsApiFp(this.configuration).updateStep(stepId, projectId, stepUpdateSchema, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }
}
