import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function PdfIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g transform="translate(-2751 -1762)">
        <g
          transform="translate(2752 1768)"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
        >
          <rect width="28" height="20" rx="4" stroke="none" />
          <rect x="1" y="1" width="26" height="18" rx="3" fill="none" />
        </g>
        <path
          d="M1.223-1.793A.666.666,0,0,0,1.9-2.457v-2H3.042A2.518,2.518,0,0,0,5.41-5.831a3.188,3.188,0,0,0,.32-1.3A2.474,2.474,0,0,0,4.346-9.48a3.224,3.224,0,0,0-1.3-.32H1.223a.664.664,0,0,0-.663.663v6.68A.664.664,0,0,0,1.223-1.793ZM1.9-8.462H3.042a1.779,1.779,0,0,1,.709.172,1.154,1.154,0,0,1,.641,1.155A1.791,1.791,0,0,1,4.2-6.4,1.167,1.167,0,0,1,3.042-5.8H1.9ZM9.253-1.793c.046,0,3.4.011,3.4-4s-3.351-4-3.4-4H7.308a.664.664,0,0,0-.663.663v6.68a.664.664,0,0,0,.663.663ZM7.983-8.462h1.27a2.218,2.218,0,0,1,1.018.32A2.521,2.521,0,0,1,11.312-5.8a2.557,2.557,0,0,1-1,2.322,2.169,2.169,0,0,1-1.064.343H7.983ZM14.228-9.8a.664.664,0,0,0-.663.663v6.68a.664.664,0,0,0,.663.663.674.674,0,0,0,.675-.663V-5.133h2.665a.664.664,0,0,0,.663-.663.664.664,0,0,0-.663-.663H14.9v-2h3.328a.676.676,0,0,0,.675-.675.674.674,0,0,0-.675-.663Z"
          transform="translate(2756.555 1783.397)"
          fill="currentColor"
        />
        <g transform="translate(-1 88)">
          <rect
            width="30"
            height="30"
            transform="translate(2752 1674)"
            fill="none"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default PdfIcon;
