import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme';
import WithConfirm from './WithConfirm';

const Wrapper = styled.div<{
  iconColor?: string;
  bgColor?: string;
  disabled?: boolean;
}>`
  background-color: ${(p) => p.bgColor || 'var(--color-white)'};

  border-radius: 50%;
  box-shadow: var(--shadow-sm);
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: ${(p) => p.iconColor || 'var(--color-primary)'}!important;
    font-size: 18px;
  }

  &:hover {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.23);
  }

  ${(p) =>
    p.disabled &&
    `
    box-shadow: var(--shadow-sm)!important;
    cursor: default;
  `}
`;

const SmallWrapper = styled(Wrapper)`
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;

  svg {
    font-size: 14px;
  }
`;

const BigWrapper = styled(Wrapper)`
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;

  svg {
    font-size: 20px;
  }
`;

interface ColoredIconButtonProps extends React.HTMLProps<HTMLDivElement> {
  small?: boolean;
  big?: boolean;
  iconColor?: string;
  bgColor?: string;
  disabled?: boolean;
  withConfirm?: boolean;
}

const ColoredIconButton = React.forwardRef<
  HTMLDivElement,
  ColoredIconButtonProps
>((props, ref) => {
  const iconColor = props.disabled ? COLORS.lightGrey : props.iconColor;
  const bgColor = props.disabled ? COLORS.white : props.bgColor;
  const onClick = props.disabled ? undefined : props.onClick;
  let element: React.ReactElement | null = null;

  if (props.small) {
    element = (
      <SmallWrapper
        {...(props as any)}
        iconColor={iconColor}
        bgColor={bgColor}
        onClick={props.withConfirm ? undefined : onClick}
        ref={ref}
        className={props.className}
      />
    );
  } else if (props.big) {
    element = (
      <BigWrapper
        {...(props as any)}
        iconColor={iconColor}
        bgColor={bgColor}
        onClick={props.withConfirm ? undefined : onClick}
        ref={ref}
        className={props.className}
      />
    );
  } else {
    element = (
      <Wrapper
        {...(props as any)}
        iconColor={iconColor}
        bgColor={bgColor}
        onClick={props.withConfirm ? undefined : onClick}
        ref={ref}
        className={props.className}
      />
    );
  }

  if (props.withConfirm && props.onClick) {
    return (
      <WithConfirm onConfirm={props.onClick as any}>{element}</WithConfirm>
    );
  }

  return element;
});

export default ColoredIconButton;
