import { Avatar as MuiAvatar } from '@material-ui/core';
import { AVATAR_COLORS } from 'config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from 'theme';
import Tooltip from './elements/Tooltip';

const Wrapper = styled(MuiAvatar)<{ admin?: boolean }>`
  box-shadow: var(--shadow-sm);
  font-size: 16px;

  ${(p) =>
    p.admin &&
    `
    border: 2px solid var(--color-primary);
  `}
`;

const ImageWrapper = styled.div<{ admin?: boolean }>`
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: var(--shadow-sm);
  background-color: var(--color-white);

  ${(p) =>
    p.admin &&
    `
    border: 2px solid var(--color-primary);
  `}
`;

const Circle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  height: 10px;
  width: 10px;
  background-color: var(--color-primary);
  border: 1px solid white;
  border-radius: 50%;
`;

const InnerWrapper = styled.div`
  position: relative;
`;

interface AvatarProps {
  profile: { id: number; avatar?: string; name: string; deleted?: boolean };
  size?: number;
  admin?: boolean;
  withCircle?: boolean;
  suffix?: string;
  disableTooltip?: boolean;
}

function Avatar(props: AvatarProps) {
  const size = props.size || 30;
  const parts = props.profile.name.split(' ');
  const color = AVATAR_COLORS[props.profile.id % AVATAR_COLORS.length];
  const { t } = useTranslation();

  if (props.profile.deleted) {
    return (
      <Tooltip title={!props.disableTooltip ? t('commons.deleted_user') : ''}>
        <Wrapper
          style={{
            height: `${size}px`,
            width: `${size}px`,
            minHeight: `${size}px`,
            minWidth: `${size}px`,
            backgroundColor: COLORS.lightGrey,
          }}
        >
          U
        </Wrapper>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        !props.disableTooltip
          ? `${props.profile.name}${
              props.admin ? ` (${t('project.members.owner')})` : ''
            }${props.suffix || ''}`
          : ''
      }
    >
     <InnerWrapper>
        {props.withCircle && <Circle />}
        {props.profile.avatar ? (
          <ImageWrapper
            admin={props.admin}
            style={{
              height: `${size}px`,
              width: `${size}px`,
              minHeight: `${size}px`,
              minWidth: `${size}px`,
              backgroundImage: `url("${props.profile.avatar}")`,
            }}
          />
        ) : (
          <Wrapper
            admin={props.admin}
            style={{
              height: `${size}px`,
              width: `${size}px`,
              minHeight: `${size}px`,
              minWidth: `${size}px`,
              backgroundColor: color,
            }}
          >
            {parts.length > 0 && parts[0].length > 0 && parts[0][0].toUpperCase()}
            {parts.length > 1 && parts[1].length > 0 && parts[1][0].toUpperCase()}
          </Wrapper>
        )}
     </InnerWrapper>
    </Tooltip>
  );
}

export default Avatar;
