import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { TableConfig } from './types';

const Wrapper = styled(TableRow)``;

interface ItemRowProps {
  item: any;
  config: TableConfig;
  index: number;
}

function ItemRow(props: ItemRowProps) {
  return (
    <Wrapper>
      {props.config.values.map((value) => (
        <TableCell>{JSON.stringify(props.item[value.key])}</TableCell>
      ))}
    </Wrapper>
  );
}

export default ItemRow;
