import React from 'react';
import styled from 'styled-components';
import DownIcon from '../icons/DownIcon';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  OutlinedInput,
  Select,
  SelectProps,
} from '@material-ui/core';

const Wrapper = styled(FormControl)`
  .MuiOutlinedInput-root,
  .MuiSelect-root {
    border-radius: 60px;
  }

  .MuiInputLabel-root {
    transform: translate(20px, -6px) scale(0.75);
  }

  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;

    legend {
      margin-left: 6px;
    }
  }

  && .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) =>
      p.error ? 'var(--color-error)' : 'var(--color-primary)'};
  }

  .MuiOutlinedInput-input {
    padding: 15px 20px;
    padding-right: 40px;
  }

  svg {
    color: var(--color-light-grey);
  }
`;

const IconComponent = styled(DownIcon)`
  && {
    margin-right: 12px;
    color: var(--color-primary);
  }
`;

interface RoundedSelectProps extends Partial<SelectProps> {
  wrapperProps?: FormControlProps;
}

function RoundedSelect({
  style,
  wrapperProps,
  fullWidth,
  ...props
}: RoundedSelectProps) {
  return (
    <Wrapper style={style} {...wrapperProps} fullWidth={fullWidth}>
      <InputLabel focused shrink>
        {props.label}
      </InputLabel>
      <Select
        IconComponent={IconComponent}
        {...(props as any)}
        fullWidth={fullWidth}
        input={<OutlinedInput notched label={props.label} />}
      />
    </Wrapper>
  );
}

export default RoundedSelect;
