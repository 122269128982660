import api from 'api';
import Axios, { AxiosRequestConfig } from 'axios';
import { store } from 'stores';

async function uploadFile(
  file: File,
  config?: AxiosRequestConfig,
): Promise<string> {
  const { data } = await api.files.getUploadUrl(file.name, file.size);
  if (data) {
    const { upload_url, result_url } = data;

    if (upload_url.startsWith('http://')) {
      const formData = new FormData();
      formData.append('file', file);
      const resp = await Axios.put(upload_url, formData, {
        headers: {
          'X-Workspace': store.workspaceStore.currentWorkspaceID,
          'Content-Type': file.type,
        },
        ...config,
      });
      return resp.data;
    } else {
      // S3 direct upload
      await Axios.put(upload_url, file, {
        headers: {
          'Content-Type': file.type,
        },
        ...config,
      });
      return result_url || '';
    }
  }
  return '';
}

export default uploadFile;
