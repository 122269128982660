import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function PreferencesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Preferences-a,.Preferences-b{fill:none;}.Preferences-b{stroke:currentColor;stroke-linecap:round;stroke-width:2px;}.Preferences-c{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-3185 -1429)">
        <g transform="translate(45)">
          <g transform="translate(38)">
            <g transform="translate(38)">
              <g transform="translate(123)">
                <rect
                  className="Preferences-a"
                  width="30"
                  height="30"
                  transform="translate(2941 1429)"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(2618.5 1027.916)">
          <g transform="translate(571.5 408.084)">
            <line
              className="Preferences-b"
              x2="19.114"
              transform="translate(0 2.124)"
            />
            <circle
              className="Preferences-c"
              cx="2.185"
              cy="2.185"
              r="2.185"
              transform="translate(2.185)"
            />
            <line
              className="Preferences-b"
              x2="19.114"
              transform="translate(0 8.268)"
            />
            <circle
              className="Preferences-c"
              cx="2.185"
              cy="2.185"
              r="2.185"
              transform="translate(11.801 6.119)"
            />
            <line
              className="Preferences-b"
              x2="19.114"
              transform="translate(0 14.412)"
            />
            <circle
              className="Preferences-c"
              cx="2.185"
              cy="2.185"
              r="2.185"
              transform="translate(7.431 12.239)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default PreferencesIcon;
