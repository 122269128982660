import { Typography } from '@material-ui/core';
import Avatar from 'components/Avatar';
import MiniProjectDisplay from 'components/projects/MiniProjectDisplay';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Resource } from 'models/resources';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme';
import ResourceActions from './ResourceActions';
import ResourceTypeIcon from './ResourceTypeIcon';

const Wrapper = styled.div`
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--shadow-xs);
  padding: 10px;
`;

const Title = styled(Typography)`
  color: var(--color-secondary);

  svg {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 20px;
    margin-top: -2px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 7px;
`;

const Members = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

const Projects = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 5px;
  }
`;

interface ResourceDisplayProps {
  resource: Resource;
  onClick?: () => void;
}

function ResourceDisplay(props: ResourceDisplayProps) {
  return (
    <Wrapper>
      <Title>
        <ResourceTypeIcon type={props.resource.type} />
        {props.resource.name}
      </Title>
      <Footer>
        <Projects onClick={props.onClick}>
          {props.resource.projects.map((p) => (
            <MiniProjectDisplay project={p} />
          ))}
        </Projects>
        <Members>
          <ResourceActions resource={props.resource} />
          {props.resource.authors.map((m) => (
            <Avatar profile={m} size={25} />
          ))}
          <span style={{ marginLeft: '5px', color: COLORS.lightGrey }}>
            {props.resource.date_created.toLocaleString(DateTime.DATE_MED)}
          </span>
        </Members>
      </Footer>
    </Wrapper>
  );
}

export default observer(ResourceDisplay);
