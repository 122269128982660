import { Box, CircularProgress } from '@material-ui/core';
import Tooltip from 'components/elements/Tooltip';
import CheckContainedIcon from 'components/icons/CheckContainedIcon';
import CheckIcon from 'components/icons/CheckIcon';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme';

const Indicator = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  border: 2px solid var(--color-light-grey);

  svg {
    transition: color 0.1s;
  }
`;

const Wrapper = styled(Box)`
  position: relative;
  width: 20px;
  height: 20px;
`;

interface ProgressDisplayProps {
  value: number;
  max: number;
  disabled?: boolean;
}

function ProgressDisplay(props: ProgressDisplayProps) {
  const isFull = props.value === props.max && props.max !== 0;
  const value = props.max === 0 ? 0 : (props.value / (props.max || 1)) * 100;
  return (
    <Tooltip
      title={
        props.max !== 0
          ? `${props.value}/${props.max} (${value.toFixed(0)}%)`
          : ''
      }
    >
      <Wrapper position="relative" display="inline-flex">
        {!isFull && <Indicator />}
        {!props.disabled && (
          <>
            {isFull ? (
              <CheckContainedIcon
                style={{
                  color: COLORS.success,
                  fontSize: '25px',
                  marginTop: '-2px',
                  marginLeft: '-2px',
                }}
              />
            ) : (
              <>
                <CircularProgress size={20} value={value} variant="static" />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CheckIcon
                    style={{
                      fontSize: '14px',
                      color:
                        props.value === 0 ? COLORS.lightGrey : COLORS.primary,
                    }}
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Wrapper>
    </Tooltip>
  );
}

export default ProgressDisplay;
