import { To } from 'history';
import { workspacePath } from 'paths';
import { NavigateFunction } from 'react-router';
import { useNavigate as _useNavigate } from 'react-router-dom';
import { useStore } from 'stores';

// Custom `useNavigate` hook that handle workspace relative paths
export default function useNavigate() {
  const { workspaceStore } = useStore();
  const _navigate = _useNavigate();

  function navigate(to: To | number, options: any): void {
    if (typeof to === 'number') return _navigate(to);
    return _navigate(
      workspacePath(to, workspaceStore.currentWorkspace),
      options,
    );
  }

  return navigate as NavigateFunction;
}
