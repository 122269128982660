import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g
        id="Groupe_2484"
        data-name="Groupe 2484"
        transform="translate(-3102 -1429)"
      >
        <g
          id="Groupe_2482"
          data-name="Groupe 2482"
          transform="translate(2888.567 954.929)"
        >
          <g
            id="Rectangle_883"
            data-name="Rectangle 883"
            transform="translate(223.433 479.071)"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
          >
            <path
              d="M4,0H7a4,4,0,0,1,4,4v7a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
              stroke="none"
            />
            <path
              d="M4,1.5H7A2.5,2.5,0,0,1,9.5,4V8A1.5,1.5,0,0,1,8,9.5H3A1.5,1.5,0,0,1,1.5,8V4A2.5,2.5,0,0,1,4,1.5Z"
              fill="none"
            />
          </g>
          <g
            id="Rectangle_882"
            data-name="Rectangle 882"
            transform="translate(220.433 487.071)"
            fill="none"
            stroke="currentColor"
            stroke-width="3"
          >
            <rect width="17" height="14" rx="2" stroke="none" />
            <rect x="1.5" y="1.5" width="14" height="11" rx="0.5" fill="none" />
          </g>
          <g
            id="Ellipse_181"
            data-name="Ellipse 181"
            transform="translate(227.433 492.071)"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
          >
            <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
            <circle cx="1.5" cy="1.5" r="0.5" fill="none" />
          </g>
        </g>
        <g id="Groupe_2483" data-name="Groupe 2483" transform="translate(38)">
          <g
            id="Groupe_2466"
            data-name="Groupe 2466"
            transform="translate(123)"
          >
            <rect
              id="Rectangle_1101"
              data-name="Rectangle 1101"
              width="30"
              height="30"
              transform="translate(2941 1429)"
              fill="none"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default LockIcon;
