import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Search-a{fill:currentColor;}.Search-b{fill:none;}`}</style>
      </defs>
      <g transform="translate(-2941 -1429)">
        <path
          className="Search-a"
          d="M21.9,20.039l-5.236-5.446a8.879,8.879,0,1,0-6.8,3.172,8.787,8.787,0,0,0,5.089-1.607l5.276,5.487a1.158,1.158,0,1,0,1.67-1.606ZM9.866,2.317A6.565,6.565,0,1,1,3.3,8.882,6.573,6.573,0,0,1,9.866,2.317Z"
          transform="translate(2944.016 1433)"
        />
        <rect
          className="Search-b"
          width="30"
          height="30"
          transform="translate(2941 1429)"
        />
      </g>
    </SvgIcon>
  );
}

export default SearchIcon;
