import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  color: var(--color-white);

  height: var(--sidebar-width);
  width: var(--sidebar-width);

  cursor: pointer;
  text-align: center;

  svg {
    font-size: 30px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Title = styled.div`
  font-size: 11px;
  margin-top: 5px;
`;

interface ClickableSidebarItemProps extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  end?: boolean;
}

const ClickableSidebarItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ClickableSidebarItemProps>
>(({ children, title, ...props }, ref) => {
  return (
    <Wrapper {...(props as any)} ref={ref}>
      {children}
      <Title>{title}</Title>
    </Wrapper>
  );
});

export default ClickableSidebarItem;
