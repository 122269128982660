import { store } from 'stores';

export const RECONNECT_TYPE = 'internal/reconnect';

const PING_PONG_INTERVAL = 5000;
const PONG_MAX_DELAY = 5000;

type Message = { type: string; data?: any };

type Listener = (message: Message) => void;

class ReconnectingWebsocket {
  private socket: WebSocket;
  private listeners: Map<string, Listener[]> = new Map();
  private open: boolean = false;

  private pongCheckerTimeout: number | null = null;
  private pingInterval: number | null = null;
  private closeTimeout: number | null = null;
  private queuedMessages: Message[] = [];

  constructor(private url: string) {}

  public send(message: Message) {
    if (this.open) {
      this.socket.send(JSON.stringify(message));
    } else {
      this.queuedMessages.push(message);
    }
  }

  private checkPong = () => {
    this.pongCheckerTimeout = window.setTimeout(this.reconnect, PONG_MAX_DELAY);
  };

  private reconnect = () => {
    this.close();
    this.socket = new WebSocket(this.url);
    this.socket.onopen = () => {
      this.open = true;

      this.queuedMessages.forEach((message) => {
        this.send(message);
      });
      this.queuedMessages = [];

      if (store.workspaceStore.currentWorkspace) {
        this.send({
          type: 'init',
          data: {
            profile_id: store.workspaceStore.currentProfile.id,
            workspace_id: store.workspaceStore.currentWorkspaceID,
          },
        });
      }

      const reconnectHandlers = this.listeners.get(RECONNECT_TYPE);
      reconnectHandlers?.forEach((listener) =>
        listener({ type: RECONNECT_TYPE }),
      );

      this.ping();
      this.pingInterval = window.setInterval(this.ping, PING_PONG_INTERVAL);
    };
    this.socket.onclose = () => {
      this.closeTimeout = window.setTimeout(this.reconnect, PONG_MAX_DELAY);
    };
    this.socket.onmessage = this.handleMessage;
  };

  private ping = () => {
    this.send({ type: 'ping' });
    this.checkPong();
  };

  private close = () => {
    if (this.socket) {
      this.socket.close();
    }
    this.open = false;

    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }

    if (this.closeTimeout) {
      clearInterval(this.closeTimeout);
    }

    if (this.pongCheckerTimeout) {
      clearTimeout(this.pongCheckerTimeout);
    }
  };

  public init() {
    this.reconnect();
  }

  private handleMessage = (message: MessageEvent) => {
    const data = JSON.parse(message.data) as Message;

    if (data.type === 'pong' && this.pongCheckerTimeout) {
      clearTimeout(this.pongCheckerTimeout);
    }

    const listeners = this.listeners.get(data.type);
    listeners?.forEach((listener) => listener(data));
  };

  public on(type: string, listener: Listener) {
    let listeners = this.listeners.get(type);

    if (!listeners) {
      listeners = [];
      this.listeners.set(type, listeners);
    }

    listeners.push(listener);

    return () => {
      const listeners = this.listeners.get(type);
      if (!listeners) return;
      this.listeners.set(
        type,
        listeners.filter((l) => l !== listener),
      );
    };
  }
}

export default ReconnectingWebsocket;
