import React, { useState } from 'react';
import styled from 'styled-components';
import stopPropagation from 'utils/stopPropagation';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export interface OpenerProvidedProps {
  close: () => void;
  anchorEl: HTMLElement | null;
}

interface OpenerProps {
  disabled?: boolean;
  content: (props: OpenerProvidedProps) => React.ReactElement;
  style?: React.CSSProperties;
  onClick?: () => void;
}

function Opener(props: React.PropsWithChildren<OpenerProps>) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  return (
    <>
      {anchorElement &&
        props.content({
          anchorEl: anchorElement,
          close: () => setAnchorElement(null),
        })}
      <Wrapper
        style={props.style}
        onClick={(e) => {
          if (!props.disabled) {
            props.onClick && props.onClick();
            setAnchorElement(e.currentTarget);
            return stopPropagation(e);
          }
        }}
      >
        {props.children}
      </Wrapper>
    </>
  );
}

export default Opener;
