import { Paper } from '@material-ui/core';
import { CreditCard, TuneRounded } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import BillingModal from 'modals/billing/Billing';
import MyProfile from 'modals/profiles/MyProfile';
import InviteMemberModal from 'modals/workspaces/InviteMember';
import Members from 'modals/workspaces/Members';
import WorkspaceSettings from 'modals/workspaces/Settings';
import paths from 'paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import useNavigate from 'utils/useNavigate';
import AddPeopleIcon from '../icons/AddPeopleIcon';
import GearIcon from '../icons/GearIcon';
import LogoIcon from '../icons/LogoIcon';
import LogoutIcon from '../icons/LogoutIcon';
import NextIcon from '../icons/NextIcon';
import TeamIcon from '../icons/TeamIcon';
import FlexSeparator from '../utils/FlexSeparator';

const Item = styled.div`
  padding: 15px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  svg {
    vertical-align: middle;
    margin-right: 10px;
  }

  &:hover {
    background-color: var(--color-primary-hover);
  }
`;

const BottomItem = styled.div`
  padding: 15px 15px;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-border-grey);

  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

const WorkspaceList = styled(Paper)`
  border-radius: 12px;
  overflow: auto;
  max-height: 220px;
  box-shadow: var(--shadow-sm);
`;

const WorkspaceListWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -16px;
  transform: translateX(100%);
  opacity: 0;
  z-index: 10;
  padding-left: 10px;
`;

const SwitchWorkspace = styled.div`
  position: relative;
  margin-left: 40px;
  white-space: nowrap;

  svg {
    vertical-align: middle;
    margin-left: 5px;
  }

  &:hover {
    ${WorkspaceListWrapper} {
      opacity: 1;
    }
  }
`;

const Wrapper = styled(Paper)`
  box-shadow: var(--shadow-sm);
  border-radius: 12px !important;
  overflow: visible !important;
  min-width: 400px !important;
`;

interface ProfileMenuProps {}

function ProfileMenu(props: ProfileMenuProps) {
  const { uiStore, workspaceStore } = useStore();
  const workspace = useCurrentWorkspace();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const workspaces = workspaceStore.workspaces.filter(
    (w) => w.id !== workspace.id,
  );

  return (
    <Wrapper>
      <div
        style={{
          overflow: 'hidden',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
        }}
      >
        <Item
          onClick={() => {
            uiStore.pushSide(<InviteMemberModal />);
          }}
        >
          <AddPeopleIcon />
          {t('sidebar.menu.add_people')}
        </Item>
        <Item
          onClick={() => {
            uiStore.pushSide(<Members />);
          }}
        >
          <TeamIcon />
          {t('sidebar.menu.members')}
        </Item>
        <Item
          onClick={() => {
            uiStore.pushSide(<MyProfile />);
          }}
        >
          <TuneRounded />
          {t('sidebar.menu.my_profile')}
        </Item>
        {workspaceStore.isOwner && (
          <>
            <Item
              onClick={() => {
                uiStore.pushSide(<BillingModal />);
              }}
            >
              <CreditCard />
              {t('sidebar.menu.billing')}
            </Item>
            <Item
              onClick={() => {
                uiStore.pushSide(<WorkspaceSettings />);
              }}
            >
              <GearIcon />
              {t('sidebar.menu.workspace_settings')}
            </Item>
          </>
        )}
        <Item
          onClick={() => {
            navigate(paths.auth.signout);
          }}
        >
          <LogoutIcon />
          {t('sidebar.menu.logout')}
        </Item>
      </div>
      <BottomItem>
        <FlexSeparator />
        <SwitchWorkspace>
          {t('sidebar.menu.switch_workspace')}{' '}
          <NextIcon color="primary" style={{ fontSize: '15px' }} />
          <WorkspaceListWrapper>
            <WorkspaceList className="vertical-scroll">
              {workspaces.map((workspace) => (
                <Item
                  onClick={() => {
                    window.location.href = paths.workspaces.root(workspace);
                  }}
                >
                  <LogoIcon />
                  <span style={{ whiteSpace: 'nowrap' }}>{workspace.name}</span>
                </Item>
              ))}
              <Item
                onClick={() => {
                  navigate(paths.workspaces.init);
                }}
              >
                <span style={{ whiteSpace: 'nowrap' }}>
                  {t('sidebar.menu.new_workspace')}
                </span>
              </Item>
            </WorkspaceList>
          </WorkspaceListWrapper>
        </SwitchWorkspace>
      </BottomItem>
    </Wrapper>
  );
}

export default observer(ProfileMenu);
