import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import HeadingCell from './HeadingCell';
import ItemRow from './ItemRow';
import { TableConfig } from './types';

const Wrapper = styled.div<{ rounded?: boolean; maxHeight?: string }>`
  max-height: ${(p) =>
    p.maxHeight ? p.maxHeight : 'calc(100vh - 65px - var(--topbar-height))'};
  max-width: 100%;
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
  border-radius: ${(p) => (p.rounded ? '20px' : 0)};
  position: relative;
  overflow: auto;

  .MuiTableCell-root {
    padding: 8px 10px;
    white-space: nowrap;
  }
`;

interface RowComponentProps<T> {
  config: TableConfig;
  item: T;
  index: number;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
}

interface TableProps<I extends { id: number }> {
  items: I[];
  filterItems?: (
    items: I[],
    config: TableConfig,
    filters: Record<string, string[]>,
    ordering: string | null,
  ) => I[];
  filterValues?: Record<string, string[]>;
  renderValue?: React.ComponentType<{
    value: string;
    type: string;
    valueKey: string;
    color?: string;
  }>;
  rowComponent?: React.ComponentType<RowComponentProps<I>>;
  config: TableConfig;
  onSelectionChange?: (selectedItems: I[]) => void;
  selected?: I[];
  rounded?: boolean;
  smallHeading?: boolean;
  defaultOrdering?: string;
  defaultFilters?: Record<string, string[]>;
  onOrderingChange?: (value: string | null) => void;
  onFiltersChange?: (value: Record<string, string[]>) => void;
  maxHeight?: string;
}

function Table<I extends { id: number }>(props: TableProps<I>) {
  const [ordering, setOrdering] = useState<string | null>(
    props.defaultOrdering || null,
  );
  const [filters, setFilters] = useState<Record<string, string[]>>(
    props.defaultFilters || {},
  );
  const RowComponent = props.rowComponent || ItemRow;

  const filteredItems = props.filterItems
    ? props.filterItems(props.items, props.config, filters, ordering)
    : props.items;

  return (
    <Wrapper
      className="horizontal-scroll vertical-scroll"
      rounded={props.rounded}
      maxHeight={props.maxHeight}
    >
      <MuiTable stickyHeader>
        <TableHead>
          <TableRow>
            {props.onSelectionChange && <TableCell />}
            {props.config.values.map((value) => (
              <HeadingCell
                small={props.smallHeading}
                setFilters={(f) => {
                  setFilters(f);
                  props.onFiltersChange && props.onFiltersChange(f);
                }}
                filters={filters}
                setOrdering={(o) => {
                  setOrdering(o);
                  props.onOrderingChange && props.onOrderingChange(o);
                }}
                ordering={ordering}
                filterValues={props.filterValues}
                disableFilters={!props.filterItems}
                renderValue={props.renderValue}
                value={value}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((i, index) => {
            const selected = props.selected && props.selected.includes(i);
            function onSelect() {
              if (!props.onSelectionChange) return;
              if (selected) {
                props.onSelectionChange(
                  props.selected?.filter((c) => c !== i) || [],
                );
              } else {
                props.onSelectionChange([...(props.selected || []), i]);
              }
            }
            return (
              <RowComponent
                config={props.config}
                item={i}
                index={index}
                selected={selected}
                onSelect={props.onSelectionChange ? onSelect : undefined}
              />
            );
          })}
        </TableBody>
      </MuiTable>
    </Wrapper>
  );
}

export default Table;
