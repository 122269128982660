import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function DownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g transform="translate(-3019 -1564)">
        <rect
          width="30"
          height="30"
          transform="translate(3019 1564)"
          fill="none"
        />
        <g transform="translate(4613.313 -1455.737) rotate(90)">
          <line
            x2="8.525"
            y2="8.525"
            transform="translate(3038.525 1579.05) rotate(90)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="3.55"
          />
          <line
            x1="8.525"
            y2="8.525"
            transform="translate(3038.525 1570.525) rotate(90)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="3.55"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default DownIcon;
