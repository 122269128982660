import { RootStore } from '.';
import api from 'api';
import { ProfileUpdateSchema } from 'api/generated/models';
import {
  action,
  computed,
  observable,
  runInAction
  } from 'mobx';
import { Profile } from 'models/profiles';

export class ProfileStore {
  @observable
  public loading: boolean = true;

  public profilesByID: Map<number, Profile> = observable.map<number, Profile>();

  constructor(private rootStore: RootStore) {}

  public async loadProfile(id: number) {
    const found = this.profilesByID.get(id);
    if (found) return found;

    const { data } = await api.profiles.getProfile(id);
    const profile = data;
    runInAction(() => {
      this.profilesByID.set(id, profile);
    });
    return profile;
  }

  @action
  public async loadProfiles() {
    this.loading = true;
    const { data } = await api.profiles.getProfiles();
    runInAction(() => {
      data.forEach((profileData) => {
        const profile = profileData;
        this.profilesByID.set(profile.id, profile);
      });
      this.loading = false;
    });
  }

  public async deleteProfile(profile: Profile) {
    await api.profiles.deleteProfile(profile.id);
    runInAction(() => {
      this.profilesByID.delete(profile.id);
    });
  }

  public async updateProfile(profile: Profile, newData: ProfileUpdateSchema) {
    const { data } = await api.profiles.updateProfile(profile.id, newData);
    runInAction(() => {
      this.profilesByID.set(profile.id, data);
    });
  }

  public reset() {
    this.profilesByID.clear();
  }

  public init() {
    this.loadProfiles();
    this.rootStore.socket.on('profiles/created', (message) => {
      const { profile_id } = message.data;
      if (profile_id) {
        this.loadProfile(profile_id);
      }
    });
  }

  @computed
  get profiles() {
    return Array.from(this.profilesByID.values());
  }
}
