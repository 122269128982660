import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function DescriptionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g transform="translate(-3185 -1429)">
        <g transform="translate(45)">
          <g transform="translate(38)">
            <g transform="translate(38)">
              <g transform="translate(123)">
                <rect
                  width="30"
                  height="30"
                  transform="translate(2941 1429)"
                  fill="none"
                />
              </g>
            </g>
          </g>
        </g>
        <g transform="translate(2495.002 1114.569)">
          <g transform="translate(696 321)">
            <g transform="translate(0 0)">
              <line
                x2="19"
                transform="translate(-0.002 0.431)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2.2"
              />
              <line
                x2="19"
                transform="translate(-0.002 5.431)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2.2"
              />
              <line
                x2="19"
                transform="translate(-0.002 10.431)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2.2"
              />
              <line
                x2="8"
                transform="translate(-0.002 16.431)"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="2.2"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default DescriptionIcon;
