import { VIEWABLE_RESOURCE_TYPES } from 'config';
import { observer } from 'mobx-react-lite';
import ResourceDetailsModal from 'modals/resources/Details';
import { Resource } from 'models/resources';
import React from 'react';
import { useStore } from 'stores';
import styled from 'styled-components';
import downloadFile from 'utils/downloadFile';
import isDownloadable from 'utils/isDownloadable';
import ColoredIconButton from '../elements/ColoredIconButton';
import DownloadIcon from '../icons/DownloadIcon';
import EyeIcon from '../icons/EyeIcon';
import PlayIcon from '../icons/PlayIcon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
`;

interface ResourceActionsProps {
  resource: Resource;
}

function ResourceActions(props: ResourceActionsProps) {
  const { uiStore } = useStore();

  function view() {
    if (props.resource.type === 'link' || props.resource.type === 'file') {
      window.open(props.resource.link, '_blank');
    } else {
      uiStore.pushSide(<ResourceDetailsModal resourceID={props.resource.id} />);
    }
  }

  return (
    <Wrapper>
      {isDownloadable(props.resource.link) && (
        <ColoredIconButton onClick={() => downloadFile(props.resource.link)}>
          <DownloadIcon />
        </ColoredIconButton>
      )}
      {VIEWABLE_RESOURCE_TYPES.includes(props.resource.type) && (
        <ColoredIconButton onClick={view}>
          <EyeIcon />
        </ColoredIconButton>
      )}
      {props.resource.type === 'video' && (
        <ColoredIconButton
          onClick={() =>
            uiStore.pushSide(
              <ResourceDetailsModal resourceID={props.resource.id} />,
            )
          }
        >
          <PlayIcon />
        </ColoredIconButton>
      )}
    </Wrapper>
  );
}

export default observer(ResourceActions);
