import api from 'api';
import { TemplateCreateSchema } from 'api/generated/models';
import { computed, observable, runInAction } from 'mobx';
import { Template } from 'models/templates';

export class TemplateStore {
  public templatesByID = observable.map<number, Template>();

  public async loadTemplates() {
    const { data } = await api.templates.getTemplates();
    runInAction(() => {
      data.forEach((templateData) => {
        const template = new Template(templateData);
        this.templatesByID.set(template.id, template);
      });
    });
  }

  public async createTemplate(newData: TemplateCreateSchema) {
    const { data } = await api.templates.createTemplate(newData);
    const template = new Template(data);
    runInAction(() => {
      this.templatesByID.set(template.id, template);
    });
    return template;
  }

  public async deleteTemplate(template: Template) {
    await api.templates.deleteTemplate(template.id);
    runInAction(() => {
      this.templatesByID.delete(template.id);
    });
  }

  @computed
  get templates() {
    return Array.from(this.templatesByID.values());
  }

  @computed
  get categories() {
    const res: Set<string> = new Set();
    this.templates.forEach((t) => {
      t.categories.forEach((c) => {
        res.add(c);
      });
    });
    return [...res];
  }

  public reset() {
    this.templatesByID.clear();
  }
}
