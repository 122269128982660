import SharingLayout from 'components/layouts/SharingLayout';
import WorkspaceLayout from 'components/layouts/Workspace';
import { observer } from 'mobx-react-lite';
import paths from 'paths';
import React from 'react';
import { BrowserRouter, Navigate, Routes } from 'react-router-dom';
import JoinPage from 'routes/workspaces/Join';
import { useStore } from 'stores';
import retry from 'utils/retry';
import AuthLayout from '../layouts/Auth';
import Side from '../Side';
import RedirectToSignin from './RedirectToSignin';
import Route from './Route';

const HomePage = React.lazy(() => retry(() => import('routes/Home')));
const SignInPage = React.lazy(() => retry(() => import('routes/auth/SignIn')));
const SignOutPage = React.lazy(() =>
  retry(() => import('routes/auth/SignOut')),
);
const SignUpPage = React.lazy(() => retry(() => import('routes/auth/SignUp')));
const SignUpJoinPage = React.lazy(() =>
  retry(() => import('routes/auth/SignUpJoin')),
);
const VerifyPage = React.lazy(() => retry(() => import('routes/auth/Verify')));
const ForgotPasswordPage = React.lazy(() =>
  retry(() => import('routes/auth/ForgotPassword')),
);
const ForgotPasswordConfirmPage = React.lazy(() =>
  retry(() => import('routes/auth/ForgotPasswordConfirm')),
);
const WorkspaceListPage = React.lazy(() =>
  retry(() => import('routes/workspaces/List')),
);
const WorkspaceInitPage = React.lazy(() =>
  retry(() => import('routes/workspaces/Init')),
);

const ProjectDetailsPage = React.lazy(() =>
  retry(() => import('routes/projects/DetailsRoot')),
);
const ProjectListPage = React.lazy(() =>
  retry(() => import('routes/projects/List')),
);

const ResourceListPage = React.lazy(() =>
  retry(() => import('routes/resources/List')),
);
const ProjectTemplateListPage = React.lazy(() =>
  retry(() => import('routes/project_templates/List')),
);

const EditorPage = React.lazy(() => retry(() => import('routes/Editor')));
const SharingCardPage = React.lazy(() =>
  retry(() => import('routes/sharing/Card')),
);
const SharingStepPage = React.lazy(() =>
  retry(() => import('routes/sharing/Step')),
);
const SharingProjectPage = React.lazy(() =>
  retry(() => import('routes/sharing/Project')),
);
const CalendarPage = React.lazy(() => retry(() => import('routes/Calendar')));

interface RouterProps {}

function Router(props: RouterProps) {
  const { authStore, workspaceStore } = useStore();

  if (authStore.loading) return null;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={paths.editor}
          element={
            <>
              <Side />
              <EditorPage />
            </>
          }
        />

        <Route path={paths.sharing.root} element={<SharingLayout />}>
          <Route
            path={paths.sharing.card(':id')}
            element={<SharingCardPage />}
          />
          <Route
            path={paths.sharing.step(':id')}
            element={<SharingStepPage />}
          />
          <Route
            path={paths.sharing.project(':id')}
            element={<SharingProjectPage />}
          />
        </Route>

        {/* Authenticated routes */}
        {authStore.authenticated && !workspaceStore.loading && (
          <>
            <Route path={paths.auth.signupjoin} element={<SignUpJoinPage />} />
            <Route path={paths.auth.signout} element={<SignOutPage />} />
            <Route path={paths.workspaces.join} element={<JoinPage />} />

            <Route
              path={paths.workspaces.root({
                id: ':workspaceID',
                name: ':workspaceName',
              })}
              element={<WorkspaceLayout />}
            >
              <Route path={paths.tasks.list} element={<HomePage />} />
              <Route path={paths.calendar} element={<CalendarPage />} />

              {/* Projects */}
              <Route path={paths.projects.list} element={<ProjectListPage />} />
              <Route
                path={paths.projects.details(
                  {
                    id: ':projectID',
                    name: ':projectName',
                  },
                  {
                    id: ':stepID',
                    name: ':stepName',
                  },
                )}
                element={<ProjectDetailsPage />}
              />

              <Route
                path={paths.projects.details({
                  id: ':projectID',
                  name: ':projectName',
                })}
                element={<ProjectDetailsPage />}
              />

              {/* Resources */}
              <Route
                path={paths.resources.list}
                element={<ResourceListPage />}
              />
              {/* Project Templates */}
              <Route
                path={paths.project_templates.list}
                element={<ProjectTemplateListPage />}
              />

              <Route
                path={paths.editor}
                element={
                  <>
                    <Side />
                    <EditorPage />
                  </>
                }
              />

              {/* Redirect */}
              <Route
                path=""
                element={<Navigate to={paths.tasks.list} replace />}
              />
            </Route>
            <Route
              path={paths.workspaces.list}
              element={<WorkspaceListPage />}
            />
            <Route
              path={paths.workspaces.init}
              element={<WorkspaceInitPage />}
            />
            <Route
              path="*"
              element={<Navigate to={paths.workspaces.list} replace />}
            />
          </>
        )}

        {/* Anonymous routes */}
        {!authStore.authenticated && (
          <>
            <Route path="*" element={<AuthLayout />}>
              <Route
                path={paths.auth.signupjoin}
                element={<SignUpJoinPage />}
              />
              <Route path={paths.auth.signin} element={<SignInPage />} />
              <Route path={paths.auth.signup} element={<SignUpPage />} />
              <Route
                path={paths.auth.forgotPassword}
                element={<ForgotPasswordPage />}
              />
              <Route
                path={paths.auth.forgotPasswordConfirm}
                element={<ForgotPasswordConfirmPage />}
              />
              <Route path={paths.auth.verify} element={<VerifyPage />} />
              <Route path="*" element={<RedirectToSignin />} />
            </Route>
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default observer(Router);
