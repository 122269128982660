import api from 'api';
import { MiniProjectSchema, ResourceUpdateSchema } from 'api/generated/models';
import { DateTime } from 'luxon';
import { computed, observable } from 'mobx';
import { store } from 'stores';
import { isAdmin } from 'utils/isAdmin';
import { Profile } from './profiles';

export type ResourceType = 'text' | 'pdf' | 'img' | 'file' | 'link' | 'video';

export class Resource {
  public id: number;

  @observable
  public name: string;

  @observable
  public is_private: boolean;

  @observable
  public is_global: boolean;

  @observable
  public content: string;

  @observable
  public link: string;

  @observable
  public type: ResourceType;

  @observable
  public tags: string[];

  @observable
  public date_created: DateTime;

  @observable
  public projects: MiniProjectSchema[];

  @observable
  public authors: Profile[];

  constructor(data: Partial<Resource>) {
    Object.assign(this, data);
  }

  private _update(data: Partial<Resource>) {
    Object.assign(this, data);
  }

  public async update(newData: ResourceUpdateSchema) {
    const { data } = await api.resources.updateResource(this.id, newData);
    this._update(data);
  }

  @computed
  get isAdmin() {
    if (
      store.workspaceStore.currentWorkspaceID &&
      isAdmin(store.workspaceStore.currentProfile)
    )
      return true;
    return this.authors.some(
      (c) => c.id === store.workspaceStore.currentProfile.id,
    );
  }
}
