import styled from 'styled-components';
import { theme } from 'theme';

export default styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  flex-direction: column;

  > * {
    max-width: 100%;
    width: ${(p) => p.width || 'var(--md-center-width)'};
  }

  ${theme.breakpoints.down('sm')} {
    padding: 20px;
  }
`;
