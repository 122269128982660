import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Check, CreditCard } from '@material-ui/icons';
import { Plan } from 'api/generated/models';
import BillingHelp from 'components/billing/BillingHelp';
import BackLink from 'components/elements/BackLink';
import RoundedButton from 'components/elements/RoundedButton';
import Title from 'components/elements/Title';
import Tooltip from 'components/elements/Tooltip';
import CenteredContent from 'components/layouts/CenteredContent';
import FlexSeparator from 'components/utils/FlexSeparator';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import { ModalContent, ModalFooter, ModalTitle } from '../styles';

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  > * {
    width: calc(25% - 20px);
  }
`;

const PricingCard = styled(Card)`
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  display: flex;
  flex-direction: column;

  button {
    margin-top: 20px;
  }
`;

const Name = styled(Typography)`
  text-align: center;
  font-weight: bold;
  font-size: 22px;
`;

const Price = styled(Typography)`
  color: var(--color-primary);
  font-size: 24px;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: -3px;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--color-dark-grey);
`;

const PriceCaption = styled(Typography)`
  text-align: center;
  color: var(--color-light-grey);
  font-weight: 300;
  border-bottom: 1px solid var(--color-light-grey);
  padding-bottom: 40px;
  height: 30px;
  margin-bottom: 20px;
`;

const Feature = styled(Typography)<{ bold?: boolean }>`
  display: flex;
  align-items: center;
  color: var(--color-dark-grey);
  font-weight: 300;
  margin-bottom: 8px;

  ${(p) =>
    p.bold &&
    `
    font-weight: 500;
  `}

  svg {
    padding: 2px;
    font-size: 22px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: var(--color-success-hover);
    color: var(--color-success);
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;

  p {
    text-align: center;
    margin-top: 40px;
  }
`;

interface PricingProps {}

function Pricing(props: PricingProps) {
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState<'monthly' | 'yearly'>('yearly');
  const workspace = useCurrentWorkspace();
  const { profileStore } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    workspace.loadInvitations();
  }, [workspace]);

  function switchToPlan(plan: Plan) {
    setLoading(true);
    window.setTimeout(() => {
      workspace.switchToPlan(plan, frequency);
    }, 2000);
  }

  const canSwitch =
    4 - profileStore.profiles.length - workspace.invitations.length >= 0;

  return (
    <CenteredContent width="var(--xl-center-width)">
      {!loading && (
        <ModalTitle>
          <Title>
            <CreditCard />
            {t('billing.title')}
          </Title>
        </ModalTitle>
      )}
      <ModalContent>
        {loading ? (
          <LoadingWrapper>
            <CircularProgress color="primary" />
            <Typography>{t('billing.redirect_to_stripe.loading')}</Typography>
          </LoadingWrapper>
        ) : (
          <>
            <Typography component="div" style={{ marginBottom: '20px' }}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <RoundedButton
                    onClick={() => setFrequency('yearly')}
                    color="primary"
                    variant={frequency === 'yearly' ? 'contained' : 'outlined'}
                  >
                    {t('billing.frequency.yearly')}
                  </RoundedButton>
                </Grid>
                <Grid item>
                  <RoundedButton
                    onClick={() => setFrequency('monthly')}
                    color="primary"
                    variant={frequency === 'monthly' ? 'contained' : 'outlined'}
                  >
                    {t('billing.frequency.monthly')}
                  </RoundedButton>
                </Grid>

                <Grid
                  item
                  style={{ color: COLORS.primary, marginLeft: '20px' }}
                >
                  {t('billing.frequency.promo')}
                </Grid>
              </Grid>
            </Typography>
            <Wrapper>
              <PricingCard>
                <Name>{t('billing.plans.trial.name')}</Name>
                <PriceWrapper>
                  <Price>{t('billing.plans.trial.price')}</Price>
                </PriceWrapper>
                <PriceCaption />
                {t('billing.plans.trial.features')
                  .split('|')
                  .map((feature) => {
                    const bold = feature.startsWith('**');
                    return (
                      <Feature bold={bold}>
                        <Check />
                        {bold ? feature.substring(2) : feature}
                      </Feature>
                    );
                  })}
                <FlexSeparator />
                {workspace.current_plan !== Plan.Unlimited && (
                  <RoundedButton
                    variant="contained"
                    color="primary"
                    disabled={workspace.current_plan === Plan.Trial}
                    onClick={() => switchToPlan(Plan.Entreprise)}
                  >
                    {workspace.current_plan === Plan.Trial
                      ? t('billing.plans.current_plan_action')
                      : t('billing.plans.trial.action')}
                  </RoundedButton>
                )}
              </PricingCard>
              <PricingCard>
                <Name>{t('billing.plans.team.name')}</Name>
                <PriceWrapper>
                  <Price>
                    {frequency === 'monthly'
                      ? t('billing.plans.team.price.monthly')
                      : t('billing.plans.team.price.yearly')}
                    €
                  </Price>
                  <span>{t('billing.plans.per_month')}</span>
                </PriceWrapper>
                <PriceCaption>
                  {frequency === 'monthly'
                    ? t('billing.plans.frequency.monthly')
                    : t('billing.plans.frequency.yearly')}
                </PriceCaption>
                {t('billing.plans.team.features')
                  .split('|')
                  .map((feature) => {
                    const bold = feature.startsWith('**');
                    return (
                      <Feature bold={bold}>
                        <Check />
                        {bold ? feature.substring(2) : feature}
                      </Feature>
                    );
                  })}
                <FlexSeparator />
                {workspace.current_plan !== Plan.Unlimited && (
                  <Tooltip
                    title={canSwitch ? '' : t('billing.plans.team.cant_switch')}
                  >
                    <div style={{ width: '100%' }}>
                      <RoundedButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={
                          workspace.current_plan === Plan.Team || !canSwitch
                        }
                        onClick={() => switchToPlan(Plan.Team)}
                      >
                        {workspace.current_plan === Plan.Team
                          ? t('billing.plans.current_plan_action')
                          : t('billing.plans.team.action')}
                      </RoundedButton>
                    </div>
                  </Tooltip>
                )}
              </PricingCard>
              <PricingCard>
                <Name>{t('billing.plans.entreprise.name')}</Name>
                <PriceWrapper>
                  <Price>
                    {frequency === 'monthly'
                      ? t('billing.plans.entreprise.price.monthly')
                      : t('billing.plans.entreprise.price.yearly')}
                    €
                  </Price>
                  <span>{t('billing.plans.per_month')}</span>
                </PriceWrapper>
                <PriceCaption>
                  {frequency === 'monthly'
                    ? t('billing.plans.frequency.monthly')
                    : t('billing.plans.frequency.yearly')}
                </PriceCaption>
                {t('billing.plans.entreprise.features')
                  .split('|')
                  .map((feature) => {
                    const bold = feature.startsWith('**');
                    return (
                      <Feature bold={bold}>
                        <Check />
                        {bold ? feature.substring(2) : feature}
                      </Feature>
                    );
                  })}
                <FlexSeparator />
                {workspace.current_plan !== Plan.Unlimited && (
                  <RoundedButton
                    variant="contained"
                    color="primary"
                    disabled={workspace.current_plan === Plan.Entreprise}
                    onClick={() => switchToPlan(Plan.Entreprise)}
                  >
                    {workspace.current_plan === Plan.Entreprise
                      ? t('billing.plans.current_plan_action')
                      : t('billing.plans.entreprise.action')}
                  </RoundedButton>
                )}
              </PricingCard>
              <PricingCard>
                <Name>{t('billing.plans.unlimited.name')}</Name>
                <PriceWrapper>
                  <Price>{t('billing.plans.on_demand.price')}</Price>
                </PriceWrapper>
                <PriceCaption />
                {t('billing.plans.unlimited.features')
                  .split('|')
                  .map((feature) => {
                    const bold = feature.startsWith('**');
                    return (
                      <Feature bold={bold}>
                        <Check />
                        {bold ? feature.substring(2) : feature}
                      </Feature>
                    );
                  })}
                <FlexSeparator />
                <a
                  href="https://www.bloomup.io/contacter-lequipe-commerciale"
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    width: '100%',
                    pointerEvents:
                      workspace.current_plan === Plan.Unlimited
                        ? 'none'
                        : undefined,
                  }}
                >
                  <RoundedButton
                    variant="contained"
                    style={{
                      backgroundColor: COLORS.green,
                      color: COLORS.white,
                      width: '100%',
                    }}
                    disabled={workspace.current_plan === Plan.Unlimited}
                  >
                    {workspace.current_plan === Plan.Unlimited
                      ? t('billing.plans.current_plan_action')
                      : t('billing.plans.unlimited.action')}
                  </RoundedButton>
                </a>
              </PricingCard>
            </Wrapper>
            <BillingHelp />
          </>
        )}
      </ModalContent>
      {!loading && (
        <ModalFooter>
          <BackLink popSide />
        </ModalFooter>
      )}
    </CenteredContent>
  );
}

export default observer(Pricing);
