import { Box } from '@material-ui/core';
import api from 'api';
import { CustomLogSchema } from 'api/generated/models';
import Placeholder from 'components/elements/Placeholder';
import TextButton from 'components/elements/TextButton';
import { HEADER_PAGINATION_HAS_MORE, LOG_LAST_SEEN_KEY } from 'config';
import { Emoji } from 'emoji-mart';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import LogsModal from 'modals/custom/Logs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import CustomLog from './CustomLog';

const Wrapper = styled.div`
  > * {
    margin-bottom: 20px;
  }
`;

interface CustomLogsProps {
  full?: boolean;
  onLoad?: () => void;
}

function CustomLogs(props: CustomLogsProps) {
  const { uiStore, workspaceStore, customStore } = useStore();
  const [logs, setLogs] = useState<CustomLogSchema[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(0);
  const [lastSeenDate] = useState(
    workspaceStore.getStorageItem<DateTime>(LOG_LAST_SEEN_KEY),
  );

  const { t } = useTranslation();

  async function loadMore() {
    const { data, headers } = await api.custom.getCustomLogs(page * 12);
    setLogs([...logs, ...data]);
    setPage(page + 1);
    setHasMore(headers[HEADER_PAGINATION_HAS_MORE] === '1');
  }

  useEffect(() => {
    loadMore().then(() => {
      setLoading(false);
      props.onLoad && props.onLoad();
      customStore.readLogs();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!logs || logs.length === 0) return;
    const log = logs[0];
    if (!lastSeenDate || log.date_created > lastSeenDate) {
      workspaceStore.setStorageItem(
        LOG_LAST_SEEN_KEY,
        log.date_created.toISO(),
      );
    }
  }, [logs, lastSeenDate, workspaceStore]);

  return (
    <Wrapper>
      {(props.full ? logs : logs.slice(0, 3)).map((log) => (
        <CustomLog
          log={log}
          isNew={
            !lastSeenDate ||
            lastSeenDate.diff(log.date_created).as('millisecond') < 0
          }
        />
      ))}
      {!props.full && logs.length > 3 && (
        <Box mt={3} textAlign="center">
          <TextButton onClick={() => uiStore.pushSide(<LogsModal />)}>
            {t('home.my_notifications.view_more')}
          </TextButton>
        </Box>
      )}
      {props.full && hasMore && (
        <Box mt={3} textAlign="center">
          <TextButton onClick={loadMore}>{t('commons.view_more')}</TextButton>
        </Box>
      )}
      {logs.length === 0 && !loading && (
        <Placeholder>
          <div style={{ marginBottom: '10px' }}>
            <Emoji
              size={64}
              emoji={t('home.my_notifications.no_items.emoji')}
              set="apple"
            />
          </div>
          {t('home.my_notifications.no_items.text')}
        </Placeholder>
      )}
    </Wrapper>
  );
}

export default observer(CustomLogs);
