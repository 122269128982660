import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ContentsLibIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <g
        id="Group_3598"
        data-name="Group 3598"
        transform="translate(-3274 -1371)"
      >
        <g id="Group_3596" data-name="Group 3596">
          <g id="Group_3594" data-name="Group 3594">
            <g id="Group_2505" data-name="Group 2505" transform="translate(90)">
              <g
                id="Group_2497"
                data-name="Group 2497"
                transform="translate(310 -145)"
              >
                <rect
                  id="Rectangle_1077"
                  data-name="Rectangle 1077"
                  width="30"
                  height="30"
                  transform="translate(2874 1516)"
                  fill="none"
                />
              </g>
            </g>
          </g>
          <g id="layer1" transform="translate(3371.85 1326.065)">
            <path
              id="path847"
              d="M-87.5,71.632a2.63,2.63,0,0,1-2.145-2.007,5.023,5.023,0,0,1-.091-1.221l-.019-.947-.518-.018a2.15,2.15,0,0,1-1.187-.285,2.608,2.608,0,0,1-1.312-1.7l-.076-.286V50.148l.1-.309a2.64,2.64,0,0,1,1.322-1.626c.565-.284.006-.258,5.787-.269l5.147-.009.133.164c.073.09.694.846,1.38,1.681l1.248,1.517h.661l.323.4,2.307,2.875,1.984,2.473-.01,6.186-.01,6.186-.078.257a2.711,2.711,0,0,1-1.825,1.892l-.257.079-6.344,0c-3.489,0-6.421-.007-6.516-.021Zm12.845-1.9a1.01,1.01,0,0,0,.252-.291l.106-.189.008-5.8.008-5.8-1.049-1.3c-.577-.717-1.4-1.739-1.823-2.272l-.775-.969h-4.71c-3.923,0-4.728.008-4.82.047a.789.789,0,0,0-.374.416l-.079.171V69.213l.1.2a.734.734,0,0,0,.416.393c.079.031,1.622.039,6.351.034l6.246-.006ZM-85.1,67.241a.9.9,0,0,1-.286-1.495c.28-.253.069-.237,3.2-.237s2.917-.017,3.208.245a.9.9,0,0,1-.242,1.466c-.152.071-.192.072-2.932.079a14.128,14.128,0,0,1-2.946-.059Zm.083-3.872a.918.918,0,0,1-.581-1.233,1.021,1.021,0,0,1,.474-.479,31.983,31.983,0,0,1,4.034-.057h3.909l.184.1a.886.886,0,0,1,0,1.58l-.183.1-3.875.006c-2.131,0-3.913-.006-3.961-.021Zm0-3.909a.935.935,0,0,1-.634-1.1.952.952,0,0,1,.68-.649c.122-.025,1.363-.034,3.984-.028l3.806.009.145.077a.891.891,0,0,1,.255,1.443c-.292.3.018.28-4.323.277C-83.207,59.487-84.968,59.474-85.015,59.46Zm-4.724.143.009-6.027.078-.274a2.718,2.718,0,0,1,1.837-1.931,36.584,36.584,0,0,1,3.953-.073l3.776-.018-.623-.754-.623-.754h-9.226l-.121.083a.9.9,0,0,0-.322.439c-.038.107-.047,1.385-.048,7.382,0,4.645.011,7.3.032,7.374a.856.856,0,0,0,.382.507,1,1,0,0,0,.518.074h.368Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default ContentsLibIcon;
