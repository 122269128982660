import { CssBaseline, ThemeProvider } from '@material-ui/core';
import Router from 'components/Router';
import { SOURCE_KEY } from 'config';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Helmet from 'react-helmet';
import { StoreProvider } from 'stores';
import { GlobalStyle, theme } from 'theme';

function App() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    if (source) {
      localStorage.setItem(SOURCE_KEY, source);
    }
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <Helmet titleTemplate="%s - Bloomup" />
          <CssBaseline />
          <GlobalStyle />
          <Router />
        </ThemeProvider>
      </StoreProvider>
    </DndProvider>
  );
}

export default App;
