import { Typography } from '@material-ui/core';
import BackLink from 'components/elements/BackLink';
import Placeholder from 'components/elements/Placeholder';
import TextButton from 'components/elements/TextButton';
import ProjectTemplateIcon from 'components/icons/ProjectTemplateIcon';
import TemplateCard from 'components/project_templates/TemplateCard';
import TemplateDetails from 'components/project_templates/TemplateDetails';
import FlexSeparator from 'components/utils/FlexSeparator';
import { observer } from 'mobx-react-lite';
import NewProjectModal from 'modals/projects/New';
import { Template } from 'models/templates';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentProfile, useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 1000px;
  max-width: 100%;
`;

const List = styled.div`
  width: 800px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    width: calc(50% - 10px);
    margin-bottom: 15px;
  }

  ${Placeholder} {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - var(--topbar-height) - 500px);
  }
`;

const Categories = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 20px;
  height: calc(100vh - 160px);

  > * {
    margin-bottom: 10px;
  }
`;

const CategoryTitle = styled(Typography)`
  color: var(--color-primary);
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 40px;
`;

const Category = styled(TextButton)<{ active?: boolean }>`
  margin-left: -20px;

  ${(p) =>
    p.active &&
    `
    background-color: var(--color-primary-hover);
    color: var(--color-primary);
  `}
`;

const BackLinkWrapper = styled.div`
  margin-left: -20px;
  margin-top: 40px;
`;

const Details = styled.div`
  width: 800px;
  max-width: 100%;
`;

interface ProjectTemplatesListProps {}

function ProjectTemplatesList(props: ProjectTemplatesListProps) {
  const profile = useCurrentProfile();
  const workspace = useCurrentWorkspace();
  const { uiStore, templateStore } = useStore();
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState<Template | null>(null);

  useEffect(() => {
    async function load() {
      await templateStore.loadTemplates();
      setLoading(false);
    }
    load();
  }, [templateStore]);

  async function onDelete(template: Template) {
    templateStore.deleteTemplate(template);
    setTemplate(null);
  }

  const templates = templateStore.templates
    .filter((template) => {
      if (filter === 'mine') {
        return template.profile_id === profile.id;
      }
      if (filter === 'workspace') {
        return template.workspace_id === workspace.id;
      }
      if (filter?.startsWith('category/')) {
        const parts = filter.split('category/');
        if (parts.length > 0) {
          const category = parts[1];
          return template.categories.includes(category);
        }
      }
      return true;
    })
    .sort((a, b) => b.id - a.id);

  if (loading) {
    return null;
  }

  return (
    <Content className="vertical-scroll">
      <Helmet>
        <title>{t('project_templates.list.title')}</title>
      </Helmet>
      <Categories>
        <Category
          active={filter === null}
          onClick={() => {
            setFilter(null);
            setTemplate(null);
          }}
        >
          {t('project_templates.list.categories.all')} (
          {templateStore.templates.length})
        </Category>
        <Category
          active={filter === 'mine'}
          onClick={() => {
            setFilter('mine');
            setTemplate(null);
          }}
        >
          {t('project_templates.list.categories.mine')} (
          {
            templateStore.templates.filter((c) => c.profile_id === profile.id)
              .length
          }
          )
        </Category>
        <Category
          active={filter === 'workspace'}
          onClick={() => {
            setFilter('workspace');
            setTemplate(null);
          }}
        >
          {t('project_templates.list.categories.workspace')} (
          {
            templateStore.templates.filter(
              (c) => c.workspace_id === workspace.id,
            ).length
          }
          )
        </Category>
        <CategoryTitle>
          {t('project_templates.list.categories.title')}
        </CategoryTitle>
        {templateStore.categories.map((category) => (
          <Category
            active={filter === `category/${category}`}
            onClick={() => {
              setFilter(`category/${category}`);
              setTemplate(null);
            }}
          >
            {category} (
            {
              templateStore.templates.filter((c) =>
                c.categories.includes(category),
              ).length
            }
            )
          </Category>
        ))}
        <FlexSeparator />
        <BackLinkWrapper>
          <BackLink
            onClick={() => {
              uiStore.popSide();
              uiStore.pushSide(<NewProjectModal />);
            }}
            withIcon
          />
        </BackLinkWrapper>
      </Categories>
      {template && (
        <Details>
          <TemplateDetails
            isSelection
            template={template}
            onDelete={() => onDelete(template)}
          />
        </Details>
      )}
      {!template && (
        <List>
          {templates.map((template) => (
            <TemplateCard
              isSelection
              template={template}
              onClick={() => setTemplate(template)}
            />
          ))}
          {templates.length === 0 && (
            <Placeholder>
              <div style={{ marginBottom: '10px' }}>
                <ProjectTemplateIcon
                  style={{ fontSize: '100px', color: COLORS.borderGrey }}
                />
              </div>
              {t('project_templates.list.no_items.text')}
            </Placeholder>
          )}
        </List>
      )}
    </Content>
  );
}

export default observer(ProjectTemplatesList);
