import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function CrossIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Cross-a,.Cross-b{fill:none;}.Cross-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.5px;}`}</style>
      </defs>
      <g transform="translate(-2946 -1573)">
        <rect
          className="Cross-a"
          width="30"
          height="30"
          transform="translate(2946 1573)"
        />
        <g transform="translate(2990.077 545.053) rotate(45)">
          <g transform="translate(716.914 749.429) rotate(45)">
            <line
              className="Cross-b"
              x2="6"
              y2="6"
              transform="translate(6 6)"
            />
            <line
              className="Cross-b"
              x1="6"
              y2="6"
              transform="translate(0 6)"
            />
            <g transform="translate(12 6) rotate(180)">
              <line
                className="Cross-b"
                x2="6"
                y2="6"
                transform="translate(6 0)"
              />
              <line
                className="Cross-b"
                x1="6"
                y2="6"
                transform="translate(0 0)"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default CrossIcon;
