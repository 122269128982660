import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Typography)`
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
  align-items: center;

  ${(p) =>
    !p.color &&
    `
    color: var(--color-secondary);
  `}

  > svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;
