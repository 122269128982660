import api from 'api';
import { RECENT_SEARCH_KEY } from 'config';
import { action, computed } from 'mobx';
import { Project } from 'models/projects';
import { Resource } from 'models/resources';
import { Template } from 'models/templates';
import { RootStore } from 'stores';

export class SearchStore {
  constructor(private rootStore: RootStore) {}

  public async searchProjects(query: string) {
    this.addRecentSearch(query);
    const { data } = await api.search.searchProjects(query);
    const projects = data.map((c) => new Project(c));
    projects.forEach((p) => {
      this.rootStore.projectStore.projectsByID.set(p.id, p);
    });
    return projects;
  }

  public async searchCards(query: string) {
    this.addRecentSearch(query);
    const { data } = await api.search.searchCards(query);
    return data;
  }

  public async searchTemplates(query: string) {
    this.addRecentSearch(query);
    const { data } = await api.search.searchTemplates(query);
    const templates = data.map((c) => new Template(c));
    templates.forEach((p) => {
      this.rootStore.templateStore.templatesByID.set(p.id, p);
    });
    return templates;
  }

  public async searchResources(query: string) {
    this.addRecentSearch(query);
    const { data } = await api.search.searchResources(query);
    const resources = data.map((c) => new Resource(c));
    return resources;
  }

  @computed
  get recentSearch() {
    return (this.rootStore.workspaceStore.getStorageItem(
      RECENT_SEARCH_KEY,
      true,
      [],
    ) as string[])
      .filter((c) => c && c.length !== 0)
      .slice(0, 5);
  }

  @action
  public addRecentSearch(query: string) {
    const recentSearch = this.recentSearch;
    if (recentSearch.length > 0 && recentSearch[0] === query) return;

    if (recentSearch.includes(query)) {
      this.rootStore.workspaceStore.setStorageItem(
        RECENT_SEARCH_KEY,
        [query, ...recentSearch.filter((c) => c !== query)],
        true,
      );
    } else {
      this.rootStore.workspaceStore.setStorageItem(
        RECENT_SEARCH_KEY,
        [query, ...recentSearch],
        true,
      );
    }
  }
}
