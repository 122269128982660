import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function NotificationsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Notifications-a{fill:none;}.Notifications-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2909 -1456)">
        <rect
          className="Notifications-a"
          width="30"
          height="30"
          transform="translate(2909 1456)"
        />
        <g transform="translate(2859.033 1505.074)">
          <path
            className="Notifications-b"
            d="M60.374-21.167a3.884,3.884,0,0,1-2.286-1.6l-.076-.117-1.454.229c-.8.126-1.562.228-1.695.228a3.021,3.021,0,0,1-2.787-2.09,3.169,3.169,0,0,1-.018-1.575,3.153,3.153,0,0,1,1.294-1.733,3.893,3.893,0,0,1,1.19-.456.592.592,0,0,0,.476-.46,23.886,23.886,0,0,0-.34-2.552,21.541,21.541,0,0,1-.473-3.8,9,9,0,0,1,.856-3.815,9.215,9.215,0,0,1,3.106-3.732l.244-.164,0-.525a3.792,3.792,0,0,1,2.511-3.517,3.863,3.863,0,0,1,2.9.121,4.246,4.246,0,0,1,1.541,1.286,4.383,4.383,0,0,1,.553,1.194l.058.26.448.155a9.409,9.409,0,0,1,3.516,2.178,9.38,9.38,0,0,1,2.683,4.766c.068.308.281,1.568.472,2.8s.379,2.31.419,2.4c.129.284.231.315,1.068.327a2.3,2.3,0,0,1,1.148.169,2.9,2.9,0,0,1,1.894,2.734,2.791,2.791,0,0,1-.883,2.053,2.6,2.6,0,0,1-1.282.75c-.149.037-2.577.431-5.4.874s-5.119.814-5.11.823a1.683,1.683,0,0,1-.1.348,3.91,3.91,0,0,1-2.425,2.37,3.7,3.7,0,0,1-2.06.076Zm1.544-2.219c.116-.048.2-.1.185-.113a3.847,3.847,0,0,0-.643.064c-.394.057-.595.105-.554.133A2.259,2.259,0,0,0,61.919-23.386Zm-3.041-1.87c13.747-2.164,16.051-2.534,16.17-2.592a.745.745,0,0,0,.356-.8.891.891,0,0,0-.215-.32l-.176-.171-.674-.012a2.808,2.808,0,0,1-1.871-.5,2.746,2.746,0,0,1-1.159-1.728c-.043-.2-.235-1.366-.425-2.583-.449-2.869-.49-3.044-.935-3.995A6.8,6.8,0,0,0,68.475-40a6.783,6.783,0,0,0-5.046-2.123,6.569,6.569,0,0,0-3.15.762,7.147,7.147,0,0,0-3.816,5.389c-.117.824-.044,1.626.4,4.389a18.294,18.294,0,0,1,.376,2.881,2.849,2.849,0,0,1-2.183,2.557,2.671,2.671,0,0,0-.5.157.9.9,0,0,0-.4.635.777.777,0,0,0,.659.709c.084,0,1.913-.277,4.065-.615Zm3.329-18.994a6.8,6.8,0,0,1,.8-.073c.387,0,.383-.066-.017-.271a1.363,1.363,0,0,0-.7-.165,1.079,1.079,0,0,0-.723.135c-.284.14-.717.526-.717.64a1.072,1.072,0,0,0,.438-.073c.241-.066.656-.153.923-.193Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default NotificationsIcon;
