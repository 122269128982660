import Progress from 'components/utils/Progress';
import { observer } from 'mobx-react-lite';
import { ResourceType } from 'models/resources';
import React, { Suspense } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import styled from 'styled-components';
import retry from 'utils/retry';
import ResourceDisplay from './ResourceDisplay';

const VideoPlayer = React.lazy(() =>
  retry(() => import('components/VideoPlayer')),
);

const PDFViewer = React.lazy(() => retry(() => import('components/PDFViewer')));

const Image = styled.img`
  display: block;
  box-shadow: var(--shadow-sm);
  border-radius: 7px;
  max-width: 100%;
`;

interface ResourceContentDisplayProps {
  resource: { type: ResourceType; link: string; name?: string };
  autoPlay?: boolean;
  fullWidth?: boolean;
}

function ResourceContentDisplay(props: ResourceContentDisplayProps) {
  if (props.resource.type === 'img') {
    return (
      <Zoom wrapStyle={{ width: props.fullWidth ? '100%' : undefined }}>
        <Image
          src={props.resource.link}
          style={{ width: props.fullWidth ? '100%' : undefined }}
        />
      </Zoom>
    );
  }

  if (props.resource.type === 'video') {
    return (
      <Suspense fallback={<Progress />}>
        <VideoPlayer
          src={props.resource.link}
          autoPlay={props.autoPlay ?? true}
        />
      </Suspense>
    );
  }

  if (props.resource.type === 'pdf') {
    return (
      <Suspense fallback={<Progress />}>
        <PDFViewer file={props.resource.link} />
      </Suspense>
    );
  }

  if (props.resource.type === 'link' || props.resource.type === 'file') {
    return <ResourceDisplay resource={props.resource} />;
  }

  return <div />;
}

export default observer(ResourceContentDisplay);
