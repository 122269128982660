import { Box, MenuItem, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import Avatar from 'components/Avatar';
import BackLink from 'components/elements/BackLink';
import ColoredIconButton from 'components/elements/ColoredIconButton';
import InfoButton from 'components/elements/InfoButton';
import InputWithConfirm from 'components/elements/InputWithConfirm';
import RoundedButton from 'components/elements/RoundedButton';
import RoundedSelect from 'components/elements/RoundedSelect';
import TextButton from 'components/elements/TextButton';
import Title from 'components/elements/Title';
import WithConfirm from 'components/elements/WithConfirm';
import DeleteIcon from 'components/icons/DeleteIcon';
import EditIcon from 'components/icons/EditIcon';
import LogoutIcon from 'components/icons/LogoutIcon';
import PersonIcon from 'components/icons/PersonIcon';
import PreferencesIcon from 'components/icons/PreferencesIcon';
import CenteredContent from 'components/layouts/CenteredContent';
import Link from 'components/Router/Link';
import i18n from 'i18n';
import { observer } from 'mobx-react-lite';
import paths from 'paths';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import uploadFile from 'utils/uploadFile';
import { ModalFooter } from '../styles';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Infos = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);

  > * {
    margin: 0 3px;
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }
`;

const Inputs = styled.div`
  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface MyProfileModalProps {}

function MyProfileModal(props: MyProfileModalProps) {
  const [editingName, setEditingName] = useState(false);

  const fileRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const profile = useCurrentProfile();
  const { workspaceStore } = useStore();

  async function onChangeAvatar(e: React.ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const url = await uploadFile(file);
      if (url) {
        workspaceStore.updateCurrentProfile({
          avatar: url,
        });
      }
    }
  }

  return (
    <CenteredContent>
      <Header>
        <Title>
          <PersonIcon />
          {t('profiles.my_profile.edit')}
        </Title>
        <Link to={paths.auth.signout}>
          <TextButton>
            <LogoutIcon />
            {t('profiles.my_profile.signout')}
          </TextButton>
        </Link>
      </Header>

      <Infos>
        <Inputs>
          <div>
            <Typography color="primary">
              {t('profiles.my_profile.name.label')}
            </Typography>
            {editingName ? (
              <InputWithConfirm
                value={profile.name}
                onChange={(name) => {
                  workspaceStore.updateCurrentProfile({ name });
                  setEditingName(false);
                }}
              />
            ) : (
              <Name>
                {profile.name}
                <ColoredIconButton onClick={() => setEditingName(true)}>
                  <EditIcon />
                </ColoredIconButton>
              </Name>
            )}
          </div>
          <div>
            <Typography color="primary">
              {t('profiles.my_profile.email.label')}
            </Typography>
            {profile.email}
          </div>
          <div>
            <Typography color="primary">
              {t('profiles.my_profile.role.label')}
            </Typography>
            <RoleWrapper>
              {workspaceStore.isAdmin
                ? t('members.roles.admin')
                : t('members.roles.member')}
              <InfoButton
                style={{ color: COLORS.lightGrey }}
                tooltip={
                  workspaceStore.isAdmin
                    ? t('members.roles.admin_tooltip')
                    : t('members.roles.member_tooltip')
                }
              />
            </RoleWrapper>
          </div>
        </Inputs>
        <AvatarWrapper>
          <Avatar profile={profile} size={130} disableTooltip />
          <Actions>
            <input
              ref={fileRef}
              type="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={onChangeAvatar}
            />
            <ColoredIconButton onClick={() => fileRef.current?.click()}>
              <EditIcon />
            </ColoredIconButton>
            <ColoredIconButton
              withConfirm
              iconColor={COLORS.error}
              onClick={() => {
                if (profile.avatar) {
                  workspaceStore.updateCurrentProfile({
                    avatar: null,
                  });
                }
              }}
            >
              <DeleteIcon />
            </ColoredIconButton>
          </Actions>
        </AvatarWrapper>
      </Infos>

      <Box mt={7}>
        <Title>
          <PreferencesIcon />
          {t('profiles.my_profile.preferences')}
        </Title>
        <Box mt={2}>
          <RoundedSelect
            wrapperProps={{ size: 'small' }}
            label={t('profiles.my_profile.language')}
            value={i18n.language}
            onChange={(e) => i18n.changeLanguage(e.target.value as string)}
          >
            <MenuItem value="fr">{t('languages.fr')}</MenuItem>
            <MenuItem value="en">{t('languages.en')}</MenuItem>
          </RoundedSelect>
        </Box>
      </Box>

      {!workspaceStore.isOwner && (
        <Box mt={7}>
          <Title>
            <ErrorOutline />
            {t('profiles.my_profile.danger_zone')}
          </Title>
          <Box mt={1}>
            <WithConfirm
              onConfirm={() => {
                workspaceStore.deleteCurrentProfile();
              }}
            >
              <RoundedButton
                style={{ borderColor: COLORS.error, color: COLORS.error }}
                variant="outlined"
              >
                {t('profiles.my_profile.delete')}
              </RoundedButton>
            </WithConfirm>
          </Box>
        </Box>
      )}
      <ModalFooter>
        <BackLink popSide />
      </ModalFooter>
    </CenteredContent>
  );
}

export default observer(MyProfileModal);
