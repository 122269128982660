import { Popover, PopoverProps } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import stopPropagation from 'utils/stopPropagation';

interface MenuProps extends PopoverProps {
  primary?: boolean;
}

function Menu({ children, primary, ...props }: MenuProps) {
  return (
    <Popover
      transitionDuration="auto"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClick={stopPropagation}
      onBackdropClick={stopPropagation}
      onPointerDown={stopPropagation}
      classes={{
        paper: primary
          ? css`
              &&& {
                border-radius: 8px;
              }

              .MuiMenuItem-root {
                color: var(--color-light-grey);
                padding: 12px 20px;
                transition: none;

                &:hover {
                  color: var(--color-primary);
                  background-color: var(--color-primary-hover);
                }

                svg {
                  vertical-align: middle;
                  font-size: 20px;
                  margin-right: 12px;
                }
              }
            `
          : css`
              &&& {
                border-radius: 8px;
              }
            `,
      }}
      {...(props as any)}
    >
      {children}
    </Popover>
  );
}

export default Menu;
