import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosTransformer,
} from 'axios';
import axiosRetry, {
  isIdempotentRequestError,
  isNetworkError,
} from 'axios-retry';
import { DateTime } from 'luxon';
import { store } from 'stores';
import { Auth } from '../amplify';
import { Configuration } from './generated';
import {
  AuthApi,
  BillingApi,
  CustomApi,
  FilesApi,
  ProfilesApi,
  ProjectsApi,
  SharingApi,
  TemplatesApi,
  WorkspacesApi,
} from './generated/api';
import { ResourcesApi } from './generated/api/resources-api';
import { SearchApi } from './generated/api/search-api';

function retryCondition(error: AxiosError) {
  return (
    isNetworkError(error) ||
    isIdempotentRequestError(error) ||
    error.code === 'ECONNABORTED'
  );
}

function transformResponse(data: any): any {
  if (!data) return data;
  if (Array.isArray(data)) {
    return data.map((c) => transformResponse(c));
  }

  if (typeof data === 'object') {
    Object.keys(data).forEach((key: string) => {
      if (
        data[key] &&
        (key.startsWith('date_') || key.endsWith('_date')) &&
        key !== 'relative_due_date'
      ) {
        try {
          data[key] = DateTime.fromISO(data[key]);
        } catch (_) {}
      } else {
        data[key] = transformResponse(data[key]);
      }
    });
    return data;
  }

  return data;
}

function transformRequest(data: any): any {
  if (!data) return data;
  if (Array.isArray(data)) {
    return data.map((c) => transformRequest(c));
  }

  if (typeof data === 'object') {
    Object.keys(data).forEach((key: string) => {
      if (
        (key.startsWith('date_') || key.endsWith('_date')) &&
        data[key] instanceof DateTime
      ) {
        data[key] = (data[key] as DateTime).toISO();
      } else {
        data[key] = transformRequest(data[key]);
      }
    });
    return data;
  }

  return data;
}

async function headersInjector(config: AxiosRequestConfig) {
  try {
    const session = await Auth.currentSession();
    if (session) {
      const idToken = session.getIdToken();
      config.headers.Authorization = `Bearer ${idToken.getJwtToken()}`;
    }
  } catch (_) {}

  const currentWorkspace = store.workspaceStore.currentWorkspace;
  if (currentWorkspace) {
    config.headers['X-Workspace'] = currentWorkspace.id;
  }
  return config;
}

async function configInjector(config: AxiosRequestConfig) {
  const method = config.method?.toLowerCase();
  if (
    method &&
    (method === 'get' || method === 'head' || method === 'options')
  ) {
    config.timeout = 5000;
  } else {
    config.timeout = 20000;
  }

  return config;
}

function createAxiosInstance(basePath: string) {
  const instance = Axios.create({
    baseURL: basePath,
  });
  instance.interceptors.request.use(headersInjector, Promise.reject);
  instance.interceptors.request.use(configInjector, Promise.reject);

  const originResponseTransform = instance.defaults
    .transformResponse as AxiosTransformer[];
  instance.defaults.transformResponse = originResponseTransform.concat(
    transformResponse,
  );

  const originRequestTransform = instance.defaults
    .transformRequest as AxiosTransformer[];
  instance.defaults.transformRequest = originRequestTransform.concat(
    transformRequest,
  );

  axiosRetry(instance, {
    retryCondition,
    retries: 3,
    shouldResetTimeout: true,
  });
  return instance;
}

class API {
  private config: Configuration;
  public axios: AxiosInstance;

  public auth: AuthApi;
  public workspaces: WorkspacesApi;
  public projects: ProjectsApi;
  public resources: ResourcesApi;
  public profiles: ProfilesApi;
  public files: FilesApi;
  public templates: TemplatesApi;
  public custom: CustomApi;
  public billing: BillingApi;
  public sharing: SharingApi;
  public search: SearchApi;

  constructor() {
    const basePath = process.env.REACT_APP_API_BASE || 'http://localhost:5000';

    this.axios = createAxiosInstance(basePath);
    this.config = new Configuration({});

    this.auth = new AuthApi(this.config, basePath, this.axios);
    this.workspaces = new WorkspacesApi(this.config, basePath, this.axios);
    this.projects = new ProjectsApi(this.config, basePath, this.axios);
    this.resources = new ResourcesApi(this.config, basePath, this.axios);
    this.profiles = new ProfilesApi(this.config, basePath, this.axios);
    this.files = new FilesApi(this.config, basePath, this.axios);
    this.templates = new TemplatesApi(this.config, basePath, this.axios);
    this.custom = new CustomApi(this.config, basePath, this.axios);
    this.billing = new BillingApi(this.config, basePath, this.axios);
    this.sharing = new SharingApi(this.config, basePath, this.axios);
    this.search = new SearchApi(this.config, basePath, this.axios);
  }
}

export default new API();
