import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RoundedButton from '../elements/RoundedButton';

const Wrapper = styled.div`
  margin-top: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-bottom: 10px;
  }

  a {
    color: inherit;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Title = styled(Typography)`
  font-weight: bold;
  color: var(--color-primary);
`;

const Description = styled(Typography)``;

interface BillingHelpProps {}

function BillingHelp(props: BillingHelpProps) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title>{t('billing.help.title')}</Title>
      <Description>{t('billing.help.description')}</Description>
      <a
        href="https://www.bloomup.io/contacter-lequipe-commerciale"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        <RoundedButton color="primary" variant="outlined">
          {t('billing.help.action')}
        </RoundedButton>
      </a>
    </Wrapper>
  );
}

export default BillingHelp;
