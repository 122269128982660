import { observer } from 'mobx-react-lite';
import { workspacePath } from 'paths';
import React from 'react';
import { NavigateProps } from 'react-router';
import { Navigate as BaseNavigate } from 'react-router-dom';
import { useStore } from 'stores';

function Navigate(props: NavigateProps) {
  const { workspaceStore } = useStore();
  return (
    <BaseNavigate
      {...(props as any)}
      to={workspacePath(props.to, workspaceStore.currentWorkspace)}
    />
  );
}

export type { NavigateProps };
export default observer(Navigate);
