import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ArrowUpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.ArrowUp-a,.ArrowUp-b{fill:none;}.ArrowUp-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.55px;}`}</style>
      </defs>
      <g transform="translate(-2867 -1564)">
        <rect
          className="ArrowUp-a"
          width="30"
          height="30"
          transform="translate(2867 1564)"
        />
        <g transform="translate(-2.5 -2)">
          <line
            className="ArrowUp-b"
            y2="17.55"
            transform="translate(2884.25 1572.5)"
          />
          <line
            className="ArrowUp-b"
            x2="6.75"
            y2="6.75"
            transform="translate(2884.25 1572.5)"
          />
          <line
            className="ArrowUp-b"
            x1="6.75"
            y2="6.75"
            transform="translate(2877.5 1572.5)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ArrowUpIcon;
