import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function ArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.ArrowRight-a,.ArrowRight-b{fill:none;}.ArrowRight-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.55px;}`}</style>
      </defs>
      <g transform="translate(-2943 -1564)">
        <rect
          className="ArrowRight-a"
          width="30"
          height="30"
          transform="translate(2943 1564)"
        />
        <g transform="translate(4539.025 -1304.975) rotate(90)">
          <line
            className="ArrowRight-b"
            y2="17.55"
            transform="translate(2884.25 1572.5)"
          />
          <line
            className="ArrowRight-b"
            x2="6.75"
            y2="6.75"
            transform="translate(2884.25 1572.5)"
          />
          <line
            className="ArrowRight-b"
            x1="6.75"
            y2="6.75"
            transform="translate(2877.5 1572.5)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default ArrowRightIcon;
