import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function LogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 1865.149 1865.149">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.134"
          y1="0.137"
          x2="1.468"
          y2="1.503"
        >
          <stop offset="0" stop-color="#5d00ff" />
          <stop offset="1" stop-color="#49dcd7" />
        </linearGradient>
        <filter
          id="Subtraction_8"
          x="437.678"
          y="433.422"
          width="988.885"
          height="1000.546"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur stdDeviation="6" result="blur" />
          <feFlood flood-opacity="0.302" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_2727" data-name="Group 2727" transform="translate(0 0)">
        <path
          id="Union_14"
          data-name="Union 14"
          d="M932.575,1864.776c-26.728.555-332.982,5.655-506.341-48.066-181.37-56.2-312.647-174.124-374.064-360.164C-6.585,1278.558-.14,959.827.528,932.575-.14,905.323-6.585,586.592,52.17,408.6,113.584,222.564,244.86,104.643,426.234,48.44,599.6-5.285,905.881-.181,932.575.374c26.693-.555,332.971-5.659,506.342,48.066,181.373,56.2,312.65,174.124,374.063,360.163,58.756,177.989,52.31,496.719,51.643,523.971.667,27.252,7.113,345.986-51.643,523.975-61.413,186.036-192.69,303.96-374.063,360.163-140.47,43.527-368.2,48.437-463.774,48.437C952.766,1865.15,937.646,1864.881,932.575,1864.776Z"
          transform="translate(0 0)"
          fill="url(#linear-gradient)"
        />
        <g
          id="Group_2729"
          data-name="Group 2729"
          transform="translate(455.678 279.679)"
        >
          <path
            id="Rectangle_1227"
            data-name="Rectangle 1227"
            d="M5,0H87.025a150,150,0,0,1,150,150V649.19a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
            transform="translate(239.636 1308.381) rotate(180)"
            fill="#49dcd7"
          />
          <g
            transform="matrix(1, 0, 0, 1, -455.68, -279.68)"
            filter="url(#Subtraction_8)"
          >
            <path
              id="Subtraction_8-2"
              data-name="Subtraction 8"
              d="M476.442,964.547a470.52,470.52,0,0,1-266.384-82.364A482.873,482.873,0,0,1,9.679,579.468a492.821,492.821,0,0,1,0-194.39A482.641,482.641,0,0,1,210.058,82.365,474.692,474.692,0,0,1,290.989,37.9a471.817,471.817,0,0,1,370.906,0,474.713,474.713,0,0,1,80.931,44.465,482.874,482.874,0,0,1,200.38,302.714,492.805,492.805,0,0,1,0,194.39,482.642,482.642,0,0,1-200.38,302.714,474.725,474.725,0,0,1-80.931,44.465,469.985,469.985,0,0,1-185.453,37.9Zm0-741.25c-126.913,0-230.165,115.661-230.165,257.827S349.529,738.948,476.442,738.948s230.165-115.66,230.165-257.825S603.356,223.3,476.442,223.3Z"
              transform="translate(455.68 451.42)"
              fill="#fff"
            />
          </g>
          <path
            id="Rectangle_1228"
            data-name="Rectangle 1228"
            d="M5,0H87.025a150,150,0,0,1,150,150V654.19a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
            transform="translate(2.611 0)"
            fill="#fff"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default LogoIcon;
