import { MiniProjectSchema } from 'api/generated/models';
import paths from 'paths';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme';
import Tag from '../elements/Tag';
import LockIcon from '../icons/LockIcon';
import Link from '../Router/Link';

const Wrapper = styled(Link)`
  cursor: pointer;
  color: var(--color-primary);
  svg {
    vertical-align: middle;
    margin-right: 5px;
    font-size: 14px;
    color: var(--color-light-grey);
  }
`;

interface MiniProjectDisplayProps {
  project: MiniProjectSchema;
}

function MiniProjectDisplay(props: MiniProjectDisplayProps) {
  return (
    <Wrapper to={paths.projects.details(props.project)}>
      <Tag color={COLORS.primary} pointer>
        {props.project.is_private && <LockIcon />}
        {props.project.name}
      </Tag>
    </Wrapper>
  );
}

export default MiniProjectDisplay;
