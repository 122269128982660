import React from 'react';
import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;

  > *:only-child {
    margin: 0 auto;
  }
`;

export const ModalTitle = styled.div`
  margin-bottom: 30px;
  padding-right: 40px;
`;

const ModalContentWrapper = styled.div`
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  margin: -20px;
  margin-left: -40px;
  margin-right: unset;

  max-height: calc(100vh - 250px);
`;

export function ModalContent(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <ModalContentWrapper {...(props as any)} className="vertical-scroll" />
  );
}
