import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { Emoji } from 'emoji-mart';
import { css } from 'emotion';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import styled from 'styled-components';
import BigTitle from '../elements/BigTitle';
import Description from '../elements/Description';
import LoadingRoundedButton from '../elements/LoadingRoundedButton';
import RoundedTextField from '../elements/RoundedTextField';
import CenteredContent from '../layouts/CenteredContent';
import InviteOnBoardingModal from './InviteOnBoarding';
import VideoOnBoarding from './VideoOnBoarding';

const InitForm = styled.form`
  width: 400px;
  max-width: 100%;

  h1 {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * {
    margin-bottom: 30px;
  }
`;

const PHONE_NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

function isValidPhoneNumber(value: string) {
  return PHONE_NUMBER_REGEX.test(value) && value.length >= 8;
}

interface ProfileOnBoardingProps {}

function ProfileOnBoarding(props: ProfileOnBoardingProps) {
  const { workspaceStore, uiStore, authStore } = useStore();

  const profile = useCurrentProfile();
  const previousPhone = authStore.currentUser.phone || '';

  const [name, setName] = useState(
    profile.name.indexOf('@') === -1 ? profile.name : '',
  );
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const isInit = !!params.get('isInit');

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    if (!previousPhone && phone) {
      await authStore.updateCurrentUser({
        phone,
      });
    }

    await workspaceStore.updateCurrentProfile({
      name,
      onboarding: true,
    });

    window.Intercom('boot', {
      app_id: window.intercomSettings?.app_id,
      email: profile.email,
      name: name,
      created_at: profile.date_created.toMillis(),
      user_id: profile.id,
    });

    uiStore.popSide();

    if (isInit) {
      uiStore.pushSide(<InviteOnBoardingModal />);
    } else {
      uiStore.pushSide(<VideoOnBoarding />);
    }

    setLoading(false);
    return false;
  }

  const valid = name.length > 0 && checked;

  return (
    <CenteredContent width="var(--center-width)">
      <InitForm onSubmit={onSubmit}>
        <Header>
          <div>
            <Emoji size={64} emoji={t('workspaces.init.emoji')} set="apple" />
          </div>
          <BigTitle>{t('workspaces.init.title')}</BigTitle>
          <Description>{t('workspaces.init.description')}</Description>
        </Header>
        <Box mt={2}>
          <RoundedTextField
            autoFocus
            label={t('workspaces.init.username.label')}
            type="text"
            margin="normal"
            placeholder={t('workspaces.init.username.placeholder')}
            value={name}
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        {isInit && !isValidPhoneNumber(previousPhone) && (
          <Box mt={2} mb={2}>
            <RoundedTextField
              label={t('workspaces.init_admin.phone.label')}
              type="tel"
              autoComplete="tel"
              fullWidth
              margin="normal"
              placeholder={t('workspaces.init_admin.phone.placeholder')}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Box>
        )}
        <Box mt={2} mb={2} pl={2}>
          <FormControlLabel
            value={checked}
            onChange={(_, checked) => setChecked(checked)}
            control={
              <Checkbox
                color="primary"
                style={{
                  marginTop: '-3px',
                  padding: '5px',
                  marginRight: '5px',
                }}
              />
            }
            classes={{
              root: css`
                &&& {
                  align-items: flex-start;
                }
              `,
              label: css`
                text-align: left;
              `,
            }}
            label={
              <Trans i18nKey="workspaces.init.terms">
                $1
                <a
                  href="https://www.bloomup.io/cgu" // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  $2
                </a>
                $3
              </Trans>
            }
          />
        </Box>
        <LoadingRoundedButton
          loading={loading}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={!valid}
          fullWidth
        >
          {t('workspaces.init.submit')}
        </LoadingRoundedButton>
      </InitForm>
    </CenteredContent>
  );
}

export default ProfileOnBoarding;
