import api from 'api';
import { ResourceCreateSchema } from 'api/generated/models';
import { computed, observable, runInAction } from 'mobx';
import { Resource } from 'models/resources';

export class ResourceStore {
  public resourcesByID = observable.map<number, Resource>();

  public async loadResource(id: number) {
    const found = this.resourcesByID.get(id);
    if (found) return found;

    const { data } = await api.resources.getResource(id);
    const resource = new Resource(data);
    runInAction(() => {
      this.resourcesByID.set(id, resource);
    });
    return resource;
  }

  public async loadGlobalResources() {
    const { data } = await api.resources.getGlobalResources();
    runInAction(() => {
      data.forEach((resourceData) => {
        const resource = new Resource(resourceData);
        this.resourcesByID.set(resource.id, resource);
      });
    });
  }

  public async loadResources() {
    const { data } = await api.resources.getResources();
    runInAction(() => {
      data.forEach((resourceData) => {
        const resource = new Resource(resourceData);
        this.resourcesByID.set(resource.id, resource);
      });
    });
  }

  public async createResource(newData: ResourceCreateSchema) {
    const { data } = await api.resources.createResource(newData);
    const resource = new Resource(data);
    runInAction(() => {
      this.resourcesByID.set(resource.id, resource);
    });
    return resource;
  }

  public async deleteResource(resource: Resource) {
    await api.resources.deleteResource(resource.id);
    runInAction(() => {
      this.resourcesByID.delete(resource.id);
    });
  }

  public reset() {
    this.resourcesByID.clear();
  }

  @computed
  get resources() {
    return Array.from(this.resourcesByID.values()).filter((c) => !c.is_global);
  }

  @computed
  get globalResources() {
    return Array.from(this.resourcesByID.values()).filter((c) => c.is_global);
  }
}
