import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function BackIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Back-a,.Back-b{fill:none;}.Back-b{stroke:currentColor;stroke-linecap:round;stroke-width:3.55px;}`}</style>
      </defs>
      <g transform="translate(-3057 -1564)">
        <rect
          className="Back-a"
          width="30"
          height="30"
          transform="translate(3057 1564)"
        />
        <g transform="translate(6144.525 3157.576) rotate(180)">
          <line
            className="Back-b"
            x2="8.525"
            y2="8.525"
            transform="translate(3068 1587.576) rotate(-90)"
          />
          <line
            className="Back-b"
            x1="8.525"
            y2="8.525"
            transform="translate(3068 1579.05) rotate(-90)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default BackIcon;
