import { Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { CustomCardSchema } from 'api/generated/models';
import CrossIcon from 'components/icons/CrossIcon';
import SearchIcon from 'components/icons/SearchIcon';
import CardDisplay from 'components/projects/CardDisplay';
import ProjectDisplay from 'components/projects/ProjectDisplay';
import TemplateCard from 'components/project_templates/TemplateCard';
import ResourceSearchDisplay from 'components/resources/ResourceSearchDisplay';
import { observer } from 'mobx-react-lite';
import { Project } from 'models/projects';
import { Resource } from 'models/resources';
import { Template } from 'models/templates';
import paths from 'paths';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import { COLORS } from 'theme';
import useNavigate from 'utils/useNavigate';

const Wrapper = styled(Paper)<{ focused?: boolean }>`
  position: fixed;
  width: 100%;
  max-width: ${(p) => (p.focused ? 800 : 400)}px;

  will-change: max-width, border-radius;
  transition: max-width 0.3s, border-radius 0.3s;

  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  overflow: hidden;

  z-index: 1001;
  background-color: ${(p) =>
    p.focused ? 'var(--color-white)' : 'var(--color-bg)'};
  border-radius: ${(p) => (p.focused ? '8px' : '16px')};
  box-shadow: var(--shadow-sm);
`;

const Overlay = styled.div<{ focused?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: ${(p) => (p.focused ? '100%' : 0)};
  width: ${(p) => (p.focused ? '100%' : 0)};
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: ${(p) => (p.focused ? 1 : 0)};
  transition: opacity 0.1s;
`;

const Results = styled.div<{ focused?: boolean }>`
  overflow-y: auto;
  max-width: 100%;
  border-top: 1px solid var(--color-lighter-grey);
  opacity: ${(p) => (p.focused ? 1 : 0)};
  max-height: ${(p) => (p.focused ? '500px' : 0)};
  transition: opacity 0.1s, max-height 0.1s;
`;

const InputWrapper = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(p) => (p.focused ? '10px' : '5px')};
  transition: padding 0.1s;

  input {
    flex-grow: 1;
    resize: none;
    display: inline-block;
    font-size: 16px;
    white-space: nowrap;
    color: var(--color-secondary);
    appearance: none;
    border: none;
    padding: 0;
    outline: 0;
    background-color: transparent;
    font-family: Roboto;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg);
  padding: 16px;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const RecentSearch = styled(Typography)`
  color: var(--color-border-grey);
  cursor: pointer;
  border-radius: 8px;
  padding: 2px 3px;
  margin-top: 1px;

  &:hover {
    background-color: var(--color-lighter-grey);
  }

  svg {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 3px;
  }
`;

const Tag = styled.div`
  display: inline-block;
  padding: 2px 7px;
  color: var(--color-primary);
  background-color: var(--color-primary-hover);
  border-radius: 16px;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;

  svg {
    vertical-align: middle;
    font-size: 12px;
  }
`;

const NotFound = styled(Typography)`
  color: var(--color-light-grey);
`;

const CustomTab = styled(Tab)`
  padding: 6px 8px;
  min-height: 30px;
  text-transform: none;
  font-weight: 400;
`;

const CustomTabs = styled(Tabs)`
  min-height: 30px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--color-white);
  box-shadow: var(--shadow-xs);
`;

const TemplatesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    min-width: 350px;
    max-width: 350px;
    margin: 10px;
  }
`;

function sizeOf<T>(array: T[]) {
  if (array.length === 0) return '';
  return `(${array.length})`;
}

type SearchType = 'projects' | 'cards' | 'resources' | 'templates';

interface SearchBarProps {}

function SearchBar(props: SearchBarProps) {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { searchStore } = useStore();
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const [type, setType] = useState<SearchType | null>(null);
  const [selectedType, setSelectedType] = useState<SearchType>('projects');

  const [resources, setResources] = useState<Resource[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [cards, setCards] = useState<CustomCardSchema[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);

  async function search(value: string, searchType: SearchType) {
    if (value.length === 0) return;
    setLoading(true);
    setResources([]);
    setProjects([]);
    setCards([]);
    setTemplates([]);

    const searchPromises = {
      resources: async function () {
        setResources(await searchStore.searchResources(value));
      },
      projects: async function () {
        setProjects(await searchStore.searchProjects(value));
      },
      cards: async function () {
        setCards(await searchStore.searchCards(value));
      },
      templates: async function () {
        setTemplates(await searchStore.searchTemplates(value));
      },
    };

    let searchTypes: SearchType[] = [
      'projects',
      'cards',
      'templates',
      'resources',
    ];

    const nextPromises = searchTypes
      .filter((c) => c !== searchType)
      .map((c) => searchPromises[c]);

    const mainPromise = searchPromises[searchType];
    await mainPromise();
    setLoaded(true);
    setLoading(false);

    nextPromises.forEach((c) => c());
  }

  function reset() {
    setFocused(false);
    setResources([]);
    setProjects([]);
    setCards([]);
    setTemplates([]);
    setValue('');
    setType(null);
    setSelectedType('projects');
    setLoaded(false);
  }

  return (
    <>
      <Overlay onClick={reset} focused={focused} />
      <Wrapper focused={focused}>
        <InputWrapper focused={focused}>
          <SearchIcon style={{ marginRight: '5px' }} />
          {type && (
            <Tag onClick={() => setType(null)}>
              {t(`search.types.${type}`)} <CrossIcon />
            </Tag>
          )}
          <input
            maxLength={30}
            readOnly={loading}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                search(value, type || selectedType || 'projects');
                setSelectedType(type || selectedType || 'projects');
              }
            }}
            placeholder={t('commons.search')}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setFocused(true)}
          />
          <CrossIcon
            style={{ opacity: focused ? 1 : 0, cursor: 'pointer' }}
            onClick={reset}
          />
        </InputWrapper>
        <Results className="vertical-scroll" focused={focused}>
          {!loaded && (
            <OptionsWrapper>
              {!type && (
                <div style={{ marginBottom: '15px' }}>
                  <Typography
                    variant="caption"
                    style={{ color: COLORS.lightGrey }}
                  >
                    {t('search.looking_for')}
                  </Typography>
                  <Tag
                    onClick={() => setType('projects')}
                    style={{ marginLeft: '5px' }}
                  >
                    {t('search.types.projects')}
                  </Tag>
                  <Tag onClick={() => setType('cards')}>
                    {t('search.types.cards')}
                  </Tag>
                  <Tag onClick={() => setType('resources')}>
                    {t('search.types.resources')}
                  </Tag>
                  <Tag onClick={() => setType('templates')}>
                    {t('search.types.templates')}
                  </Tag>
                </div>
              )}
              {searchStore.recentSearch && searchStore.recentSearch.length > 0 && (
                <Typography
                  variant="caption"
                  style={{ color: COLORS.lightGrey }}
                >
                  {t('search.recent_search')}
                </Typography>
              )}
              {searchStore.recentSearch.map((v) => (
                <RecentSearch
                  onClick={() => {
                    setValue(v);
                    setSelectedType(type || 'projects');
                    search(v, type || 'projects');
                  }}
                >
                  <SearchIcon />
                  {v}
                </RecentSearch>
              ))}
            </OptionsWrapper>
          )}
          {loaded && (
            <>
              <CustomTabs
                value={selectedType}
                onChange={(e, newType) => {
                  setType(null);
                  setSelectedType(newType as SearchType);
                }}
                indicatorColor="primary"
                textColor="primary"
              >
                <CustomTab
                  value="projects"
                  label={`${t('search.types.projects')} ${sizeOf(projects)}`}
                />
                <CustomTab
                  value="cards"
                  label={`${t('search.types.cards')} ${sizeOf(cards)}`}
                />
                <CustomTab
                  value="resources"
                  label={`${t('search.types.resources')} ${sizeOf(resources)}`}
                />
                <CustomTab
                  value="templates"
                  label={`${t('search.types.templates')} ${sizeOf(templates)}`}
                />
              </CustomTabs>
              {!loading && (
                <ResultsWrapper>
                  {selectedType === 'projects' && (
                    <>
                      {projects.length !== 0 ? (
                        projects.map((project) => (
                          <ProjectDisplay project={project} onClick={reset} />
                        ))
                      ) : (
                        <NotFound>{t('search.no_results')}</NotFound>
                      )}
                    </>
                  )}
                  {selectedType === 'resources' && (
                    <>
                      {resources.length !== 0 ? (
                        resources.map((resource) => (
                          <ResourceSearchDisplay
                            resource={resource}
                            onClick={reset}
                          />
                        ))
                      ) : (
                        <NotFound>{t('search.no_results')}</NotFound>
                      )}
                    </>
                  )}
                  {selectedType === 'cards' && (
                    <>
                      {cards.length !== 0 ? (
                        cards.map((card) => (
                          <CardDisplay card={card} onClick={reset} />
                        ))
                      ) : (
                        <NotFound>{t('search.no_results')}</NotFound>
                      )}
                    </>
                  )}
                  {selectedType === 'templates' && (
                    <>
                      {templates.length !== 0 ? (
                        <TemplatesWrapper>
                          {templates.map((template) => (
                            <TemplateCard
                              template={template}
                              onClick={() => {
                                navigate(
                                  paths.project_templates.details(template),
                                );
                                reset();
                              }}
                            />
                          ))}
                        </TemplatesWrapper>
                      ) : (
                        <NotFound>{t('search.no_results')}</NotFound>
                      )}
                    </>
                  )}
                </ResultsWrapper>
              )}
            </>
          )}
        </Results>
      </Wrapper>
    </>
  );
}

export default observer(SearchBar);
