import { CardStatus } from 'api/generated/models';
import BloomupEmoji from 'components/elements/BloomupEmoji';
import React from 'react';

interface ProjectCardStatusIconProps {
  status?: string;
  icon?: string;
  statusList?: CardStatus[];
  size?: number;
  withDefault?: boolean;
}

function ProjectCardStatusIcon(props: ProjectCardStatusIconProps) {
  const icon =
    props.icon ||
    (props.statusList || []).find((c) => c.key === props.status)?.icon;

  if (!icon) {
    if (props.withDefault) {
      return <BloomupEmoji emoji=":exclamation:" size={props.size || 25} />;
    }
    return null;
  }

  return <BloomupEmoji emoji={icon} size={props.size || 25} />;
}

export default ProjectCardStatusIcon;
