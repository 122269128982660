import { TableCell, TableRow } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { WorkspaceInvitationSchema } from 'api/generated/models';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import { isAdmin } from 'utils/isAdmin';
import ColoredIconButton from '../elements/ColoredIconButton';
import InfoButton from '../elements/InfoButton';
import Tooltip from '../elements/Tooltip';
import DeleteIcon from '../icons/DeleteIcon';
import { TableConfig } from '../Table/types';

const FloatingActions = styled.div`
  position: absolute;
  right: 20px;
  margin-top: 7px;
  opacity: 0;
  transition: opacity 0.1s;
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }
`;

const Wrapper = styled(TableRow)<{ index: number }>`
  &:hover,
  &.force {
    ${FloatingActions} {
      opacity: 1;
    }
  }
  .resend-button {
    transition: transform 1s;
    &.resent {
      transform: rotate(360deg);
    }
  }
`;

const RoleWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--color-light-grey);
  }
`;

interface InvitationTableRowProps {
  item: WorkspaceInvitationSchema;
  config: TableConfig;
  index: number;
}

function InvitationTableRow(props: InvitationTableRowProps) {
  const workspace = useCurrentWorkspace();
  const { workspaceStore } = useStore();
  const { t } = useTranslation();
  const [resent, setResent] = useState(false);
  const [hideResend, setHideResend] = useState(false);

  const admin = isAdmin(props.item);

  return (
    <Wrapper
      index={props.index}
      className={resent && !hideResend ? 'force' : ''}
    >
      <TableCell>{props.item.email}</TableCell>
      <TableCell>
        {props.item.date_created.toLocaleString(DateTime.DATE_MED)}
      </TableCell>
      <TableCell style={{ paddingRight: '70px' }}>
        <RoleWrapper>
          {admin ? t('members.roles.admin') : t('members.roles.member')}
          <InfoButton
            tooltip={
              admin
                ? t('members.roles.admin_tooltip')
                : t('members.roles.member_tooltip')
            }
          />
        </RoleWrapper>
      </TableCell>
      {workspaceStore.isAdmin && (
        <FloatingActions>
          {!hideResend && (
            <Tooltip title={t('invitations.resend')}>
              <ColoredIconButton
                onClick={() => {
                  if (resent) return;
                  workspace.resendInvitation(props.item);
                  setResent(true);
                  setTimeout(() => {
                    setHideResend(true);
                  }, 1000);
                }}
              >
                <Refresh
                  className={`resend-button ${resent ? 'resent' : ''}`}
                />
              </ColoredIconButton>
            </Tooltip>
          )}
          <Tooltip title={t('invitations.remove')}>
            <ColoredIconButton
              withConfirm
              iconColor={COLORS.error}
              onClick={() => workspace.deleteInvitation(props.item)}
            >
              <DeleteIcon />
            </ColoredIconButton>
          </Tooltip>
        </FloatingActions>
      )}
    </Wrapper>
  );
}

export default observer(InvitationTableRow);
