import { MenuItem, TableCell, TableRow } from '@material-ui/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { Profile, Role } from 'models/profiles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import styled from 'styled-components';
import { COLORS } from 'theme';
import { isAdmin } from 'utils/isAdmin';
import Avatar from '../Avatar';
import ColoredIconButton from '../elements/ColoredIconButton';
import InfoButton from '../elements/InfoButton';
import Menu from '../elements/Menu';
import Opener from '../elements/Opener';
import Tooltip from '../elements/Tooltip';
import DeleteIcon from '../icons/DeleteIcon';
import DownIcon from '../icons/DownIcon';
import { TableConfig } from '../Table/types';

const FloatingActions = styled.div`
  position: absolute;
  right: 20px;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.1s;
  display: flex;
  align-items: center;

  > * {
    margin-left: 5px;
  }
`;

const Wrapper = styled(TableRow)<{ index: number }>`
  &:hover {
    ${FloatingActions} {
      opacity: 1;
    }
  }
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

const RoleSelector = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  padding-right: 40px;

  > svg {
    margin-left: 6px;
  }
`;

interface ProfileTableRowProps {
  item: Profile;
  config: TableConfig;
  index: number;
}

function ProfileTableRow(props: ProfileTableRowProps) {
  const { profileStore, workspaceStore } = useStore();
  const profile = useCurrentProfile();
  const { t } = useTranslation();

  const isMe = props.item.id === profile.id;
  const admin = isAdmin(props.item);

  return (
    <Wrapper index={props.index}>
      <TableCell>
        <Name>
          <Avatar profile={props.item} />
          {props.item.name}
        </Name>
      </TableCell>
      <TableCell>{props.item.email}</TableCell>
      <TableCell>
        {props.item.date_created.toLocaleString(DateTime.DATE_MED)}
      </TableCell>
      <TableCell>
        {isMe || !workspaceStore.isAdmin ? (
          <RoleSelector>
            {admin ? t('members.roles.admin') : t('members.roles.member')}
            <InfoButton
              style={{ color: 'var(--color-light-grey)' }}
              tooltip={
                admin
                  ? t('members.roles.admin_tooltip')
                  : t('members.roles.member_tooltip')
              }
            />
          </RoleSelector>
        ) : (
          <Opener
            content={(contentProps) => (
              <Menu
                anchorEl={contentProps.anchorEl}
                open={Boolean(contentProps.anchorEl)}
                onClose={contentProps.close}
              >
                <MenuItem
                  onClick={() => {
                    profileStore.updateProfile(props.item, {
                      role: Role.ADMINISTRATOR as number,
                    });
                    contentProps.close();
                  }}
                >
                  {t('members.roles.admin')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    profileStore.updateProfile(props.item, {
                      role: Role.MEMBER as number,
                    });
                    contentProps.close();
                  }}
                >
                  {t('members.roles.member')}
                </MenuItem>
              </Menu>
            )}
          >
            <RoleSelector>
              {admin ? t('members.roles.admin') : t('members.roles.member')}
              <InfoButton
                style={{ color: 'var(--color-light-grey)' }}
                tooltip={
                  admin
                    ? t('members.roles.admin_tooltip')
                    : t('members.roles.member_tooltip')
                }
              />
              <DownIcon color="primary" />
            </RoleSelector>
          </Opener>
        )}
      </TableCell>
      <FloatingActions>
        {!isMe && workspaceStore.isAdmin && (
          <Tooltip title={t('members.remove')}>
            <ColoredIconButton
              withConfirm
              iconColor={COLORS.error}
              onClick={() => profileStore.deleteProfile(props.item)}
            >
              <DeleteIcon />
            </ColoredIconButton>
          </Tooltip>
        )}
      </FloatingActions>
    </Wrapper>
  );
}

export default observer(ProfileTableRow);
