/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { UploadFileSchema } from '../models';
/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Upload Url
         * @param {string} filename 
         * @param {number} size 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl: async (filename: string, size: number, lang?: string, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            if (filename === null || filename === undefined) {
                throw new RequiredError('filename','Required parameter filename was null or undefined when calling getUploadUrl.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getUploadUrl.');
            }
            const localVarPath = `/files/upload_url`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload File
         * @param {any} file 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: any, xWorkspace?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFile.');
            }
            const localVarPath = `/files/upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (xWorkspace !== undefined && xWorkspace !== null) {
                localVarHeaderParameter['x-workspace'] = String(xWorkspace);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Upload Url
         * @param {string} filename 
         * @param {number} size 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadUrl(filename: string, size: number, lang?: string, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileSchema>> {
            const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).getUploadUrl(filename, size, lang, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Upload File
         * @param {any} file 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: any, xWorkspace?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await FilesApiAxiosParamCreator(configuration).uploadFile(file, xWorkspace, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Upload Url
         * @param {string} filename 
         * @param {number} size 
         * @param {string} [lang] 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadUrl(filename: string, size: number, lang?: string, xWorkspace?: string, options?: any): AxiosPromise<UploadFileSchema> {
            return FilesApiFp(configuration).getUploadUrl(filename, size, lang, xWorkspace, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload File
         * @param {any} file 
         * @param {string} [xWorkspace] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: any, xWorkspace?: string, options?: any): AxiosPromise<string> {
            return FilesApiFp(configuration).uploadFile(file, xWorkspace, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @summary Get Upload Url
     * @param {string} filename 
     * @param {number} size 
     * @param {string} [lang] 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getUploadUrl(filename: string, size: number, lang?: string, xWorkspace?: string, options?: any) {
        return FilesApiFp(this.configuration).getUploadUrl(filename, size, lang, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload File
     * @param {any} file 
     * @param {string} [xWorkspace] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public uploadFile(file: any, xWorkspace?: string, options?: any) {
        return FilesApiFp(this.configuration).uploadFile(file, xWorkspace, options).then((request) => request(this.axios, this.basePath));
    }
}
