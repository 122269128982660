import React from 'react';
import posed, { PoseGroup } from 'react-pose';

export const FadeInItem = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

interface FadeInProps {
  style?: React.CSSProperties;
  children: React.ReactElement[];
}

function FadeIn(props: FadeInProps) {
  return (
    <PoseGroup style={props.style}>
      {React.Children.map(props.children, (child) => (
        <FadeInItem key={`item-${child.key}`}>{child}</FadeInItem>
      ))}
    </PoseGroup>
  );
}

export default FadeIn;
