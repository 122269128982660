const accentMap: any = {
  á: 'a',
  â: 'a',
  ä: 'a',
  é: 'e',
  è: 'e',
  ê: 'e',
  ë: 'e',
  î: 'i',
  ï: 'i',
  í: 'i',
  ó: 'o',
  ô: 'o',
  û: 'u',
  ú: 'u',
};

export default function toSearchString(s: string) {
  if (!s) {
    return '';
  }
  const fixedSearch = s.toLocaleLowerCase();
  let ret = '';
  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < fixedSearch.length; i++) {
    ret += accentMap[fixedSearch.charAt(i)] || fixedSearch.charAt(i);
  }
  return ret;
}
