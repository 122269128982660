import { NEXT_KEY } from 'config';
import paths from 'paths';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Navigate from './Navigate';

interface RedirectToSigninProps {}

function RedirectToSignin(props: RedirectToSigninProps) {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (pathname.startsWith(paths.auth.signout)) return;
    if (pathname === '/') return;
    localStorage.setItem(NEXT_KEY, pathname + search);
  }, [pathname, search]);

  return <Navigate to={paths.auth.signin} replace />;
}

export default RedirectToSignin;
