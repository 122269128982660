import { Language } from 'api/generated/models';
import { LANGUAGE_KEY } from 'config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';
import { initReactI18next } from 'react-i18next';
import { store } from 'stores';
import enTranslations from './locales/en/translation.json';
import frTranslations from './locales/fr/translation.json';
import './translations';

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'user',
  lookup() {
    if (!store || !store.authStore.authenticated) {
      const params = new URLSearchParams(document.location.search.substring(1));
      return (
        params.get('lang') || localStorage.getItem(LANGUAGE_KEY) || undefined
      );
    }
    return store.authStore.currentUser.language;
  },
  cacheUserLanguage(language) {
    Settings.defaultLocale = language;
    if (!store || !store.authStore.authenticated) {
      return localStorage.setItem(LANGUAGE_KEY, language);
    }
    if (language === store.authStore.currentUser.language) return;
    store.authStore.updateCurrentUser({
      language: language as Language,
    });
  },
});

const resources = {
  fr: {
    translation: frTranslations,
  },
  en: {
    translation: enTranslations,
  },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    detection: {
      order: ['user', 'navigator'],
      caches: ['user'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
