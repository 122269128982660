import { Box } from '@material-ui/core';
import BackLink from 'components/elements/BackLink';
import RoundedButton from 'components/elements/RoundedButton';
import Title from 'components/elements/Title';
import AddIcon from 'components/icons/AddIcon';
import AddPeopleIcon from 'components/icons/AddPeopleIcon';
import TeamIcon from 'components/icons/TeamIcon';
import CenteredContent from 'components/layouts/CenteredContent';
import InvitationTableRow from 'components/profiles/InvitationTableRow';
import ProfileTableRow from 'components/profiles/ProfileTableRow';
import Table from 'components/Table';
import { TableConfig } from 'components/Table/types';
import FlexSeparator from 'components/utils/FlexSeparator';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import InviteMemberModal from './InviteMember';
import { ModalContent, ModalFooter, ModalTitle } from '../styles';

const CONFIG: TableConfig = {
  values: [
    {
      key: 'name',
      name: 'Name',
      type: 'text',
      sortable: true,
    },

    {
      key: 'email',
      name: 'Email',
      type: 'text',
      sortable: true,
    },

    {
      key: 'date_created',
      name: 'Date',
      type: 'date',
      sortable: true,
    },

    {
      key: 'role',
      name: 'Role',
      type: 'text',
      sortable: true,
    },
  ],
};

const INVITATIONS_CONFIG: TableConfig = {
  values: [
    {
      key: 'email',
      name: 'Email',
      type: 'text',
      sortable: true,
    },

    {
      key: 'date_created',
      name: 'Date',
      type: 'date',
      sortable: true,
    },

    {
      key: 'role',
      name: 'Role',
      type: 'text',
      sortable: true,
    },
  ],
};

interface MembersModalProps {}

function MembersModal(props: MembersModalProps) {
  const { profileStore, uiStore, workspaceStore } = useStore();
  const { t } = useTranslation();
  const workspace = useCurrentWorkspace();

  useEffect(() => {
    workspace.loadInvitations();
  }, [workspace, workspaceStore]);

  const profiles = profileStore.profiles;

  return (
    <CenteredContent width="var(--md-center-width)">
      <ModalTitle>
        <Title>
          <TeamIcon />
          {t('members.manage.title')}
          <FlexSeparator />
          <RoundedButton
            color="primary"
            variant="contained"
            onClick={() => uiStore.pushSide(<InviteMemberModal />)}
          >
            <AddIcon />
            {t('members.manage.invite')}
          </RoundedButton>
        </Title>
      </ModalTitle>
      <ModalContent>
        <Table
          smallHeading
          config={CONFIG}
          items={profiles}
          rounded
          rowComponent={ProfileTableRow}
        />
        {workspace.invitations.length > 0 && (
          <Box mt={5}>
            <Title>
              <AddPeopleIcon />
              {t('members.invitations.title')}
            </Title>
            <Box mt={2}>
              <Table
                smallHeading
                config={INVITATIONS_CONFIG}
                items={workspace.invitations}
                rounded
                rowComponent={InvitationTableRow}
              />
            </Box>
          </Box>
        )}
      </ModalContent>
      <ModalFooter>
        <BackLink popSide />
      </ModalFooter>
    </CenteredContent>
  );
}

export default observer(MembersModal);
