import { Paper, Typography } from '@material-ui/core';
import BloomupEmoji from 'components/elements/BloomupEmoji';
import ColoredIconButton from 'components/elements/ColoredIconButton';
import InfoButton from 'components/elements/InfoButton';
import Tooltip from 'components/elements/Tooltip';
import AddIcon from 'components/icons/AddIcon';
import ProjectTemplateIcon from 'components/icons/ProjectTemplateIcon';
import FlexSeparator from 'components/utils/FlexSeparator';
import { AVATAR_COLORS } from 'config';
import { observer } from 'mobx-react-lite';
import NewProjectModal from 'modals/projects/New';
import { Template } from 'models/templates';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import { COLORS } from 'theme';
import stopPropagation from 'utils/stopPropagation';

const Wrapper = styled(Paper)`
  border-radius: 8px;
  box-shadow: var(--shadow-sm);
  cursor: pointer;

  &:hover {
    box-shadow: var(--shadow-md);
  }
`;

const Infos = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
`;

const ImageOuter = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  color: white;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const Name = styled(Typography)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Label = styled.div`
  margin-right: 10px;
  height: 25px;
  width: 25px;
`;

interface TemplateCardProps {
  template: Template;
  onDelete?: () => void;
  isSelection?: boolean;
  onClick?: () => void;
}

function TemplateCard(props: TemplateCardProps) {
  const { uiStore } = useStore();
  const { t } = useTranslation();

  return (
    <Wrapper onClick={props.onClick}>
      <ImageOuter>
        <ImageWrapper
          style={{
            backgroundImage: props.template.image
              ? `url("${props.template.image}")`
              : undefined,
            backgroundColor: props.template.image
              ? undefined
              : AVATAR_COLORS[props.template.id % AVATAR_COLORS.length],
          }}
        >
          {!props.template.image && (
            <ProjectTemplateIcon style={{ fontSize: '40px' }} />
          )}
        </ImageWrapper>
      </ImageOuter>
      <Infos>
        {props.template.label && (
          <Label>
            <BloomupEmoji size={25} emoji={props.template.label} />
          </Label>
        )}
        <Name>
          {props.template.name}
          {props.template.description.length > 0 && (
            <InfoButton
              tooltip={props.template.description}
              style={{ marginTop: '-2px', color: COLORS.lightGrey }}
            />
          )}
        </Name>
        <FlexSeparator />
        <Tooltip
          title={
            props.isSelection
              ? t('project_templates.list.select_template')
              : t('project_templates.list.use_template')
          }
        >
          <ColoredIconButton
            style={{ marginLeft: '5px' }}
            iconColor={COLORS.white}
            bgColor={COLORS.primary}
            onClick={(e) => {
              uiStore.pushSide(<NewProjectModal template={props.template} />);
              return stopPropagation(e);
            }}
          >
            <AddIcon />
          </ColoredIconButton>
        </Tooltip>
      </Infos>
    </Wrapper>
  );
}

export default observer(TemplateCard);
