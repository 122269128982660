import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function FilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Filter-a{fill:none;}.Filter-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2752 -1619)">
        <g transform="translate(2729.259 1653.221)">
          <path
            className="Filter-b"
            d="M35.022-10.024l-.35-.32-.047-3.961-.047-3.961L31.244-22.3c-2.036-2.465-3.379-4.169-3.446-4.375a1.15,1.15,0,0,1,.379-1.234l.285-.273,9.188-.024c8.984-.023,9.2-.02,9.488.16a1.251,1.251,0,0,1,.441,1.668c-.092.14-1.633,2.032-3.425,4.2L40.9-18.23v2.836c0,2.513-.019,2.873-.164,3.164s-.4.448-2.316,1.423c-1.833.932-2.219,1.1-2.6,1.1a.9.9,0,0,1-.8-.316ZM37.8-13.258l.552-.279.03-2.747.03-2.747.277-.367c.152-.2,1.366-1.687,2.7-3.3s2.4-2.951,2.381-2.972-2.753-.027-6.071-.014l-6.034.024,2.518,3.041c1.385,1.673,2.6,3.168,2.7,3.322.166.261.178.492.179,3.3a17.929,17.929,0,0,0,.1,3.018,3.6,3.6,0,0,0,.649-.279Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default FilterIcon;
