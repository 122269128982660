import { Input, InputProps } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from 'theme';
import CheckIcon from '../icons/CheckIcon';
import ReturnIcon from '../icons/ReturnIcon';
import ColoredIconButton from './ColoredIconButton';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 50px;

  input {
    font-size: 14px;
    padding: 0;
    flex-grow: 1;
  }

  > *:first-child {
    flex-grow: 1;
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

interface InputWithConfirmProps {
  value?: string;
  onChange: (value: string) => void;
  onClose?: () => void;
  inputProps?: InputProps;
  autoSelect?: boolean;
  width?: number;
  allowEmpty?: boolean;
}

function InputWithConfirm(props: InputWithConfirmProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(props.value || '');

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13 && value.length > 0) {
      props.onChange(value);
    }
    if (e.keyCode === 27 && props.onClose) {
      props.onClose();
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (props.autoSelect) {
        ref.current?.focus();
        ref.current?.select();
      }
    }, 50);
  }, [props.autoSelect]);

  return (
    <Wrapper>
      <Input
        {...props.inputProps}
        autoFocus
        onBlur={() => value.length > 0 && props.onChange(value)}
        style={{ maxWidth: `${props.width}px`, minWidth: `${props.width}px` }}
        disableUnderline
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        inputRef={ref}
      />
      <ColoredIconButton
        onClick={() => props.onChange(value)}
        disabled={value.length === 0 && !props.allowEmpty}
        iconColor={COLORS.success}
      >
        <CheckIcon />
      </ColoredIconButton>
      <ColoredIconButton onClick={props.onClose}>
        <ReturnIcon />
      </ColoredIconButton>
    </Wrapper>
  );
}

export default InputWithConfirm;
