import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import ProgressDisplay from './ProgressDisplay';

const Wrapper = styled(Typography)`
  display: flex;
  align-items: center;
  color: var(--color-light-grey);
`;

const Value = styled.span`
  margin-left: 7px;
  font-size: 14px;
`;

interface ProjectProgressProps {
  value: [number, number];
}

function ProjectProgress(props: ProjectProgressProps) {
  const [doneCount, maxCount] = props.value;
  const value = doneCount / (maxCount || 1);
  return (
    <Wrapper>
      <ProgressDisplay value={doneCount} max={maxCount} />
      <Value>
        {doneCount}/{maxCount} ({(value * 100).toFixed(0)}%)
      </Value>
    </Wrapper>
  );
}

export default observer(ProjectProgress);
