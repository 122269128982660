import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function HelpIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Help-a{fill:none;}.Help-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2874 -1456)">
        <rect
          className="Help-a"
          width="30"
          height="30"
          transform="translate(2874 1456)"
        />
        <g transform="translate(8.927 105)">
          <circle
            className="Help-b"
            cx="1.273"
            cy="1.273"
            r="1.273"
            transform="translate(2878.763 1371.002)"
          />
          <path
            className="Help-b"
            d="M13.037,0A13.037,13.037,0,1,0,26.073,13.037,13.03,13.03,0,0,0,13.037,0Zm0,24.036a11,11,0,1,1,11-11A10.994,10.994,0,0,1,13.037,24.036Z"
            transform="translate(2867 1353)"
          />
          <path
            className="Help-b"
            d="M180.074,128.5A4.079,4.079,0,0,0,176,132.574a1.018,1.018,0,0,0,2.037,0,2.037,2.037,0,1,1,2.037,2.037,1.018,1.018,0,0,0-1.018,1.018v2.546a1.018,1.018,0,1,0,2.037,0v-1.657a4.074,4.074,0,0,0-1.018-8.019Z"
            transform="translate(2699.963 1231.044)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default HelpIcon;
