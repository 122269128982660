import { observable } from 'mobx';

type Language = 'fr' | 'en';

export class User {
  public id: string;

  @observable
  public email: string;

  @observable
  public language: Language;

  @observable
  public phone: string;

  constructor(data: Partial<User>) {
    Object.assign(this, data);
  }

  public update(data: Partial<User>) {
    Object.assign(this, data);
  }
}
