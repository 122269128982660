import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from 'stores';
import { useCurrentProfile } from 'stores/workspaces';
import ProfileOnBoarding from './ProfileOnBoarding';

interface OnBoardingProps {}

function OnBoarding(props: OnBoardingProps) {
  const { uiStore } = useStore();
  const profile = useCurrentProfile();

  useEffect(() => {
    if (uiStore.sideStack.length > 0) return;
    if (profile.onboarding) return;

    uiStore.pushSide(<ProfileOnBoarding />, true);
  }, [uiStore, profile]);

  return null;
}

export default observer(OnBoarding);
