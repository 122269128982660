import React from 'react';
import ReconnectingWebsocket from 'utils/ReconnectingWebsocket';
import { AuthStore } from './auth';
import { CustomStore } from './custom';
import { ProfileStore } from './profiles';
import { ProjectStore } from './projects';
import { ResourceStore } from './resources';
import { SearchStore } from './search';
import { TemplateStore } from './templates';
import { UIStore } from './ui';
import { WorkspaceStore } from './workspaces';

export class RootStore {
  public socket: ReconnectingWebsocket = new ReconnectingWebsocket(
    process.env.REACT_APP_REALTIME_URL || 'ws://localhost:9000/ws/',
  );

  public authStore: AuthStore = new AuthStore(this);
  public workspaceStore: WorkspaceStore = new WorkspaceStore(this);
  public projectStore: ProjectStore = new ProjectStore(this);
  public templateStore: TemplateStore = new TemplateStore();
  public resourceStore: ResourceStore = new ResourceStore();
  public profileStore: ProfileStore = new ProfileStore(this);
  public uiStore: UIStore = new UIStore();
  public customStore: CustomStore = new CustomStore(this);
  public searchStore: SearchStore = new SearchStore(this);

  public reset(isSignout?: boolean) {
    this.workspaceStore.reset(isSignout);
    this.projectStore.reset();
    this.resourceStore.reset();
    this.templateStore.reset();
    this.profileStore.reset();
    this.uiStore.reset();
  }

  public init() {
    this.profileStore.init();
    this.customStore.init();
  }
}

const storeContext = React.createContext<RootStore | null>(null);

export const store = new RootStore();

(window as any).store = store;

export function StoreProvider(props: React.PropsWithChildren<{}>) {
  return (
    <storeContext.Provider value={store}>
      {props.children}
    </storeContext.Provider>
  );
}

export function useStore() {
  const store = React.useContext(storeContext);

  if (!store) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  return store;
}
