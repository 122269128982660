import { CircularProgress, Typography } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';
import { Plan } from 'api/generated/models';
import BillingHelp from 'components/billing/BillingHelp';
import BackLink from 'components/elements/BackLink';
import RoundedButton from 'components/elements/RoundedButton';
import Title from 'components/elements/Title';
import CenteredContent from 'components/layouts/CenteredContent';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import { useCurrentWorkspace } from 'stores/workspaces';
import styled from 'styled-components';
import { ModalContent, ModalFooter, ModalTitle } from '../styles';
import Pricing from './Pricing';

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 40px;
  }

  div {
    display: flex;
    flex-direction: column;
    width: calc(50% - 20px);
  }
`;

const SubTitle = styled(Typography)`
  color: var(--color-primary);
`;

const Value = styled(Typography)`
  font-weight: bold;
  margin-top: 10px;
  font-size: 23px;
`;

const Caption = styled(Typography)`
  color: var(--color-dark-grey);
  font-weight: 300;
  margin-top: 10px;

  .link {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px;

  p {
    text-align: center;
    margin-top: 40px;
  }
`;

interface BillingModalProps {}

function BillingModal(props: BillingModalProps) {
  const { t } = useTranslation();
  const { profileStore, uiStore } = useStore();
  const workspace = useCurrentWorkspace();
  const [loading, setLoading] = useState(false);

  function openBillingPortal() {
    setLoading(true);
    window.setTimeout(() => {
      workspace.openBillingPortal();
    }, 2000);
  }

  return (
    <CenteredContent width="var(--md-center-width)">
      {!loading && (
        <ModalTitle>
          <Title>
            <CreditCard />
            {t('billing.title')}
          </Title>
        </ModalTitle>
      )}
      <ModalContent>
        {loading ? (
          <LoadingWrapper>
            <CircularProgress color="primary" />
            <Typography>{t('billing.redirect_to_stripe.loading')}</Typography>
          </LoadingWrapper>
        ) : (
          <>
            <Row>
              <div>
                <SubTitle>{t('billing.current_plan.title')}</SubTitle>
                <Value>
                  {t(`billing.plans.${workspace.current_plan}.name`)}
                </Value>
                <Caption onClick={() => uiStore.pushSide(<Pricing />)}>
                  <span className="link" style={{ textDecoration: 'none' }}>
                    {t('billing.current_plan.description')}
                  </span>
                </Caption>
              </div>
              {(workspace.current_plan === Plan.Trial ||
                workspace.is_stripe) && (
                <div>
                  {workspace.current_plan === Plan.Trial ? (
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => uiStore.pushSide(<Pricing />)}
                    >
                      {t('billing.subscribe')}
                    </RoundedButton>
                  ) : (
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={openBillingPortal}
                    >
                      {t('billing.manage')}
                    </RoundedButton>
                  )}
                </div>
              )}
            </Row>
            <Row>
              <div>
                <SubTitle>{t('billing.profile_count.title')}</SubTitle>
                <Value>
                  {workspace.max_profile_count !== 0
                    ? t('billing.profile_count.count_max', {
                        currentCount: profileStore.profiles.length,
                        maxCount: workspace.max_profile_count,
                      })
                    : t('billing.profile_count.count', {
                        currentCount: profileStore.profiles.length,
                      })}
                </Value>
                <Caption>
                  {(workspace.current_plan === Plan.Trial ||
                    workspace.current_plan === Plan.Team) && (
                    <Trans i18nKey="billing.profile_count.description">
                      $1
                      <span
                        onClick={() => uiStore.pushSide(<Pricing />)}
                        className="link"
                      >
                        $2
                      </span>
                    </Trans>
                  )}
                </Caption>
              </div>
              <div>
                <SubTitle>{t('billing.total_storage.title')}</SubTitle>
                <Value>
                  {t('billing.total_storage.size', {
                    size: workspace.total_storage.toFixed(1),
                    maxSize: workspace.max_storage,
                    percent: (
                      (workspace.total_storage / workspace.max_storage) *
                      100
                    ).toFixed(0),
                  })}
                </Value>
                <Caption>
                  {(workspace.current_plan === Plan.Trial ||
                    workspace.current_plan === Plan.Team) && (
                    <Trans i18nKey="billing.total_storage.description_entreprise">
                      $1
                      <span
                        onClick={() => uiStore.pushSide(<Pricing />)}
                        className="link"
                      >
                        $2
                      </span>
                    </Trans>
                  )}
                  {workspace.current_plan === Plan.Entreprise && (
                    <Trans i18nKey="billing.total_storage.description_unlimited">
                      $1
                      <span
                        onClick={() => uiStore.pushSide(<Pricing />)}
                        className="link"
                      >
                        $2
                      </span>
                    </Trans>
                  )}
                </Caption>
              </div>
            </Row>
            {workspace.current_plan === Plan.Trial && (
              <Row>
                <div>
                  <SubTitle>{t('billing.card_count.title')}</SubTitle>
                  <Value>
                    {workspace.max_card_count !== 0 &&
                      t('billing.card_count.count', {
                        currentCount: workspace.card_count,
                        maxCount: workspace.max_card_count,
                      })}
                  </Value>
                  <Caption>
                    {(workspace.current_plan === Plan.Trial ||
                      workspace.current_plan === Plan.Team) && (
                      <Trans i18nKey="billing.card_count.description">
                        $1
                        <span
                          onClick={() => uiStore.pushSide(<Pricing />)}
                          className="link"
                        >
                          $2
                        </span>
                      </Trans>
                    )}
                  </Caption>
                </div>
              </Row>
            )}
            <BillingHelp />
          </>
        )}
      </ModalContent>
      {!loading && (
        <ModalFooter>
          <BackLink popSide />
        </ModalFooter>
      )}
    </CenteredContent>
  );
}

export default BillingModal;
