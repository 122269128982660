import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function EyeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Eye-a{fill:none;}.Eye-b{fill:currentColor;}`}</style>
      </defs>
      <g transform="translate(-2753 -1564)">
        <rect
          className="Eye-a"
          width="30"
          height="30"
          transform="translate(2753 1564)"
        />
        <g transform="translate(2736.258 1583.96)">
          <path
            className="Eye-b"
            d="M30.922,2.809a13.059,13.059,0,0,1-1.5-.254,15.123,15.123,0,0,1-6.416-3.57,25.5,25.5,0,0,1-2.989-3.2A1.469,1.469,0,0,1,20.076-6a23.109,23.109,0,0,1,2.549-2.771A15.257,15.257,0,0,1,29.88-12.8a10.421,10.421,0,0,1,4.478.178,16.38,16.38,0,0,1,7.267,4.61,17.058,17.058,0,0,1,1.824,2.09,1.388,1.388,0,0,1,.191,1.373A18.767,18.767,0,0,1,40.252-.819c-2.812,2.413-5.78,3.683-8.526,3.65-.335,0-.7-.014-.8-.022ZM32.561-.121a11.219,11.219,0,0,0,5.6-2.754,24.429,24.429,0,0,0,1.99-1.908l.26-.292-.446-.486a18.671,18.671,0,0,0-3.692-3.064,11.164,11.164,0,0,0-2.784-1.219A7.454,7.454,0,0,0,30.248-9.9a12.413,12.413,0,0,0-5.339,2.991,21.8,21.8,0,0,0-1.816,1.828,19.99,19.99,0,0,0,1.653,1.709,16.4,16.4,0,0,0,3.175,2.266,9,9,0,0,0,2.914.98,9.059,9.059,0,0,0,1.726.005ZM31.053-.969A4.253,4.253,0,0,1,27.6-4.45a4.209,4.209,0,0,1,2.22-4.421,4.169,4.169,0,0,1,5.021.862,4.182,4.182,0,0,1,.2,5.528,4.215,4.215,0,0,1-3.98,1.513Zm1.2-2.611a1.7,1.7,0,0,0,.966-.826,1.646,1.646,0,0,0-1.268-2.347,1.619,1.619,0,0,0-1.811,1.648A1.631,1.631,0,0,0,32.253-3.58Z"
            transform="translate(0 0)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default EyeIcon;
