import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...(props as any)} viewBox="0 0 30 30">
      <defs>
        <style>{`.Logout-a,.Logout-b{fill:none;}.Logout-b{stroke:currentColor;stroke-linecap:round;stroke-width:2px;}`}</style>
      </defs>
      <g transform="translate(-3184 -1371)">
        <g transform="translate(310 -145)">
          <rect
            className="Logout-a"
            width="30"
            height="30"
            transform="translate(2874 1516)"
          />
        </g>
        <g transform="translate(-0.5 0.5)">
          <g transform="translate(4781.544 -1496.454) rotate(90)">
            <line
              className="Logout-b"
              y2="12.745"
              transform="translate(2882.402 1572.5)"
            />
            <line
              className="Logout-b"
              x2="4.902"
              y2="4.902"
              transform="translate(2882.402 1572.5)"
            />
            <line
              className="Logout-b"
              x1="4.902"
              y2="4.902"
              transform="translate(2877.5 1572.5)"
            />
          </g>
          <line
            className="Logout-b"
            x1="14"
            transform="translate(3190.5 1374.5)"
          />
          <line
            className="Logout-b"
            y2="23"
            transform="translate(3190.5 1374.5)"
          />
          <line
            className="Logout-b"
            x2="14"
            transform="translate(3190.5 1397.5)"
          />
          <line
            className="Logout-b"
            y2="3"
            transform="translate(3204.5 1374.5)"
          />
          <line
            className="Logout-b"
            y1="3"
            transform="translate(3204.5 1394.5)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default LogoutIcon;
