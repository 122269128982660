import { TodayRounded } from '@material-ui/icons';
import ProjectTemplateIcon from 'components/icons/ProjectTemplateIcon';
import FlexSeparator from 'components/utils/FlexSeparator';
import { observer } from 'mobx-react-lite';
import LogsModal from 'modals/custom/Logs';
import paths from 'paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import ContentsLibIcon from '../icons/ContentsLibIcon';
import NotificationsIcon from '../icons/NotificationsIcon';
import ProjectsIcon from '../icons/ProjectsIcon';
import TasksIcon from '../icons/TasksIcon';
import ClickableSidebarItem from './ClickableItem';
import SidebarItem from './Item';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;

  width: var(--sidebar-width);
  height: calc(100vh - var(--topbar-height));

  position: sticky;
  z-index: 15;
  top: 0;

  background-color: var(--color-secondary);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

const Notif = styled.div`
  position: absolute;
  height: 15px;
  width: 15px;
  top: 10px;
  right: 20px;
  z-index: 8;
  border-radius: 50%;
  background-color: var(--color-error);
`;

interface SidebarProps {}

function Sidebar(props: SidebarProps) {
  const { uiStore, customStore } = useStore();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SidebarItem to={paths.tasks.list} title={t('sidebar.tooltips.tasks')}>
        <TasksIcon color="inherit" />
      </SidebarItem>
      <SidebarItem
        to={paths.projects.list}
        title={t('sidebar.tooltips.projects')}
      >
        <ProjectsIcon color="inherit" />
      </SidebarItem>
      <SidebarItem to={paths.calendar} title={t('sidebar.tooltips.calendar')}>
        <TodayRounded color="inherit" />
      </SidebarItem>
      <SidebarItem
        to={paths.resources.list}
        title={t('sidebar.tooltips.resources')}
      >
        <ContentsLibIcon color="inherit" />
      </SidebarItem>
      <SidebarItem
        to={paths.project_templates.list}
        title={t('sidebar.tooltips.project_templates')}
      >
        <ProjectTemplateIcon color="inherit" />
      </SidebarItem>
      <FlexSeparator />
      <ClickableSidebarItem
        onClick={() => uiStore.pushSide(<LogsModal />)}
        title={t('sidebar.tooltips.notifications')}
      >
        {customStore.hasNewLog && <Notif />}
        <NotificationsIcon />
      </ClickableSidebarItem>
    </Wrapper>
  );
}

export default observer(Sidebar);
