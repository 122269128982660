import { Typography } from '@material-ui/core';
import Avatar from 'components/Avatar';
import BackLink from 'components/elements/BackLink';
import ColoredIconButton from 'components/elements/ColoredIconButton';
import Placeholder from 'components/elements/Placeholder';
import Title from 'components/elements/Title';
import DeleteIcon from 'components/icons/DeleteIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import CenteredContent from 'components/layouts/CenteredContent';
import ResourceContentDisplay from 'components/resources/ResourceContentDisplay';
import ResourceTypeIcon from 'components/resources/ResourceTypeIcon';
import { Emoji } from 'emoji-mart';
import { DateTime } from 'luxon';
import { Resource } from 'models/resources';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';
import styled from 'styled-components';
import { COLORS } from 'theme';
import downloadFile from 'utils/downloadFile';
import isDownloadable from 'utils/isDownloadable';
import { ModalFooter } from '../styles';

const Actions = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 10px;
  }
`;

const Infos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Date = styled(Typography)`
  margin-left: 10px;
  color: var(--color-light-grey);

  &:first-child {
    margin-left: 0;
  }
`;

interface ResourceDetailsModalProps {
  resourceID: number;
}

function ResourceDetailsModal(props: ResourceDetailsModalProps) {
  const { resourceStore, uiStore } = useStore();
  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState<Resource | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    resourceStore
      .loadResource(props.resourceID)
      .then((p) => {
        setResource(p);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.resourceID, resourceStore, uiStore]);

  if (loading) return null;
  if (!resource)
    return (
      <CenteredContent width="var(--md-center-width)">
        <Placeholder>
          <div style={{ marginBottom: '10px' }}>
            <Emoji
              size={64}
              emoji={t('resources.details.deleted_emoji')}
              set="apple"
            />
          </div>
          <span>{t('resources.details.deleted')}</span>
        </Placeholder>
        <ModalFooter>
          <BackLink popSide />
        </ModalFooter>
      </CenteredContent>
    );

  return (
    <CenteredContent width="var(--md-center-width)">
      <Title>
        <ResourceTypeIcon type={resource.type} />
        {resource.name}
      </Title>

      <Infos>
        {resource.authors.length !== 0 && (
          <Avatar profile={resource.authors[0]} />
        )}
        <Date>{resource.date_created.toLocaleString(DateTime.DATE_SHORT)}</Date>
        <Actions>
          {isDownloadable(resource.link) && (
            <ColoredIconButton big onClick={() => downloadFile(resource.link)}>
              <DownloadIcon />
            </ColoredIconButton>
          )}
          {!resource.is_global && (
            <ColoredIconButton
              withConfirm
              iconColor={COLORS.error}
              onClick={() => {
                resourceStore.deleteResource(resource);
                uiStore.popSide();
              }}
            >
              <DeleteIcon />
            </ColoredIconButton>
          )}
        </Actions>
      </Infos>
      <Content>
        <ResourceContentDisplay resource={resource} />
      </Content>
      <ModalFooter>
        <BackLink popSide />
      </ModalFooter>
    </CenteredContent>
  );
}

export default ResourceDetailsModal;
