import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

export interface CustomTooltipProps extends Omit<TooltipProps, 'title'> {
  title: any;
}

function Tooltip({ title, ...props }: CustomTooltipProps) {
  return <MuiTooltip {...(props as any)} title={title as string} />;
}

export default Tooltip;
