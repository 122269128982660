import { css } from 'emotion';
import { transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from 'theme';
import Tooltip from './Tooltip';

const ConfirmLink = styled.div`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`;

const Keybind = styled.div`
  display: inline-block;
  padding: 1px 5px;
  border-radius: 4px;
  margin-left: 7px;
  font-size: 0.9em;
  background-color: ${transparentize(0.4, COLORS.white)};
  color: var(--color-white);
`;

interface WithConfirmProps {
  onConfirm: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  title?: string;
  disabled?: boolean;
}

function WithConfirm(props: React.PropsWithChildren<WithConfirmProps>) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      function handleKeyDown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
          setOpen(false);
        }
        if (e.key === 'Enter') {
          props.onConfirm(e as any);
          setOpen(false);
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props, open]);

  return (
    <Tooltip
      classes={{
        tooltip: css`
          background-color: var(--color-error) !important;
        `,
      }}
      placement="right"
      interactive
      leaveDelay={100000}
      title={
        <span>
          <Trans i18nKey="commons.with_confirm">
            $1
            <ConfirmLink
              onClick={(e) => {
                props.onConfirm(e);
                setOpen(false);
              }}
            >
              $2
            </ConfirmLink>
          </Trans>
          <Keybind>{t('keybinds.enter')}</Keybind>
        </span>
      }
      open={open}
      onClose={() => setOpen(false)}
    >
      <div onClick={() => !props.disabled && setOpen(true)}>
        {props.children}
      </div>
    </Tooltip>
  );
}

export default WithConfirm;
