import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export default styled(Typography)`
  font-size: 1.3em;
  font-weight: 500;

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;
