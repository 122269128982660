import { Typography } from '@material-ui/core';
import Popup from 'components/Popup';
import { MINIMUM_WIDTH } from 'config';
import { Emoji } from 'emoji-mart';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import useWindowSize from 'utils/useWindowSize';
import Side from '../Side';

const Wrapper = styled.div``;

const MinimumWidthWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  text-align: center;

  > * {
    margin-top: 20px;
  }
`;

const BloomupLink = styled.a`
  color: var(--color-primary);
`;

const SharingStyles = createGlobalStyle`
.intercom-lightweight-app {
  display: none!important;
}
`;

interface SharingLayoutProps {}

function SharingLayout(props: SharingLayoutProps) {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  if (width && width < MINIMUM_WIDTH) {
    return (
      <MinimumWidthWrapper>
        <div>
          <Emoji size={64} emoji={t('minimum_width.emoji')} />
        </div>
        <Typography color="secondary">{t('minimum_width.title')}</Typography>
        <BloomupLink target="_blank" href="https://www.bloomup.io">
          {t('minimum_width.subtitle')}
        </BloomupLink>
      </MinimumWidthWrapper>
    );
  }

  return (
    <Wrapper>
      <SharingStyles />
      <Side />
      <Popup />
      <Outlet />
    </Wrapper>
  );
}

export default observer(SharingLayout);
